import axios from 'axios';
import React, {useState, useEffect} from 'react';



export async function getAllProducts() {
    try {
      const response = await axios.get('https://noswingcc.net/noswingcc/api/products?page=0&size=20', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('An error occurred while trying to get all products:', error);
      return null;
    }
}


  
export async function postReceivedOrder(body) {
  
  try {
    const response = await axios.post('https://noswingcc.net/noswingcc/api/receivedorders', body, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response;
  } catch (error) {
    console.error('An error occurred while creating the receivedorder:', error);
    return null;
  }                   
}
export async function postDeliveredOrder(body) {
  
  try {
    const response = await axios.post('https://noswingcc.net/noswingcc/api/deliveredorders', body, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response;
  } catch (error) {
    console.error('An error occurred while creating the deliveredorder:', error);
    return null;
  }                   
}

export async function postCranePart(part) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/craneparts', part, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the part:', error);
    return false;
  }
}

export async function getContainers() {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/containers?cranelocationId.notEquals=83&page=0&size=20`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get containers:', error);
    return null;
  }
}

export async function patchCrane(data) {
  
  try {
    await axios.patch('https://noswingcc.net/noswingcc/api/cranes/4', data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while patching crane:', error);
    return false;
  }
}

export async function postProduct(product) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/products', product, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the part:', error);
    return false;
  }
}