import React from 'react'
import { Box, Typography, Stack, Button, IconButton, Divider } from '@mui/material'
import { Link } from 'react-router-dom';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const Home = () => {
  return (
    <Box display="flex" justifyContent="center" >
        <Stack mt={3}>
        <Box ><Typography variant='h4'>Inventory Overview</Typography></Box>
        <Divider sx={{mt: 2}}/>
        <Stack direction="row" gap={2} mt={5}>
            <Box bgcolor="#fff" width="330px" p={2} borderRadius={2} sx={{border: '1px solid lightgray',}}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3'>Received</Typography>
                    <MoreVertOutlinedIcon/>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={3} gap={5} alignItems="center">
                    <Button variant='contained' component={Link} to="/wms/received" flex={1}>3 To process</Button>
                    <Typography flex={2}>1 Late</Typography>
                </Box>
            </Box>

            <Box bgcolor="#fff" width="330px" p={2} borderRadius={2} sx={{border: '1px solid lightgray',}}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3'>Delivered</Typography>
                    <MoreVertOutlinedIcon/>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={3} gap={5} alignItems="center">
                    <Button variant='contained' component={Link} to="/wms/delivered" flex={1}>4 To process</Button>
                    <Typography flex={2}>2 Late</Typography>
                </Box>
            </Box>

            <Box bgcolor="#fff" width="330px" p={2} borderRadius={2} sx={{border: '1px solid lightgray',}}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3'>Returns</Typography>
                    <MoreVertOutlinedIcon/>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={3} gap={5} alignItems="center">
                    <Button variant='contained' component={Link} to="/wms/returns" flex={1}>1 To process</Button>
                    
                </Box>
            </Box>

            {/* <Box bgcolor="#fff" width="250px" p={2} borderRadius={2} border={0.2}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3'>Scrap</Typography>
                    <MoreVertOutlinedIcon/>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={3} gap={5} alignItems="center">
                    <Button variant='contained' component={Link} to="/wms/returns" flex={1}>1 To process</Button>
                    
                </Box>
            </Box> */}
        </Stack>
        </Stack>
    </Box>
  )
}

export default Home