import { BugAntIcon, ChatBubbleLeftRightIcon, ComputerDesktopIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

export default function Support() {
  return (
    <div className="isolate  px-6 py-24 sm:py-32 lg:px-8 h-screen">
      <div className="mx-auto max-w-2xl sm:text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Need help?</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          We want you to get the most out of our software so if you don't find the answer here, please contact us.
        </p>
      </div>
      <div className="mx-auto mt-20 max-w-lg space-y-10">
        <div className="flex gap-x-6">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-indigo-600">
            <QuestionMarkCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">FAQ</h3>
            <p className="mt-2 leading-7 text-gray-600">
              Commonly asked questions and answers can be found here.
            </p>
            <p className="mt-2">
              <a href="/support/FAQ" className="text-sm font-semibold leading-6 text-indigo-600">
                Check for an answer <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>
        <div className="flex gap-x-6">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-indigo-600">
            <ChatBubbleLeftRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">Sales support</h3>
            <p className="mt-2 leading-7 text-gray-600">
              If your question is about one of our products or licenses.
            </p>
            <p className="mt-2">
              <a href="/support/Sales" className="text-sm font-semibold leading-6 text-indigo-600">
                Contact sales <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>
        <div className="flex gap-x-6">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-indigo-600">
            <ComputerDesktopIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">Technical support</h3>
            <p className="mt-2 leading-7 text-gray-600">
              If you are having technical problems contact us here.
            </p>
            <p className="mt-2">
              <a href="/support/Technical" className="text-sm font-semibold leading-6 text-indigo-600">
                Get technical help <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
