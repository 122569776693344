import React from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  IconButton,
  Stack,
  Modal,
  Button,
  CircularProgress,
} from "@mui/material";
import FileSystemNavigator from "./craneSideNav";
import Cranecard, { getCraneImage } from "./oldCraneItemCard";
import useFetch from "../../components/fetch";
import { useState } from "react";
import CraneData from "./craneItemPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Wizard from "./newCraneWizard";
import useFetchV2 from "../../components/PartsApi";
import { useParams } from "react-router-dom";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { MapPinIcon, LockClosedIcon, LockOpenIcon, PhotoIcon } from "@heroicons/react/24/outline";

import { useTranslation, Trans } from "react-i18next";
import { getFile, getFileContent } from "../../components/FilesApi";

import { toast } from 'react-toastify';

const CranePage = ({ cranesAllowed, user, isSidePanel, userRoles }) => {
  const {
    data: cranesdata,
    loading: cranesloading,
    error: craneserror,
    refetch: refetchCraneData,
  } = useFetchV2("https://noswingcc.net/noswingcc/api/cranes?page=0&size=200");
  const {
    data: craneDocs,
    loading: craneDocsloading,
    error: craneDocserror,
    refetch: refetchCraneDocs,
  } = useFetchV2("https://noswingcc.net/noswingcc/api/cranedocs?fileName.contains=craneimage&folderName.equals=image&page=0&size=200");
  const { data: useraccessdata, loading: useraccessloading, error: useraccesserror , refetch: refetchuseraccessdata} = useFetchV2(`https://noswingcc.net/noswingcc/api/craneusers?user_login.equals=${user}&page=0&size=100`);
  const [idArray, setIdArray] = useState([]);
  const [showCraneWithId, setShowCraneWithId] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const allowedCranes = cranesAllowed;
  const { t, i18n } = useTranslation();
  const [locationTitle, setLocationTitle] = useState(t("pages.cranes.allcranes"));
  const [expanded, setExpanded] = React.useState([]);
  const [allNodeIds, setAllNodeIds] = React.useState([]);
  const [craneimage, setCraneimage] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  React.useEffect(() => {
    const downloadImages = async () => {
      setImagesLoading(true);
    
      // Use map to iterate over craneDocs and download each image without waiting for all to finish
      craneDocs.forEach(async (crane) => {
        try {
          if (crane.document === null) {
            console.error("Could not load crane image for crane: " + crane.name);
            // Optionally, handle this case in the UI, e.g., by showing a placeholder
            return;
          }
    
          const image = await getFileContent(crane.document?.id);
          if (!image) {
            console.error("No data received for download for crane: " + crane.name);
            // Again, you could handle this case with UI feedback
            return;
          }
    
          const url = URL.createObjectURL(image);
          setCraneimage((prev) => [...prev, { id: crane.crane.id, url: url }]);
        } catch (error) {
          console.error("Error downloading file for crane: " + crane.name, error);
          // Handle any error in the UI if necessary
        }
      });
    
      // Since downloads are happening in parallel and updating state individually,
      // there's no single point to set loading to false. Consider alternative approaches:
      // - Track the number of outstanding requests and set loading to false when all are done.
      // - Use a separate state or indicator for each image to show loading individually.
      // - Use a global loading indicator that stays active until the last image is loaded.
      setImagesLoading(false); // This might not accurately represent the loading state now.
    };
    
    

    if (craneDocs?.length > 0) {
      console.log("cranedocs", craneDocs);
      downloadImages();
      
    }
  }, [craneDocs]);

  const { id } = useParams();

  console.log(useraccessdata)

  React.useEffect(() => {
    console.log(craneimage);
  }, [craneimage]);

  let allTheNodeIds = [];

  if (craneserror) {
    return (
      <div className="w-full h-screen flex justify-center items-center text-5xl font-semibold">
        {craneserror.message}
      </div>
    ); // or some error component
  }
  if (cranesloading) {
    return <div></div>; // or some loading spinner
  }
  //console.log(allowedCranes);
  const filterCranes = (cranes, allowedCranes) => {
    return cranes.filter((crane) => allowedCranes.includes(String(crane.id)));
  };

  //const filteredCranes = filterCranes(cranesdata, allowedCranes);
  const filteredCranes = cranesdata;
  //console.log(filteredCranes);
  const handleAddCrane = () => {
    handleOpen();
  };
  

  return (
    <Box
      display="flex"
      justifyContent={!isMobile ? "space-between" : "center"}
      pb={10}
    >
      {!isSidePanel && (
        <div className="hidden lg:flex">
          <Box
            borderRight={2}
            borderLeft={2}
            borderColor="#ECECEC"
            height="110vh"
            bgcolor={"white"}
          >
            <FileSystemNavigator
              cranesdata={cranesdata ? cranesdata : []}
              allTheNodeIds={allTheNodeIds}
              setAllNodeIds={setAllNodeIds}
              setExpanded={setExpanded}
              expanded={expanded}
              setIdArray={setIdArray}
              setShowCraneWithId={setShowCraneWithId}
              allowedCranes={allowedCranes}
              setLocationTitle={setLocationTitle}
            />
            <Box
              height="100%"
              display="flex"
              justifyContent="space-around"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                console.log("Click");
                if (expanded.length > 0) {
                  setExpanded([]);
                  //console.log(allNodeIds);
                } else {
                  setExpanded(allTheNodeIds);
                  //console.log(allTheNodeIds);
                  /*setIdArray(allowedCranes.map(Number));
                setShowCraneWithId(null);
                setLocationTitle("All Cranes");*/
                }
              }}
            ></Box>
          </Box>
        </div>
      )}

      <div className="w-full md:px-4">
        {!showCraneWithId && !id && (
          <div className="flex flex-row gap-1 justify-between items-center px-4 mt-10">
            <p className="text-lg font-semibold ">{locationTitle}</p>
            <div className="flex flex-row gap-1 items-center cursor-pointer border rounded-md px-2 mr-2" onClick={() => (userRoles?.some(role => role === "ROLE_MANAGE_CRANES") ? setOpen(true) : toast.error("You do not have permission to manage cranes"))}>
            <p className="font-semibold">New Crane</p>
            <AddOutlinedIcon sx={{ fontSize: 28 }} />
            </div>
          </div>
        )}
        {/* {showCraneWithId || id ? 
            <Box >
              <CraneData id={showCraneWithId ? showCraneWithId : id} refetchCraneData={refetchCraneData}/>
            </Box>
          : */}

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xldesktop:grid-cols-6 xl:gap-x-8 p-5 ">
          {cranesdata
            ? filteredCranes.map((item, index) =>
                idArray.length > 0 ? (
                  idArray.includes(item.id) && (
                    <div
                    key={item.id}
                    className="group aspect-h-1 aspect-w-1 relative overflow-hidden rounded-md bg-gray-100 shadow-lg "
                  >
                    {craneimage.find((craneImg) => craneImg.id === item.id) ? (
                      <img
                        src={
                          craneimage.find((craneImg) => craneImg.id === item.id)
                            ?.url
                        }
                        alt={item.name}
                        className="object-cover object-center group-hover:opacity-75"
                      />
                    ) : (
                      <div class="animate-pulse flex items-center justify-center bg-gray-300 rounded sm:w-96 dark:bg-gray-700"></div>
                    )}
                    <div className="flex flex-col justify-end">
                      <div className="bg-black bg-opacity-60 p-4 text-base sm:text-sm">
                        <a
                          href={`/cranes/${item.id}`}
                          className="font-medium text-white "
                        >
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                        <div className="flex flex-row gap-1 items-center">
                          <MapPinIcon className="w-5 h-5 text-white" />
                          <p
                            aria-hidden="true"
                            className="mt-0.5 text-white sm:mt-1 text-lg"
                          >
                            {item.country} / {item.city} / {item.location}
                          </p>
                        </div>
                      </div>
                      {useraccessdata?.filter(access => access.crane.id === item.id).length === 0 && <div className="absolute w-full h-full flex items-center justify-center ">
                      <a
                          href={`/cranes/${item.id}`}
                          className="font-medium text-white "
                        ><div className="flex flex-row gap-2 items-center px-4 py-2 rounded-full bg-gray-900 group/locked"> <p className="text-2xl font-semibold text-white ">No Access</p> <LockClosedIcon className="w-7 h-7 text-white group-hover/locked:hidden" /> <LockOpenIcon className="w-7 h-7 text-white hidden group-hover/locked:flex" /> </div></a>
                      </div>}
                    </div>
                  </div>
                  )
                ) : (
                  <div
                    key={item.id}
                    className="group aspect-h-1 aspect-w-1 relative overflow-clip rounded-md bg-gray-100 shadow-lg "
                  >
                    {craneimage.find((craneImg) => craneImg.id === item.id) ? (
                      <img
                        src={
                          craneimage.find((craneImg) => craneImg.id === item.id)
                            ?.url
                        }
                        alt={item.name}
                        className="object-cover object-center group-hover:opacity-75"
                      />
                    ) : craneDocs?.some(docs => docs.crane.id === item.id) ? (
                      <div class="animate-pulse flex bg-gray-300 rounded w-full p-5 ">
                        <CircularProgress />
                      </div>
                    ): (
                      <div class="flex bg-gray-300 rounded-lg w-full p-5 flex-col ">
                        <div className="flex flex-row gap-1 items-center">
                        <p className="font-semibold">No Image</p>
                        <PhotoIcon className="w-7 h-7 text-black" />
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col justify-end">
                      <div className="bg-black bg-opacity-60 p-4 text-base sm:text-sm">
                        <a
                          href={`/cranes/${item.id}`}
                          className="font-medium text-white "
                        >
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                        <div className="flex flex-row gap-1 items-center">
                          <MapPinIcon className="w-5 h-5 text-white" />
                          <p
                            aria-hidden="true"
                            className="mt-0.5 text-white sm:mt-1 text-lg"
                          >
                            {item.country} / {item.city} / {item.location}
                          </p>
                        </div>
                      </div>
                      {useraccessdata?.filter(access => access.crane.id === item.id).length === 0 && <div className="absolute w-full h-full flex items-center justify-center ">
                      <a
                          href={`/cranes/${item.id}`}
                          className="font-medium text-white "
                        ><div className="flex flex-row gap-2 items-center px-4 py-2 rounded-full bg-gray-900 group/locked"> <p className="text-2xl font-semibold text-white ">No Access</p> <LockClosedIcon className="w-7 h-7 text-white group-hover/locked:hidden" /> <LockOpenIcon className="w-7 h-7 text-white hidden group-hover/locked:flex" /> </div></a>
                      </div>}
                    </div>
                  </div>
                )
              )
            : "Loading..."}
        </div>
      </div>
      {/* <Cranecard loading={cranesloading} id={item.id} key={item.id} name={item.name} location={item.location} city={item.city} country={item.country} onClick={() => {setShowCraneWithId(item.id)}}/> */}

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Wizard
              openStateOfWizard={setOpen}
              refetchCraneData={refetchCraneData}
              cranesdata={cranesdata}
            />
          </Box>
        </Modal>
      </div>
    </Box>
  );
};

export default CranePage;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};