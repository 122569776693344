import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useFetch from "../../../components/fetch";
import { boxAlignments } from "@nivo/core";
import useFetchV2 from "../../../components/PartsApi";
import { EllipsisVerticalIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const PLCtags = ({ opc }) => {
  const {
    data: opcdata,
    loading: opcdataIsLoading,
    error: errorInopcData,
    refetch: refetchOpcData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/opcs?name.equals=${opc.name}&page=0&size=20`
  );

  const [intervalId, setIntervalId] = useState(null);
  
  const startPolling = () => {
    // Clear existing interval if it exists
    if (intervalId) clearInterval(intervalId);

    const id = setInterval(refetchOpcData, 1000); // Call refetchOpcData every second
    setIntervalId(id);
  };

  useEffect(() => {
    startPolling();

    return () => {
      // Clear the interval when the component unmounts
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="w-10/12 mt-10 mb-32">
      <div className="w-full" style={{ height: "600px" }}>
        {opcdata?.length > 0 && <Categories opcdata={opcdata} />}
      </div>
    </div>
  );
};

export default PLCtags;

const bitMapping = {
  xstatus1: [
    "forward-start",
    "reverse-start",
    "high/low",
    "automatstartenabled",
    "automatstart",
    "slow1",
    "slow2",
    "end1",
    "end2",
    "pos-done",
    "antisway-enabled",
    "inching-enabled",
    "drive-ready",
    "drive-running",
    "brake-open",
    "spare",
  ],
  xstatus2: [
    "drive-fault",
    "positioning-fault",
    "move-prohibited",
    "termistor-fault",
    "feedback-fault",
    "speeddeviation",
    "drive-alarm",
    "overtemp",
    "collisiondetection-active",
    "drive-emergencystop-active",
    "spare",
    "spare",
    "spare",
    "spare",
    "spare",
  ],
  xactspeed: ["speed mm/s"],
  xspeedref: ["-100 to 100"],
  xactpos: ["position in mm"],
  xtargetpos: ["position in mm"],

  y1status1: [
    "forward-start",
    "reverse-start",
    "high/low",
    "automatstartenabled",
    "automatstart",
    "slow1",
    "slow2",
    "end1",
    "end2",
    "pos-done",
    "antisway-enabled",
    "inching-enabled",
    "drive-ready",
    "drive-running",
    "brake-open",
    "spare",
  ],
  y1status2: [
    "drive-fault",
    "positioning-fault",
    "move-prohibited",
    "termistor-fault",
    "feedback-fault",
    "speeddeviation",
    "drive-alarm",
    "overtemp",
    "collisiondetection-active",
    "drive-emergencystop-active",
    "spare",
    "spare",
    "spare",
    "spare",
    "spare",
  ],
  y1actspeed: ["speed mm/s"],
  y1speedref: ["-100 to 100"],
  y1actpos: ["position in mm"],
  y1targetpos: ["position in mm"],

  y2status1: [
    "forward-start",
    "reverse-start",
    "high/low",
    "automatstartenabled",
    "automatstart",
    "slow1",
    "slow2",
    "end1",
    "end2",
    "pos-done",
    "antisway-enabled",
    "inching-enabled",
    "drive-ready",
    "drive-running",
    "brake-open",
    "spare",
  ],
  y2status2: [
    "drive-fault",
    "positioning-fault",
    "move-prohibited",
    "termistor-fault",
    "feedback-fault",
    "speeddeviation",
    "drive-alarm",
    "overtemp",
    "collisiondetection-active",
    "drive-emergencystop-active",
    "spare",
    "spare",
    "spare",
    "spare",
    "spare",
  ],
  y2actspeed: ["speed mm/s"],
  y2speedref: ["-100 to 100"],
  y2actpos: ["position in mm"],
  y2targetpos: ["position in mm"],

  z1status1: [
    "hoisting-start",
    "lowering-start",
    "high/low",
    "automatstartenabled",
    "automatstart",
    "slow1",
    "slow2",
    "end1",
    "end2",
    "pos-done",
    "inching-enabled",
    "drive-ready",
    "drive-running",
    "brake-open",
    "spare",
  ],
  z1status2: [
    "drive-fault",
    "positioning-fault",
    "move-prohibited",
    "termistor-fault",
    "feedback-fault",
    "speeddeviation",
    "overloadprotection-fault",
    "overload",
    "summary-overload",
    "drive-alarm",
    "drive-emergencystop-active",
    "overtemp",
    "spare",
    "spare",
    "spare",
    "spare",
  ],

  z2actspeed: ["speed mm/s"],
  z2actpos: ["position in mm"],
  z2targetpos: ["position in mm"],
  z2poslimitmin: ["speed mm/s"],
  z2poslimitmax: ["-100 to 100"],

  cranestatus1: [
    "emergencystop-active",
    "maincontactor-enabled",
    "fault",
    "ready for operation",
    "in operation",
    "safetygates-open",
    "fuse monitor 1",
    "fuse monitor 2",
    "fuse monitor 3",
    "voltagemonitor ok 1",
    "voltagemonitor ok 2",
  ],

  cranesafetystatus1: [
    "emergencystop 1 pressed",
    "emergencystop 2 pressed",
    "emergencystop 3 pressed",
    "emergencystop 4 pressed",
    "safetygate open 1",
    "safetygate open 2",
    "safetygate open 3",
    "safetygate open 4",
    "emergencystop reset pressed",
    "emergencystop ok",
  ],
  antiswaystatus: ["enabled", "active", "fault", "isSwinging"],
  idealrampup: ["en sekund = 100"],
  idealrampdown: ["en sekund = 100"],
  antisnagstatus: ["enabled", "active", "fault"],

  workorderdone_nr: [
    "replyes with same workordernumber as in workorder when order is done",
  ],
};

const projects = [
  {
    name: "Crane",
    initials: "C",
    href: "#",
    count: "Crane",
    bgColor: "bg-pink-600",
  },
  {
    name: "Orders",
    initials: "O",
    href: "#",
    count: "Orders",
    bgColor: "bg-yellow-500",
  },
  {
    name: "Functions",
    initials: "F",
    href: "#",
    count: "Functions",
    bgColor: "bg-green-500",
  },
  {
    name: "Y axis",
    initials: "Y",
    href: "#",
    count: "Y axis",
    bgColor: "bg-purple-600",
  },
  {
    name: "X axis",
    initials: "X",
    href: "#",
    count: "X axis",
    bgColor: "bg-purple-600",
  },
  {
    name: "Z axis",
    initials: "Z",
    href: "#",
    count: "Z axis",
    bgColor: "bg-purple-600",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const parameterCategories = {
  antisnagstatus: "Functions",
  antiswaystatus: "Functions",
  communicationstatus: "Crane",
  cranesafetystatus1: "Crane",
  cranestatus1: "Crane",
  cranestatus2: "Crane",
  distancetraveled: "Crane",
  emergencystops: "Crane",
  extrafunction1: "Functions",
  // Assuming 'id' and 'name' are not specific to any category
  idealrampdown: "Crane",
  idealrampup: "Crane",
  liftsdone: "Crane",
  // 'name' can be skipped if it's just an identifier
  overloads: "Crane",
  parameter1: "Functions",
  parameter2: "Functions",
  plcstatus: "Crane",
  runninghours: "Crane",
  snagstatus1: "Functions",
  snagstatus2: "Functions",
  tonslifted: "Crane",
  workorderdone: "Orders",
  workordernbr: "Orders",
  workorderpos1: "Orders", // Assuming this refers to a position on the X axis
  workorderpos2: "Orders", // Assuming this refers to a position on the Y axis
  workorderstatus: "Orders",
  workordertargetposx: "Orders",
  workordertargetposy: "Orders",
  workordertargetposz: "Orders",
  workordertype: "Orders",
  xactpos: "X axis",
  xactspd: "X axis",
  xbrakeopencnt: "X axis",
  xposlimitmax: "X axis",
  xposlimitmin: "X axis",
  xspdref: "X axis",
  xstatus1: "X axis",
  xstatus2: "X axis",
  xtargetpos: "X axis",
  y1actpos: "Y axis",
  y1actspd: "Y axis",
  y1brakeopencnt: "Y axis",
  y1poslimitmax: "Y axis",
  y1poslimitmin: "Y axis",
  y1spdref: "Y axis",
  y1status1: "Y axis",
  y1status2: "Y axis",
  y1targetpos: "Y axis",
  y2actpos: "Y axis",
  y2actspd: "Y axis",
  y2brakeopencnt: "Y axis",
  y2poslimitmax: "Y axis",
  y2poslimitmin: "Y axis",
  y2spdref: "Y axis",
  y2status1: "Y axis",
  y2status2: "Y axis",
  y2targetpos: "Y axis",
  ystatus1: "Y axis",
  z1actpos: "Z axis",
  z1actspd: "Z axis",
  z1brakeopencnt: "Z axis",
  z1poslimitmax: "Z axis",
  z1poslimitmin: "Z axis",
  z1spdref: "Z axis",
  z1status1: "Z axis",
  z1status2: "Z axis",
  z1targetpos: "Z axis",
  z1weight: "Z axis",
  z2actpos: "Z axis",
  z2actspd: "Z axis",
  z2brakeopencnt: "Z axis",
  z2poslimitmax: "Z axis",
  z2poslimitmin: "Z axis",
  z2spdref: "Z axis",
  z2status1: "Z axis",
  z2status2: "Z axis",
  z2targetpos: "Z axis",
  z2weight: "Z axis",
};

const processDataWithBitMapping = (key, value) => {
  if (bitMapping[key]) {
    if (bitMapping[key].length === 1) {
      // If there's only one description, return it with the whole value
      return [{ bitDescription: bitMapping[key][0], state: value?.toString() }];
    } else {
      // Convert value to binary string, pad with zeros to match the length of bitMapping
      const binaryString = parseInt(value)
        .toString(2)
        .padStart(bitMapping[key].length, '0');
      return binaryString.split('').map((bit, index) => {
        return {
          bitDescription: bitMapping[key][index],
          state: bit === '1' ? 'Yes' : 'No',
        };
      });
    }
  } else {
    // If no bitMapping information, return value as is in an array
    return [{ bitDescription: key, state: value.toString() }];
  }
};

function Categories({ opcdata }) {
  const countOccurrences = (valueToCount) => {
    return Object.values(parameterCategories).reduce((count, value) => {
      return count + (value === valueToCount ? 1 : 0);
    }, 0);
  };
  const [categorySelected, setCategorySelected] = useState("Crane");
  console.log(opcdata);
  console.log(parameterCategories);

  return (
    <div className="w-11/12">
      <h2 className="text-sm font-medium text-gray-500">
        OPC Data Categorised
      </h2>
      <div className="flex flex-row gap-10  justify-between">
      <ul
        role="list"
        className="mt-3 flex flex-col gap-5"
      >
        {projects.map((project) => (
          <div className="group flex flex-row w">
            <li
              key={project.name}
              className=" flex rounded-md shadow-sm cursor-pointer w-72"
              onClick={() => setCategorySelected(project.count)}
            >
              <div
                className={classNames(
                  project.bgColor,
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {project.initials}
              </div>
              <div className={"flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 " + (project.count === categorySelected ? "bg-gray-200" : "bg-white")}>
                <div className="flex-1 truncate px-4 py-2 text-sm ">
                  <a
                    href={project.href}
                    className="font-medium text-gray-900 hover:text-gray-600"
                  >
                    {project.name}
                  </a>
                  <p className="text-gray-500">
                    {countOccurrences(project.count)} Parameters
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent  text-gray-400 hover:text-gray-500 "
                  >
                    <span className="sr-only">Open options</span>
                    <ChevronRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </li>
            
          </div>
        ))}
      </ul>
    
      <div className=" bg-gray-100 rounded-lg p-3  mx-1 ml-16 z-10 mb-20 w-2/3">
              
              <div className="flex flex-col gap-7 ">
              {/* <div className="font-semibold">
                <p>{categorySelected}</p>
              </div> */}
                {opcdata?.map((item, index) => (
                  <div key={index}>
                    {Object.entries(parameterCategories)
                      .filter(([, category]) => category === categorySelected)
                      .map(([key]) => (
                        <div key={key} className="flex flex-row gap-2 ">
                          <p className="font-semibold text-lg w-52 mb-2">{key}:</p>
                          <div className="">
                            {bitMapping[key] ? (
                              <div className="grid grid-cols-1 bg-gray-100 p-2 rounded-md mt-1">
                                {processDataWithBitMapping(
                                key,
                                item[key]?.toString()
                              ).map((bitInfo, idx) => (
                                <div
                                  key={idx}
                                  className="font-medium flex flex-row gap-2 w-52  "
                                >
                                  <p className="font-semibold text-gray-900">{bitInfo.bitDescription}:</p>
                                  <p>{bitInfo.state}</p>
                                </div>
                              ))}
                              </div>
                            ) : item[key] !== undefined ? (
                              <p className="text-xl">{item[key]?.toString()}</p>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                
                ))}
              </div>
              
            </div>
            
      </div>
    </div>
  );
}
