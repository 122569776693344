import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  GlobeAltIcon,
  MoonIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
import { useTranslation, Trans } from "react-i18next";
import craneImg from "./NavBar/flags/result.svg";
import wmsImg from "./NavBar/flags/wms.svg";
import vrImg from "./NavBar/flags/vr.svg";
import chineese from "./NavBar/flags/chineese.svg";
import english from "./NavBar/flags/united-kingdom.svg";
import german from "./NavBar/flags/germany.svg";
import finnish from "./NavBar/flags/finland.svg";
import swedish from "./NavBar/flags/sweden.svg";
import { Box, Modal, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useFetchV2 from "../../components/PartsApi";

import { Disclosure, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { changePassword } from "../../components/UserApi";
import { getCraneImage } from "../cranesV2/oldCraneItemCard";

import { Switch } from "@headlessui/react";
import { getFile } from "../../components/FilesApi";

const callsToAction = [
  { name: "Something here", href: "#", icon: PlayCircleIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "3px",
  bgcolor: "background.paper",
  
  boxShadow: 24,
  p: 4,
};

const Navbar = ({
  user,
  setIsLogin,
  setShowNewVersion,
  version,
  toggleSidePanel,
  isSidePanel,
  setTier,
  tier,
  userRoles
}) => {
  const {
    data: cranesdata,
    loading: cranesloading,
    error: craneserror,
    refetch: refetchCraneData,
  } = useFetchV2("https://noswingcc.net/noswingcc/api/cranes?page=0&size=200");
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [mode, setMode] = useState("light");
  const navigate = useNavigate();
  console.log(pathname);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [craneimage, setCraneimage] = useState([]);
  const getNavLinkClass = (path) => {
    return (path === "/"
    ? pathname === path
    : pathname.includes(path))
      ? "inline-flex items-center border-b-2 border-nsorange-500 px-1 pt-1 text-lg font-large text-coolgray-700"
      : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-lg font-large text-coolgray-500 hover:border-coolgray-300 hover:text-coolgray-700";
  };

  const getNavLinkClassMobile = (path) => {
    return pathname === path
      ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
      : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700";
  };

  const handleSetAccess = () => {
    if (tier > 1) {
      setTier(1);
      localStorage.setItem("tier", 1);
    } else {
      setTier(2);
      localStorage.setItem("tier", 2);
    }
  };

  React.useEffect(() => {
    const downloadImages = async () => {
      for (const crane of cranesdata) {
        console.log(crane.id);
        const image = await getFile(`${crane.id}_craneimage.png`);
        console.log("Image: ", image);
        if (image) {
          const url = URL.createObjectURL(image);
          console.log(url);
          setCraneimage((prev) => [...prev, { id: crane.id, url: url }]);
        }
      }
    };

    if (cranesdata?.length > 0) {
      downloadImages();
    }
  }, [cranesdata]);

  function SignOut({ user, toggleSidePanel }) {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="">
            <span className="text-lg bg-gray-100 rounded-lg p-2">{user}</span>
            {/* <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-4 py-3">
              <p className="text-sm">Signed in as</p>
              <p className="truncate text-sm font-medium text-gray-900">
                {user}
              </p>
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={handleOpenModal}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Change password
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/support"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Support
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => {
                      toggleSidePanel(false);
                      localStorage.setItem("panel", "top");
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <div className="flex flex-row gap-1 ">
                      <div>Switch to Side Panel</div>
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleSetAccess}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <div className="flex flex-row gap-1 ">
                      <div>
                        {tier > 1 ? "CC BASIC ACCESS" : "CC FULL ACCESS"}
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        setIsLogin(false);

                        navigate("/");
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  const popoverButtonRef = React.useRef(null);

  const togglePopover = () => {
    // Simulate a click on the Popover Button
    popoverButtonRef.current?.click();
  };

  React.useEffect(() => {
    if (mode == "dark") {
      toast.info("Coming soon! // NoSwing Dev Team");
    }
  }, [mode]);

  return (
    <div className="fixed w-full z-10 ">
      <Disclosure as="nav" className="bg-white shadow ">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 ">
              <div className="flex h-20 justify-between">
                <div className="flex">
                  {/* <div className="flex shrink-0 items-center sm:flex">
                    <Image
                      src="/assets/images/LOGO.jpg"
                      width={233}
                      height={89}
                      alt="DevFlow"
                    />
                  </div> */}

                  <div className="  items-center flex md:mr-10 flex-row gap-2   ">
                    <img
                      alt="logo"
                      height="28px"
                      src={`../../assets/NSLOGOV2.png`}
                      onClick={() => setShowNewVersion(true)}
                      className="hover:cursor-pointer"
                    />
                    <div>
                      <DropDown
                        pathname={pathname}
                        version={version}
                        setShowNewVersion={setShowNewVersion}
                      />
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center ">
                    {pathname.includes("wms") ? (
                      <div className="hidden sm:ml-6 md:flex sm:space-x-8">
                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                        <a
                          href="/wms/overview"
                          className={getNavLinkClass("/wms/overview")}
                        >
                          {t(`sidebar.Overview`)}
                        </a>
                        <a
                          href="/wms/products"
                          className={getNavLinkClass("/wms/products")}
                        >
                          {t(`sidebar.Products`)}
                        </a>
                        <a
                          href="/wms/configurations"
                          className={getNavLinkClass("/wms/configurations")}
                        >
                          {t(`sidebar.Configurations`)}
                        </a>
                      </div>
                    ) : (
                      <div className="hidden sm:ml-6 md:flex sm:space-x-8">
                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                        <div>
                          <a
                            href="/cranes"
                            className={getNavLinkClass("/cranes")}
                            onMouseEnter={() => {}}
                          >
                            {t(`sidebar.Cranes`)}
                          </a>
                          {/* <RecentCranes
                            popoverButtonRef={popoverButtonRef}
                            cranes={cranesdata}
                            craneimage={craneimage}
                          /> */}
                        </div>

                        {true && <a href="/users" className={getNavLinkClass("/users")}>
                          {t(`sidebar.Users`)}
                        </a>}
                        <a
                          href="/craneparts"
                          className={getNavLinkClass("/craneparts")}
                        >
                          {"Craneparts"}
                        </a>
                        <a
                          href="/documents"
                          className={getNavLinkClass("/documents")}
                        >
                          {t(`sidebar.Documents`)}
                        </a>
                        <a
                          href="/support"
                          className={getNavLinkClass("/support")}
                        >
                          {t(`sidebar.Support`)}
                        </a>
                        <a
                          href="/api"
                          className={getNavLinkClass("/api")}
                        >
                          {"API"}
                        </a>
                        <a
                          href="/simulator"
                          className={getNavLinkClass("/simulator")}
                        >
                          {"Simulator"}
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <div className="ml-6 mr-5 flex items-center justify-end grow xs:mr-5  gap-10 ">
                  <div
                    onClick={() => setShowNewVersion(true)}
                    className="hover:cursor-pointer hover:text-gray-400"
                  >
                    Version {version}
                  </div>
                  <div
                    onClick={() =>
                      setMode((prev) => (prev === "light" ? "dark" : "light"))
                    }
                    className="flex items-center justify-center shrink-0 ml-6 hover:cursor-pointer "
                  >
                    {mode === "light" ? (
                      <MoonIcon
                        className="h-6 w-6 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <SunIcon
                        className="h-6 w-6 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="flex items-center justify-center shrink-0 mt-1">
                    <SimpleDropdown />
                  </div>
                </div>
                <div className="flex items-center justify-center shrink-0 ml-6">
                  <SignOut user={user} toggleSidePanel={toggleSidePanel} />
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <ChangePasswordForm handleCloseModal={handleCloseModal} />
                    </Box>
                  </Modal>
                </div>

                <div className="-mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              {pathname.includes("wms") ? (
                <div className="space-y-1 pb-3 pt-2">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <Disclosure.Button
                    as="a"
                    href="/wms/overview"
                    className={getNavLinkClassMobile("/wms/overview")}
                  >
                    Overview
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/wms/products"
                    className={getNavLinkClassMobile("/wms/products")}
                  >
                    Products
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/wms/configurations"
                    className={getNavLinkClassMobile("/wms/configurations")}
                  >
                    Configurations
                  </Disclosure.Button>
                </div>
              ) : (
                <div className="space-y-1 pb-3 pt-2">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <Disclosure.Button
                    as="a"
                    href="/"
                    className={getNavLinkClassMobile("/cranes")}
                  >
                    Cranes
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/store"
                    className={getNavLinkClassMobile("/store")}
                  >
                    Users
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/recepies"
                    className={getNavLinkClassMobile("/support")}
                  >
                    Support
                  </Disclosure.Button>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navbar;

function DropDown({ pathname, version, setShowNewVersion }) {
  const solutions = [
    {
      name: "Control Center",
      description: "Manage and monitor cranes",
      href: "/cranes",
      icon: craneImg,
      version: version,
    },
    {
      name: "WMS",
      description: "Warehouse management system",
      href: "/wms/overview",
      icon: wmsImg,
      version: "0.1.0",
    },
    {
      name: "Academy",
      description: "Get certified in crane operations",
      href: "/certificate",
      icon: vrImg,
    },
  ];

  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        {/* <AppsOutlinedIcon fontSize="large"/> */}
        <span className="text-xl">
          {pathname.includes("wms")
            ? "WMS"
            : pathname.includes("certificate")
            ? "Academy"
            : "Control Center"}
        </span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-6 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {solutions.map((item) => (
                <div
                  key={item.name}
                  className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white p-1">
                    <img src={item.icon} alt="crane" />
                  </div>
                  <div>
                    <a href={item.href} className="font-semibold text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <div className="w-80 flex flex-row justify-between text-center ">
                      <p className="mt-1 text-gray-600">{item.description}</p>

                      {item?.version && (
                        <h1
                          onClick={() => setShowNewVersion(true)}
                          className="invisible text-md text-gray-400 ml-4 mt-1 group-hover:visible "
                        >
                          v{item.version}
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callsToAction.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
const languages = [
  { name: "English", code: "en", icon: english },
  { name: "Swedish", code: "sv", icon: swedish },
  { name: "Finnish", code: "fi", icon: finnish },
  { name: "German", code: "de", icon: german },
  { name: "Chinese", code: "zh", icon: chineese },
];
function SimpleDropdown() {
  const { t, i18n } = useTranslation();
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-6 text-sm font-semibold leading-6 text-gray-900">
        <GlobeAltIcon
          className="h-6 w-6 hover:text-gray-500"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
          <div className="w-36 shrink rounded-lg bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {languages.map((item) => (
              <button
                key={item.name}
                className="block "
                onClick={() => {
                  i18n.changeLanguage(item.code);
                }}
              >
                <div className="flex flex-row items-center gap-2 hover:bg-gray-50 p-2 rounded-lg">
                  <img src={item.icon} className="w-6 h-6" alt="flag" />
                  {item.name}
                </div>
              </button>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ChangePasswordForm = ({ handleCloseModal }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  // async function handleDelete(login) {
  //   const success = await deleteUser(login);

  //   if (success) {
  //     // Fetch data again or do whatever is needed to reflect the deletion
  //     refetchAccounts();
  //     //console.log("DELETED : " , accountsdata);
  //     toast.success('Succefully deleted user!');
  //   }
  // }
  const passwordBody = {
    currentPassword: "string",
    newPassword: "string",
  };

  async function handleChangePassword() {
    // Perform password change logic here
    if (newPassword !== repeatPassword) {
      setPasswordMismatch(true);
      return;
    }

    if (passwordMismatch) {
      setPasswordMismatch(false);
    }

    passwordBody.currentPassword = oldPassword;
    passwordBody.newPassword = newPassword;

    const data = await changePassword(passwordBody);

    if (data === true) {
      toast.success("Succefully updated the password!");
      if (incorrectPassword) {
        setIncorrectPassword(false);
      }
    } else {
      if (data === "Incorrect password") {
        setIncorrectPassword(true);
      }
      toast.error("Could not update the password!");
      return;
    }

    // Reset form fields
    setOldPassword("");
    setNewPassword("");
    setRepeatPassword("");

    handleCloseModal();
  }

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <TextField
        error={incorrectPassword}
        helperText={incorrectPassword && "Incorrect password."}
        label="Old Password"
        type="password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <TextField
        label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        error={passwordMismatch}
        helperText={passwordMismatch && "Passwords does not match."}
        label="Repeat New Password"
        type="password"
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
      />
      <Button variant="contained" onClick={handleChangePassword}>
        Change Password
      </Button>
    </Box>
  );
};

// function RecentCranes({ popoverButtonRef, cranes, craneimage }) {
//   return (
//     <div>
//       <Popover className="relative">
//         <Popover.Button
//           className="hidden"
//           ref={popoverButtonRef}
//         ></Popover.Button>

//         <Transition
//           as={Fragment}
//           enter="transition ease-out duration-200"
//           enterFrom="opacity-0 translate-y-1"
//           enterTo="opacity-100 translate-y-0"
//           leave="transition ease-in duration-150"
//           leaveFrom="opacity-100 translate-y-0"
//           leaveTo="opacity-0 translate-y-1"
//         >
//           <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
//             <div className="w-screen max-w-sm flex-auto rounded-lg bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
//               <p className="ml-2 mb-5">Recently viewed cranes</p>
//               {cranes?.map((crane) => (
//                 <div
//                   key={crane.name}
//                   className="relative rounded-lg p-2 pb-0 hover:bg-gray-50 flex flex-row gap-3 "
//                 >
//                   <div className="w-20 h-20 rounded-lg overflow-hidden">
//                     <img
//                       style={{ borderRadius: "5px" }}
//                       src={craneimage.find((craneImg) => craneImg.id === crane.id)
//                             ?.url || ""}
//                       alt="craneimg"
//                     />
//                   </div>
//                   <div className="flex flex-col">
//                     <a
//                       href={`/cranes/${crane.id}`}
//                       className="font-semibold text-gray-900"
//                     >
//                       {crane.name}
//                       <span className="absolute inset-0" />
//                     </a>
//                     <p className="mt-1 text-gray-600">
//                       {crane.country} / {crane.city} / {crane.location}
//                     </p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </Popover.Panel>
//         </Transition>
//       </Popover>
//     </div>
//   );
// }
