import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  CubeIcon,
  HomeIcon,
  PhotoIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  UsersIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import * as THREE from "three";
import { OBJLoader, MTLLoader } from "three-stdlib";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import {
  get3DFile,
  getFileMimeType,
  postFile,
  postFileDataToDatabase,
  updateFile,
  updateModelconf,
  uploadContent,
  uploadDocument,
} from "../../../../components/FilesApi";

import beem from "./images/beem.svg";
import trolley from "./images/trolley.svg";
import hook from "./images/hook.svg";
import grabber from "./images/grabber.svg";
import building from "./images/building.svg";
import { CircularProgress, LinearProgress } from "@mui/material";
import useFetchV2 from "../../../../components/PartsApi";
import { uploadfile } from "../documents";

const models = [
  {
    id: 1,
    name: "Building",
    icon: building,
    profileUrl: "#",
  },
  {
    id: 2,
    name: "Beem",
    icon: beem,
    profileUrl: "#",
  },
  {
    id: 3,
    name: "Trolley",
    icon: trolley,
    profileUrl: "#",
  },
  {
    id: 4,
    name: "Hook",
    icon: hook,
    profileUrl: "#",
  },
  {
    id: 5,
    name: "Grabber",
    icon: grabber,
    profileUrl: "#",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Upload3D({
  open,
  setOpen,
  obj,
  setStep,
  setObjFiles,
  setFilePaths,
  load,
  id,
  existingDocs,
  refetchModels,
  refetchModelconf,
  modelconf,
}) {
  const [query, setQuery] = useState("");
  const filteredPeople = models;
  const [activePerson, setActivePerson] = useState(models[0]);
  const cancelButtonRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [optionMarked, setOptionMarked] = useState({});
  const [isNoFileSelected, setIsNoFileSelected] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileContainsGrabber, setFileContainesGrabber] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesLoaded, setFilesLoaded] = useState(0);
  const [confFile, setConfFile] = useState(null);
  const [isConfDataUpToDate, setIsConfDataUpToDate] = useState(true);

  const {
    data,
    loading: dataIsLoading,
    error: dateError,
    refetch: refetchAllData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&folderName.equals=3D&page=0&size=50`
  );

  
  function readFileAsText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
  const storeConfData = async (modelconf) => {
        
    
    const body = {
      id: modelconf[0].id,
      ...confFile,

      crane: {
        id: id,
      },
    };

    console.log("body ", body);

    const post = await updateModelconf(modelconf[0].id, body);
    if (!post) {
      toast.error("Error updating configuration");
      return;
    }
    else {
      toast.success("Configuration updated");
    }

    refetchModelconf();
  }

  async function preRender(mtlFile, objFile) {
    try {
      const [mtlText, objText] = await Promise.all([
        readFileAsText(mtlFile),
        readFileAsText(objFile),
      ]);

      const mtlLoader = new MTLLoader();
      const mtl = mtlLoader.parse(mtlText);
      mtl.preload();

      const objLoader = new OBJLoader();
      objLoader.setMaterials(mtl);
      const obj = objLoader.parse(objText);

      return obj; // This is a THREE.Group object
    } catch (error) {
      console.error("Error loading files:", error);
      return null;
    }
  }
  React.useEffect(() => {
    console.log("Files: ", files);
    if (files.length > 0) {
      for (const model of models) {
        console.log(model.name);
        if (files.filter((item) => item.name.includes(model.name)).length > 0) {
          console.log(files.filter((item) => item.name.includes(model.name)));
          const obj = files.find(
            (item) =>
              item.name.includes(model.name) && item.name.includes(".obj")
          );
          const mtl = files.find(
            (item) =>
              item.name.includes(model.name) && item.name.includes(".mtl")
          );

          if (obj && mtl) {
            preRender(mtl, obj).then((obj) => {
              if (obj) {
                setUploadedFiles((prev) => {
                  return { ...prev, [model.name]: obj };
                });
              }
            });
          } else {
            toast.error(
              `Error loading ${model.name}, either .obj or .mtl file is missing`
            );
          }
        }
      }
      let file = files.find((item) => item.name.includes("ConfData"));
      if (file) {
        console.log(file);
        const reader = new FileReader();

        reader.onload = function(e) {
          // Assuming e.target.result is the JSON string
          const content = e.target.result;
  
          // Parse the JSON string to an object
          const data = JSON.parse(content);
  
         
          console.log("Processed Data: ", data);
          setConfFile(data);
      };
  
      // Read the file content as text
      reader.readAsText(file);
        
      }
    }
  }, [files]);

  React.useEffect(() => {
    console.log("Uploaded Files: ", uploadedFiles);
  }, [uploadedFiles]);

  function parseFileContent(content) {
    const lines = content.split("\n"); // Split the content into lines
    const data = {};

    lines.forEach((line) => {
      const [key, value] = line.split(":").map((part) => part.trim());
      if (key && value) {
        data[key] = value;
      }
    });

    console.log(data);
    // Now 'data' is an object with keys and values extracted from the file
  }

  const isItemSelected = (item) => {
    return optionMarked.name == item.name && optionMarked.type == item.type;
  };
  const isTypeSelected = (type) => {
    return selectedItems[type] !== undefined;
  };
  const handleSelectItem = (item) => {
    setSelectedItems((prevItems) => {
      // Create a new object based on the previous state
      const newItems = { ...prevItems };

      // If the item of the same type is already selected, remove it
      if (newItems[item.type] === item.name) {
        delete newItems[item.type];
      } else {
        // Update the selected item for the given type
        newItems[item.type] = item.name;
      }
      //console.log("New Items:", newItems);
      return newItems;
    });
  };

  const uploadAFile = async (body, file, fileName) => {
    const readFileAsBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
  
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };
  
  
      try {
        if (!file) {
          throw new Error("No file provided");
        }
  
        const type = file.name.split(".");
        const base64 = await readFileAsBase64(file);
  
       
  
        const fileMimeType = getFileMimeType(fileName);
        
        // I'm assuming uploadContent and other functions are defined elsewhere in your code
        try {
          toast.info("Uploading file...");
          
          await uploadContent({
            dataContentType: fileMimeType,
            data: base64,
          })
          .then((content) =>
            uploadDocument({
              title: fileName,
              documentSize: file.size,
              mimeType: fileMimeType,
              content: content,
              craneRelated: true,
            })
          )
          .then((document) =>
            postFile({
              ...body,
              document: document,
            })
          )
          .catch((error) => {
            toast.error(error.message || "Error during upload");
          });
  
          toast.success("File uploaded successfully!");
          return true;
        } catch (error) {
          toast.error("Something went wrong with the upload...");
          console.error(error);
          return false;
          
        }
  
      } catch (error) {
        console.error(error);
        toast.error("Failed to upload image");
      }
  
   
  }
  const updateAFile = async (body, cranedocID, file) => {
    console.log("Updating file: ", body, cranedocID, file);
    
    if(body.fileName.includes("image")){
      toast.info("Got image?");
      return false;
    }
    const readFileAsBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
  
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };
  
  
      try {
        if (!file) {
          throw new Error("No file provided");
        }
  
        const type = file.name.split(".");
        const base64 = await readFileAsBase64(file);
  
       
  
        const fileMimeType = getFileMimeType(body.fileName);
        
        // I'm assuming uploadContent and other functions are defined elsewhere in your code
        try {
         
          
          await uploadContent({
            dataContentType: fileMimeType,
            data: base64,
          })
          .then((content) =>
            uploadDocument({
              title: id + "_" + body.fileName,
              documentSize: file.size,
              mimeType: fileMimeType,
              content: content,
              craneRelated: true,
            })
          )
          .then((document) =>
            updateFile({
              ...body,
              document: document,
            }, cranedocID)
          )
          .catch((error) => {
            toast.error(error.message || "Error during upload");
          });
  
          toast.success("File updated successfully! - ", body.fileName);
          return true;
        } catch (error) {
          toast.error("Something went wrong with the upload...");
          console.error(error);
          return false;
          
        }
  
      } catch (error) {
        console.error(error);
        toast.error("Failed to upload file - ", body.fileName);
        return false;
      }

  }


  const handleSave = async (event) => {
    for (const model of models) {
      if (uploadedFiles[model.name]) {
        console.log("OK!");
        // let pathsObject = {};
        // const path = `${model.name}_${selectedFile.example}`;
        //  pathsObject[type] = path;
        //  setFilePaths(pathsObject);
      } else if (fileContainsGrabber && model.name === "Grabber") {
        console.log("Ok");
      } else {
        toast.error("Missing files");
        setIsNoFileSelected(true);
        return;
      }
    }
    const sendFiles = async (data) => {

      toast.info("Uploading files, this may take up to 30 seconds, please wait for confirmation...");
      for (const model of models) {
        if (
          uploadedFiles[model.name] &&
          !(fileContainsGrabber && model.name === "Grabber")
        ) {
          console.log("OK!");
          // let pathsObject = {};
          // const path = `${model.name}_${selectedFile.example}`;
          //  pathsObject[type] = path;
          //  setFilePaths(pathsObject);
          let bodyObj = {
            id: "",
            fileName: `${model.name}.obj`,
            folderName: "3D",
            dbPath: `${id}_${model.name}`,
            crane: { id: id },
          };
          console.log(bodyObj);
          let file = files.find(
            (item) =>
              item.name.includes(model.name) && item.name.includes(".obj")
          );
          try {
            console.log(data);
            if (data?.find((item) => item.fileName == `${model.name}.obj`)) {
              bodyObj.id = data.find(
                (item) => item.fileName == `${model.name}.obj`
              ).id;
              const update = await updateAFile(
                bodyObj,
                data.find((item) => item.fileName == `${model.name}.obj`).id,
                file
              );
              if (!update) {
                toast.error(`Error updating ${model.name}.obj`);
              } else {
                //toast.success(`${type}.obj updated`);
              }
            } else {
              
              const storeFile = await uploadAFile(
                bodyObj,
                file,
                `${id}_${model.name}.obj`
              );
              if (!storeFile) {
                throw Error;
              }
              // const uploadObj = await postFile(bodyObj);
              // console.log(`${model.name}.obj uploaded:`, uploadObj);
            }
          } catch (error) {
            console.error(`Error uploading ${model.name}.obj:`, error);
            return false;
          }

          let bodyMtl = {
            id: "",
            fileName: `${model.name}.mtl`,
            folderName: "3D",
            dbPath: `${id}_${model.name}`,
            crane: { id: id },
          };
          file = files.find(
            (item) =>
              item.name.includes(model.name) && item.name.includes(".mtl")
          );

          try {
            console.log(data);
            if (data?.find((item) => item.fileName == `${model.name}.mtl`)) {
              bodyMtl.id = data.find(
                (item) => item.fileName == `${model.name}.mtl`
              ).id;
              const update = await updateAFile(
                bodyMtl,
                data.find((item) => item.fileName == `${model.name}.mtl`).id,
                file
              );
              if (!update) {
                toast.error(`Error updating ${model.name}.mtl`);
              } else {
                //toast.success(`${type}.mtl updated`);
              }
            } else {
              
              const storeFile = await uploadAFile(
                bodyMtl,
                file,
                `${id}_${model.name}.obj`
              );
              if (!storeFile) {
                throw Error;
              }
              // const uploadmtl = await postFile(bodyMtl);
             // console.log(`${model.name}.mtl uploaded:`, uploadmtl);
            }
          } catch (error) {
            console.error(`Error uploading ${model.name}.mtl:`, error);
            return false;
          }
        }
      }
      return true;
    };
    console.log("test");
    const success = await sendFiles(data);
    if (success) {
      
      if(confFile){
        storeConfData(modelconf);
      }
      setOpen(false);
      setStep(1);
      refetchModels();
    }

    //   // processFiles(pathsObject, id, data);
  };

  function isEqual(obj1, obj2, excludeKeys = []) {
    // Create copies of the objects with the excluded keys removed
    const obj1Filtered = Object.entries(obj1).reduce((acc, [key, value]) => {
      if (!excludeKeys.includes(key)) {
        acc[key] = value;
      }
      return acc;
    }, {});
  
    const obj2Filtered = Object.entries(obj2).reduce((acc, [key, value]) => {
      if (!excludeKeys.includes(key)) {
        acc[key] = value;
      }
      return acc;
    }, {});
  
    // Perform deep comparison
    const keys1 = Object.keys(obj1Filtered);
    const keys2 = Object.keys(obj2Filtered);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      const val1 = obj1Filtered[key];
      const val2 = obj2Filtered[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !isEqual(val1, val2, excludeKeys)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
  
    return true;
  }
  
  function isObject(object) {
    return object != null && typeof object === 'object';
  }
  React.useEffect(() => {
    if(modelconf.length > 0 && confFile){
      let equal = isEqual(modelconf[0], confFile, ["id", "crane"]);
      console.log("Equal: ", equal);
      if(!equal){
        setIsConfDataUpToDate(false);
      }
      else{
        setIsConfDataUpToDate(true);
      }
      
    }
  }, [modelconf, confFile])
  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gray-100">
                    <CubeIcon
                      className="h-16 w-16 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Upload 3D Models
                    </Dialog.Title>
                    <div className="my-10">
                      {/* {filesLoaded !== fakeFiles.length && (
                        <div className="mb-5 animate-pulse h-20 w-120">
                          <span className="font-semibold text-lg ">
                            {filesLoaded} / {fakeFiles.length} models downloaded
                          </span>
                        </div>
                      )} */}
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        {files?.length === 0 ? (
                          <div className="text-center">
                            <DocumentIcon
                              className="mx-auto h-12 w-12 text-gray-300"
                              aria-hidden="true"
                            />
                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="px-1 relative cursor-pointer rounded-md bg-nsorange-100 font-semibold text-nsorange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-nsorange-500 focus-within:ring-offset-2 hover:text-nsorange-500"
                              >
                                <span>Upload all files</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  multiple={true}
                                  onChange={(event) => {
                                    if (
                                      event.target.files &&
                                      event.target.files[0]
                                    ) {
                                      const file = event.target.files;

                                      // You can now use this file object as needed
                                      setFiles(Array.from(event.target.files));
                                      // setCraneimage(URL.createObjectURL(file));
                                      // setUploadedImage(file);
                                    }
                                  }}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs leading-5 text-gray-600 w-52 mt-2 ">
                              OBJ and MTL files, named as the model type
                              "Beem.obj and Beem.mtl"
                            </p>
                          </div>
                        ) : (
                          <div className="flex flex-col gap-2">
                            <p className="font-semibold">Files uploaded:</p>
                            <div className="grid grid-cols-2 gap-4">
                              {files.length > 0 &&
                                files?.map((item, index) => (
                                  <p key={index} className="text-left">
                                    {item.name}
                                  </p>
                                ))}
                            </div>
                            {!isConfDataUpToDate && <button
                              className="mx-auto mt-4 px-4 py-2 bg-nsorange-500 rounded-full text-white"
                              onClick={() => {
                                if(confFile){
                                  storeConfData(modelconf);
                                }
                              }}
                            >
                              UPDATE CONF
                            </button>}
                            <button
                              className="mx-auto mt-4 px-4 py-2 bg-nsorange-500 rounded-full text-white"
                              onClick={() => {
                                setFiles([]);
                                setUploadedFiles([]);
                              }}
                            >
                              CLEAR
                            </button>
                          </div>
                        )}
                      </div>
                      <Combobox
                        value={activePerson}
                        onChange={(person) => setActivePerson(person)}
                      >
                        {({ activeOption }) => (
                          <>
                            {models.length > 0 && (
                              <Combobox.Options
                                as="div"
                                static
                                hold
                                className="flex transform-gpu divide-x divide-gray-100"
                              >
                                <div
                                  className={classNames(
                                    "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                                    activeOption && "sm:h-96"
                                  )}
                                >
                                  <div className="-mx-2 text-sm text-gray-700">
                                    {models.map((person) => (
                                      <Combobox.Option
                                        as="div"
                                        key={person.id}
                                        value={person}
                                        className={({ active }) =>
                                          classNames(
                                            "flex cursor-default select-none items-center rounded-md p-2",
                                            active &&
                                              "bg-gray-100 text-gray-900"
                                          )
                                        }
                                      >
                                        {({ active }) => (
                                          <>
                                            <img
                                              src={person.icon}
                                              alt="icon"
                                              className="h-6 w-6 flex-none"
                                            />
                                            <div className="flex-auto truncate flex-row gap-2">
                                              <span className="ml-3 ">
                                                {person.name}
                                              </span>
                                              <span className="ml-3  text-gray-400">
                                                {/* {`(${
                                                 uploadedFiles[person.name] ? 1 : 0
                                                })`} */}
                                              </span>
                                            </div>
                                            {uploadedFiles[person.name] ||
                                            (person.name == "Grabber" &&
                                              fileContainsGrabber) ? (
                                              <CheckCircleIcon className="ml-3 h-5 w-5 flex-none text-green-500" />
                                            ) : (uploadedFiles.length > 0 &&
                                                !uploadedFiles[person.name]) ||
                                              isNoFileSelected ? (
                                              <ExclamationCircleIcon className="ml-3 h-5 w-5 flex-none text-red-500" />
                                            ) : (
                                              <QuestionMarkCircleIcon className="ml-3 h-5 w-5 flex-none text-gray-500" />
                                            )}

                                            {active && (
                                              <ChevronRightIcon
                                                className="ml-3 h-5 w-5 flex-none text-gray-400"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </div>
                                </div>

                                {activeOption && (
                                  <div className="hidden w-1/2 flex-none flex-col divide-y divide-gray-100  sm:flex">
                                    <div className="flex-none p-6 text-center ">
                                      <div className="flex justify-center">
                                        <div className="w-full h-52 flex justify-center items-center">
                                          {uploadedFiles[activeOption.name] ? (
                                            <div className="w-full h-full hover:cursor-grab focus:cursor-pointer">
                                              <ObjViewer
                                                obj={uploadedFiles[
                                                  activeOption.name
                                                ].clone()}
                                                type={activeOption.name}
                                              />
                                            </div>
                                          ) : (
                                            <span className="text-lg font-bold">
                                              No file available
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <h2 className="mt-3 font-semibold text-gray-900">
                                        {optionMarked.name}
                                      </h2>
                                      <p className="text-sm leading-6 text-gray-500">
                                        Model preview
                                      </p>
                                    </div>
                                    <div className="flex  flex-col justify-between p-6 h-24">
                                      {activeOption.name == "Grabber" &&
                                      fileContainsGrabber &&
                                      uploadedFiles["Hook"] ? (
                                        <span className="text-green-500">
                                          Hook model already contains the
                                          grabber.
                                        </span>
                                      ) : (
                                        <dl className="grid grid-cols-1 gap-x-9 gap-y-3 text-sm text-gray-700 ">
                                          {activeOption.name}
                                        </dl>
                                      )}
                                      {/* <div className="w-full flex justify-center">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleSelectItem(optionMarked)
                                          }
                                          className="mt-6 w-40 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                          Select
                                        </button>
                                      </div> */}
                                      {activeOption.name === "Hook" && (
                                        <form>
                                          <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                              <input
                                                id="grabber"
                                                aria-describedby="grabber-description"
                                                name="grabber"
                                                type="checkbox"
                                                checked={fileContainsGrabber}
                                                onChange={(event) =>
                                                  setFileContainesGrabber(
                                                    event.target.checked
                                                  )
                                                }
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                              />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                              <label
                                                htmlFor="twobeems"
                                                className="font-medium text-gray-900"
                                              >
                                                3D model contains a grabber
                                              </label>
                                            </div>
                                          </div>
                                        </form>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Combobox.Options>
                            )}
                          </>
                        )}
                      </Combobox>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className={
                      "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 "
                    }
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const ObjViewer = ({ obj, type }) => {
  const [target, setTarget] = useState(null);
  const orbitControlsRef = useRef();
  const [theType, setTheType] = useState("");
  // Function to reset OrbitControls
  const resetOrbitControls = () => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.reset();
    }
  };

  React.useEffect(() => {
    if (obj) {
      console.log("Object loaded:");
      obj.rotation.set(0, 0, 0);
      obj.position.set(0, 0, 0);
      obj.scale.set(1, 1, 1);
      if (type == "Building") {
        console.log("Building");
        // Apply rotation directly to the object
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, -400, 0);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z - 200]);
      } else if (type == "Beem") {
        // Apply rotation directly to the object
        console.log("Beem");
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, 0, 0);
        obj.scale.set(0.1, 0.1, 0.1);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z]);
      } else {
        console.log("Other");
        // Apply rotation directly to the object
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, 0, 0);
        obj.scale.set(3, 3, 3);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z]);
      }
      if (type != theType) {
        resetOrbitControls();
        setTheType(type);
      }
    }
    return () => {
      // setTarget(null);
    };
  }, [obj]);

  return (
    <Canvas className="w-full h-full">
      <ambientLight intensity={0.3} />
      <directionalLight position={[0, 1, 0]} intensity={1} />
      <OrbitControls
        ref={orbitControlsRef}
        target={target}
        autoRotate={true}
        autoRotateSpeed={0.9}
      />
      {/* <axesHelper args={[5]} /> */}
      <group>
        {target !== null && obj && (
          <mesh position={[0, 0, 0]}>
            <primitive object={obj} />
          </mesh>
        )}
      </group>
    </Canvas>
  );
};

const ObjModel = ({ obj }) => {
  const { scene } = useThree();

  React.useEffect(() => {
    if (obj) {
      // Display the bounding box
      const boundingBoxHelper = new THREE.Box3Helper(
        boundingBox(obj),
        0xff0000
      ); // Red box
      scene.add(boundingBoxHelper);
      //scene.add(obj);
      return () => {
        scene.remove(obj);
      };
    }
  }, [obj, scene]);

  return null;
};

function boundingBox(object) {
  const box = new THREE.Box3();
  box.setFromObject(object);
  return box;
}
