import React from 'react'
import { Box, Stack, Typography, IconButton, Autocomplete, TextField, MenuItem, FormControl, Select, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';

function StatusAutocomplete() {
    const options = ['draft', 'waiting', 'ready', 'done'];
  
    return (
      <Box width={130}>
    
        <Autocomplete
          options={options}
          defaultValue="ready"
          renderInput={(params) => (
            <TextField {...params} label="Status" variant="outlined" fullWidth />
          )}
        />
      </Box>
    );
  }
const DeliveredOrderinfo = () => {
    const [timespan, setTimespan] = React.useState("Ready");
  return (
    <Box display="flex" justifyContent="center">
            <Stack>
            
            <Box p={4} boxShadow={1} bgcolor="white" borderRadius={1} width="1100px" mt={5} height={560}>
                
                <Stack direction="row" display="flex" justifyContent="space-between">
                    <Box display="flex" justifyContent="space-between" gap={10}>
                        <Stack>
                            <Typography variant="h5">Order reference</Typography>
                            <Typography variant="h4" mt={1}>FG03</Typography>
                        </Stack>

                        <Box>
                        <Box flex={2}>
                            <Typography>Status</Typography>
                            <FormControl variant="standard" sx={{ minWidth: 150, margin: 2 }}>
                            <Select
                            value={timespan}
                            onChange={e => setTimespan(e.target.value)}
                            inputProps={{ disableUnderline: true }}
                            renderValue={(selected) => (
                                <span style={{ fontWeight: '500', fontSize: '18px' }}>{selected}</span>
                            )}
                            >   
                            <MenuItem value="Draft">Draft</MenuItem>
                            <MenuItem value="Waiting">Waiting</MenuItem>
                            <MenuItem value="Ready">Ready</MenuItem>
                            <MenuItem value="Done">Done</MenuItem>
                            </Select>
                            </FormControl>
                        </Box>
                         </Box>
                    
                    </Box>
                    

                    <Box display="flex" alignItems="center" gap={2} >
                        {/* Print Icon with Text */}
                        <Box display="flex" alignItems="center" gap={0}>
                            <IconButton color="primary">
                                <PrintIcon />
                            </IconButton>
                            <Typography variant="body1">Print</Typography>
                        </Box>

                        {/* Settings Icon with Text */}
                        <Box display="flex" alignItems="center" gap={0}>
                            <IconButton color="primary">
                                <SettingsIcon />
                            </IconButton>
                            <Typography variant="body1">Action</Typography>
                        </Box>
                    </Box>
                </Stack>

                <Box mt={10} display="flex" justifyContent="space-between">
                    <Stack direction="row" gap={4}>
                        <Typography fontWeight="bold">Recieved From</Typography>
                        <Typography >STA</Typography>
                    </Stack>
                    <Stack direction="column" mr={10}>
                        <Stack direction="row" gap={4}>
                            <Typography fontWeight="bold">Scheduled Date</Typography>
                            <Typography >10/08/2023 </Typography>
                        </Stack>
                        <Stack direction="row" gap={4}>
                            <Typography fontWeight="bold">Source Document</Typography>
                            <Typography >-</Typography>
                        </Stack>
                    </Stack>
                </Box>

                <Box mt={2} display="flex" gap={4} >
                    <Box flex={1} mt={2}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: "#ebeced" }}>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Product</Typography>
                                </th>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Demand</Typography>
                                </th>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Done</Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}>Steel Rectangular Tube </Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}>3</Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}>0</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}>Steel Square Bar</Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}>5</Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}>0</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography  ml={1}></Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>

                
                        
                </Box>
                <Box display="flex" gap={2} justifyContent="flex-end" mt={4}>
                    <Button variant='outlined' component={Link} to="/wms/received">Close</Button>
                    <Button variant='contained'>Save</Button>
                </Box>

             
            </Box>
            </Stack>
        </Box>
  )
}

export default DeliveredOrderinfo;