import axios from 'axios';
import React, {useState, useEffect} from 'react';

export async function deleteUser(login) {
  if (window.confirm(`Do you really want to delete "${login.toUpperCase()}" ?`)) {
    try {
      await axios.delete(`https://noswingcc.net/noswingcc/api/admin/users/${login}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });

      return true;
    } catch (error) {
      console.error('An error occurred while deleting the item:', error);
      return false;
    }
  }

  return false;
}

export async function getAllUsers() {
    try {
      const response = await axios.get('https://noswingcc.net/noswingcc/api/admin/users?page=0&size=20', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('An error occurred while trying to get all users:', error);
      return null;
    }
}

export async function getCurrentUser() {
  try {
    const response = await axios.get('https://noswingcc.net/noswingcc/api/account', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get current user:', error);
    return null;
  }
}
  
export default function useFetchV2(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUrl = async () => {
      try {
        const res = await axios.get(url, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Accept': '*/*'
          }
        });
          setData(res.data);
          setLoading(false);
      } catch (error) {
          setError(error);
          setLoading(false);
      }
  };

  useEffect(() => {
      fetchUrl();
  }, [url]);

  return { data, loading, error, refetch: fetchUrl };
}

export async function updateUser(userData) {
  
  try {
    await axios.put('https://noswingcc.net/noswingcc/api/admin/users', userData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while updating the user:', error);
    return false;
  }
}

export async function createUser(userData) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/register', userData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the user:', error);
    return false;
  }
}

export async function changePassword(password) {
  
  try {
    const res = await axios.post('https://noswingcc.net/noswingcc/api/account/change-password', password, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while changing password: ', error.response.data.title);
    return error.response.data.title;
  }
}

export async function createUserAccess(userData) {
  
  try {
    const response = await axios.post('https://noswingcc.net/noswingcc/api/craneusers', userData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response;
  } catch (error) {
    console.error('An error occurred while creating the userAccess:', error);
    return false;
  }
}
export async function updateUserAccess(id, userData) {
  
  try {
    const response = await axios.put(`https://noswingcc.net/noswingcc/api/craneusers/${id}`, userData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response;
  } catch (error) {
    console.error('An error occurred while updating the userAccess:', error);
    return false;
  }
}
export async function deleteUserAccess(id) {
  
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/craneusers/${id}`,  {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while deleting the userAccess:', error);
    return false;
  }
}