//https://noswingcc.net/noswingcc/api/files
import axios from 'axios';
import React, {useState, useEffect} from 'react';

export async function get3DFile(fileName) {
    try {
      const response = await axios.get(`https://noswingcc.net/noswingcc/api/files/${fileName}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return response.data;
    } catch (error) {
      //console.error('An error occurred while trying to get the file:', error);
      return false;
    }
  }
  export async function deleteFileFromDB(fileName) {
    try {
      const response = await axios.delete(`https://noswingcc.net/noswingcc/api/files/${fileName}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      //console.error('An error occurred while trying to get the file:', error);
      return false;
    }
  }
  export async function getFile(fileName) {
    console.log("filename: ", fileName);
    try {
      const response = await axios.get(`https://noswingcc.net/noswingcc/api/files/${fileName}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
        responseType: 'blob' // Specify that the response should be a blob
      });
  
      return response.data; // This will be a blob
    } catch (error) {
      console.error('Error fetching file:', error);
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error Data:', error.response.data);
          console.error('Error Status:', error.response.status);
          console.error('Error Headers:', error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
      } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error Message:', error.message);
      }
      return null;
  }
  }
  export async function getFileContent(id) {
  console.log(id);
    try {
      const response = await axios.get(`https://noswingcc.net/noswingcc/api/documents/${id}/$content`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
        responseType: 'blob' // Specify that the response should be a blob
      });
  
      return response.data; // This will be a blob
    } catch (error) {
      console.error('Error fetching file:', error);
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error Data:', error.response.data);
          console.error('Error Status:', error.response.status);
          console.error('Error Headers:', error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
      } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error Message:', error.message);
      }
      return null;
  }
  }
  
export async function postFile(body) {
  
    try {
      await axios.post('https://noswingcc.net/noswingcc/api/cranedocs', body, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('Error fetching file:', error);
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error Data:', error.response.data);
          console.error('Error Status:', error.response.status);
          console.error('Error Headers:', error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
      } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error Message:', error.message);
      }
      return null;
  }
}
  export async function updateFile(body, id) {
    //console.log("body: ", body, "id: ", id);
  
    try {
      await axios.put(`https://noswingcc.net/noswingcc/api/cranedocs/${id}`, body, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while updating file:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error Data:', error.response.data);
        console.error('Error Status:', error.response.status);
        console.error('Error Headers:', error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
    } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error Message:', error.message);
    }
      return error;
    }
  }
  export async function deleteFile( id) {
  
    try {
      await axios.delete(`https://noswingcc.net/noswingcc/api/cranedocs/${id}`,  {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while deleting file:', error);
      return false;
    }
  }
  export async function checkFile(crane, fileName) {
  
    try {
      await axios.post(`https://noswingcc.net/noswingcc/api/cranedocs?fileName.equals=${fileName}&craneId.equals=${crane}&page=0&size=20`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while checking for file:', error);
      return false;
    }
  }
  export async function postFileDataToDatabase(fileName, data) {
  
    try {
      const response = await axios.post(`https://noswingcc.net/noswingcc/api/files/${fileName}`, data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
      console.log(response)
      return true;
    } catch (error) {
      console.error('An error occurred while uploading file:', error);
      console.log(error.response.data);
      console.log(fileName, data);
      return false;
    }
  }

  export async function useFetchBlob(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const fetchUrl = async () => {
        try {
          const res = await axios.get(url,  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Accept': '*/*'
            },
            
          }
          );
            setData(res.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };
  
    useEffect(() => {
        fetchUrl();
    }, [url]);
  
    return { data, loading, error, refetch: fetchUrl };
  }

  export async function postModelconf(body) {
  
    try {
      await axios.post('https://noswingcc.net/noswingcc/api/modelconfs', body, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while posting modelconf:', error);
      return false;
    }
  }
  export async function updateModelconf(id, body) {
    console.log("body: ", body, "id: ", id);
  
    try {
      await axios.put(`https://noswingcc.net/noswingcc/api/modelconfs/${id}`, body, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while updating modelconf:', error);
      console.log(error.response.data);
      return false;
    }
  }

  export async function deleteModelconf(id) {
  
    try {
      await axios.delete(`https://noswingcc.net/noswingcc/api/modelconfs/${id}`,  {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while deleting modelconf:', error);
      console.log(error.response.data);
      return false;
    }
  }

  export async function uploadContent(content) {
    try {
  
      return window
        .fetch(
          `https://noswingcc.net/noswingcc/api/contents`, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content)
        }
        )
        .then(res => res.json())
  
    } catch (error) {
      console.error('An error occurred while trying to upload file:', error);
      return false;
    }
  }
  
  
  export async function uploadDocument(document) {
    try {
  
      return window
        .fetch(
          `https://noswingcc.net/noswingcc/api/documents`, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(document)
        }
        )
        .then(res => res.json())
  
    } catch (error) {
      console.error('An error occurred while trying to upload file:', error);
      return false;
    }
  }

  export async function getContent(id) {
    try {
  
      return window
        .fetch(
          `https://noswingcc.net/noswingcc/api/contents/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
            'Content-Type': 'application/json'
          },
         
        }
        )
        .then(res => res.json())
  
    } catch (error) {
      console.error('An error occurred while trying get content:', error);
      return false;
    }
  }
  
  
  export async function getDocument(id) {
    try {
  
      return window
        .fetch(
          `https://noswingcc.net/noswingcc/api/documents/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token') || '',
            'Content-Type': 'application/json'
          },
          
        }
        )
        .then(res => res.json())
  
    } catch (error) {
      console.error('An error occurred while trying to get document:', error);
      return false;
    }
  }

 
  export async function deleteContent( id) {
  
    try {
      await axios.delete(`https://noswingcc.net/noswingcc/api/contents/${id}`,  {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while deleting content:', error);
      return false;
    }
  }
  
 

  export async function deleteDocument( id) {
  
    try {
      await axios.delete(`https://noswingcc.net/noswingcc/api/documents/${id}`,  {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return true;
    } catch (error) {
      console.error('An error occurred while deleting document:', error);
      return false;
    }
  }

  export const getFileMimeType = (fileName) => {
    const type = fileName.split('.');
    // console.log(type);
    switch (type[type.length - 1]) {
      case "pdf":
        return 'application/pdf';
      case "png":
        return 'image/png';
      case "img":
        return 'image/img';
      case "jpg":
        return 'image/jpg';
      case "obj":
        return 'model/obj';
      case "mtl":
        return 'model/mtl';
      case "xml":
          return 'text/xml';
      case "txt":
        return 'text/plain';
      default:
        return "";
    }
  }