import React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Slider,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Divider,
  Checkbox,
} from "@mui/material";
import Scene2 from "./relatedComponents/3DobjV2Configuration";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import useFetchV2, {
  deleteCranePart,
  getPartsWithCraneID,
} from "../../../components/PartsApi";
import { useParams } from "react-router-dom";
import {
  get3DFile,
  postFile,
  postFileDataToDatabase,
  postModelconf,
  updateModelconf,
} from "../../../components/FilesApi";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { FileUploader } from "react-drag-drop-files";
import Skeleton from "@mui/material/Skeleton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { toast } from "react-toastify";

import { OBJLoader, MTLLoader } from "three-stdlib";

import mtl from "./images/mtl.png";
import obj from "./images/obj2.png";
import { uploadfile } from "./documents";
import { deleteCrane } from "../../../components/CraneApi";
import DeleteCraneDialog from "./relatedComponents/deleteCraneDialog";
import { createUserAccess } from "../../../components/UserApi";
import { ConstructionOutlined, Edit } from "@mui/icons-material";
import Cranecard, { getCraneImage } from "../oldCraneItemCard";

const initialConfig = [
  {
    name: "x",
    label: "Crane X pos",
    min: -30000,
    max: 40000,
    step: 1,
    initialValue: 27239,
  },
  {
    name: "y",
    label: "Crane Y pos",
    min: -20000,
    max: 20000,
    step: 1,
    initialValue: -727,
  },
  {
    name: "z",
    label: "Crane Z pos",
    min: -150,
    max: 150,
    step: 1,
    initialValue: 119,
  },
  {
    name: "Building_x",
    label: "Building X pos",
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Building_y",
    label: "Building Y pos",
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Building_z",
    label: "Building Z pos",
    min: -200,
    max: 200,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_x",
    label: "Beem X pos",
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_y",
    label: "Beem Y pos",
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_z",
    label: "Beem Z pos",
    min: -50,
    max: 50,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Beem2_x",
    label: "Beem2 X pos",
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem2_y",
    label: "Beem2 Y pos",
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem2_z",
    label: "Beem2 Z pos",
    min: -50,
    max: 50,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Trolley_x",
    label: "Trolley X pos",
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Trolley_y",
    label: "Trolley Y pos",
    min: -100,
    max: 100,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Trolley_z",
    label: "Trolley Z pos",
    min: -100,
    max: 100,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Rope_x",
    label: "Rope X pos",
    min: -50,
    max: 50,
    step: 0.2,
    initialValue: 0,
  },
  {
    name: "Rope_y",
    label: "Rope Y pos",
    min: -50,
    max: 50,
    step: 0.2,
    initialValue: 0,
  },
  {
    name: "Rope_z",
    label: "Rope Z TOP pos",
    min: -50,
    max: 50,
    step: 0.2,
    initialValue: 0,
  },
  {
    name: "Rope_length",
    label: "Rope Length",
    min: -50,
    max: 50,
    step: 0.2,
    initialValue: 0,
  },
  {
    name: "Hook_x",
    label: "Hook X pos",
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Hook_y",
    label: "Hook Y pos",
    min: -100,
    max: 100,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Hook_z",
    label: "Hook Z pos",
    min: -100,
    max: 200,
    step: 0.5,
    initialValue: 0,
  },
];

function populateStack(markerData, containerData) {
  // Make a deep copy of markerData to avoid mutating the original array
  const newMarkerData = JSON.parse(JSON.stringify(markerData));

  newMarkerData.forEach((marker) => {
    // Initialize an empty stack array for each marker item
    marker.stack = [];

    // Filter containerData for matching cranelocation.id
    const matchingContainers = containerData.filter(
      (container) => container.cranelocation.id === marker.id
    );

    matchingContainers.forEach((container) => {
      // Set the containercode at the position specified by rowindex (subtract 1 to convert to 0-based index)
      marker.stack[container.rowindex - 1] = container.product.productcode;
    });
  });

  return newMarkerData;
}

function ConfigurationsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState("");
  const [obj, setObj] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [editUsers, setEditUsers] = useState(false);

  const [modelLoading, setModelLoading] = useState(0);

  const {
    data,
    loading: dataIsLoading,
    error: dateError,
    refetch: refetchAllData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&folderName.equals"3D"&page=0&size=20`
  );
  const {
    data: products,
    loading: productdataIsLoading,
    error: productdataError,
    refetch: refetchProductData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/products?id.greaterThanOrEqual=12&page=0&size=20`
  );
  const {
    data: cranelocationdata,
    loading: locationdataIsLoading,
    error: errorInData,
    refetch: refetchLocationdata,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${id}&page=0&size=20`
  );
  //console.log(products, " ", files);
  const {
    data: markerData,
    loading: markerDataLoading,
    error: markerDataError,
    refetch: refetchAllMarkers,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${id}&inuse.equals=true&page=0&size=20`
  );
  const {
    data: containerData,
    loading: containerDataLoading,
    error: containerDataError,
    refetch: refetchAllContainers,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/containers?page=0&size=100`
  );
  const {
    data: cranedata,
    loading: craneloading,
    error: craneerror,
    refetch: refetchCraneData,
  } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${id}`);
  const {
    data: craneuserdata,
    loading: craneuserloading,
    error: craneusererror,
    refetch: refetchCraneUserData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/craneusers?craneId.equals=${id}&page=0&size=100`
  );
  const { data: userdata, loading: userloading, error: usererror } = useFetchV2(
    "https://noswingcc.net/noswingcc/api/users?page=0&size=50"
  );
  const {
    data: modelconf,
    loading: modelconfdataloading,
    error: modelconfdataerror,
    refetch: refetchModelconf,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/modelconfs?craneId.equals=${id}&page=0&size=1`
  );
  //console.log(userdata, craneuserdata);
  const [userStatus, setUserStatus] = useState({});
  const [userAccessChanges, setUserAccessChanges] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  console.log("modelconf", modelconf);

  const [values, setValues] = useState(() => {
    const initialState = {};
    initialConfig.forEach((item) => {
      initialState[item.name] = item.initialValue;
    });
    console.log("hep1 ", initialState);
    return initialState;
  });

  React.useEffect(() => {
    refetchCraneData();
  }, [openEditDialog]);

  React.useEffect(() => {
    if (!modelconfdataloading && modelconf.length > 0) {
      const configObject = Object.keys(modelconf[0]).reduce((obj, key) => {
        // Exclude 'id' and 'crane' from the new object
        if (key !== "id" && key !== "crane") {
          // Capitalize the first letter of each key and replace it in the new object
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          obj[capitalizedKey] = modelconf[0][key];
        }
        return obj;
      }, {});
      //console.log(configObject);

      let updatedValues = { ...values };

      updatedValues = {
        ...updatedValues,
        ...configObject,
        Rope_length: 42,
      };

      setValues(updatedValues);
    }
  }, [modelconf, modelconfdataloading]);

  React.useEffect(() => {
    if (!userloading && !craneuserloading) {
      const newUserStatus = userdata.reduce((statusObj, user) => {
        // Check if user login exists in the items array
        const exists = craneuserdata.some(
          (item) => item.user && item.user.login === user.login
        );
        // Set true or false based on existence
        statusObj[user.id] = exists;
        return statusObj;
      }, {});

      setUserStatus(newUserStatus);
    }
  }, [userloading, craneuserloading, editUsers]);

  let fullMarkerData = null;
  if (!markerDataLoading && !containerDataLoading) {
    fullMarkerData = populateStack(markerData, containerData);
    // const test = containerData.filter(item => item.cranelocation.locationcode == selectedMarker[0] && item.rowindex == 1).map(item => item.product.productcode)[0];
    // console.log(test);
  }
  //console.log(data);
  const columns = [
    { field: "locationcode", headerName: "Location Code", width: 130 },
    { field: "x", headerName: "X", width: 100 },
    { field: "y", headerName: "Y", width: 100 },
    { field: "z", headerName: "Z", width: 100 },
    { field: "rowmax", headerName: "Row Max", width: 100 },
    {
      field: "inuse",
      headerName: "In Use",
      width: 110,
      valueGetter: (params) => (params.row.inuse ? "Yes" : "No"),
    },
  ];

  const rows =
    cranelocationdata?.map((item, index) => ({
      ...item,
      id: index, // Ensure each row has a unique id
    })) || [];

  async function fetchModelFiles() {
    let modelFiles = [];

    if (!dataIsLoading) {
      const entries = await Promise.all(
        data.map(async (item) => {
          const fileData = await get3DFile(`${id}_${item.fileName}`);
          setModelLoading((prev) => prev + 1);
          return [item.fileName, fileData];
        })
      );

      modelFiles = Object.fromEntries(entries);
    }

    return modelFiles;
  }

  //console.log(files);

  function preRender(mtlUrl, objUrl) {
    if (mtlUrl != undefined && mtlUrl != "") {
    } else {
      return null;
    }
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    const obj = objLoader.parse(rawObjText);

    return obj;
  }

  React.useEffect(() => {
    async function fetchData() {
      const files = await fetchModelFiles();
      setObj(preRender(files["Hook.mtl"], files["Hook.obj"]));
      setFiles(files);

      //console.log(files);
    }

    fetchData();
  }, [data, dataIsLoading]);

  //console.log(files);

  const setValue = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const body = {
      id: modelconf[0].id,
      building_x: values.Building_x,
      building_y: values.Building_y,
      building_z: values.Building_z,
      beem_x: values.Beem_x,
      beem_y: values.Beem_y,
      beem_z: values.Beem_z,
      trolley_x: values.Trolley_x,
      trolley_y: values.Trolley_y,
      trolley_z: values.Trolley_z,
      hook_x: values.Hook_x,
      hook_y: values.Hook_y,
      hook_z: values.Hook_z,
      rope_x: values.Rope_x,
      rope_y: values.Rope_y,
      rope_z: values.Rope_z,
      cranexposmin: values.Cranexposmin,
      cranexposmax: values.Cranexposmax,
      craneyposmin: values.Craneyposmin,
      craneyposmax: values.Craneyposmax,
      cranezposmin: values.Cranezposmin,
      cranezposmax: values.Cranezposmax,
      crane: {
        id: id,
      },
    };
    const post = await updateModelconf(modelconf[0].id, body);
    if (post === false) {
      toast.error("Could not save the new values");
    } else {
      toast.success("Succefully saved new values");
    }
    refetchModelconf();
  };

  const renderSlider = (name) => {
    const config = initialConfig.find((cfg) => cfg.name === name);
    //config && console.log(config.name)
    //config && console.log(modelconf[0][config.name.toLowerCase()]);
    return (
      config && (
        <div key={config.name}>
          <Typography variant="h5">
            {config.label}: {values[config.name]}
          </Typography>
          <Stack
            direction={"row"}
            display={"flex"}
            alignItems={"center"}
            spacing={2}
          >
            <CustomSlider
              value={values[config.name]}
              min={config.min}
              max={config.max}
              step={config.step}
              setValue={(value) => setValue(config.name, value)}
            />
            {/* <Typography variant='h4'> + </Typography>
                <TextField size='small' type="number" ></TextField> */}
          </Stack>
        </div>
      )
    );
  };
  const handleChange = (itemName, fileType) => async (file) => {
    let body = {
      id: "",
      fileName: `${itemName}.${fileType}`,
      folderName: "3D",
      crane: {
        id: id,
      },
    };
    let data = "";

    const newFiles = { ...files };
    const reader = new FileReader();
    reader.onload = (evt) => {
      //newFiles[`${itemName}.${fileType}`] = evt.target.result;
      //setFiles(newFiles);
      data = evt.target.result;
    };
    reader.readAsBinaryString(file);
    console.log(body);
    //
    // const dataUpload = await postFileDataToDatabase(`${id}_${itemName}.${fileType}`, data);
    const upload = await postFile(body);

    const db = await uploadfile(file, `${id}_${body.fileName}`);

    console.log(file, " ", db);
    refetchAllData();
  };

  const handleDeleteCrane = async () => {
    setOpenDeleteDialog(true);
  };
  const handleUserAccessChange = (user) => {
    if (editUsers) {
      setUserStatus((prevStatus) => ({
        ...prevStatus,
        [user]: !prevStatus[user], // Toggle the current status
      }));

      setUserAccessChanges((prevChanges) => ({
        ...prevChanges,
        [user]: !(userAccessChanges[user] || userStatus[user]), // Update changes
      }));
    } else {
      toast.info("You need to be in Edit mode");
    }
    console.log(userStatus);
  };

  const updateUserAccess = async () => {
    toast.info("This functionality is being developed");
    // const body = {
    //   "id": "",
    //   "crane": {
    //     "id": id,
    //   },
    //   "user": {
    //     "id": null,
    //   }
    // }

    // for (const item in userAccessChanges) {
    //   if (userAccessChanges.hasOwnProperty(item)) {
    //     const status = userAccessChanges[item];
    //     //console.log(`Item: ${item}, Status: ${status}`);

    //     body.user.id = item;
    //     // body.user.login = item;
    //     const post = await createUserAccess(body);
    //     console.log(item, " ", post);

    //   }
    // }

    // toast.success("Updated user access for this crane");

    refetchCraneUserData();
    setEditUsers(false);
  };

  const configurate3Dmodel = async () => {
    const body = {
      id: "",
      building_x: 0,
      building_y: 0,
      building_z: 0,
      beem_x: 0,
      beem_y: 0,
      beem_z: 0,
      trolley_x: 0,
      trolley_y: 0,
      trolley_z: 0,
      hook_x: 0,
      hook_y: 0,
      hook_z: 0,
      rope_x: 0,
      rope_y: 0,
      rope_z: 0,
      cranexposmin: 0,
      cranexposmax: 0,
      craneyposmin: 0,
      craneyposmax: 0,
      cranezposmin: 0,
      cranezposmax: 0,
      crane: {
        id: id,
      },
    };
    const post = await postModelconf(body);
    if (post === false) {
      toast.error("Could not generate a configurations file");
    }
    refetchModelconf();
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box
        mt={2}
        display="flex"
        alignContent="center"
        gap={1}
        position="absolute"
        left="20px"
        zIndex={1}
        onClick={() => navigate(-1)}
        sx={{ cursor: "pointer" }}
      >
        <ArrowBackOutlinedIcon />
        Back
      </Box>

      <Box width={"1200px"} mt={5} p={1}>
        <Typography variant="h1" fontWeight={"bold"}>
          Crane configurationpage
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <Box mt={2}>
          <h2 className="text-3xl font-bold underline">Crane ID: {id}</h2>
          <Typography variant="h4">Crane settings</Typography>
          <Box p={2} display={"flex"} flexDirection={"row"} gap={4}>
            <Box>
              <Cranecard
                name={cranedata?.name}
                loading={craneloading}
                location={cranedata?.location}
                city={cranedata?.city}
                country={cranedata?.country}
                id={id}
              />
            </Box>
            <Stack>
              <Stack direction={"row"}>
                <Stack spacing={1} width={"200px"}>
                  <Typography variant="h3">Name: {cranedata?.name}</Typography>
                  <Typography variant="h3">
                    Facility: {cranedata?.location}
                  </Typography>
                  <Typography variant="h3">City: {cranedata?.city}</Typography>
                  <Typography variant="h3">
                    Country: {cranedata?.country}
                  </Typography>
                </Stack>
                <Stack>
                  <img
                    src={getCraneImage(cranedata?.location)}
                    width={"200px"}
                  ></img>
                </Stack>
              </Stack>
              <Stack spacing={1} width={"700px"} mt={4}>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="h3">
                    Number of Beems: {cranedata?.craneconfiguration[0]}
                  </Typography>{" "}
                  <Typography
                    display={
                      cranedata?.craneconfiguration[0] == 2 ? "flex" : "none"
                    }
                    alignSelf={"end"}
                    color={"red"}
                  >
                    {" "}
                    (2 beems is only supported here in the configuration page)
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="h3">
                    Number of Trolleys: {cranedata?.craneconfiguration[1]}
                  </Typography>{" "}
                  <Typography
                    display={
                      cranedata?.craneconfiguration[1] == 2 ? "flex" : "none"
                    }
                    alignSelf={"end"}
                    color={"red"}
                  >
                    {" "}
                    (only 1/4 trolleys supported for now)
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="h3">
                    Number of Hooks: {cranedata?.craneconfiguration[2]}
                  </Typography>{" "}
                  <Typography
                    display={
                      cranedata?.craneconfiguration[2] == 2 ? "flex" : "none"
                    }
                    alignSelf={"end"}
                    color={"red"}
                  >
                    {" "}
                    (not supported yet)
                  </Typography>
                </Stack>
              </Stack>
              <Box mt={3}>
                <Button
                  variant="contained"
                  sx={{ width: 10 }}
                  onClick={() => setOpenEditDialog(true)}
                >
                  Edit
                </Button>
              </Box>
            </Stack>
          </Box>

          <Box mt={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteCrane}
            >
              Delete Crane
            </Button>
          </Box>
          <DeleteCraneDialog
            open={openDeleteDialog}
            id={id}
            setOpen={setOpenDeleteDialog}
          />
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Box mt={5}>
          <Typography variant="h4" mb={1}>
            3D scene
          </Typography>

          {!modelconfdataloading && modelconf.length == 0 ? (
            <Box p={1}>
              <Button variant="contained" onClick={configurate3Dmodel}>
                Configure
              </Button>
            </Box>
          ) : (
            modelconf != undefined && (
              <Stack direction={"row"} gap={2}>
                <Box>
                  <Box width={600} height={400}>
                    <Scene2
                      confdata={cranedata?.craneconfiguration[0] || 1}
                      obj={obj}
                      products={[]}
                      files={files}
                      values={values}
                      className="my-component"
                      x={values?.x || 0}
                      y={values?.y || 0}
                      z={values?.z || 0}
                      view={1}
                      setMarker={true}
                      setNewMarkerPosition={true}
                      markerdata={fullMarkerData}
                      selectedMarker={[]}
                      setSelectedMarker={true}
                      setTempMarker={true}
                      tempMarker={[]}
                      cargoWeight={0}
                      crane={404}
                      cargoProduct={111}
                    />
                  </Box>
                  <Box width={600} mt={4}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"300px"}>{renderSlider("x")}</Box>
                      <Box display={"flex"} gap={2}>
                        <Button
                          variant="contained"
                          sx={{ height: "35px" }}
                          onClick={() => setValue("Cranexposmin", values.x)}
                        >
                          Set min limit
                        </Button>
                        {values.Cranexposmin}
                        <Button
                          variant="contained"
                          sx={{ height: "35px" }}
                          onClick={() => setValue("Cranexposmax", values.x)}
                        >
                          Set max limit
                        </Button>
                        {values.Cranexposmax}
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"300px"}>{renderSlider("y")}</Box>
                      <Box display={"flex"} gap={2}>
                        <Button
                          variant="contained"
                          sx={{ height: "35px" }}
                          onClick={() => setValue("Craneyposmin", values.y)}
                        >
                          Set min limit
                        </Button>
                        {values.Craneyposmin}
                        <Button
                          variant="contained"
                          sx={{ height: "35px" }}
                          onClick={() => setValue("Craneyposmax", values.y)}
                        >
                          Set max limit
                        </Button>
                        {values.Craneyposmax}
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"300px"}>{renderSlider("z")}</Box>
                      <Box display={"flex"} gap={2}>
                        <Button
                          variant="contained"
                          sx={{ height: "35px" }}
                          onClick={() => setValue("Cranezposmin", values.z)}
                        >
                          Set min limit
                        </Button>
                        {values.Cranezposmin}
                        <Button
                          variant="contained"
                          sx={{ height: "35px" }}
                          onClick={() => setValue("Cranezposmax", values.z)}
                        >
                          Set max limit
                        </Button>
                        {values.Cranezposmax}
                      </Box>
                    </Stack>
                  </Box>
                  <Box mt={5} display={"flex"} justifySelf={"flex-end"} gap={2}>
                    <Button
                      variant="contained"
                      onClick={() => refetchModelconf()}
                    >
                      Reset to stored values
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                      Save new values
                    </Button>
                  </Box>
                </Box>

                {!modelconfdataloading && (
                  <Box width={450}>
                    {/* <Typography variant='h3'>Configure position of the building model</Typography> */}

                    <ItemAccordion
                      itemName="Building"
                      files={files}
                      handleChange={handleChange}
                      renderSlider={renderSlider}
                      dataIsLoading={dataIsLoading}
                    />
                    <ItemAccordion
                      itemName="Beem"
                      files={files}
                      handleChange={handleChange}
                      renderSlider={renderSlider}
                      dataIsLoading={dataIsLoading}
                    />
                    {cranedata?.craneconfiguration[0] > 1 && (
                      <ItemAccordion
                        itemName="Beem2"
                        files={files}
                        handleChange={handleChange}
                        renderSlider={renderSlider}
                        dataIsLoading={dataIsLoading}
                      />
                    )}
                    <ItemAccordion
                      itemName="Trolley"
                      files={files}
                      handleChange={handleChange}
                      renderSlider={renderSlider}
                      dataIsLoading={dataIsLoading}
                    />
                    <ItemAccordion
                      itemName="Hook"
                      files={files}
                      handleChange={handleChange}
                      renderSlider={renderSlider}
                      dataIsLoading={dataIsLoading}
                    />
                    <ItemAccordion
                      itemName="Rope"
                      files={files}
                      handleChange={handleChange}
                      renderSlider={renderSlider}
                      dataIsLoading={dataIsLoading}
                    />

                    <Stack mt={5}>
                      <Stack direction={"row"}>
                        <Typography variant="h4">Products</Typography>
                        {/* <AddOutlinedIcon/> */}
                      </Stack>
                      {products?.map((item) => (
                        <ProductItemAccordion
                          itemName={item.name}
                          files={files}
                          handleChange={handleChange}
                          renderSlider={renderSlider}
                        />
                      ))}
                    </Stack>
                  </Box>
                )}
              </Stack>
            )
          )}
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Box mt={2}>
          <Typography variant="h4">User access</Typography>
          <Box p={2}>
            <Typography>Users with access to this crane:</Typography>
            <Stack spacing={1}>
              <Box p={1}>
                {userdata?.map((user) => (
                  <Box>
                    <Stack
                      direction={"row"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Checkbox
                        checked={userStatus[user.id] || false}
                        onChange={() => handleUserAccessChange(user.id)}
                      />
                      <Typography>{user.login}</Typography>
                    </Stack>
                  </Box>
                ))}
              </Box>
              <Button
                variant={editUsers ? "outlined" : "contained"}
                sx={{ width: 10 }}
                onClick={() =>
                  editUsers ? updateUserAccess() : setEditUsers((prev) => !prev)
                }
              >
                {editUsers ? "Save" : "Edit"}
              </Button>
            </Stack>
          </Box>
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Box mt={2}>
          <Typography variant="h4">OPC Server settings</Typography>
          <Box p={2}>
            <Stack spacing={1}>
              <Typography>IP-address: {cranedata?.opcIP}</Typography>
              <Button
                variant="contained"
                sx={{ width: 10 }}
                onClick={() => toast.info("Sry, does not work yet :)")}
              >
                Edit
              </Button>
            </Stack>
          </Box>
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Box mt={10}>
          <Typography variant="h4">Stored Locations</Typography>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default ConfigurationsPage;

function CustomSlider({ value, min, max, step, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Slider
      value={value}
      onChange={handleChange}
      min={min}
      max={max}
      step={step}
      sx={{ minWidth: "200px" }}
    />
  );
}
const ItemAccordion = ({
  itemName,
  files,
  handleChange,
  renderSlider,
  dataIsLoading,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack
          spacing={1}
          direction={"row"}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography variant="h3">{itemName}</Typography>
          {files.length == 0 ? (
            <CircularProgress size={15} />
          ) : (
            files[`${itemName}.obj`] === undefined &&
            itemName !== "Rope" && (
              <Typography variant="h5" color={"red"}>
                {" "}
                (not configured)
              </Typography>
            )
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction={"row"}
          spacing={4}
          justifyContent={"space-between"}
          p={1}
        >
          <Stack spacing={2} display={itemName == "Rope" ? "none" : "flex"}>
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              spacing={1}
            >
              {files[`${itemName}.obj`] ? (
                <img src={obj} alt="objfileicon" width={"50px"} />
              ) : (
                <FileUploader
                  multiple={false}
                  handleChange={handleChange(itemName, "obj")}
                  name="file"
                  types={["obj"]}
                  children={
                    <img
                      src={obj}
                      alt="objfileicon"
                      width={"50px"}
                      style={{ cursor: "pointer" }}
                    />
                  }
                />
              )}
              {files[`${itemName}.obj`] ? (
                <CheckOutlinedIcon fontSize="medium" color="success" />
              ) : files[`${itemName}.obj`] == "" ? (
                <ErrorOutlinedIcon fontSize="medium" color="error" />
              ) : (
                <AddOutlinedIcon fontSize="medium" />
              )}
            </Stack>
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              spacing={1}
            >
              {files[`${itemName}.mtl`] ? (
                <img src={mtl} alt="mtlfileicon" width={"50px"} />
              ) : (
                <FileUploader
                  multiple={false}
                  handleChange={handleChange(itemName, "mtl")}
                  name="file"
                  types={["mtl"]}
                  children={
                    <img
                      src={mtl}
                      alt="mtlfileicon"
                      width={"50px"}
                      style={{ cursor: "pointer" }}
                    />
                  }
                />
              )}

              {files[`${itemName}.mtl`] ? (
                <CheckOutlinedIcon fontSize="medium" color="success" />
              ) : files[`${itemName}.mtl`] == "" ? (
                <ErrorOutlinedIcon fontSize="medium" color="error" />
              ) : (
                <AddOutlinedIcon fontSize="medium" />
              )}
            </Stack>
          </Stack>
          <Stack width={"400px"}>
            {itemName != "Beem2" && renderSlider(`${itemName}_x`)}
            {renderSlider(`${itemName}_y`)}
            {itemName != "Beem2" && renderSlider(`${itemName}_z`)}
            {itemName == "Rope" && renderSlider(`Rope_length`)}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const ProductItemAccordion = ({
  itemName,
  files,
  handleChange,
  renderSlider,
  configured,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack
          spacing={1}
          direction={"row"}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography variant="h3">{itemName}</Typography>
          {files.length == 0 ? (
            <CircularProgress size={15} />
          ) : (
            files[`${itemName}.obj`] === undefined && (
              <Typography variant="h5" color={"red"}>
                {" "}
                (not configured)
              </Typography>
            )
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction={"row"}
          spacing={4}
          justifyContent={"space-between"}
          p={1}
        >
          <Stack spacing={2} display={itemName == "Rope" ? "none" : "flex"}>
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              spacing={1}
            >
              {files[`${itemName}.obj`] ? (
                <img src={obj} alt="objfileicon" width={"50px"} />
              ) : (
                <FileUploader
                  multiple={false}
                  handleChange={handleChange(itemName, "obj")}
                  name="file"
                  types={["obj"]}
                  children={
                    <img
                      src={obj}
                      alt="objfileicon"
                      width={"50px"}
                      style={{ cursor: "pointer" }}
                    />
                  }
                />
              )}
              {files[`${itemName}.obj`] ? (
                <CheckOutlinedIcon fontSize="medium" color="success" />
              ) : files[`${itemName}.obj`] == "" ? (
                <ErrorOutlinedIcon fontSize="medium" color="error" />
              ) : (
                <AddOutlinedIcon fontSize="medium" />
              )}
            </Stack>
            <Stack
              direction={"row"}
              display={"flex"}
              alignItems={"center"}
              spacing={1}
            >
              {files[`${itemName}.mtl`] ? (
                <img src={mtl} alt="mtlfileicon" width={"50px"} />
              ) : (
                <FileUploader
                  multiple={false}
                  handleChange={handleChange(itemName, "mtl")}
                  name="file"
                  types={["mtl"]}
                  children={
                    <img
                      src={mtl}
                      alt="mtlfileicon"
                      width={"50px"}
                      style={{ cursor: "pointer" }}
                    />
                  }
                />
              )}

              {files[`${itemName}.mtl`] ? (
                <CheckOutlinedIcon fontSize="medium" color="success" />
              ) : files[`${itemName}.mtl`] == "" ? (
                <ErrorOutlinedIcon fontSize="medium" color="error" />
              ) : (
                <AddOutlinedIcon fontSize="medium" />
              )}
            </Stack>
          </Stack>
          <Stack width={"400px"}>
            {renderSlider(`${itemName}_x`)}
            {renderSlider(`${itemName}_y`)}
            {renderSlider(`${itemName}_z`)}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
