import {
  Box,
  Button,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import useFetchV2 from "../../components/PartsApi";
import {
  deleteContent,
  deleteDocument,
  deleteFile,
  deleteFileFromDB,
  getContent,
  getDocument,
  getFile,
  getFileContent,
  getFileMimeType,
  postFile,
  uploadContent,
  uploadDocument,
} from "../../components/FilesApi";
import { useFetchBlob } from "../../components/FilesApi";
import { FileUploader } from "react-drag-drop-files";
import { pdfjs } from "react-pdf";

import { Document, Page } from "react-pdf";

import { Fragment, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";

import pdf from "./images/pdf.png";
import image from "./images/image.png";

import { toast } from "react-toastify";

import mtl from "./images/mtl.png";
import obj from "./images/obj2.png";
import xml from "./images/xml.png";
import unknown from "./images/unknown.png";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { useNavigate } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export async function uploadfile(file, filename) {
  try {
    const data = new FormData(); // eslint-disable-line no-undef
    data.append("file", file, filename);

    return window
      .fetch(`https://noswingcc.net/noswingcc/api/files`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "",
        },
        body: data,
      })
      .then((res) => res.json());
  } catch (error) {
    console.error("An error occurred while trying to upload file:", error);
    return false;
  }
}

// async function fetchModelFiles() {
//   let modelFiles = [];

//   if (!dataIsLoading) {
//     const entries = await Promise.all(data.map(async item => {
//       const fileData = await getFile(`${id}_${item.fileName}`);
//       return [item.fileName, fileData];
//     }));

//     modelFiles = Object.fromEntries(entries);
//   }

//   return modelFiles;
// }

function preprocessFiles(files) {
  // Object to hold the processed data, with crane IDs as keys
  const groupedFiles = {};

  files.forEach((item) => {
    // Check if the item is a headline
    if (item.isHeadline) {
      // Initialize the group with the headline and an empty array for files
      groupedFiles[item.craneId] = {
        craneName: item.craneName,
        craneCity: item.craneCity,
        craneCountry: item.craneCountry,
        craneLocation: item.craneLocation,
        files: [],
      };
    } else {
      // Add the file to the corresponding group
      if (groupedFiles[item.crane.id]) {
        groupedFiles[item.crane.id].files.push(item);
      } else {
        // Handle cases where a file might appear before its headline
        // by initializing the group here too
        groupedFiles[item.crane.id] = {
          craneName: `Crane ${item.crane.id} Files`, // Default name, can be updated when headline is found
          files: [item],
        };
      }
    }
  });

  // Convert the groupedFiles object back to an array for rendering
  const processedFiles = Object.keys(groupedFiles).map((craneId) => {
    return {
      isHeadline: true, // Mark this entry as a headline for rendering purposes
      craneId: craneId,
      craneName: groupedFiles[craneId].craneName,
      craneLocation: groupedFiles[craneId].craneLocation,
      craneCity: groupedFiles[craneId].craneCity,
      craneCountry: groupedFiles[craneId].craneCountry,
      files: groupedFiles[craneId].files,
    };
  });

  return processedFiles;
}

function createDisplayFiles(files, filterCategories = null, cranes) {
  const categoryOrder = ["pdf", "image", "3D", "xml"];
  let categorizedFiles = {};

  // Group files by crane id, then sort into categories
  files.forEach((file) => {
    const { folderName } = file;
    const craneId = file.crane.id;

    if (!categorizedFiles[craneId]) {
      categorizedFiles[craneId] = {
        pdf: [],
        image: [],
        "3D": [],
        xml: [],
        others: [],
      };
    }

    if (categoryOrder.includes(folderName)) {
      categorizedFiles[craneId][folderName].push(file);
    } else {
      categorizedFiles[craneId].others.push(file);
    }
  });

  // Sort and combine files for each crane, maintaining order by crane id
  let displayFiles = [];
  Object.keys(categorizedFiles)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .forEach((craneId) => {
      let craneFiles = [];
      categoryOrder.forEach((category) => {
        if (!filterCategories || filterCategories.includes(category)) {
          craneFiles = [...craneFiles, ...categorizedFiles[craneId][category]];
        }
      });

      if (!filterCategories || filterCategories.includes("others")) {
        craneFiles = [...craneFiles, ...categorizedFiles[craneId].others];
      }

      let crane = cranes.find((item) => item.id == craneId);
      console.log("craneID: ", craneId);
      // Optionally, add a headline or marker for each crane's file list here
      const headline = {
        isHeadline: true,
        craneId: craneId,
        craneName: crane?.name || "Crane without name",
        craneLocation: crane?.location,
        craneCity: crane?.city,
        craneCountry: crane?.country,
      };
      displayFiles.push(headline, ...craneFiles);
    });

  return displayFiles;
}

const Documents = ({ id }) => {
  const {
    data: files,
    loading: dataIsLoading,
    error: dataError,
    refetch: refetchAllData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranedocs?page=0&size=1000`
  );
  const {
    data: documents,
    loading: documentsIsLoading,
    error: documentsError,
    refetch: refetchAllDocuments,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/documents?craneRelated.equals=false&page=0&size=1000`
  );
  const {
    data: cranesdata,
    loading: cranesloading,
    error: craneserror,
    refetch: refetchCraneData,
  } = useFetchV2("https://noswingcc.net/noswingcc/api/cranes?page=0&size=200");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [tags, setTags] = useState([]);
  //console.log(files);
  const [isXML, setIsXML] = useState(false);

  const [fileSelectedForDownload, setFileSelectedForDownload] = useState();
  const [hover, setHover] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [filter, setFilter] = useState(["pdf", "image", "3D", "xml", "all"]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [fileAsBase64, setFileAsBase64] = useState(null);
  const [showCraneFiles, setShowCraneFiles] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {


    if (files?.length > 0 && cranesdata?.length > 0) {
      console.log(files);
      let filterCategories = filter;
      console.log(cranesdata);
      let filterFiles = createDisplayFiles(files, filterCategories, cranesdata);
      let craneLists = preprocessFiles(filterFiles);
      //console.log(craneLists);
      setFilteredFiles(craneLists);
      
    }
  }, [files, filter, cranesdata, dataIsLoading]);

  //console.log(filteredFiles);
   

  const handleFileSelect = (e) => {
    
    if (!e.target.files) {
      return;
    }
    if (e.target.files[0].size > 100000000) {
      toast.error("File is too large > 100MB");
      return;
    }
    
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function() {
      setFile(e.target.files[0]);
      setFileAsBase64(reader.result.split(",")[1]);
      setFileName(e.target.files[0].name);
     

      const type = e.target.files[0].name.split(".");
      if (type[1] == "xml") {
        setIsXML(true);
      }
      return reader.result; 
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
      return;
    };
  };

  

  const downloadFile = async (filename, crane, id) => {

    console.log(filename, crane, id);

    if (filename) {
      try {
        console.log(files);
        const cranedoc = files.find(
          (f) => f.fileName === filename && f.crane.id === crane
        );
        // const cranedoc2 = await getFile(cranedoc.id);
        // console.log(cranedoc2)
        if (cranedoc?.document === null && crane !== null) {
          toast.error("File has no document");
          console.log(cranedoc);
          return;
        }
   

        const blob = await getFileContent(crane === null ? id : cranedoc.document.id);
        console.log("BLOB: _-------- : ", blob);
        if (!blob) {
          console.error("No data received for download");
          toast.error("Could not find the file to download.");
          return;
        }

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Ensure this includes the file extension
        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(url);
        a.remove();
      } catch (error) {
        console.error("Error downloading file:", error);
        toast.error("Error, could not download the file.");
      }
      console.log(filename);
    }
  };

  const deleteSelectedFile = async (file, crane, id) => {
    console.log(files);
    console.log(file, crane);
    console.log(files.find((f) => f.fileName === file && f.crane.id === crane));
    toast.info("Deleting file...");
    try {
      const cranedoc = files.find(
        (f) => f.fileName === file && f.crane.id === crane
      );
      if (crane !== null && cranedoc?.document === null) {
        toast.info("File has no document but deleting the crane doc...");
        const deleteCranedoc = await deleteFile(cranedoc.id);
       
      } else {
        

        const document = await getDocument(crane === null ? id : cranedoc.document.id);
        if(crane !== null){
          const deleteCranedoc = await deleteFile(cranedoc.id);
          console.log("Deletecranedoc: ", deleteCranedoc);
        }
       
        if(document.title.includes("MEX_")){
         refetchAllDocuments();
          toast.info("This file is linked to example file.");
          refetchAllData();
          return;
        }
        const deleteDocumentData = await deleteDocument(document.id);
        console.log("Deletedocument: ", deleteDocumentData);
        const content = await getContent(document.content.id);
        if (!content) {
          toast.error("Could not find content data...");
        }

        const deleteContentData = await deleteContent(content.id);
        console.log("Deletecontent: ", deleteContentData);
      }

      console.log(cranedoc, document);

      toast.success("Successfully deleted the file");
      refetchAllData();
      refetchAllDocuments();
    } catch (error) {
      toast.error("Something went wrong with the deletion...");
      console.log(error);
    }

    // const deleteFile = await deleteFileFromDB(file);

    // if(deleteFile){

    //   const id = files.filter(item => item.fileName == file && item.crane.id == crane)[0].id;
    //   const deleted = await deleteFile(id);
    //   if(deleted){
    //   toast.success("Successfully deleted the file");
    //   refetchAllData();

    //   }
    //   else {
    //     toast.error("Something went wrong with the deletion...");
    //   }
    // }
    // else{
    //   toast.error("Something went wrong with the deletion...");
    // }
  };

  const extractTags = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xmlString = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");
        const uaVariables = xmlDoc.getElementsByTagName("UAVariable");

        let extractedTags = [];
        for (let variable of uaVariables) {
          const displayNameNode = variable.getElementsByTagName(
            "DisplayName"
          )[0];
          const dataTypeNode = variable.getAttribute("DataType");

          if (displayNameNode && dataTypeNode) {
            const displayName = displayNameNode.textContent;
            const dataType = dataTypeNode;

            // Filter out specific tags
            if (
              !["Icon", "InterfaceUuid", "InterfaceVersion"].includes(
                displayName
              )
            ) {
              extractedTags.push({
                name: displayName,
                type: dataType,
              });
            }
          }
        }
        setTags(extractedTags);
      };
      reader.readAsText(file);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      return;
    }

    const getFileType = () => {
      const type = fileName.split(".");
      console.log(type);
      switch (type[1]) {
        case "pdf":
          return "pdf";
        case "png":
          return "image";
        case "img":
          return "image";
        case "jpg":
          return "image";
        case "obj":
          return "3D";
        case "mtl":
          return "3D";
        case "xml":
          return "xml";
        default:
          return "Unknown";
      }
    };

    let body = {
      id: "",
      fileName: fileName,
      folderName: getFileType(),
      crane: {
        id: 4,
      },
    };

    const fileMimeType = getFileMimeType(fileName);
    // const dataUpload = await postFileDataToDatabase(`${id}_${itemName}.${fileType}`, data);
    try {
      toast.info("Uploading file, please wait...");
      // const db = await uploadfile(file, `4_${body.fileName}`);
      console.log("64:", fileAsBase64);
      let upload;
      await uploadContent({
        dataContentType: fileMimeType,
        data: fileAsBase64,
      })
        .then((content) =>
          uploadDocument(
            //Adding content to document
            {
              title: file.name,
              documentSize: file.size,
              mimeType: fileMimeType,
              content: content,
              craneRelated: false,
            }
          )
          
        )
        .catch((error) => {
          toast.error(error);
          // If any of the Content - Document - Cranedoc requests fail post cranedoc without document
          // upload = postFile(body);
        });

      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.error("Something went wrong with the upload...");
    }

    //console.log(file , " ",  db);
    setFile();
    setFileName();
    refetchAllData();
    refetchAllDocuments();
  };

  const handleChange = (file) => {
    setFile(file);
  };

  function GetFileImg({ fileName, fileType, crane, id }) {
    const type = fileName.split(".");
    let theImage = "";

    if(crane !== null){
    switch (fileType) {
      case "pdf":
        theImage = pdf;
        break;
      case "image":
        theImage = image;
        break;
      case "3D":
        theImage = type[1] === "obj" ? obj : mtl;
        break;

      case "xml":
        theImage = xml;
        break;
      default:
        theImage = unknown;
        break;
    }
  }
  else {
    const type = fileName.split('.');
    // console.log(type);
    switch (type[type.length - 1]) {
      case "pdf":
        theImage = pdf;
        break;
      case "png":
        theImage = image;
        break;
      case "img":
        theImage = image;
        break;
      case "jpg":
        theImage = image;
        break;
      case "obj":
        theImage = obj
        break;
      case "mtl":
        theImage = mtl;
        break;
      case "xml":
        theImage = xml;
        break;
      case "txt":
        theImage = xml;
        break;
      default:
        theImage = unknown;
        break;
    }
  }

    return (
      <div
        className="w-[100px] cursor-pointer relative"
        onMouseEnter={() => setHover(`${crane}_${fileName}`)}
        onMouseLeave={() => setHover()}
      >
        {hover == `${crane}_${fileName}` && (
          <CloudDownloadOutlinedIcon
            onClick={() => downloadFile(fileName, crane, id)}
            sx={{ position: "absolute", top: -30, left: 15 }}
            fontSize="large"
            className="hover:text-gray-400"
          />
        )}
        {hover == `${crane}_${fileName}` && (
          <DeleteOutlineOutlinedIcon
            onClick={() => deleteSelectedFile(fileName, crane, id)}
            sx={{ position: "absolute", top: -30, right: 15 }}
            fontSize="large"
            className="hover:text-gray-400"
          />
        )}
        <img
          src={theImage}
          alt="fileTypeImage"
          width={"100px"}
          className={" " + (fileType === "3D" ? "px-1.5 " : "")}
        />
        <p className="text-sm ">
          {fileName}
        </p>
      </div>
    );
  }

  function Filter() {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    return (
      <div className="w-full flex justify-center px-20 mt-5">
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        Filters
                      </h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4">
                      {filters.map((section) => (
                        <Disclosure
                          as="div"
                          key={section.name}
                          className="border-t border-gray-200 pb-4 pt-4"
                        >
                          {({ open }) => (
                            <fieldset>
                              <legend className="w-full px-2">
                                <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                  <span className="text-sm font-medium text-gray-900">
                                    {section.name}
                                  </span>
                                  <span className="ml-6 flex h-7 items-center">
                                    <ChevronDownIcon
                                      className={classNames(
                                        open ? "-rotate-180" : "rotate-0",
                                        "h-5 w-5 transform"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Disclosure.Button>
                              </legend>
                              <Disclosure.Panel className="px-4 pb-2 pt-4">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div
                                      key={option.value}
                                      className="flex items-center cursor-pointer"
                                    >
                                      <input
                                        id={`${section.id}-${optionIdx}-mobile`}
                                        name={`${section.id}[]`}
                                        value={
                                          filter.includes(option.value)
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={`${section.id}-${optionIdx}-mobile`}
                                        className="ml-3 text-sm text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </fieldset>
                          )}
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="max-w-6xl px-4 py-16 sm:px-2 sm:py-5 w-full lg:px-2 ">
            <div className="border-b border-gray-200 pb-10">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                All documents and files
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Both files uploaded to cranes and non-crane files can be found
                here.
              </p>
            </div>

            <div className="pt-10 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 ">
              <aside>
                <h2 className="sr-only">Filters</h2>

                <button
                  type="button"
                  className="inline-flex items-center lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="text-sm font-medium text-gray-700">
                    Filters
                  </span>
                  <PlusIcon
                    className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                </button>

                <div className="hidden lg:block">
                  <form className="space-y-10 divide-y divide-gray-200 w-40">
                    {filters.map((section, sectionIdx) => (
                      <div
                        key={section.name}
                        className={sectionIdx === 0 ? null : "pt-10"}
                      >
                        <fieldset>
                          <legend className="block text-sm font-medium text-gray-900">
                            {section.name}
                          </legend>
                          <div className="space-y-3 pt-6">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center cursor-pointer"
                              >
                                <input
                                  id={`${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultChecked={
                                    section.id === "show" ? showCraneFiles : filter.includes(option.value) ? true : false
                                  }
                                  onChange={(e) =>
                                    (section.id === "show" ? setShowCraneFiles(!showCraneFiles) : setFilter(
                                      e.target.checked
                                        ? [...filter, option.value]
                                        : filter.filter(
                                            (item) => item !== option.value
                                          )
                                    ))
                                  }
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                                />
                                <label
                                  htmlFor={`${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600 cursor-pointer hover:text-nsorange-500"
                                  onClick={() =>
                                    option.value === "all"
                                      ? setFilter([
                                          "pdf",
                                          "image",
                                          "3D",
                                          "xml",
                                          "all",
                                        ])
                                      : setFilter(option.value)
                                  }
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    ))}
                  </form>
                  <div className="mt-10 w-32">
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      <Stack>
                        <Button variant="contained" component="label">
                          Select File
                          <input
                            type="file"
                            hidden
                            onChange={handleFileSelect}
                          />
                        </Button>
                      </Stack>
                      {file && (
                        <Button
                          variant="contained"
                          component="label"
                          onClick={uploadFile}
                        >
                          Upload file
                        </Button>
                      )}
                    </Box>
                    <Box>{file ? file.name : ""}</Box>
                  </div>
                </div>
              </aside>
              

              {/* Product grid */}
              {dataError ? (
                <div className="col-span-3 h-full flex justify-center items-center text-2xl font-semibold ">
                  {dataError.message}
                </div>
              ) : (
                // Assuming `filteredFiles` is already grouped by crane ID, and each group contains a list of files.
                <div className="w-full mt-6 col-span-3 lg:mt-0 p-6 flex flex-col ">
                <div className="mb-40 rounded-lg ">
                <p></p>
                <div className="grid grid-cols-6 gap-x-20 gap-y-6 ">
                        {documents?.length > 0 ? documents?.filter(file => !file.title.includes("MEX_")).map((file) => (
                          <GetFileImg
                            key={file.id}
                            fileName={file.title}
                            fileType={file.title}
                            crane={null}
                            id={file.id}
                          />
                        ))
                        :
                        <div className="text-center w-full col-span-4 flex flex-col justify-center items-center border-dashed border rounded-lg p-10">
                          <DocumentIcon className="h-10 w-10 text-gray-400" aria-hidden="true" />
                          
                          <h3 className="mt-2 text-sm font-semibold text-gray-900">No files</h3>
                          <p className="mt-1 text-sm text-gray-500">Upload files to show them here</p>
                          
                        </div>}
                      </div>
                </div>
                  {showCraneFiles && filteredFiles.map((craneGroup, index) => (
                    <div key={`crane-group-${index}`} className="mb-20">
                      {/* Render the headline for each crane group */}
                      <h2 className="text-2xl font-bold cursor-pointer" onClick={() => navigate(`/cranes/${craneGroup.craneId}`)}>
                        {craneGroup.craneName}
                      </h2>
                      <di className="flex flex-row mb-8 cursor-pointer" onClick={() => navigate(`/cranes/${craneGroup.craneId}`)}>
                        <p>{craneGroup.craneCountry}</p> /{" "}
                        <p>{craneGroup.craneCity}</p>/
                        <p>{craneGroup.craneLocation}</p>
                      </di>
                      {/* Render the files for the current crane in a grid */}
                      <div className="grid grid-cols-6 gap-x-20 gap-y-6 " >
                        {craneGroup.files.length > 0 ? craneGroup.files.map((file) => (
                          <GetFileImg
                            key={file.id}
                            fileName={file.fileName}
                            fileType={file.folderName}
                            crane={file.crane.id}
                            id={file.id}
                          />
                        ))
                        : <div className="text-center w-full col-span-4 flex flex-col justify-center items-center border-dashed border rounded-lg p-10">
      <DocumentIcon className="h-10 w-10 text-gray-400" aria-hidden="true" />
      
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No files</h3>
      <p className="mt-1 text-sm text-gray-500">Try changing your filter</p>
      
    </div>}
                        
                      </div>
                    </div>
                  ))}

                  
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Filter />
    </div>
  );
};

export default Documents;

function XMLParserComponent() {
  const [tags, setTags] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xmlString = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");
        const uaVariables = xmlDoc.getElementsByTagName("UAVariable");

        let extractedTags = [];
        for (let variable of uaVariables) {
          const displayNameNode = variable.getElementsByTagName(
            "DisplayName"
          )[0];
          const dataTypeNode = variable.getAttribute("DataType");

          if (displayNameNode && dataTypeNode) {
            const displayName = displayNameNode.textContent;
            const dataType = dataTypeNode;

            // Filter out specific tags
            if (
              !["Icon", "InterfaceUuid", "InterfaceVersion"].includes(
                displayName
              )
            ) {
              extractedTags.push({
                name: displayName,
                type: dataType,
              });
            }
          }
        }
        setTags(extractedTags);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept=".xml" />
      <div>
        <h3>Extracted Tags:</h3>
        <ul>
          {tags.map((tag, index) => (
            <li key={index}>
              {tag.name} (Type: {tag.type})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const filters = [
  {
    id: "type",
    name: "File Type",
    options: [
      { value: "all", label: "All Types" },
      { value: "pdf", label: "PDF" },
      { value: "image", label: "Image" },
      { value: "xml", label: "XML" },
      { value: "3D", label: "3D-Model" },
    ],
  },
  {
    id: "show",
    name: "Visibility",
    options: [{ value: "crane", label: "Show crane files" }],
  },
  // {
  //   id: 'crane',
  //   name: 'Filter by Crane',
  //   options: [
  //     { value: 'crane', label: 'Crane 1' },
  //     { value: 'crane', label: 'Crane 2' },
  //     { value: 'crane', label: 'Crane 3' },
  //   ],
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
