import React from "react";
import useFetchV2, { deleteCranePart } from "../../../../components/PartsApi";
import { Fragment, useState } from 'react'
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { deleteCrane, deleteCraneLog, deleteZone, updateCrane } from "../../../../components/CraneApi";
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom";
import { deleteContent, deleteDocument, deleteFile, deleteFileFromDB, deleteModelconf, getContent, getDocument, getFile, getFileContent, getFileMimeType, postFile, uploadContent, uploadDocument } from "../../../../components/FilesApi";
import { deleteUserAccess } from "../../../../components/UserApi";
import { deleteContainerAtId, deleteMarker, deleteOrder } from "../../../../components/OrderApi";
import { uploadfile } from "../documents";

const Settings = ({ id, userRoles }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    data: cranedata,
    loading: craneloading,
    error: craneerror,
    refetch: refetchCraneData,
  } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${id}`);

  const {
    data: craneDocs,
    loading: craneDocsloading,
    error: craneDocserror,
    refetch: refetchCraneDocs,
  } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranedocs?fileName.contains=craneimage&craneId.equals=${id}&folderName.equals=image&page=0&size=200`);
  
  const [uploadedImage, setUploadedImage] = useState(null);


  const handleSave = async (event) => {
    event.preventDefault();
    let crane = cranedata;
    crane.name = document.getElementById("name").value;
    crane.location = document.getElementById("location").value;
    crane.city = document.getElementById("city").value;
    crane.country = document.getElementById("country").value;

    
    // crane.craneconfiguration = `${event.target[6].checked ? 1 : 2}${
    //   event.target[9].checked ? 1 : 2
    // }${event.target[12].checked ? 1 : 2}`;
    //crane.opcIp = "ip";

    console.log(crane);
    const post = await updateCrane(id, crane);
    if (post) {
      toast.success("Successfully updated crane");
    } else {
      toast.error("Could not update crane!");
    }
    if(uploadedImage) {
     
      const uploaded = await uploadImage();
      console.log(uploaded);
      if(uploaded) {
       setUploadedImage(null);
       setCraneimage(URL.createObjectURL(uploadedImage));
      } else {
        toast.error("Could not upload image!");
      }
    }
  };
  const [craneimage, setCraneimage] = useState("");

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = async () => {
    try {
      if (!uploadedImage) {
        throw new Error("No file provided");
      }

      const type = uploadedImage.name.split(".");
      const base64 = await readFileAsBase64(uploadedImage);

      let body = {
        id: "",
        fileName: "craneimage",
        folderName: "image",
        crane: {
          id: id,
        },
      };

      const fileMimeType = getFileMimeType(id + "_craneimage.png");
      
      // I'm assuming uploadContent and other functions are defined elsewhere in your code
      try {
        toast.info("Uploading image file...");
        
        await uploadContent({
          dataContentType: fileMimeType,
          data: base64,
        })
        .then((content) =>
          uploadDocument({
            title: id + "_craneimage.png",
            documentSize: uploadedImage.size,
            mimeType: fileMimeType,
            content: content,
            craneRelated: true,
          })
        )
        .then((document) =>
          postFile({
            ...body,
            document: document,
          })
        )
        .catch((error) => {
          toast.error(error.message || "Error during upload");
        });

        toast.success("File uploaded successfully!");
        return true;
      } catch (error) {
        toast.error("Something went wrong with the upload...");
        console.error(error);
        return false;
      }

    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    }
  };
  

  React.useEffect(() => {

    const downloadImages = async () => {
     
        console.log(cranedata.id);
 

        const image = await getFileContent(craneDocs[0]?.document?.id);
          if (!image) {
            //console.error("No data received for download for crane: " + crane.name);
            // Again, you could handle this case with UI feedback
            toast.error("No data received for download for crane");
            return;
          }
    
        

        if(image) {
          const url = URL.createObjectURL(image);
          console.log(url);
          setCraneimage(url);
          
        }
      
      
    }
    
    if(cranedata && craneDocs && craneDocs.length > 0 && !craneloading && !craneDocsloading) {
      console.log("cranedata ",cranedata);
        downloadImages();
        
    }
  }, [cranedata, craneloading, craneDocs])

  const handleDeleteImage = async () => {
    if(uploadedImage) {
      setUploadedImage(null);
      setCraneimage(null);
      return;
    }
    const deleted = await deleteSelectedFile(craneDocs[0]);
    if(deleted) {
      toast.success("Successfully deleted image");
      setCraneimage("");
    } else {
      toast.error("Could not delete image!");
    }
    
  }

  const deleteSelectedFile = async (cranedoc) => {
    
    toast.info("Deleting file...");
    console.log(cranedoc);
   
    try {
    

        
        const deleteCranedoc = await deleteFile(cranedoc.id);
        console.log("Deletecranedoc: ", deleteCranedoc);
        const document = await getDocument(cranedoc.document.id);
        const deleteDocumentData = await deleteDocument(cranedoc.document.id);
        
        console.log("Deletedocument: ", deleteDocumentData);
        const content = await getContent(document.content.id);
        if (content) {
          toast.info("Found content data...");
        }

       
        const deleteContentData = await deleteContent(content.id);
        console.log("Deletecontent: ", deleteContentData);
      

      

      // console.log(cranedoc, document);

      
      return true;
     
    } catch (error) {
      
      console.log(error);
      return false;
    }

  
  };

  const handleDelete = async () => {

    // modelconf
    // cranedocs
    // cranelocations
    // craneusers
    // orders
    // containers
    // cranepart
    // zone
    // opc

    // delete image
    toast.info("Deleting crane...");

    if(craneimage){
      await handleDeleteImage();
    }
    const post = await deleteCrane(id);
    if (post) {
      toast.success("Successfully deleted crane");
      navigate("/cranes");
    } else {
      toast.error("Could not delete crane!");
    }

  };

  return (
    <div>
    <DeleteDialog open={open} setOpen={setOpen} handleDelete={handleDelete} id={id} image={craneimage}/>
      <div className="">
        {!craneloading && (
          <form onSubmit={handleSave}>
            <div className="space-y-12">
              <div className="grid grid-cols-1 gap-x-32 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Crane
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Edit the name and location.
                  </p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                  <div className="col-span-full">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Crane Image
                    </label>
                    <div className="flex flex-row gap-5">
                    {craneimage || uploadedImage ? <div className="">
                      <div className=" aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100  w-72" >
                      <img src={craneimage} alt="craneimage" className="object-cover object-center "/>
                      </div>
                      <div className="flex flex-row justify-between">
                      {uploadedImage && <p>Unsaved</p>}
                      {<p className="cursor-pointer" onClick={handleDeleteImage}>Remove Image</p>}
                      </div>
                    </div> :
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-gray-300"
                            aria-hidden="true"
                          />
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="px-1 relative cursor-pointer rounded-md bg-nsorange-100 font-semibold text-nsorange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-nsorange-500 focus-within:ring-offset-2 hover:text-nsorange-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={(event) => {
                                  if (event.target.files && event.target.files[0]) {
                                    const file = event.target.files[0];
                                    console.log(file); // This will log the file object
                                    // You can now use this file object as needed
                                    if (file && file.size > 1000000){
                                      //toast.error("File too large! Max 1MB");
                                     
                                    } 
                                    setCraneimage(URL.createObjectURL(file));
                                    setUploadedImage(file);
                                  }
                                }}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            PNG, JPG, up to 1MB
                          </p>
                        </div>
                      </div>}
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={cranedata?.name}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-nsblue-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Country
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="country"
                        id="country"
                        defaultValue={cranedata?.country}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-nsblue-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="city"
                        id="city"
                        defaultValue={cranedata?.city}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-nsblue-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Location
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="location"
                        id="location"
                        defaultValue={cranedata?.location}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-nsblue-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>

              

              <div className="grid grid-cols-1 gap-x-32 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">Delete crane</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    You can delete your crane here. This action is not reversible.
                    All data related to this crane will be deleted permanently (parts list, 3D configuration, users with access).
                  </p>
                </div>
                
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <form className="flex items-start md:col-span-4 ">
                  <button
                    type="submit"
                    onClick={(event) => {if(userRoles?.some(role => role === "ROLE_MANAGE_CRANES" || role === "ROLE_ADMIN")){
                      setOpen(true);
                    }
                    else {
                      toast.error("You do not have permission to manage cranes");
                    } event.preventDefault();}}
                    className="rounded-md  px-3 py-2 text-sm font-semibold text-red-500 border border-red-200"
                  >
                    Delete the crane and related data
                  </button>
                </form>
                </div>
              </div>
            </div>

            

           

            <div className="mt-6 flex items-center justify-end gap-x-6">
              
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-nsblue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-nsblue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-nsblue-600"
              >
                Save
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Settings;


function DeleteDialog({open, setOpen, id, image}) {
  
  const { data: modelconfData, loading: modelconfDataLoading, error: modelconfDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/modelconfs?craneId.equals=${id}&page=0&size=1000`);
  const { data: userData, loading: userDataLoading, error: userDataError, refetch: refetchUserData} = useFetchV2(`https://noswingcc.net/noswingcc/api/craneusers?craneId.equals=${id}&page=0&size=100`);
  const { data: partData, loading: partDataLoading, error: partDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/craneparts?craneId.equals=${id}&page=0&size=100`);
  const { data: orderData, loading: orderDataLoading, error: orderDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/craneorders?craneId.equals=${id}&page=0&size=100`);
  const { data: logData, loading: logDataLoading, error: logDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/cranelogs?craneId.equals=${id}&page=0&size=1000`);
  const { data: locationData, loading: locationDataLoading, error: locationDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${id}&page=0&size=100`);
  const { data: docsData, loading: docsDataLoading, error: docsDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&page=0&size=100`);
  const { data: containersData, loading: containersDataLoading, error: containersDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/containers?craneId.equals=${id}&page=0&size=100`);
  const { data: opcData, loading: opcDataLoading, error: opcDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/opcs/count?craneId.equals=${id}`);
  const { data: zoneData, loading: zoneDataLoading, error: zoneDataError} = useFetchV2(`https://noswingcc.net/noswingcc/api/zones?craneId.equals=${id}&page=0&size=100`);

  const navigate = useNavigate();

  console.log(userData);

  const handleDelete = async () => {

    if(modelconfData?.length > 0) {
    
      for (const modelconf of modelconfData) {
        const success = await deleteModelconf(modelconf.id);
      }
    }
    if(userData?.length > 0) {
      console.log("userData", userData);
      for (const user of userData) {
        console.log("deleting ", user.user_login);
        const success = await deleteUserAccess(user.id);
      }
      refetchUserData();
    }

    if(containersData?.length > 0) {
      console.log("containersData", containersData);
      for (const container of containersData) {
        const success = await deleteContainerAtId(container.id);
      }
      
    }


    if(partData?.length > 0) {
      console.log("partData", partData);
      for (const part of partData) {
        const success = await deleteCranePart(part.id);
      }
    }

    if(orderData?.length > 0) {
      console.log("orderData", orderData);
      for (const order of orderData) {
        const success = await deleteOrder(order.id);
      }
     
    }
    if(locationData?.length > 0) {
      console.log("locationData", locationData);
      for (const location of locationData) {
        const success = await deleteMarker(location.id);
      }
     
    }

    if(logData?.length > 0) {
      console.log("logData", logData);
      for (const log of logData) {
        const success = await deleteCraneLog(log.id);
      }
     
    }

    

    if(docsData?.length > 0) {
      console.log("docsData", docsData);
      for (const doc of docsData) {
        const success = await deleteSelectedFile(doc);
      }
     
    }

    
    // if(opcData > 0) {
    //   console.log("opcData", opcData);
    //   toast.error("Could not delete crane! There are OPCs related to this crane.");
      
    // }
    if(zoneData?.length > 0) {
      console.log("zoneData", zoneData);
      for (const zone of zoneData) {
        const success = await deleteZone(zone.id);
      }
    
      
    }
  

   
    const post = await deleteCrane(id);
    if (post) {
      toast.success("Successfully deleted crane");
      navigate("/cranes");
    } else {
      toast.error("Could not delete crane!");
      return;
    }

  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-nsblue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Delete crane
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this crane? All data related to this crane will be deleted permanently:
                      </p>
                      
                      <div className="text-base mt-3  font-semibold flex flex-col gap-1" >
                        {modelconfData?.length > 0 && <p>Modelconfigurationsdata</p>}
                        {userData?.length > 0 && <p>Usersdata</p>}
                        {partData?.length > 0 && <p>Partsdata</p>}
                        {orderData?.length > 0 && <p>Ordersdata</p>}
                        {logData?.length > 0 && <p>Logsdata</p>}
                        {locationData?.length > 0 && <p>Locationsdata</p>}
                        {docsData?.length > 0 && <p>Documentsdata (including 3D files)</p>}
                        {containersData?.length > 0 && <p>Containersdata</p>}
                        {/* {opcData > 0 && <p>OPCdata</p>} */}
                        {zoneData?.length > 0 && <p>Zonedata</p>}
                        {image && <p>Crane Image</p>}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {setOpen(false); handleDelete();}}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const deleteSelectedFile = async (cranedoc) => {
 
  //toast.info("Deleting file...");
 
  try {
    
    if (cranedoc.document === null) {
      //toast.error("File has no document: " + cranedoc.id + " _ " + cranedoc.fileName);
      const deleteCranedoc = await deleteFile(cranedoc.id);
      
    } else {
      console.log("cranedoc id: ", cranedoc.id);

      const document = await getDocument(cranedoc.document.id);
      if(document.title.includes("MEX_")){
        const deleteCranedoc = await deleteFile(cranedoc.id);
        //toast.info("This file is linked to example file.");
        
        return;
      }
      const deleteCranedoc = await deleteFile(cranedoc.id);
      console.log("Deletecranedoc: ", deleteCranedoc);
      const deleteDocumentData = await deleteDocument(document.id);
      console.log("Deletedocument: ", deleteDocumentData);
      const content = await getContent(document.content.id);
     

     
      const deleteContentData = await deleteContent(content.id);
      console.log("Deletecontent: ", deleteContentData);
    }

    

    // console.log(cranedoc, document);

    //toast.success("Successfully deleted the file");
    
  } catch (error) {
    toast.error("Something went wrong with the deletion...");
    console.log(error);
  }

  // const deleteFile = await deleteFileFromDB(file);

  // if(deleteFile){

  //   const id = files.filter(item => item.fileName == file && item.crane.id == crane)[0].id;
  //   const deleted = await deleteFile(id);
  //   if(deleted){
  //   toast.success("Successfully deleted the file");
  //   refetchAllData();

  //   }
  //   else {
  //     toast.error("Something went wrong with the deletion...");
  //   }
  // }
  // else{
  //   toast.error("Something went wrong with the deletion...");
  // }
};