import React, {useEffect, useState} from 'react'
import useFetchV2 from '../../../components/PartsApi'
import { Box, Typography, Stack, Checkbox, Button, CircularProgress } from '@mui/material';
import { deleteContainerAtId, deleteOrder, getOrdersToDelete } from '../../../components/OrderApi';
import LinearProgress from '@mui/material/LinearProgress';

const Warehouse = ({id, fullMarkerData, warehouseError}) => {
    const [containerData, setContainerData] = useState([]);
    const { data: containers, loading: containerDataLoading, error: containerDataError, refetch: refetchAllContainers } = useFetchV2(`https://noswingcc.net/noswingcc/api/containers?id.greaterThan=9&page=0&size=1000`);
    const [selected, setSelected] = useState();
    const [selectedItems, setSelectedItems] = useState();
    const [deleteProgress, setDeleteProgress] = useState(0);

    //console.log(containers);
    useEffect(() => {
        
        if(fullMarkerData != undefined)
            setContainerData(fullMarkerData);
    }, [fullMarkerData])

    if(containerDataLoading){
        return "Loading";
    }
    // Step 1: Sort the array based on cranelocation.id
        containers.sort((a, b) => a.cranelocation.id - b.cranelocation.id);

        // Step 2: Group the sorted array by cranelocation.id
        const groupedByCraneLocation = containers.reduce((acc, item) => {
            if (!acc[item.cranelocation.id]) {
                acc[item.cranelocation.id] = [];
            }
            acc[item.cranelocation.id].push(item);
            return acc;
        }, {});

        // Step 3: Sort each group of containers by rowindex
        for (const craneLocationId in groupedByCraneLocation) {
            groupedByCraneLocation[craneLocationId].sort((a, b) => a.rowindex - b.rowindex);
        }
    const updateSelected = (item) => {
        console.log(item);
        setSelectedItems();
        setSelected(item);
    }
    const updateSelectedItems = (item) => {
        console.log(item);
        setSelected();
        setSelectedItems(item);
    }

    const deleteContainer = async (containerid) => {
        console.log(id);
        const orders = await getOrdersToDelete(id, containerid);
        
        console.log(orders);
        for (const order of orders) {
            await deleteOrder(order.id);
        }
        const orders2 = await getOrdersToDelete(id, containerid);
        console.log("Deleted orders! ", orders2);
        if(orders2.length == 0){
            await deleteContainerAtId(containerid);
        }
        else{
            alert("error");
        }
        refetchAllContainers();
        setSelected();
    }
    const deleteContainers = async (array) => {
        const step = 100/array.length;

        for(const item in array){
            const orders = await getOrdersToDelete(id, array[item].id);
            
            console.log("Found " , orders.length, " orders for container ",  array[item].id);
            
            for (const order of orders) {
                await deleteOrder(order.id);
            }
            const orders2 = await getOrdersToDelete(id, array[item].id);
            console.log("Deleted orders!");

            if(orders2.length == 0){
                await deleteContainerAtId(array[item].id);
            }
            else{
                alert("error");
            }
            setDeleteProgress(prev => prev + step);
            
        }
        refetchAllContainers();
        setSelectedItems();

    }
  return (
    <Box>
        <Box display={"flex"} flexDirection={"row"} gap={4} mt={10} p={1} border={1} borderRadius={"5px"}>
        {containerData?.map(location => (

            <Stack direction={"column-reverse"}>
                <Typography>{location.locationcode}</Typography>
                {location.stack?.map(item => <Typography>{item}</Typography>)}
            </Stack>
        ))}
        </Box>

        <Box display={"flex"} flexDirection={"row"} gap={5} mt={10} p={1} border={1} borderRadius={"5px"}>
            {
                Object.entries(groupedByCraneLocation)?.map(([craneLocationId, array]) => (
                    <Stack direction={"column-reverse"} key={craneLocationId}>
                        <Typography>{array[0].cranelocation.locationcode}</Typography>
                        
                        {array[0].cranelocation.locationcode === "Scrap" ? <Typography onClick={() => updateSelectedItems(array) }>{array.length} items</Typography> : array.map(item => <Box p={0.1} onClick={() => updateSelected(item) } sx={{cursor: "pointer"}}><Typography key={item.id}>{item.id} | {item.rowindex}.</Typography></Box>)}
                    </Stack>
                ))
            }
        </Box>
        <Box mt={5}>
            {warehouseError && warehouseError?.map(item => <Typography color={"red"}>{item}</Typography>)}
        </Box>
        { selected && 
            <Box mt={10} display={"flex"} flexDirection={"row"} gap={4}>
                <Typography>{selected.id}</Typography>
                <Typography>{selected.containercode}</Typography>
                <Typography>{selected.product?.productcode }</Typography>
                <Button onClick={() => deleteContainer(selected.id)} variant='contained'>Delete Container</Button>
            </Box>
        }
        { selectedItems && 
            <Box mt={10} display={"flex"} flexDirection={"row"} gap={4}>
                <Typography>{selectedItems.length} &nbsp; items selected</Typography>
                <Button onClick={() => deleteContainers(selectedItems)} variant='contained'>Delete All</Button>
                <Box width={200}>
                    <LinearProgress variant="determinate" value={deleteProgress} />
                </Box>
            </Box>
        }
        

    </Box>
  )
}

export default Warehouse