import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, CubeIcon, HomeIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  UsersIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  DocumentIcon,    
  PencilSquareIcon,
  CodeBracketSquareIcon,

} from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import * as THREE from "three";
import { OBJLoader, MTLLoader } from "three-stdlib";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { deleteContent, deleteDocument, get3DFile, getContent, getDocument, getFileMimeType, postFile, updateFile, updateModelconf, uploadContent, uploadDocument } from "../../../../components/FilesApi";

import beem from "./images/beem.svg";
import trolley from "./images/trolley.svg";
import hook from "./images/hook.svg";
import grabber from "./images/grabber.svg";
import building from "./images/building.svg";
import { CircularProgress, LinearProgress } from "@mui/material";
import useFetchV2 from "../../../../components/PartsApi";

const models = [
  {
    id: 1,
    name: "Building",
    icon: building,
    profileUrl: "#",
  },
  {
    id: 2,
    name: "Beem",
    icon: beem,
    profileUrl: "#",
  },
  {
    id: 3,
    name: "Trolley",
    icon: trolley,
    profileUrl: "#",
  },
  {
    id: 4,
    name: "Hook",
    icon: hook,
    profileUrl: "#",
  },
  {
    id: 5,
    name: "Grabber",
    icon: grabber,
    profileUrl: "#",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Library({
  open,
  setOpen,
  obj,
  setStep,
  setObjFiles,
  setFilePaths,
  load,
  id,
  existingDocs,
  reloadCraneFiles,
  modelconf,
}) {
  const [query, setQuery] = useState("");
  const filteredPeople = models;
  const [activePerson, setActivePerson] = useState(models[0]);
  const cancelButtonRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [optionMarked, setOptionMarked] = useState({});
  const [isNoFileSelected, setIsNoFileSelected] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileContainsGrabber, setFileContainesGrabber] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [confFile, setConfFile] = useState({});
  const [isConfDataUpToDate, setIsConfDataUpToDate] = useState(false);
 
  const [exampleName, setExampleName] = useState("");
  const [modelFiles, setModelFiles] = useState([]);
  const [filesLoaded, setFilesLoaded] = useState(0);
  const [admin, setAdmin] = useState(false);
  const [examples, setExamples] = useState([]);
  const [uploading, setUploading] = useState(0);
  const [contents, setContents] = useState([]);
  const [selectedConf, setSelectedConf] = useState(null);

  const {
    data,
    loading: dataIsLoading,
    error: dateError,
    refetch: refetchAllData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&folderName.equals"3D"&page=0&size=50`
  );

  const {
    data: documents,
    loading: documentsIsLoading,
    error: documentsError,
    refetch: refetchAllDocuments,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/documents?title.contains=MEX_&page=0&size=1000`
  );
    
  const [fakeFiles, setFakeFiles] = useState([
    {
      name: "NoSwing Demo",
      type: "Building",
      example: "Example_1",
      info: "Noswing Simulation Enviroment",
      data: "loading",
    },
    {
      name: "Technobotnia Vaasa",
      type: "Building",
      example: "Example_2",
      info: "TB crane enviroment",
      data: "loading",
    },
    {
      name: "Empty",
      type: "Building",
      example: "Example_1",
      info: "An empty building",
      data: "loading",
    },

    {
      name: "NoSwing Demo",
      type: "Beem",
      example: "Example_1",
      info: "Information about Beem File 1",
      data: "loading",
    },
    {
      name: "Technobotnia Vaasa",
      type: "Beem",
      example: "Example_2",
      info: "TB crane Beem",
      data: "loading",
    },
    {
      name: "Konecranes Beem",
      type: "Beem",
      example: "Example_1",
      info: "Information about Beem File 2",
      data: "loading",
    },
    {
      name: "Chinese Beem",
      type: "Beem",
      example: "Example_1",
      info: "Information about Beem File 3",
      data: "loading",
    },

    {
      name: "NoSwing Demo",
      type: "Trolley",
      example: "Example_1",
      info: "Just a trolley",
      data: "loading",
    },
    {
      name: "Technobotnia Vaasa",
      type: "Trolley",
      example: "Example_2",
      info: "TB crane Trolley",
      data: "loading",
    },

    {
      name: "NoSwing Demo",
      type: "Hook",
      example: "Example_1",
      info: "Hook + NoSwing Grabber",
      data: "loading",
    },
    {
      name: "Technobotnia Vaasa",
      type: "Hook",
      example: "Example_2",
      info: "Hook + KP Grabber v1",
      data: "loading",
    },

    // {
    //   name: "NoSwing Demo",
    //   type: "Grabber",
    //   example: "Example_1",
    //   info: "Information about Grabber File 1",
    //   data: "loading",
    // },
  ]);

  function preRender(mtlText, objText) {
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlText);
    mtl.preload();

    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const obj = objLoader.parse(objText);

    return obj;
  }



  //console.log(files);
  React.useEffect(() => {
    const decodeContent = (base64Content) => {
      return atob(base64Content);
    };

    const processFiles = async () => {
      // Assume documents is flat and contains both obj and mtl files
      const processedFiles = [];
      const imageFiles = [];
      const confFiles = [];
  
      for (const doc of documents) {
        if (doc.mimeType === 'model/obj') {
          // Assuming you have some way to associate obj and mtl files, maybe via naming convention
          const baseName = doc.title.replace('.obj', '');
          const mtlDoc = documents.find(d => d.title === `${baseName}.mtl`);
          
          if (mtlDoc) {
            // Fetch contents only if both obj and mtl files are present
            const objContent = await getContent(doc.id);
            const mtlContent = await getContent(mtlDoc.id);

            setContents((prev) => [...prev, objContent, mtlContent]);
            
            if (objContent && mtlContent) {
              const decodedObjContent = decodeContent(objContent.data);
            const decodedMtlContent = decodeContent(mtlContent.data);
            const objModel = preRender(decodedMtlContent, decodedObjContent);
              processedFiles.push({
                ...doc, // Spread the original doc object if you want to keep all its properties
                mtlDocId: mtlDoc.id, // Store the mtl file id for later use
                objModel: objModel, // This is your preRendered model
              });
              setFilesLoaded(processedFiles.length);
            }
          }
        }
        if(doc.mimeType === 'image/png' || doc.mimeType === 'image/jpeg'){
          const imgContent = await getContent(doc.id);
          if(imgContent){
            const decodedImgContent = imgContent.data;
            imageFiles.push({
              name: doc.title,
              image: decodedImgContent,
            });
            //toast.success("Image loaded, " + doc.title);
          }
        }
        if(doc.mimeType === 'text/plain' ){
          const confContent = await getContent(doc.id);
          if(confContent){
            const decodedConfContent = decodeContent(confContent.data);
           confFiles.push({
              name: doc.title,
              conf: decodedConfContent,
            });
            //toast.success("Conf loaded: " + doc.title);
          }
        }
      }
  
      // ProcessedFiles now contains documents with their associated 3D models pre-rendered
      console.log("processedfiles: ",processedFiles);
      setModelFiles(processedFiles);
      // Assuming processFiles is your processed array with objModel included
        const formattedFiles = processedFiles.map(file => {
          // Extracting name and type from the title
          const matches = file.title.match(/MEX_(.*?)_(.*?)\.obj$/);

          if (matches && matches.length > 2) {
            const name = matches[1].replace(/_/g, ' '); // Replace underscores with spaces
            const type = matches[2];

            return {
              name: name,
              type: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize first letter
              example: "", // Placeholder, as you specified
              info: "", // Placeholder, as you specified
              data: file.objModel, // Assign the pre-rendered 3D model
              objDocId: file.id, // Store the obj file id for later use
              mtlDocId: file.mtlDocId, // Store the mtl file id for later use
            };
          }

          // In case the regex doesn't match, return a placeholder object
          return {
            name: "Unknown",
            type: "Unknown",
            example: "",
            info: "",
            data: null,
            objDocId: null,
            mtlDocId: null,
          };
        });

        console.log(formattedFiles);
        setFakeFiles(formattedFiles);

        const uniqueNames = [...new Set(formattedFiles.map(item => item.name))];

        setExamples(
          uniqueNames.map((item) => ({
            name: item,
            image: imageFiles.find((img) => img.name.includes(item))?.image,
            conf: confFiles.find((conf) => conf.name.includes(item))?.conf,
          }))
        );
        
        console.log(uniqueNames.map((item) => ({
          name: item,
          image: imageFiles.find((img) => img.name.includes(item))?.image,
          conf: confFiles.find((conf) => conf.name.includes(item))?.conf,
        })));

        console.log("Contents: ", contents);

      // Use processedFiles for your state or further processing
    };
  
    if (documents?.length) {
      processFiles();
    }
  }, [documents]);
  
  

  const isItemSelected = (item) => {
    return optionMarked.name == item.name && optionMarked.type == item.type;
  };
  const isTypeSelected = (type) => {
    return selectedItems[type] !== undefined;
  };
  const handleSelectItem = (item) => {
    setSelectedItems((prevItems) => {
      // Create a new object based on the previous state
      const newItems = { ...prevItems };

      // If the item of the same type is already selected, remove it
      if (newItems[item.type] === item) {
        delete newItems[item.type];
      } else {
        // Update the selected item for the given type
        newItems[item.type] = item;
      }
      //console.log("New Items:", newItems);
      return newItems;
    });
  };

  const handleSave = async (event) => {

    // const confFile = examples.find((item) => item.name === selectedItems["Beem"].name);
   //console.log(selectedConf.conf);
  

    let count = models.length;
    if (fileContainsGrabber) {
      count = count - 1;
    }
    //console.log(selectedItems);
    let pathsObject = {};
    console.log(selectedItems);

    for (const [type, item] of Object.entries(selectedItems)) {
      //console.log(`Type: ${type}, Item: ${item}`);
      //console.log("Item: ", item);

      if(true){
      

        let mtlID = data.find(cranedoc => cranedoc.fileName.includes(`${type}.mtl`))?.id || "";
        let objID = data.find(cranedoc => cranedoc.fileName.includes(`${type}.obj`))?.id || "";

        let bodyObj = {
          id: objID,
          fileName: `${type.charAt(0).toUpperCase() + type.slice(1)}.obj`,
          folderName: "3D",
          dbPath: "",
          crane: { id: id },
          document: { id: item.objDocId },
        };
        //console.log(data.filter(cranedoc => cranedoc.fileName.includes(type)));
        

        let bodyMtl = {
          id: mtlID,
          fileName: `${type.charAt(0).toUpperCase() + type.slice(1)}.mtl`,
          folderName: "3D",
          dbPath: "",
          crane: { id: id },
          document: { id: item.mtlDocId },
        };
       

        try {
          let success = false;
          if(data.some(cranedoc => cranedoc.fileName.includes(type))){
          const updateObj = await updateFile(bodyObj, objID);
          const updateMtl = await updateFile(bodyMtl, mtlID);
          //console.log("Should UPDATE, " + type, bodyObj, bodyMtl);
          //toast.info("Updating files..." + type);
          if(updateObj && updateMtl){
            success = true;
          }
        }
          
          else {
            //console.log("Should UPLOAD, " + type, bodyObj, bodyMtl);
            //toast.info("Uploading files..." + type);
            bodyObj.id = '';
            bodyMtl.id = '';
            const uploadObj = await postFile(bodyObj);
            const uploadMtl = await postFile(bodyMtl);
            if(uploadObj && uploadMtl){
           
            success = true;
            }
           
          }
          if(success){
            toast.success(`${type} updated`);
           
          }
          else {
            toast.error(`Error updating ${type}`);
          }

        } catch (error) {
          toast.error(`Error updating ${type}: ${error}`);
          return;
          
        }
      }
      else {
      
        
        
      }
    }
    let step = 1;
    if(selectedConf === null){
      toast.info("Not loading conf file ");
     }
     else {
      if(selectedItems["Beem"].name === selectedConf.name && selectedItems["Trolley"].name === selectedConf.name && selectedItems["Hook"].name === selectedConf.name){
        //toast.info("Loading conf file");
        let newmodelconf = JSON.parse(selectedConf.conf);
        newmodelconf.id = modelconf[0].id;
        newmodelconf.crane = { id: id };
        //console.log(newmodelconf, modelconf[0]);
        const update = await updateModelconf(newmodelconf.id, newmodelconf);
        if(update){
          toast.success("Configuration file loaded");
          step = 6;
        }
        else{
          toast.error("Error loading configuration file");
        }
        
      }
      else {
        toast.info("Conf file not matching selected items");
      }
     }
    
    reloadCraneFiles();
    setStep(step);
    setOpen(false);
 
  };

  const handleSelectExample = (example) => {
    console.log(example);
    setSelectedConf(example);
    let selectedFiles = fakeFiles.filter((item) => item.name.includes(example.name));
    selectedFiles.forEach((item) => {
      handleSelectItem(item);
    });
    if(selectedFiles.some((item) => item.type == "Grabber")){
      setFileContainesGrabber(false);
    }
    else {
      setFileContainesGrabber(true);
    }
    console.log(selectedFiles);
    console.log(selectedItems);
  }

  const uploadLibraryExample = async () => {
    console.log("Name: ", exampleName);
    const length = files.length;
    for (const file of files) {
      let type = file.name.split(".")[1];
      let model = "";
      if(file.name.includes("Building")){
        model = "Building";
      }
      else if(file.name.includes("Beem")){
        model = "Beem";
      }
      else if(file.name.includes("Trolley")){
        model = "Trolley";
      }
      else if(file.name.includes("Hook")){
        model = "Hook";
      }
      else if(file.name.includes("Grabber")){
        model = "Grabber";
      }
      else if(file.name.includes("Image")){
        model = "Image";
      }
      else if(file.name.includes("ConfData")){
        model = "ConfData";
      }

      let fileName = "MEX_" + exampleName + "_" + model.toLowerCase() + "." + type;
      await uploadAFile(null, file, fileName);
      setUploading(prev => prev + 1);
    }
    toast.success("Example uploaded");
    refetchAllDocuments();
    setAdmin(false);
    console.log("files: ", files);
    console.log("uploadedFiles: ", uploadedFiles);
  };

  const handleDeleteExample = async (example) => {
    console.log("Deleting: ", example);
    const selectedFiles = documents.filter(item => item.title.split('_').slice(1, -1).join(' ') === example);

    console.log(selectedFiles, data);
    
    
    for (const file of selectedFiles) {
      

      if(data.some(cranedoc => cranedoc.document.id === file.id)){
        toast.error("Cannot delete example, it is in use");
        return;
        
      }

     
      
      const deleted = await deleteSelectedFile(file);
      toast.info("File deleted");
      
    }
    toast.success("Example deleted");
    refetchAllDocuments();
    refetchAllData();
   
  }

  const uploadAFile = async (body = null, file, fileName) => {
    console.log("Uploading: ", file, fileName);
    const readFileAsBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
  
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };
  
  
      try {
        if (!file) {
          throw new Error("No file provided");
        }
  
        const type = file.name.split(".");
        const base64 = await readFileAsBase64(file);
  
       
  
        const fileMimeType = getFileMimeType(fileName);
        
        // I'm assuming uploadContent and other functions are defined elsewhere in your code
        try {
          // toast.info("Uploading file...");
          
          await uploadContent({
            dataContentType: fileMimeType,
            data: base64,
          })
          .then((content) =>
            uploadDocument({
              title: fileName,
              documentSize: file.size,
              mimeType: fileMimeType,
              content: content,
              craneRelated: body === null ? false : true,
            })
          )
          .then((document) => {
            if(body !== null){
              body.document.id = document.id;
              const post = postFile(body);
              toast.success("CRANDOC YEAH!");
            }
          })
         
          .catch((error) => {
            toast.error(error.message || "Error during upload");
          });
  
          toast.success("File uploaded successfully!");
          return true;
        } catch (error) {
          toast.error("Something went wrong with the upload...");
          console.error(error);
          return false;
          
        }
  
      } catch (error) {
        console.error(error);
        toast.error("Failed to upload image");
      }
  
   
  }

  const deleteSelectedFile = async (document = null) => {
   
 
   
    try {
      
      if (document === null) {
        toast.error("File has no document");
        return ;
      } else {
    

       
       
       
        const deleteDocumentData = await deleteDocument(document.id);
        console.log("Deletedocument: ", deleteDocumentData);
        const content = await getContent(document.content.id);
 

       
        const deleteContentData = await deleteContent(content.id);
        console.log("Deletecontent: ", deleteContentData);
      }

      

      // console.log(cranedoc, document);

      toast.success("Successfully deleted the file");
     
    } catch (error) {
      toast.error("Something went wrong with the deletion...");
      console.log(error);
    }

    
  };



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gray-100" onDoubleClick={() => setAdmin(!admin)}>
                    <CubeIcon
                      className="h-16 w-16 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Select 3D Models
                    </Dialog.Title>
                    {admin && <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        {files?.length === 0 ? (
                          <div className="text-center">
                            <DocumentIcon
                              className="mx-auto h-12 w-12 text-gray-300"
                              aria-hidden="true"
                            />
                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="px-1 relative cursor-pointer rounded-md bg-nsorange-100 font-semibold text-nsorange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-nsorange-500 focus-within:ring-offset-2 hover:text-nsorange-500"
                              >
                                <span>Upload files</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  multiple={true}
                                  onChange={(event) => {
                                    if (
                                      event.target.files &&
                                      event.target.files[0]
                                    ) {
                                      const file = event.target.files;

                                      // You can now use this file object as needed
                                      setFiles(Array.from(event.target.files));
                                      // setCraneimage(URL.createObjectURL(file));
                                      // setUploadedImage(file);
                                    }
                                  }}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs leading-5 text-gray-600 w-52 mt-2 ">
                              OBJ and MTL files, named as the model type
                              "Beem.obj and Beem.mtl"
                            </p>
                          </div>
                        ) : (
                          <div>
                          <div className="flex flex-col gap-2">
                            <p className="font-semibold">Files to upload:</p>
                            <div className="grid grid-cols-2 gap-4">
                              {files.length > 0 &&
                                files?.map((item, index) => (
                                  <p key={index} className="text-left">
                                    {item.name}
                                  </p>
                                ))}
                            </div>
                            
                            {uploadedFiles && <button
                              className="mx-auto mt-4 px-4 py-2 bg-nsorange-500 rounded-full text-white"
                              onClick={() => {
                                setFiles([]);
                                setUploadedFiles([]);
                              }}
                            >
                              CLEAR
                            </button>}
                            <div>
                              <input type="text" placeholder="Example Name" onChange={(e) => setExampleName(e.target.value)} className="rounded-lg" />
                            </div>
                            <button
                            type="button"
                            className="mt-5 inline-flex mx-auto justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                            onClick={() => uploadLibraryExample()}
                          >
                            Upload
                          </button>
                          </div>
                          <div>
                            <p>{uploading} files uploaded</p>
                          </div>
                          </div>
                        )}
                      </div>}
                    <div className="my-10">
                    
                      {filesLoaded !== fakeFiles.length && (
                        <div className="mb-5 animate-pulse h-20 w-120">
                          <span className="font-semibold text-lg ">
                            {filesLoaded} / {fakeFiles.length} models downloaded
                          </span>
                        </div>
                      )}

                      {examples?.length > 0 && <div className="flex flex-col items-start gap-2">
                      <p>Quick select</p>
                        <div className="flex flex-row gap-3 mb-10 overflow-auto  w-full pb-5 p-2">
                          {examples?.map((example, index) => fakeFiles?.filter((item) => item.name.includes(example.name))?.length > 2 && <div key={example.name} className="relative min-w-52 w-52 h-44 rounded-xl bg-gray-200 flex flex-col items-center justify-end cursor-pointer shadow-lg " onClick={() => handleSelectExample(example)}>
                            <img src={`data:image/png;base64,${example.image}`} className="rounded-lg w-52 h-44 object-cover object-center" alt="crane"/>
                            {admin && <button className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-2 z-10" onClick={() => handleDeleteExample(example.name)}>X</button>}
                            {admin && example.conf && <CodeBracketSquareIcon className="absolute top-1 bg-white rounded-md left-1  text-black z-10 h-8 w-8" />}
                            <h2 className="text-lg font-semibold text-gray-900 absolute bg-gray-200 w-full rounded-b-lg py-2">{example.name}</h2>
                          </div>)}
                        </div>
                      </div>}
                      <Combobox
                        value={activePerson}
                        onChange={(person) => setActivePerson(person)}
                      >
                        {({ activeOption }) => (
                          <>
                            {models.length > 0 && (
                              <Combobox.Options
                                as="div"
                                static
                                hold
                                className="flex transform-gpu divide-x divide-gray-100"
                              >
                                <div
                                  className={classNames(
                                    "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                                    activeOption && "sm:h-96"
                                  )}
                                >
                                  <div className="-mx-2 text-sm text-gray-700">
                                    {models.map((person) => (
                                      <Combobox.Option
                                        as="div"
                                        key={person.id}
                                        value={person}
                                        className={({ active }) =>
                                          classNames(
                                            "flex cursor-default select-none items-center rounded-md p-2",
                                            active &&
                                              "bg-gray-100 text-gray-900"
                                          )
                                        }
                                      >
                                        {({ active }) => (
                                          <>
                                            <img
                                              src={person.icon}
                                              alt="icon"
                                              className="h-6 w-6 flex-none"
                                            />
                                            <div className="flex-auto truncate flex-row gap-2">
                                              <span className="ml-3 ">
                                                {person.name}
                                              </span>
                                              <span className="ml-3  text-gray-400">
                                                {`(${
                                                  fakeFiles.filter(
                                                    (item) =>
                                                      item.type ==
                                                        person.name &&
                                                      item.data !== "loading" &&
                                                      item.data !== ""
                                                  )?.length
                                                })`}
                                              </span>
                                            </div>
                                            {isTypeSelected(person.name) ||
                                            (person.name == "Grabber" &&
                                              fileContainsGrabber &&
                                              isTypeSelected("Hook")) ? (
                                              <CheckCircleIcon className="ml-3 h-5 w-5 flex-none text-green-500" />
                                            ) : isNoFileSelected ? (
                                              <ExclamationCircleIcon className="ml-3 h-5 w-5 flex-none text-red-500" />
                                            ) : (
                                              <QuestionMarkCircleIcon className="ml-3 h-5 w-5 flex-none text-gray-500" />
                                            )}

                                            {active && (
                                              <ChevronRightIcon
                                                className="ml-3 h-5 w-5 flex-none text-gray-400"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </div>
                                </div>

                                {activeOption && (
                                  <div className="hidden w-1/2 flex-none flex-col divide-y divide-gray-100  sm:flex">
                                    <div className="flex-none p-6 text-center ">
                                      <div className="flex justify-center">
                                        <div className="w-full h-52 flex justify-center items-center">
                                          {optionMarked?.data &&
                                          optionMarked?.data?.children?.length >
                                            0 ? (
                                            <div className="w-full h-full hover:cursor-grab focus:cursor-pointer">
                                              <ObjViewer
                                                obj={optionMarked.data.clone()}
                                                type={optionMarked.type}
                                              />
                                            </div>
                                          ) : (
                                            <span className="text-lg font-bold">
                                              {fakeFiles.find(
                                                (item) =>
                                                  item.name ==
                                                    optionMarked.name &&
                                                  item.type == optionMarked.type
                                              )?.data == "loading"
                                                ? "Loading..."
                                                : "No file available"}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <h2 className="mt-3 font-semibold text-gray-900">
                                        {optionMarked.name}
                                      </h2>
                                      <p className="text-sm leading-6 text-gray-500">
                                        Model preview
                                      </p>
                                    </div>
                                    <div className="flex flex-auto flex-col justify-between p-6 h-72">
                                      {activeOption.name == "Grabber" &&
                                      fileContainsGrabber &&
                                      isTypeSelected("Hook") ? (
                                        <span>
                                          Hook model already contains Grabber
                                          model
                                        </span>
                                      ) : (
                                        <dl className="grid grid-cols-1 gap-x-9 gap-y-3 text-sm text-gray-700 overflow-x-auto ">
                                          {fakeFiles
                                            .filter(
                                              (item) =>
                                                item.type ==
                                                  activeOption.name &&
                                                item.data !== "loading"
                                            )
                                            .map((item) => (
                                              <div
                                                key={item.name}
                                                onClick={() =>
                                                  setOptionMarked(item)
                                                }
                                                className=" font-semibold text-gray-900 flex flex-col items-start  hover:cursor-pointer hover:bg-slate-100 rounded-lg p-2 mr-4 bg-gray-50"
                                              >
                                                <dt
                                                  className={
                                                    (isItemSelected(item) || selectedItems[item.type] === item)
                                                      ? "text-blue-500 w-full  flex justify-start "
                                                      : "hover:text-blue-500 w-full flex justify-start "
                                                  }
                                                >
                                                  {item.name}
                                                </dt>
                                                <dd className="text-gray-700 font-medium">
                                                  {item.info}
                                                </dd>
                                              </div>
                                            ))}
                                        </dl>
                                      )}
                                      <div className="w-full flex justify-center">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleSelectItem(optionMarked)
                                          }
                                          className="mt-6 w-40 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                      {activeOption.name === "Hook" && (
                                        <form>
                                          <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                              <input
                                                id="grabber"
                                                aria-describedby="grabber-description"
                                                name="grabber"
                                                type="checkbox"
                                                checked={fileContainsGrabber}
                                                onChange={(event) =>
                                                  setFileContainesGrabber(
                                                    event.target.checked
                                                  )
                                                }
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                              />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                              <label
                                                htmlFor="twobeems"
                                                className="font-medium text-gray-900"
                                              >
                                                3D model contains a grabber
                                              </label>
                                            </div>
                                          </div>
                                        </form>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Combobox.Options>
                            )}
                          </>
                        )}
                      </Combobox>
                    </div>
                    {/* <div>
                    test
                      {documents?.map((item) => <p>{item.title}</p>)}
                    </div> */}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const ObjViewer = ({ obj, type }) => {
  const [target, setTarget] = useState(null);
  const orbitControlsRef = useRef();
  const [theType, setTheType] = useState("");
  // Function to reset OrbitControls
  const resetOrbitControls = () => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.reset();
    }
  };

  React.useEffect(() => {
    if (obj) {
      // console.log("Object loaded:");
      obj.rotation.set(0, 0, 0);
      obj.position.set(0, 0, 0);
      obj.scale.set(1, 1, 1);
      if (type == "Building") {
        // console.log("Building");
        // Apply rotation directly to the object
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, -400, 0);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z - 200]);
      } else if (type == "Beem") {
        // Apply rotation directly to the object
       // console.log("Beem");
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, 0, 0);
        obj.scale.set(0.1, 0.1, 0.1);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z]);
      } else {
        // console.log("Other");
        // Apply rotation directly to the object
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, 0, 0);
        obj.scale.set(3, 3, 3);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z]);
      }
      if(type != theType){
        resetOrbitControls();
        setTheType(type);
      }
      
    }
    return () => {
     // setTarget(null);
    };
  }, [obj]);

  return (
    <Canvas className="w-full h-full">
      <ambientLight intensity={0.3} />
      <directionalLight position={[0, 1, 0]} intensity={1} />
      <OrbitControls
        ref={orbitControlsRef}
        target={target}
        autoRotate={true}
        autoRotateSpeed={0.9}
      />
      {/* <axesHelper args={[5]} /> */}
      <group>
        {target !== null && obj && (
          <mesh position={[0, 0, 0]}>
            <primitive object={obj} />
          </mesh>
        )}
      </group>
    </Canvas>
  );
};

const ObjModel = ({ obj }) => {
  const { scene } = useThree();

  React.useEffect(() => {
    if (obj) {
      // Display the bounding box
      const boundingBoxHelper = new THREE.Box3Helper(
        boundingBox(obj),
        0xff0000
      ); // Red box
      scene.add(boundingBoxHelper);
      //scene.add(obj);
      return () => {
        scene.remove(obj);
      };
    }
  }, [obj, scene]);

  return null;
};

function boundingBox(object) {
  const box = new THREE.Box3();
  box.setFromObject(object);
  return box;
}
