import React from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const StatusBox = ({ value }) => {

  const getStatusColor = (value) => {
      switch (value) {
        case 'Waiting':
          return 'Orange';
        case 'Cancelled':
          return 'red';
        case 'Done':
          return 'lightgreen';
        case 'Ready':
          return 'lightblue';
        default:
          return 'lightgray'; // Default color for any other values not accounted for
      }
    };
  
  return (
    <Box 
      display="inline-block"
      px={1}
      py={0.5}
      borderRadius="16px"
      bgcolor={getStatusColor(value)}
      color="black"
    >
      <Typography fontSize="12px" fontWeight={500} color="white">{value}</Typography>
    </Box>
  );
};

const Returns = () => {
  const columns = [
 
    { field: 'reference', headerName: 'Reference', width: 150 },
    { field: 'contact', headerName: 'Contact', width: 150 },
    { field: 'scheduledDate', headerName: 'Scheduled Date', width: 200 },
    { field: 'sourceDocument', headerName: 'Source Document', width: 200 },
    { field: 'company', headerName: 'Company', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => <StatusBox value={params.value} />,
    },
  ];

  const rows = [
    {
      id: 1, reference: 'REF03', contact: 'Östman', scheduledDate: 'Today', sourceDocument: '', company: 'Trans', status: 'Ready',
    },
    {
        id: 2, reference: 'REF05', contact: 'Östman', scheduledDate: 'Today', sourceDocument: '', company: 'Trans', status: 'Done',
    },
    
    // ... Add more sample rows as needed
  ];

  const defaultTheme = createTheme();
  const customTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-row': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'lightgrey',
            },
          },
        },
      },
    },
  });

  return (
    <Box display="flex" justifyContent="center" >
        <Stack mt={3}>
            <Typography variant='h4'>Returns</Typography>
            <Box>
            <Button variant="contained" style={{ marginBottom: '10px' }} sx={{ mt: 3}}>
              New
            </Button>
            <ThemeProvider theme={{ ...defaultTheme, ...customTheme }}>
                <div style={{ height: 400, width: '100%' }}>
               
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
                </div>
            </ThemeProvider>
            </Box>
        </Stack>
    </Box>
  );
};

export default Returns;
