import React from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useFetchV2 from '../../components/PartsApi';

const StatusBox = ({ value }) => {

  const getStatusColor = (value) => {
      switch (value) {
        case 'Waiting':
          return 'Orange';
        case 'Cancelled':
          return 'red';
        case 'Done':
          return 'lightgreen';
        case 'Ready':
          return 'lightblue';
        default:
          return 'lightgray'; // Default color for any other values not accounted for
      }
    };

  return (
    <Box 
      display="inline-block"
      px={1}
      py={0.5}
      borderRadius="16px"
      bgcolor={getStatusColor(value)}
      color="black"
    >
      <Typography fontSize="12px" fontWeight={500} color="white">{value}</Typography>
    </Box>
  );
};

const Delivered = () => {

  const { data: orders, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/deliveredorders?page=0&size=100&sort=desc`);
  
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    navigate(`order/${params.row.reference}`);
  };

  console.log(orders);

  const columns = [
    
    { field: 'reference', headerName: 'Reference', width: 150 },
    
    { field: 'scheduleddate', headerName: 'Scheduled Date', width: 200 },
    
    {
      field: 'product',
      headerName: 'Product',
      width: 150,
      renderCell: (params) => params.name ,
    },
    { field: 'demand', headerName: 'Demand', width: 150 },
    { field: 'done', headerName: 'Items fulfilled', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => <StatusBox value={params.value} />,
    },
  ];

 

  const defaultTheme = createTheme();
  const customTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-row': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'lightgrey',
            },
          },
        },
      },
    },
  });

  return (
    <Box display="flex" justifyContent="center" >
        <Stack mt={3}>
            <Typography variant='h4'>Delivery orders</Typography>
            <Box>
            <Button variant="contained" style={{ marginBottom: '10px' }} sx={{ mt: 3}} onClick={() => navigate('new')}>
                    New
            </Button>
            {!dataIsLoading && <Box>
            <ThemeProvider theme={{ ...defaultTheme, ...customTheme }}>
                <div style={{ height: 400, width: '100%' }}>
                
                <DataGrid
                    rows={orders.reverse()}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    onRowClick={handleRowClick}
                    
                />
                </div>
            </ThemeProvider>
            </Box>}
            </Box>
        </Stack>
    </Box>
  );
};

export default Delivered;
