import React from 'react'
import { Box, Typography, Stack, Button, IconButton, Divider, ButtonBase, TextField, InputAdornment, Rating } from '@mui/material'
import { Link } from 'react-router-dom';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SearchIcon from '@mui/icons-material/Search';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GroupIcon from '@mui/icons-material/Group';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import useFetchV2 from '../../../../components/PartsApi';

import Long from './images/Long.png';
import Short from './images/Short.png';

function ToolbarActions() {
  return (
    <Stack direction="row" spacing={2} ml={3}>
      <Button
        startIcon={<FilterAltOutlinedIcon />}
        
      >
        Filter
      </Button>
      
      <Button
        startIcon={<LayersOutlinedIcon />}
        
      >
        Group by
      </Button>
      
      <Button
        startIcon={<StarBorderIcon />}
        
      >
        Favorites
      </Button>
    </Stack>
  );
}

//     {
//       id: 11,
//       title: 'Steel ST ',
//       price: '$137.83',
//       inventory: 140,
//       imageUrl: 'path_to_fir_image.jpg'
//     },
    
// ];


const Products = () => {
  const { data: products, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/products?id.greaterThanOrEqual=12&page=0&size=20`);
  console.log(products);
  
  return (
    <div>
    <form >
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Products
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Create products that can be used in the 3D enviroment.
            </p>
          </div>

          <div className="max-w-2xl space-y-10 md:col-span-2">
            <fieldset>
              <legend className="text-base font-semibold leading-6 text-gray-900">
                Users
              </legend>
              <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 h-96 overflow-auto">
                
              </div>
            </fieldset>
          </div>
        </div>

        
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          className={"rounded-md bg-nsblue-700 px-3 w-20 py-2 text-sm font-semibold text-white shadow-sm hover:bg-nsblue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-nsblue-600" }
        >
          {"Save"}
        </button>
      </div>
    </form>
  </div>

  )
}

export default Products