import React from 'react'
import { Box, Typography, Stack, Skeleton } from '@mui/material';
import useFetch from '../../components/fetch';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Link } from 'react-router-dom';

export function getCraneImage(name) {
  switch (name) {
   
    default:
      return 'https://www.dqcranes.com/d/file/overhead-crane/e380686131ab7783a1fcc08bc663743b.jpg';
  }
}

const Cranecard = ({loading, onClick, name, country, city, location, imageUrl,id}) => {
  return (
    <Link to={`/cranes/${id}`} style={{ textDecoration: 'none', color: 'inherit' }} key={id}>
    <Box boxShadow={2} minWidth="300px" width="380px" minHeight="300px" bgcolor="#f2fefe" borderRadius="10px" 
      display="flex" flexDirection="column" justifyContent="flex-end" sx={{'&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.03)'
      }}}>
    {loading ? <Skeleton variant="squared" width={"380px"} height={"250px"} /> : <Box
        component="img"
        sx={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          width: '380px',
          height: '250px'
        }}
        alt="Crane"
        src={imageUrl}
      />}
        
        <Box p={2} height="100px" bgcolor="#fefefe" display="flex" flexDirection="column" sx={{borderBottomLeftRadius:"10px" , borderBottomRightRadius:"10px"}}>
            <Typography variant='h4'>{name}</Typography>
            <Stack direction="row" mt={1}>
              <PlaceOutlinedIcon />
              <Typography variant='subtitle1' ml={0.5}>{country} / {city} / {location}</Typography>
            </Stack>
            
        </Box>
       
    </Box>
    
    </Link>
  )
}

export default Cranecard
