import React, { useState, useEffect, useRef } from "react";
import HmiDisplay from "./HmiDisplay";
import Joystick from "./Joystick";
import { toast } from "react-toastify";
import Scene2 from "../cranesV2/TabPages/relatedComponents/3DobjV2";
import CraneSim from "./CraneSim";
import {
  ArrowPathIcon,
  ArrowsPointingOutIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassPlusIcon,
  CursorArrowRippleIcon,
} from "@heroicons/react/24/outline";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useFetchV2 from "../../components/PartsApi";
import { TailAutocomplete } from "../cranesV2/newCraneWizard";
import { updateLocation, updateOpc } from "../../components/OrderApi";
import { getCranePos } from "../../components/CraneApi";

const Courses = () => {
  const {
    data: cranesdata,
    loading: cranesloading,
    error: craneserror,
    refetch: refetchCraneData,
  } = useFetchV2("https://noswingcc.net/noswingcc/api/cranes?page=0&size=200");
  const {
    data: modelconfs,
    loading: mcloading,
    error: mcerror,
    refetch: refetchMcData,
  } = useFetchV2(
    "https://noswingcc.net/noswingcc/api/modelconfs?page=0&size=200"
  );

  //console.log(modelconfs);

  const [action, setAction] = useState("");
  const [extraAction, setExtraAction] = useState("");
  const [X, setX] = useState(5000);
  const [Y, setY] = useState(5000);
  const [Z, setZ] = useState(100);
  const [miniHmi, setMiniHmi] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const handle = useFullScreenHandle();
  const [crane, setCrane] = useState(4504);
  const [writeToOpc, setWriteToOpc] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [pos, setPos] = useState({ x: 5000, y: 5000, z: 100 });
  const [readFromOpc, setReadFromOpc] = useState(false);
  const [VRcontrols, setVRcontrols] = useState({});
  const [stopAnimation, setStopAnimation] = useState(false);
  const [boxReady, setBoxReady] = useState(false);
  const [pickupBox, setPickupBox] = useState(false);

  const getPos = async () => {
    const data = await getCranePos(7);
    console.log(data);
  };

  // useEffect(() => {
  //   // Define an async function to fetch data
  //   const fetchData = async () => {
  //    if(writeToOpc){
  //     const data = await getCranePos(cranesdata?.find(c => c.id === crane)?.opc.id);
  //     console.log("Cranepos: ",data.xactpos, data.y1actpos, data.z1actpos);
  //     setX(data?.xactpos);
  //     setY(data?.y1actpos);
  //     setZ(data?.z1actpos);
  //     latestX.current = data?.xactpos;
  //     latestY.current = data.y1actpos;
  //     latestZ.current = data?.z1actpos;
  //    }
  //   };

  //   // Call fetchData every second
  //   const intervalId = setInterval(fetchData, 1000);

  //   // Clear the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [writeToOpc]);

  const enterFullscreen = () => {
    setFullscreen(true);
    handle.enter();
  };

  const exitFullscreen = () => {
    setFullscreen(false);
    handle.exit();
  };
  // Object to keep track of which specified keys are currently pressed
  const keyPresses = useRef({
    w: false,
    a: false,
    s: false,
    d: false,
    q: false,
    e: false,
    space: false,
  }).current;
  let actionTimeout;

  useEffect(() => {
    const handleKeyDown = (event) => {
      let key = event.key;
      if (key === " ") {
        // Map the space character to the name you use in your state
        key = "space";
      }
      if (Object.keys(keyPresses).includes(key)) {
        clearTimeout(actionTimeout);
        keyPresses[key] = true;
        updateAction();
        //toast.success("Stopped animation: ");
        setStopAnimation(true);
      }
    };

    const handleKeyUp = (event) => {
      let key = event.key;
      if (key === " ") {
        // Map the space character to the name you use in your state
        key = "space";
      }
      if (Object.keys(keyPresses).includes(key)) {
        keyPresses[key] = false;
        // Start a timer to reset the action if no keys are pressed after 1 second
        actionTimeout = setTimeout(() => {
          if (Object.values(keyPresses).every((pressed) => !pressed)) {
            setAction("");
            setExtraAction("");
          }
        }, 50);
      }
    };

    const updateAction = () => {
      if (keyPresses.w) setAction("Move forward");
      else if (keyPresses.a) setAction("Move left");
      else if (keyPresses.s) setAction("Move backward");
      else if (keyPresses.d) setAction("Move right");
      else if (keyPresses.q) setExtraAction("Down");
      else if (keyPresses.e) setExtraAction("Up");
      else if (keyPresses.space) {
        console.log("Box ready: ", boxReady);
        if (boxReady && !pickupBox) {
          setPickupBox(true);
        } else if (pickupBox) {
          setPickupBox(false);
        }
      }
    };

    // Add event listeners
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      clearTimeout(actionTimeout);
    };
  }, [boxReady, pickupBox]);

  const changeCrane = (crane) => {
    console.log(crane);
    setCrane(crane.id);
  };

  // Use refs to hold the latest values
  const latestX = useRef(X);
  const latestY = useRef(Y);
  const latestZ = useRef(Z);

  const latestCrane = useRef(crane);

  // Update refs whenever X, Y, Z change
  useEffect(() => {
    latestX.current = X;
    latestY.current = Y;
    latestZ.current = Z;
    latestCrane.current = crane;
  }, [X, Y, Z, crane]);

  // useEffect(() => {
  //   if (writeToOpc) {
  //     let timer = 0;
  //     const intervalId = setInterval(() => {
  //       timer = timer + 1;
  //       let conf = modelconfs?.find(
  //         (conf) => conf?.crane?.id === latestCrane.current
  //       );
  //       let body = {
  //         id: 7,
  //         name: "VRSIM",
  //         xstatus1: "string",
  //         ystatus1: "string",
  //         antisnagstatus: "string",
  //         communicationstatus: timer,
  //         cranesafetystatus1: "string",
  //         cranestatus1: "string",
  //         cranestatus2: "string",
  //         extrafunction1: "string",
  //         idealrampdown: 0,
  //         idealrampup: 0,
  //         plcstatus: "string",
  //         snagstatus1: "string",
  //         snagstatus2: "string",
  //         workorderdone: 0,
  //         workordernbr: 0,
  //         workorderpos1: 0,
  //         workorderpos2: 0,
  //         workorderstatus: "string",
  //         parameter1: "string",
  //         parameter2: "string",
  //         xbrakeopencnt: 0,
  //         xactpos: latestX.current,
  //         xactspd: 0,
  //         xposlimitmax: 0,
  //         xposlimitmin: 0,
  //         xstatus2: "string",
  //         xtargetpos: 0,
  //         y1brakeopencnt: 0,
  //         y1actpos: latestY.current,
  //         y1actspd: 0,
  //         y1poslimitmax: conf?.craneyposmax || 0,
  //         y1poslimitmin: 0,
  //         y1status2: "string",
  //         y1targetpos: 0,
  //         y2brakeopencnt: 0,
  //         y2actpos: 0,
  //         y2actspd: 0,
  //         y2poslimitmax: 0,
  //         y2poslimitmin: 0,
  //         y2status1: "string",
  //         y2status2: "string",
  //         y2targetpos: 0,
  //         z1brakeopencnt: 0,
  //         z1actpos: latestZ.current,
  //         z1actspd: 0,
  //         z1poslimitmax: 0,
  //         z1poslimitmin: 0,
  //         z1status1: "string",
  //         z1status2: "string",
  //         z1targetpos: 0,
  //         z2brakeopencnt: 0,
  //         z2actpos: 0,
  //         z2actspd: 0,
  //         z2poslimitmax: 0,
  //         z2poslimitmin: 0,
  //         z2status1: "string",
  //         z2status2: "string",
  //         z2targetpos: 0,
  //         xspdref: 0,
  //         y1status1: "string",
  //         y1spdref: 0,
  //         y2spdref: 0,
  //         z1spdref: 0,
  //         z1weight: 0,
  //         z2spdref: 0,
  //         z2weight: 0,
  //         antiswaystatus: "string",
  //         workordertargetposx: 0,
  //         workordertargetposy: 0,
  //         workordertargetposz: 0,
  //         runninghours: 0,
  //         distancetraveled: 0,
  //         liftsdone: 0,
  //         tonslifted: 0,
  //         overloads: 0,
  //         emergencystops: 0,
  //         workordertype: 0,
  //         tiltX: 0,
  //         tiltY: 0,
  //       };
  //       setUpdating(true);
  //       // toast.success("Updating OPC");
  //       console.log(
  //         "crane: ",
  //         modelconfs?.find((conf) => conf?.crane?.id === latestCrane.current)
  //       );
  //       updateOpc(7, body);
  //       const fetchData = async () => {
  //         if (readFromOpc) {
  //           const data = await getCranePos(7);
  //           console.log(
  //             "Cranepos: ",
  //             data.xactpos,
  //             data.y1actpos,
  //             data.z1actpos
  //           );
  //           //  setX(data?.xactpos);
  //           //  setY(data?.y1actpos);
  //           //  setZ(data?.z1actpos);
  //           //  latestX.current = data?.xactpos;
  //           //  latestY.current = data.y1actpos;
  //           //  latestZ.current = data?.z1actpos;

  //           setPos({ x: data.xactpos, y: data.y1actpos, z: data.z1actpos });
  //         }
  //       };
  //       fetchData();
  //     }, 100);

  //     return () => {
  //       clearInterval(intervalId);
  //       setUpdating(false);
  //     };
  //   }
  // }, [writeToOpc]);
  // useEffect(() => {
  //   if (readFromOpc) {
  //     const intervalId = setInterval(() => {
  //       const fetchData = async () => {
  //         if (readFromOpc) {
  //           const data = await getCranePos(7);
  //           console.log(
  //             "Cranepos: ",
  //             data.xactpos,
  //             data.y1actpos,
  //             data.z1actpos
  //           );
  //           //  setX(data?.xactpos);
  //           //  setY(data?.y1actpos);
  //           //  setZ(data?.z1actpos);
  //           //  latestX.current = data?.xactpos;
  //           //  latestY.current = data.y1actpos;
  //           //  latestZ.current = data?.z1actpos;

  //           setPos({ x: data.xactpos, y: data.y1actpos, z: data.z1actpos });
  //         }
  //       };
  //       fetchData();
  //     }, 100);

  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }
  // }, [readFromOpc]);
  const prevButton1State = useRef(false);

  useEffect(() => {
   if( VRcontrols?.right?.trigger &&  VRcontrols?.left?.trigger){
    if (
      VRcontrols?.right?.joystickY > 0.5 ||
      VRcontrols?.right?.joystickY < -0.5
    ) {
      setZ(Z + 1 * VRcontrols.right.joystickY);
    }
    if (
      VRcontrols?.left?.joystickY > 0.5 ||
      VRcontrols?.left?.joystickY < -0.5
    ) {
      setY(Y + 4 * VRcontrols.left.joystickY);
    }
    if (
      VRcontrols?.left?.joystickX > 0.5 ||
      VRcontrols?.left?.joystickX < -0.5
    ) {
      setX(X + 5 * VRcontrols.left.joystickX);
    }
   }
    // Handling button press for button1 on the right controller
    const isButton1Pressed = VRcontrols?.right?.button1;
    if (isButton1Pressed && !prevButton1State.current) {
      // Button has just been pressed, toggle pickupBox
      if (boxReady) {
        setPickupBox(!pickupBox);
      }
    }
    // Update the ref with the current button state for the next comparison
    prevButton1State.current = isButton1Pressed;
  }, [VRcontrols]);

  return (
    <div>
      {/* <div className=" flex flex-row justify-center items-center mx-auto pt-5">
        <TailAutocomplete
          options={cranesdata?.map((crane) => crane) || []}
          func={changeCrane}
          placeholder={"Select crane"}
          withId={true}
        />

        
        <div>
      
        </div>
        <div className="flex flex-row gap-3 ml-10">
          <p className="block text-nowrap">Write to OPC</p>
          <div className="ml-3 flex h-6 items-center">
            <input
              id={`opc`}
              onChange={(event) => setWriteToOpc(event.target.checked)}
              checked={writeToOpc}
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-nsblue-500 focus:ring-nsblue-500 cursor-pointer"
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 ml-10">
          <p className="block text-nowrap">Read from OPC</p>
          <div className="ml-3 flex h-6 items-center">
            <input
              id={`opcread`}
              onChange={(event) => setReadFromOpc(event.target.checked)}
              checked={readFromOpc}
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-nsblue-500 focus:ring-nsblue-500 cursor-pointer"
            />
          </div>
        </div>
        {updating && (
          <p className="px-3 py-1 rounded-full bg-blue-100 animate-bounce text-black ml-10">
            Updating
          </p>
        )}
    
        </div>
         */}
      <FullScreen handle={handle}>
        <div
          className={
            "flex flex-col xldesktop:flex-row justify-center items-center w-full gap-10 " +
            (fullscreen ? "h-screen" : "py-5 px-32 h-[92vh]")
          }
        >
          <div
            className="relative w-full h-full"
            onDoubleClick={fullscreen ? exitFullscreen : enterFullscreen}
          >
            <CraneSim
              crane={crane}
              x={writeToOpc ? pos.x : X}
              y={writeToOpc ? pos.y : Y}
              z={writeToOpc ? pos.z : Z}
              setVRcontrols={setVRcontrols}
              boxReady={boxReady}
              setBoxReady={setBoxReady}
              pickupBox={pickupBox}
              setPickupBox={setPickupBox}
            />

            {/* Bottom-Left Joystick */}
            <div className="absolute top-2 left-0 flex flex-col  w-52 h-full justify-between">
              <div className="gap-4 flex flex-col pl-10 w-52 ">
                <div className="flex flex-row items-center gap-4 font-semibold">
                  <p
                    className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg bg-white"
                    onClick={() => setX(X + 100)}
                  >
                    A
                  </p>
                  <p>- LEFT</p>
                </div>
                <div className="flex flex-row items-center gap-4 font-semibold">
                  <p
                    className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg bg-white"
                    onClick={() => setX(X - 100)}
                  >
                    D
                  </p>
                  <p>- RIGHT</p>
                </div>
                <div className="flex flex-row items-center gap-4 font-semibold">
                  <p
                    className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg bg-white"
                    onClick={() => setY(Y + 100)}
                  >
                    W
                  </p>
                  <p>- FORWARD</p>
                </div>
                <div className="flex flex-row items-center gap-4 font-semibold">
                  <p
                    className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg bg-white"
                    onClick={() => setY(Y - 100)}
                  >
                    S
                  </p>
                  <p>- BACKWARD</p>
                </div>
              </div>
              <div className="  transform origin-bottom-left">
                <Joystick action={action} setX={setX} setY={setY} setZ={setZ} />
              </div>
            </div>

            <div className="absolute top-2 right-0 flex flex-col h-full justify-between">
              <div className="gap-4 flex flex-col justify-end items-end ">
                <div className="flex flex-row items-center gap-4 font-semibold w-44 ">
                  <p
                    className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg bg-white"
                    onClick={() => setZ(Z - 100)}
                  >
                    E
                  </p>
                  <p>- UP</p>
                </div>
                <div className="flex flex-row items-center gap-4 font-semibold w-44">
                  <p
                    className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg bg-white"
                    onClick={() => setZ(Z + 100)}
                  >
                    Q
                  </p>
                  <p>- DOWN</p>
                </div>
                <div className="flex flex-row items-center gap-4 font-semibold w-44 ">
                  <p className="font-semibold border shadow-md w-16d h-12 flex justify-center items-center rounded-lg bg-white">
                    SPACE
                  </p>
                  <p>- Grabber</p>
                </div>
                <div className="flex flex-row items-center gap-4 font-semibold w-44 invisible">
                  <p className="font-semibold border shadow-md w-12 h-12 flex justify-center items-center rounded-lg">
                    Q
                  </p>
                  <p>- DOWN</p>
                </div>
              </div>
              <div className="  transform origin-bottom-left ">
                <Joystick
                  action={extraAction}
                  setX={setX}
                  setY={setY}
                  setZ={setZ}
                />
              </div>
            </div>
            <div className="flex w-full justify-center h-5 absolute bottom-0 right-0 mt-2 items-end scale-[0.4] transform origin-bottom mb-5">
              <HmiDisplay
                action={action}
                setX={setX}
                setY={setY}
                setZ={setZ}
                x={X}
                y={Y}
                z={Z}
                mini={miniHmi}
                setMiniHmi={setMiniHmi}
                stopAnimation={stopAnimation}
                setStopAnimation={setStopAnimation}
              />
            </div>
            <div className="hidden absolute top-0 w-full justify-center mt-2 items-center xl:flex">
              <div className="flex flex-row text-sm gap-10 xldesktop:text-base xldesktop:gap-24 items-center justify-center px-4 bg-white rounded-lg shadow-md border p-2">
                <div className="flex flex-row gap-2 items-center">
                  <MagnifyingGlassPlusIcon className="xldesktop:w-6 xldesktop:h-6 h-4 w-4" />
                  <p>Scroll to zoom in or out</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <ArrowPathIcon className="xldesktop:w-6 xldesktop:h-6 h-4 w-4" />
                  <p>Hold left mouse button to rotate</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <ArrowsPointingOutIcon className="xldesktop:w-6 xldesktop:h-6 h-4 w-4" />
                  <p>Hold right mouse button to move</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <CursorArrowRippleIcon className="xldesktop:w-6 xldesktop:h-6 h-4 w-4" />
                  <p>Double-click to toggle fullscreen</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FullScreen>
    </div>
  );
};

export default Courses;
