import * as React from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Box , Typography} from '@mui/material';
import useFetch from '../../components/fetch';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

export default function FileSystemNavigator({allTheNodeIds, setAllNodeIds, setIdArray, setShowCraneWithId, allowedCranes, setLocationTitle, setExpanded, expanded, cranesdata}) {
  // const { data: cranesdata, loading: cranesloading, error: craneserror } = useFetch('https://noswingcc.net/noswingcc/api/cranes?page=0&size=200');
  const { t, i18n } = useTranslation();

  
  const filterCranes = (cranes, allowedCranes) => {
    return cranes.filter(crane => allowedCranes.includes(String(crane.id)));
  };
  

  if (!cranesdata) {
    return <div>Loading...</div>;  // or some loading spinner
  }

 
  //const filteredCranes = filterCranes(cranesdata, allowedCranes);
  const filteredCranes = cranesdata;
  
  if(allTheNodeIds.length > 0) setAllNodeIds(allTheNodeIds);

  let nestedData = filteredCranes.reduce((acc, curr) => {
    if (!acc[curr.country]) acc[curr.country] = {};
    if (!acc[curr.country][curr.city]) acc[curr.country][curr.city] = {};
    if (!acc[curr.country][curr.city][curr.location]) acc[curr.country][curr.city][curr.location] = {};
    if (!acc[curr.country][curr.city][curr.location][curr.name]) acc[curr.country][curr.city][curr.location][curr.name] = [];
    acc[curr.country][curr.city][curr.location][curr.name].push(curr);
    return acc;
  }, {});

  const collectLeafIds = (branch) => {
    let ids = [];
    for (const key in branch) {
      if (typeof branch[key] === 'object' && !Array.isArray(branch[key])) {
        ids = ids.concat(collectLeafIds(branch[key]));
      }
      else if (Array.isArray(branch[key])) {
        branch[key].forEach(item => ids.push(item.id));
      }
    }
    return ids;
  };

  const generateTreeItems = (data, parentId = '', level = 1) => {
    return Object.keys(data).map((key, index) => {
      const id = `${parentId}-${key}-${index}`;
      // Check if data[key] is an object and its values are not arrays
      // If true, this is a non-leaf node, and we need to generate children for it
      allTheNodeIds.push(id);
      

      if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
        return (
          <TreeItem sx={level === 1 ? {mt: 0.5} : {}} nodeId={id} label={key} key={id} onClick={() => {
            const leafIds = collectLeafIds(data[key]);
            //console.log(leafIds);
            setIdArray(leafIds);
            setShowCraneWithId(null);
            // console.log(key);
            setLocationTitle(key);
          }}>
            {generateTreeItems(data[key], id, level + 1)}
          </TreeItem>
        );
      }
      // If data[key] is an array, this is a leaf node
      else if (Array.isArray(data[key])) {
        return data[key].map((item, itemIndex) => {
          const itemId = `${id}-${itemIndex}`;
          //console.log(item.name + " id: " + item.id);
          return (
            <Link to={`/cranes/${item.id}`} key={itemId} style={{color: 'inherit' }}>
              <TreeItem nodeId={itemId} label={item.name} onClick={() => setShowCraneWithId(item.id)} className='font-bold' sx={{fontWeight: "bold"}}/>
            </Link>
          );
        });
      }
    });
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
    // console.log(allTheNodeIds);
    
  };
  
  
  
  return (
      <div className='bg-white'>
        <Box width="230px" mt="10px" >
      <Box position="fixed" >
      {/* <p onClick={() => {
        setIdArray(allowedCranes.map(Number));
        setShowCraneWithId(null);
        setLocationTitle(t("pages.cranes.allcranes"));
      
      }} variant='h5' ml={1} mb={1} fontWeight={500} fontSize={20} className='cursor-pointer ml-1 mb-1 text-xl mt-2'>{t(`pages.cranes.treeTitle`)}</p> */}
      <TreeView
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {generateTreeItems(nestedData)}
         
      </TreeView>
      <Box height="100%" width="230px" display="flex" justifyContent="space-around" sx={{cursor: 'pointer'}} onClick={() => {
            console.log("Click");
            if(expanded.length > 0) {
             setExpanded([]);
             //console.log(allNodeIds);
            } 
            else {
                setExpanded(allTheNodeIds);
                //console.log(allTheNodeIds);
                /*setIdArray(allowedCranes.map(Number));
                setShowCraneWithId(null);
                setLocationTitle("All Cranes");*/
            }
            
          }}> 
          {expanded.length > 0 ? <Typography mt={15}><ExpandLessOutlinedIcon fontSize="large" color="disabled"/></Typography> : <Typography mt={15} ><ExpandMoreIcon fontSize="large" color="disabled"/> </Typography>}
         </Box>
      </Box>
      </Box>
      </div>
    
  );
}