import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
  Divider,
  IconButton,
  InputAdornment,
  selectClasses,
} from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  TextField,
  Stack,
  Autocomplete,
  Modal,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import Post from "../../components/POST";
import Get from "../../components/GET";
import Cranecard from "./oldCraneItemCard";
import Demo from "./wizard/imageCropper";
import { getAllParts, postPart } from "../../components/PartsApi";
import useFetchV2, { createUserAccess } from "../../components/UserApi";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import {
  postCrane,
  postCranePart,
  getAllCranes,
} from "../../components/CraneApi";
import VirtualizedList from "./wizard/smallPartsList";
import { t } from "i18next";
import { uploadfile } from "./TabPages/documents";
import { getFileMimeType, postFile, postFileDataToDatabase, uploadContent, uploadDocument } from "../../components/FilesApi";
import { set } from "lodash";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";

const steps = [
  "Configure Your Crane",
  "Add User Access",
  "OPC Connection",
  "Add Parts",
  "Confirm",
];

const requestBodyBase = {
  id: "",
  craneconfiguration: "00000",
  cranestop: true,
  cranestatus: "00000",
  x: 0,
  y: 0,
  z: 0,
  country: "test",
  city: "test",
  name: "Crane",
  location: "test",
  opcIP: "",
  opc: {
    id: "",
    name: "",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#fff",
  borderRadius: "5px",
  border: "2px solid #000",
  p: 2,
};

const cranePartsBody = {
  id: "",
  replaceddate: "2023-06-27T05:09:01.588Z",
  crane: {
    id: "",
  },
  part: {
    id: "",
  },
};
export default function Wizard({
  openStateOfWizard,
  refetchCraneData,
  cranesdata,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [requestData, setRequestData] = React.useState(null);
  const {
    data: postCraneData,
    loading: postCraneLoading,
    error: postCraneError,
  } = Post("https://noswingcc.net/noswingcc/api/cranes", requestData);
  const [requestBody, setRequestBody] = React.useState(requestBodyBase);
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [craneExists, setCraneExists] = React.useState(false);
  const { data: opcdata, loading: opcloading, error: opcerror } = Get(
    "https://noswingcc.net/noswingcc/api/opcs?page=0&size=20"
  );
  const { data: userdata, loading: userloading, error: usererror } = Get(
    "https://noswingcc.net/noswingcc/api/users?page=0&size=20"
  );
  const [craneExistsUrl, setCraneExistsUrl] = React.useState("");
  //const { data: craneExistsData, loading: craneExistsLoading, error: CraneExistsError } = Get(craneExistsUrl);
  const [next, setNext] = React.useState(false);
  const [idsSelected, setIdsSelected] = React.useState("");
  const [usersWithAccess, setUsersWithAccess] = React.useState([]);

  //console.log(postCraneData);

  const [craneParts, setCraneParts] = React.useState([]);

  // if(postCraneError){
  //   console.log("Post Crane Error: " , postCraneError );
  // }

  const isStepOptional = (step) => {
    return step === 1 || step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  async function handlePostCrane() {
    //post new opc server with name
    // await opc server id
    //post new crane with opc server id
    if (requestBody.opc.id !== "") {
      const postdata = await postCrane(requestBody);
      if (postdata.data.id) {
        console.log(
          "Created a new crane and here is the ID: ",
          postdata.data.id
        );

        cranePartsBody.crane.id = postdata.data.id;
        handlePostCranePart();
        handlePostUsersWithAccess(postdata.data.id);
        //post craneimage with crane id
        const readFileAsBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
      
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
          });
        };
      
        const uploadImage = async () => {
          try {
            if (!avatarUrl) {
              throw new Error("No file provided");
            }
      
            const type = avatarUrl.name.split(".");
            const base64 = await readFileAsBase64(avatarUrl);
      
            let body = {
              id: "",
              fileName: "craneimage",
              folderName: "image",
              crane: {
                id: postdata.data.id,
              },
            };
      
            const fileMimeType = getFileMimeType(postdata.data.id + "_craneimage.png");
            
            // I'm assuming uploadContent and other functions are defined elsewhere in your code
            try {
              toast.info("Uploading image file in the background...");
              
              await uploadContent({
                dataContentType: fileMimeType,
                data: base64,
              })
              .then((content) =>
                uploadDocument({
                  title: postdata.data.id + "_craneimage.png",
                  documentSize: avatarUrl.size,
                  mimeType: fileMimeType,
                  content: content,
                  craneRelated: true,
                })
              )
              .then((document) =>
                postFile({
                  ...body,
                  document: document,
                })
              )
              .catch((error) => {
                toast.error(error.message || "Error during upload");
              });
      
              toast.success("File uploaded successfully!");
              refetchCraneData();
            } catch (error) {
              toast.error("Something went wrong with the upload...");
              console.error(error);
            }
      
          } catch (error) {
            console.error(error);
            toast.error("Failed to upload image");
          }
        };
        uploadImage();
        refetchCraneData();
        openStateOfWizard(false);
        return true;
      } else {
        console.log("Could not create a crane: ", postdata);
        return false;
      }
    } else {
      toast.error("Could not create a crane: No OPC id was found");
      return false;
    }
  }
  async function handlePostUsersWithAccess(id) {
    //console.log(craneParts, " ", cranePartsBody);

    try {
      for (const user of usersWithAccess) {
        let body = {
          id: "",
          craneAccess: true,
          configure: false,
          createOrders: false,
          createLocations: false,
          disableFunctions: false,
          filepermissionlevel: 1,
          extra1: false,
          extra2: false,
          extra3: false,
          crane: {
            id: id,
          },
          user_login: user.login,
        };
        //console.log(useraccessdata);

        const response = await createUserAccess(body);
        //console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handlePostCranePart() {
    //console.log(craneParts, " ", cranePartsBody);
    // upload crane image

    for (let item of craneParts) {
      let cranePart = { ...cranePartsBody, part: { id: item.id } };
      console.log(cranePart);
      const success = await postCranePart(cranePart);
      console.log(success);
    }
  }

  if (activeStep === 5) {
    setNext(true);
  }
  console.log(next);
  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      //setCraneExistsUrl(`https://noswingcc.net/noswingcc/api/cranes?country.equals=${requestBody.country}&city.equals=${requestBody.city}&location.equals=${requestBody.location}&name.equals=${requestBody.name}&page=0&size=20`);
      // console.log("Added crane: " + requestBody.name + "/" + requestBody.location + "/" + requestBody.city + "/" + requestBody.country);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);

      setNext(false);
    } else {
      if (activeStep === steps.length - 1) {
        //console.log("Last step");

        handlePostCrane();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        if (activeStep !== steps.length - 2) {
          setNext(false);
        }
      }
    }
  };
  // if(!craneExistsLoading && craneExistsUrl !== ''){
  //   if(craneExistsData.length === 0){
  //     //console.log("No cranes exist");
  //     setRequestData(requestBody);
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);

  //   }
  //   else {
  //     //setCraneExists(true);
  //     console.log("Cannot post a new crane: A crane already exists with that name: ", craneExistsData[0].name);

  //   }
  // }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%", minHeight: "400px" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          {
            /* if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          } */
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
            labelProps.optional = (
              <Typography variant="caption">Skipped</Typography>
            );
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box mt={5} height="400px">
            <AddingCrane
              postCraneError={postCraneError}
              setRequestData={setRequestData}
              requestBody={requestBody}
              postCraneLoading={postCraneLoading}
              postCraneData={postCraneData}
            />
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", pt: 2 }}
            justifyContent="flex-end"
          >
            <Button
              color="inherit"
              onClick={() => openStateOfWizard(false)}
              sx={{ mr: 1 }}
            >
              Close
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box mt={5}>
            <Box height="400px">
              {activeStep === 0 && (
                <Step1
                  setRequestBody={setRequestBody}
                  cranesdata={cranesdata}
                  requestBody={requestBody}
                  avatarUrl={avatarUrl}
                  setAvatarUrl={setAvatarUrl}
                  craneExists={craneExists}
                  setNext={setNext}
                />
              )}
              {activeStep === 1 && (
                <Step2
                  userdata={userdata}
                  setUsersWithAccess={setUsersWithAccess}
                  setNext={setNext}
                  usersWithAccess={usersWithAccess}
                />
              )}
              {activeStep === 2 && (
                <Step3
                  opcdata={opcdata && opcdata}
                  setRequestBody={setRequestBody}
                  requestBody={requestBody}
                  setNext={setNext}
                />
              )}
              {activeStep === 3 && (
                <Step4
                  setCraneParts={setCraneParts}
                  craneParts={craneParts}
                  setNext={setNext}
                />
              )}
              {activeStep === 4 && (
                <Confirm
                  requestBody={requestBody}
                  avatarUrl={avatarUrl}
                  craneParts={craneParts}
                  usersWithAccess={usersWithAccess}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip for now
                </Button>
              )}

              <Button
                onClick={handleNext}
                variant={next || activeStep === 4 ? "contained" : ""}
                disabled={activeStep !== 4 ? !next : false}
              >
                {activeStep === steps.length - 1 ? "Create" : "Next"}
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

const Step1 = ({
  requestBody,
  setRequestBody,
  avatarUrl,
  setAvatarUrl,
  setNext,
  cranesdata,
}) => {
  const fileInput = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [craneExists, setCraneExists] = React.useState(false);

  const [craneconfiguration, setCraneconfiguration] = React.useState("111");

  const [uploadedImage, setUploadedImage] = React.useState(null);

  React.useEffect(() => {
    return () => {
      setRequestBody((prevRequestBody) => ({
        ...prevRequestBody,
        craneconfiguration: craneconfiguration,
      }));
    };
  }, []);

  React.useEffect(() => {
    const existingCrane = cranesdata.find((crane) => {
      return (
        crane.name === requestBody.name &&
        crane.location === requestBody.location &&
        crane.city === requestBody.city &&
        crane.country === requestBody.country
      );
    });

    if (existingCrane) {
      setCraneExists(true);
    } else {
      setCraneExists(false);
    }
  }, [
    requestBody.name,
    requestBody.location,
    requestBody.city,
    requestBody.country,
    cranesdata,
  ]);

  const handleConfChange = (index) => (event) => {
    setCraneconfiguration((prevCraneconfiguration) => {
      // Create a new array with the updated value
      const newCraneconfiguration = [...prevCraneconfiguration];
      newCraneconfiguration[index] =
        event.target.value > 2
          ? 2
          : event.target.value < 1
          ? 1
          : event.target.value;
      return newCraneconfiguration;
    });
  };

  const handleCountryEvent = (finalValue) => {
    setSelectedCountry(finalValue);
    setSelectedCity("");
    setSelectedLocation("");
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      country: finalValue,
      city: "",
      location: "",
    }));
  };

  const handleCityEvent = (finalValue) => {
    setSelectedCity(finalValue);
    setSelectedLocation("");
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      city: finalValue,
      location: "",
    }));
  };

  const handleLocationEvent = (finalValue) => {
    setSelectedLocation(finalValue);
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      location: finalValue,
    }));
  };
  const handleInputChange = (value) => {
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      name: value,
    }));
  };

  console.log(cranesdata);

  const handleClose = () => {
    setOpen(false);
    setTempImage("");
  };
  const [tempImage, setTempImage] = React.useState("");

  console.log(requestBody.location);
  if (
    requestBody.name !== "" &&
    requestBody.location !== "" &&
    requestBody.city !== "" &&
    requestBody.country !== "" &&
    !craneExists
  ) {
    setNext(true);
  } else {
    setNext(false);
  }

  const getNewAvatarUrl = (event) => {
    // Handle the file input change event
    const file = event.target.files[0];

    if (file.size > 100000000) {
      toast.error("File is too large > 100MB");
    }
    // Read the selected file and create a URL representing its data
    const reader = new FileReader();
    reader.onload = (e) => {
      setTempImage(e.target.result);
    };
    reader.readAsDataURL(file);
    handleOpen();
  };
  const openCropper = () => {
    handleOpen();
  };

  const handleUpload = async () => {
    const upload = await uploadfile(avatarUrl, "craneimage.png");
    console.log(upload);
    if (upload == false) {
      //console.log(imageFile);
    }
  };

  // useEffect(() => {
  //   const readFileAsBase64 = (file) => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  
  //       reader.onload = () => resolve(reader.result.split(",")[1]);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   };
  
  //   const uploadImage = async () => {
  //     try {
  //       if (!avatarUrl) {
  //         throw new Error("No file provided");
  //       }
  
  //       const type = avatarUrl.name.split(".");
  //       const base64 = await readFileAsBase64(avatarUrl);
  
  //       let body = {
  //         id: "",
  //         fileName: "craneimage",
  //         folderName: type[type.length - 1],
  //         crane: {
  //           id: 4,
  //         },
  //       };
  
  //       const fileMimeType = getFileMimeType("11_craneimage.png");
        
  //       // I'm assuming uploadContent and other functions are defined elsewhere in your code
  //       try {
  //         toast.info("Uploading file, please wait...");
          
  //         await uploadContent({
  //           dataContentType: fileMimeType,
  //           data: base64,
  //         })
  //         .then((content) =>
  //           uploadDocument({
  //             title: "11_craneimage.png",
  //             documentSize: avatarUrl.size,
  //             mimeType: fileMimeType,
  //             content: content,
  //             craneRelated: true,
  //           })
  //         )
  //         .then((document) =>
  //           postFile({
  //             ...body,
  //             document: document,
  //           })
  //         )
  //         .catch((error) => {
  //           toast.error(error.message || "Error during upload");
  //         });
  
  //         toast.success("File uploaded successfully!");
  //       } catch (error) {
  //         toast.error("Something went wrong with the upload...");
  //         console.error(error);
  //       }
  
  //     } catch (error) {
  //       console.error(error);
  //       toast.error("Failed to upload image");
  //     }
  //   };
  
  //   if (avatarUrl) {
  //     uploadImage();
  //   }
  // }, [avatarUrl]);
  

  const handleDeleteImage = async () => {
    if (uploadedImage) {
      setUploadedImage(null);

      return;
    }
  };

  return (
    <Box p={2} display="flex" justifyContent="space-evenly" mb={10} gap={4}>
      <Box>
        {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {tempImage && <Demo imageUrl={tempImage} setImageUrl={setAvatarUrl} closeCropper={handleClose}/> }
        </Box>
      </Modal> */}
        <p className="font-semibold text-lg">Crane Image</p>
        {/* <Box border={1} borderRadius="5px" style={{ cursor: 'pointer' }} width="280px" height="200px" mt={1} onClick={() => fileInput.current.click()} >
          {avatarUrl ? (
            <img src={URL.createObjectURL(avatarUrl)} alt="Crane" style={{ width: '100%', height: '100%', borderRadius: "5px" }} />
          ) : (
            <Box height="100%" display="flex" justifyContent="space-evenly" alignItems="center"> Add image</Box>
          )}
        </Box> */}
        <div className="flex flex-row gap-5">
          {uploadedImage ? (
            <div className="">
              <div className=" aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100  w-72">
                <img
                  src={URL.createObjectURL(uploadedImage)}
                  alt="craneimage"
                  className="object-cover object-center "
                />
              </div>
              <div className="flex flex-row justify-between">
                {
                  <p className="cursor-pointer" onClick={handleDeleteImage}>
                    Remove Image
                  </p>
                }
              </div>
            </div>
          ) : (
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 w-44">
              <div className="text-center">
                <PhotoIcon
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600 w-full justify-center">
                  <label
                    htmlFor="file-upload"
                    className="text-center px-1 relative cursor-pointer rounded-md bg-nsorange-100 font-semibold text-nsorange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-nsorange-500 focus-within:ring-offset-2 hover:text-nsorange-500"
                  >
                    <span className="">Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          const file = event.target.files[0];
                          console.log(file); // This will log the file object
                          // You can now use this file object as needed
                          if (file && file.size > 1000000) {
                            toast.error("File too large! Max 1MB");
                            return;
                          }
                          setAvatarUrl(file);
                          setUploadedImage(file);
                        }
                      }}
                    />
                  </label>
                </div>

                <p className="text-xs leading-5 text-gray-600 mt-2">
                  PNG, JPG, up to 1MB
                </p>
              </div>
            </div>
          )}
        </div>
        <Box mt={2}>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={getNewAvatarUrl}
            style={{ display: "none" }}
            ref={fileInput}
          />
        </Box>
      </Box>

      <Stack>
        <Stack>
          <p className="font-semibold text-lg">Crane Name</p>
          {/* <Button onClick={handleUpload}>Upload</Button> */}

          <div className="w-40 mt-2">
            <TailAutocomplete
              options={[]}
              func={handleInputChange}
              placeholder={""}
            />
          </div>
          {craneExists && (
            <p className="text-xs text-red-600 mt-2">
              A crane with this name and location already exists
            </p>
          )}
        </Stack>

        <p className="font-semibold text-lg mt-10">Location</p>
        <Stack direction="row" gap={2} mt={1}>
          {/* <Autocomplete
            id="country"
            freeSolo
            sx={{ width: "25ch" }}
            value={selectedCountry}
            onChange={handleCountryEvent}
            className=""
            onBlur={handleCountryEvent}
            options={
              cranesdata
                .map((crane) => crane.country)
                .filter((v, i, a) => a.indexOf(v) === i) // remove duplicates
            }
            renderInput={(params) => <TextField {...params} label="Country" sx={{
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Removes the border
            boxShadow: 'none', // Removes shadow if any
          },
        },
      }}/>}
          /> */}
          <TailAutocomplete
            options={cranesdata
              .map((crane) => crane.country)
              .filter((v, i, a) => a.indexOf(v) === i)}
            func={handleCountryEvent}
            placeholder={"Country"}
          />
          <TailAutocomplete
            options={
              !selectedCountry
                ? []
                : cranesdata
                    .filter((crane) => crane.country === selectedCountry)
                    .map((crane) => crane.city)
                    .filter((v, i, a) => a.indexOf(v) === i)
            }
            func={handleCityEvent}
            placeholder={"City"}
          />
          <TailAutocomplete
            options={
              !selectedCity
                ? []
                : cranesdata
                    .filter((crane) => crane.city === selectedCity)
                    .map((crane) => crane.location)
                    .filter((v, i, a) => a.indexOf(v) === i)
            }
            func={handleLocationEvent}
            placeholder={"Facility"}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const Step2 = ({ userdata, setUsersWithAccess, setNext, usersWithAccess }) => {
  const [selectedUsers, setSelectedUsers] = React.useState(usersWithAccess);

  const handleCheckboxChange = (login) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(login)
        ? prevSelectedUsers.filter((user) => user !== login)
        : [...prevSelectedUsers, login]
    );
  };

  const selectAll = () => {
    const allUserLogins = userdata.map((user) => user.login);
    setSelectedUsers(allUserLogins);
  };

  const deselectAll = () => {
    setSelectedUsers([]);
  };

  React.useEffect(() => {
    console.log(selectedUsers); // Logs the selected users in the console
    if (usersWithAccess.length === 0) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [usersWithAccess]);

  const changeUserAccess = (event, user, permission) => {
    if (permission) {
      // Handle permission changes
      setUsersWithAccess((prevUsers) => {
        return prevUsers.map((u) => {
          if (u.id === user.id) {
            return {
              ...u,
              permissions: {
                ...u.permissions,
                [permission]: event.target.checked,
              },
            };
          }
          return u;
        });
      });
    } else {
      // Handle user access changes
      if (event.target.checked) {
        setUsersWithAccess((prevUsers) => [
          ...prevUsers,
          {
            ...user,
            permissions: {
              "Crane access": true,
              Configure: false,
              "Create orders": false,
              "Create locations": false,
              "Toggle functions": false,
              "Filesystem access level": 0,
            },
          },
        ]);
      } else {
        setUsersWithAccess((prevUsers) =>
          prevUsers.filter((u) => u.id !== user.id)
        );
      }
    }
  };

  return (
    <Box p={2} mb={10}>
      {/* <Box mb={2}>
        <Button onClick={selectAll} variant="outlined">Select All</Button>
        <Button onClick={deselectAll} variant="outlined" style={{ marginLeft: '10px' }}>Deselect All</Button>
      </Box> */}

      {/* {userdata && userdata.map((item) => (
        <Box key={item.login} display="flex" alignItems="center">
          <Checkbox
            checked={selectedUsers.includes(item.login)}
            onChange={() => handleCheckboxChange(item.login)}
          />
          <Typography
            variant='h4'
            sx={{'&:hover': {
              cursor: 'pointer',
              transform: 'scale(1.03)'
            }}}
          >
            {item.login}
          </Typography>
        </Box>
      ))} */}
      <div className="h-96">
        <fieldset>
          <legend className="text-base font-semibold leading-6 text-gray-900">
            Users with access to the crane
          </legend>
          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 h-72 overflow-auto">
            {userdata?.map((person, personIdx) => (
              <div key={personIdx} className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`person-${person.id}`}
                    className="select-none font-medium text-gray-900"
                  >
                    {person.login}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center mr-20">
                  <input
                    id={`person-${person.id}`}
                    name={`person-${person.id}`}
                    onChange={(event) => changeUserAccess(event, person)}
                    checked={usersWithAccess?.some((u) => u.id === person.id)}
                    type="checkbox"
                    className="h-5 w-5 rounded border-gray-300 text-nsblue-500 focus:ring-nsblue-500"
                  />
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </Box>
  );
};

const Step3 = ({ opcdata, setRequestBody, requestBody, setNext }) => {
  const [opcservername, setOpcservername] = React.useState("");
  const [value, setValue] = React.useState(null); // state to control the value

  const handleUpdateOpcName = (event, value) => {
    setOpcservername(event.target.value);
    let updatedRequestBody = { ...requestBody };

    setValue(null);
    setNext(true);
    updatedRequestBody.opc = { id: "", name: event.target.value };

    // Call setRequestBody with the updated object
    setRequestBody(updatedRequestBody);
  };
  const handleAutoCompleteChange = (value) => {
    // Create a copy of the existing requestBody
    console.log(value);
    setValue(value);

    let updatedRequestBody = { ...requestBody };

    // Check if value is null (happens when clearing selection)
    if (value !== null) {
      // Update the opc property with the new value object (which has name and id)
      updatedRequestBody.opc = value;
      setOpcservername("");
      setNext(true);
    } else {
      // If value is null, we clear the opc property
      updatedRequestBody.opc = null;
      setNext(false);
    }

    // Call setRequestBody with the updated object
    setRequestBody(updatedRequestBody);
  };

  let options =
    opcdata?.map((item) => ({
      id: item.id,
      name: item.name === "opc-6" ? "opc-6 (Demodata)" : item.name,
    })) || [];

  const handleUpdateIP = (event) => {
    setRequestBody((prevRequestBody) => ({
      ...prevRequestBody,
      opcIP: event.target.value,
    }));

    if (requestBody.opcIP !== "" || value !== null) {
      setNext(true);
    } else {
      setNext(false);
    }
  };

  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={10} height={"200px"}>
        <Box>
          <p className="font-semibold text-xl mb-4">
            Choose from an existing OPC server
          </p>
          <TailAutocomplete
            options={options}
            func={handleAutoCompleteChange}
            placeholder={"Available OPC-servers"}
            withId={true}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <p className="font-semibold text-xl mb-4">Create a new OPC server </p>
          <Box>
            <input
              type="text"
              value={opcservername}
              onChange={handleUpdateOpcName}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={"Name"}
            />
          </Box>
          <Box mb={5}>
            <div className="w-72 mt-4">
              <input
                type="text"
                value={requestBody.opcIP}
                onChange={handleUpdateIP}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={"IP-Address"}
              />
            </div>
            <Button variant="contained" sx={{ mt: 2 }}>
              Test Connection
            </Button>
          </Box>
        </Box>
      </Stack>

      <Box></Box>
    </Box>
  );
};
const Step4 = ({ setCraneParts, craneParts, setNext }) => {
  const {
    data: partsdata,
    loading: partsloading,
    error: partserror,
    refetch: refetchParts,
  } = useFetchV2("https://noswingcc.net/noswingcc/api/parts?page=0&size=1000");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  //console.log("row selection model", rowSelectionModel);

  //console.log("craneparts: ",craneParts);
  if (rowSelectionModel.length > 0) {
    setNext(true);
  } else {
    setNext(false);
  }

  React.useEffect(() => {
    if (craneParts.length > 0) {
      const rowIDS = craneParts.map((part) => part.id);
      //console.log("Crane parts: " , rowIds);

      //setRowSelectionModel(rowIds);
    } else {
      console.log("Null!");
    }

    return () => {
      onRowsSelectionHandler(rowSelectionModel);
      //console.log("updating");
    };
  }, [rowSelectionModel]);

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    //console.log(selectedRowsData);
    setCraneParts(selectedRowsData);
    //console.log(ids,selectedRowsData);
  };

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const columns = [
    { field: "componentname", headerName: "Component Name", width: 150 },
    { field: "partnumber", headerName: "Part Number", width: 130 },
    { field: "parttype", headerName: "Part Type", width: 120 },
    { field: "manufacturer", headerName: "Manufacturer", width: 150 },
    { field: "note", headerName: "Note", width: 200 },
    { field: "lifetime", headerName: "Lifetime", width: 120 },
  ];

  const rows = partsdata || [];

  function searchParts(searchTerm) {
    if (!Array.isArray(rows)) {
      return [];
    }

    const filteredParts = rows.filter((item) =>
      item.componentname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    //console.log(filteredParts);
    return filteredParts;
  }
  //console.log('selectedRows: ', selectedRows);
  //console.log('DataGrid rows: ', rows);

  return (
    <Box pl={2} display="flex" justifyContent="space-between" mb={10} gap={8}>
      <Box>
        <div className="flex flex-row justify-between mb-4">
          <p className="font-semibold text-lg">
            Select components from the database to add to the new crane
          </p>
          <div>
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border rounded-md"
              placeholder="Search"
            />
          </div>
        </div>
        <Box height="300px" width="900px">
          <DataGrid
            rows={searchParts(searchTerm)}
            columns={columns}
            pageSize={5}
            disableRowSelectionOnClick
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
          />
        </Box>
      </Box>
    </Box>
  );
};

const Confirm = ({ requestBody, avatarUrl, craneParts, usersWithAccess }) => {
  const [ShowList, SetshowList] = React.useState(false);
  const partsDataExists = craneParts.length > 0;
  //console.log("partsdataexists: ",partsDataExists);
  console.log(requestBody);
  console.log(usersWithAccess);
  console.log(craneParts);
  console.log(avatarUrl);
  //console.log(craneParts);
  return (
    <Box p={2} display="flex" justifyContent="space-around" mb={10}>
      <Box flex={1}>
        <Cranecard
          name={requestBody.name}
          location={requestBody.location}
          city={requestBody.city}
          country={requestBody.country}
          imageUrl={avatarUrl ? URL.createObjectURL(avatarUrl) : ""}
        />
      </Box>
      <Stack flex={1} spacing={5}>
        <div className="flex flex-row items-center">
          <p className="font-semibold text-large">User Access:</p>
          <Typography
            fontSize={20}
            ml={2}
            color={usersWithAccess.length > 0 ? "black" : "grey"}
          >
            {usersWithAccess.length > 0 ? "Configured" : "(Configuring later)"}
          </Typography>
        </div>
        <div className="flex flex-row items-center">
          <p className="font-semibold text-large">OPC Server:</p>
          <Typography
            fontSize={20}
            ml={2}
            color={requestBody?.opc.name ? "black" : "grey"}
          >
            {requestBody?.opc.name
              ? requestBody?.opc.name
              : "(Configuring later)"}
          </Typography>
        </div>
        <div className="flex flex-row items-center">
          <p className="font-semibold text-large">Crane Parts:</p>
          <Typography
            fontSize={20}
            sx={{
              textDecoration: partsDataExists ? "underline" : "none",
              cursor: !partsDataExists ? "undefined" : "pointer",
            }}
            ml={2}
            color={!partsDataExists && "grey"}
            onClick={
              partsDataExists ? () => SetshowList((prev) => !prev) : null
            }
          >
            {!partsDataExists
              ? "(Configuring later)"
              : !ShowList
              ? "Show list"
              : "Hide list"}
          </Typography>
        </div>
        {ShowList && (
          <Box border={1} p={0.5} borderColor="gray" borderRadius="5px">
            <VirtualizedList craneParts={partsDataExists && craneParts} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const AddingCrane = ({
  requestBody,
  setRequestData,
  postCraneLoading,
  postCraneError,
  postCraneData,
}) => {
  const [showComponent, setShowComponent] = React.useState(true);
  {
    postCraneError && console.log("Post Crane Error: ", postCraneError);
  }

  // React.useEffect(() => {
  //   // Show the component for 2 seconds
  //   const timeout = setTimeout(() => {
  //     setShowComponent(false);
  //     console.log(postCraneData);
  //   }, 2000);

  //   // Clean up the timeout when the component is unmounted
  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, []);

  return (
    <Box mt={5}>
      {showComponent ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress size={140} thickness={2} />
          <Typography
            variant="h3"
            color="textSecondary"
            style={{ marginTop: 20 }}
          >
            Adding your crane...
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!postCraneError ? (
            <Typography
              variant="h3"
              color="textSecondary"
              style={{ marginTop: 170 }}
            >
              Succesfully added the new crane!
            </Typography>
          ) : (
            <Box>
              <Typography
                variant="h3"
                color="textSecondary"
                style={{ marginTop: 170 }}
              >
                Unfortunately an error occurred!
              </Typography>
              <Typography variant="h5" color="black" mt={5}>
                {postCraneError.errorKey}: {postCraneError.title}
              </Typography>
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};

const initialFormValues = {
  id: "",
  componentname: "",
  partnumber: "",
  parttype: "",
  manufacturer: "",
  note: "",
  lifetime: 0,
};

const PartFormComponent = ({ refetchParts }) => {
  const [formValues, setFormValues] = React.useState(initialFormValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Values:", formValues);
    handlePost();
    setFormValues(initialFormValues);
  };

  async function handlePost() {
    const success = await postPart(formValues);

    if (success) {
      toast.success("Succefully saved part!");
      refetchParts();
    } else {
      toast.error("Could not save part!");
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} display="flex" gap={2}>
      <TextField
        label="Component Name"
        name="componentname"
        value={formValues.componentname}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Part Number"
        name="partnumber"
        value={formValues.partnumber}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Part Type"
        name="parttype"
        value={formValues.parttype}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Manufacturer"
        name="manufacturer"
        value={formValues.manufacturer}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Note"
        name="note"
        value={formValues.note}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Lifetime"
        name="lifetime"
        type="number"
        value={formValues.lifetime}
        onChange={handleChange}
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Box>
  );
};

export const TailAutocomplete = ({ options, func, placeholder, withId = false }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null); // Create a ref

  useEffect(() => {
    const filtered = withId
      ? options
      : options.filter((option) =>
          option.toLowerCase().includes(inputValue.toLowerCase())
        );
    setFilteredOptions(filtered);
  }, [inputValue, options]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowSuggestions(true);

    func(e.target.value);
  };

  const handleSuggestionClick = (value) => {
    console.log(value);
    setInputValue(value);
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }

    func(value);
  };

  return (
    <div className="relative">
      <input
        type="text"
        ref={inputRef}
        value={withId ? inputValue.name : inputValue}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
        placeholder={placeholder}
        readOnly={withId}
      />
      {showSuggestions && options.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md mt-1 max-h-60 overflow-auto">
          {filteredOptions.map((option, index) => (
            <li
              key={withId ? option.id : option}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSuggestionClick(option)}
              onMouseDown={(e) => e.preventDefault()} // Prevent onBlur from firing on click
            >
              {withId ? option.name : option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
