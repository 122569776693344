import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';

function renderRow(props) {
    const { index, style, data } = props;
  
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={data[index].componentname} />
        </ListItemButton>
      </ListItem>
    );
  }
  
  export default function VirtualizedList({craneParts}) {
    return (
      <Box
        sx={{ width: '100%', height: 150,  bgcolor: 'background.paper' }}
      >
        <FixedSizeList
          height={150}
          width={440}
          itemSize={36}
          itemCount={craneParts.length}
          overscanCount={5}
          itemData={craneParts} // pass craneParts as itemData to the list
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    );
  }
  