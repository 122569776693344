import React from 'react'
import { Box, Stack, Typography, IconButton, Autocomplete, TextField, MenuItem, FormControl, Select, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';
import useFetchV2 from '../../components/PartsApi';
import {postDeliveredOrder} from '../../components/WMS_Apis';
import { useNavigate } from 'react-router-dom';

const NewDeliveredOrder = () => {
    const { data: containers, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/containers?cranelocationId.notEquals=83&page=0&size=20`);
    const [rows, setRows] = React.useState([{ product: '', demand: '1', done: '0' }]);
    const [timespan, setTimespan] = React.useState("Ready");
    const navigate = useNavigate();
    console.log(containers);
    const products = [{id: 12, productcode: 1, name: 'Wood-Long', category: 'Wood', batchnumber: 2}, {id: 13, productcode: 2, name: 'Wood-short', category: 'Wood', batchnumber: 2}];
    const handleSave = async () => {

        const body = {
            "id": "",
            "reference": "ref",
            "scheduleddate": "2023-11-13T08:09:20.217Z",
            "demand": rows[0].demand,
            "done": rows[0].done,
            "status": "Ready",
            "product": {
              "id": rows[0].product
            }
          }

        console.log(body);
        const posted = await postDeliveredOrder(body);
        navigate("/wms/delivered");
    }

  return (
    <Box display="flex" justifyContent="center">
            <Stack>
            
            <Box p={4} boxShadow={1} bgcolor="white" borderRadius={1} width="1100px" mt={5} height={560}>
                
                <Stack direction="row" display="flex" justifyContent="space-between">
                    <Box display="flex" justifyContent="space-between" gap={10}>
                        <Stack>
                            <Typography variant="h5">Order reference</Typography>
                            <Typography variant="h4" mt={1}>-</Typography>
                        </Stack>

                        <Box>
                        <Box flex={2}>
                            <Typography>Status</Typography>
                            <FormControl variant="standard" sx={{ minWidth: 150, margin: 2 }}>
                            <Select
                            value={timespan}
                            onChange={e => setTimespan(e.target.value)}
                            inputProps={{ disableUnderline: true }}
                            renderValue={(selected) => (
                                <span style={{ fontWeight: '500', fontSize: '18px' }}>{selected}</span>
                            )}
                            >   
                            <MenuItem value="Draft">Draft</MenuItem>
                            <MenuItem value="Ready">Ready</MenuItem>
                            
                            </Select>
                            </FormControl>
                        </Box>
                         </Box>
                    
                    </Box>
                    

                    <Box display="flex" alignItems="center" gap={2} >
                        {/* Print Icon with Text */}
                        <Box display="flex" alignItems="center" gap={0}>
                            <IconButton color="primary">
                                <PrintIcon />
                            </IconButton>
                            <Typography variant="body1">Print</Typography>
                        </Box>

                        {/* Settings Icon with Text */}
                        <Box display="flex" alignItems="center" gap={0}>
                            <IconButton color="primary">
                                <SettingsIcon />
                            </IconButton>
                            <Typography variant="body1">Action</Typography>
                        </Box>
                    </Box>
                </Stack>

                <Box mt={10} display="flex" justifyContent="space-between">
                    <Stack direction="row" gap={4}>
                        <Typography fontWeight="bold">Recieved From</Typography>
                        <Typography >-</Typography>
                    </Stack>
                    <Stack direction="column" mr={10}>
                        <Stack direction="row" gap={4}>
                            <Typography fontWeight="bold">Scheduled Date</Typography>
                            <Typography >- </Typography>
                        </Stack>
                        <Stack direction="row" gap={4}>
                            <Typography fontWeight="bold">Source Document</Typography>
                            <Typography >-</Typography>
                        </Stack>
                    </Stack>
                </Box>

                <MyTableComponent products={products} rows={rows} setRows={setRows}/>
        
                
                <Box display="flex" gap={2} justifyContent="flex-end" mt={4}>
                    <Button variant='outlined' component={Link} to="/wms/received">Close</Button>
                    <Button variant='contained' onClick={handleSave}>Save</Button>
                </Box>

             
            </Box>
            </Stack>
        </Box>
  )
}

export default NewDeliveredOrder;

function MyTableComponent({products, rows, setRows}) {
    

    // Function to handle input change
    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);
    };

    // Function to add a new row
    const addNewRow = () => {
        setRows([...rows, { product: '', demand: '', done: '' }]);
    };

    return (
        <Box mt={2} display="flex" gap={4}>
            <Box flex={1} mt={2}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{ backgroundColor: "#ebeced" }}>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Product</Typography>
                                </th>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Demand</Typography>
                                </th>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Done</Typography>
                                </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Select
                                            fullWidth
                                            value={row.product}
                                            onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                            displayEmpty
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true, // Removes the underline
                                                style: { border: 'none' } // Removes any border
                                            }}
                                        >
                                            {products?.map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name || `Product ${item.productcode}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <TextField 
                                        fullWidth 
                                        value={row.demand}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true, // Removes the underline
                                            style: { border: 'none' } // Removes any border
                                        }}
                                        onChange={(e) => handleInputChange(index, 'demand', e.target.value)}
                                    />
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <TextField 
                                        fullWidth 
                                        value={row.done}
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true, // Removes the underline
                                            style: { border: 'none' } // Removes any border
                                        }}
                                        onChange={(e) => handleInputChange(index, 'done', e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Box mt={2}>
                    <button onClick={addNewRow}>Add Row</button>
                </Box>
            </Box>
        </Box>
    );
}