import React from "react";
import useFetchV2 from "../../../../components/PartsApi";

import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { getCrane, updateCrane } from "../../../../components/CraneApi";

const OPCserver = ({ id }) => {
  const {
    data: cranedata,
    loading: craneloading,
    error: craneerror,
    refetch: refetchCraneData,
  } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${id}`);

  const handleSave = async (event) => {
    event.preventDefault();
    const cranedata = await getCrane(id);
    cranedata.opcIP = event.target.ip.value;
    console.log(cranedata);
    const updated = await updateCrane(id, cranedata);
    if (updated) {
      toast.success("Saved");
    } else {
      toast.error("Could not save");
    }
  };

  return (
    <div>
      <form onSubmit={handleSave}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                OPC Server connection
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600"></p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label
                  htmlFor="ip"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  IP address
                </label>
                {
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-nsblue-500 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        opc.tcp://
                      </span>
                      <input
                        type="text"
                        name="ip"
                        id="ip"
                        defaultValue={cranedata?.opcIP}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="192.168.1.10/8480"
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-nsblue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-nsblue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-nsblue-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default OPCserver;
