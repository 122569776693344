import React from 'react'
import { Box, Tabs, Tab, Typography } from '@mui/material'
import PropTypes from 'prop-types';
import useFetch from '../../../components/fetch';
import ReactApexChart from "react-apexcharts";
import { Select, MenuItem, FormControl, InputLabel, Button, ButtonGroup, Stack } from '@mui/material';


const Data = ({id}) => {
const { data: cranedata, loading: cranedataIsLoading, error: errorIncraneData } = useFetch(`https://noswingcc.net/noswingcc/api/cranelogs?craneId.equals=${id}&page=0&size=1000`);
//if(cranedata) console.log(cranedata);
if(cranedataIsLoading){
  return ""
}
if(cranedata.length == 0){
  return <div className='p-20'><p>Datalog not configured</p></div>
}
// console.log("Last log: ", cranedata[cranedata.length - 1].timestamp);
const dateObj = new Date(cranedata[cranedata.length - 1].timestamp);
const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${dateObj.getFullYear()} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;

return (
    <div className='mt-5 w-9/12'>
        <VerticalTabs cranedata={cranedata}/>
        <Typography variant='subtitle1' color={"grey"}>Latest log: {formattedDate}</Typography>
    </div>
  )
}

export default Data

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} width="100%" height="100%">
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const VerticalTabs = ({cranedata}) => {
    const [value, setValue] = React.useState(0);
    const [timespan, setTimespan] = React.useState("All Time");
    const [seriesNbr, setseriesNbr] = React.useState(0);
    const aggregateDataByMonth = (data) => {
      const monthlyData = {};
    
      data.forEach(item => {
        const date = new Date(item.timestamp);
        const monthYearKey = `${date.getMonth() + 1}-${date.getFullYear()}`;
        const monthName = date.toLocaleString('en-US', { month: 'long' });
    
        if (!monthlyData[monthYearKey]) {
          monthlyData[monthYearKey] = { timestamp: monthName };
        }
    
        Object.keys(item).forEach(key => {
          if (key !== 'timestamp') {
            if (!monthlyData[monthYearKey][key]) {
              monthlyData[monthYearKey][key] = 0;
            }
    
            monthlyData[monthYearKey][key] += item[key];
          }
        });
      });
      
      return Object.values(monthlyData);
    };
    

    const filterData = (data, timespan) => {
      const now = new Date();
      if(timespan === "All Time"){
        const newdata = aggregateDataByMonth(data);
        //console.log("AggregatedDATA: " , newdata);
        return newdata;
      }
      else {
        const newdata = data.filter(item => {
          const itemDate = new Date(item.timestamp);
          switch (timespan) {
            case "Last Month":
              return now.getMonth() === itemDate.getMonth()  && now.getFullYear() === itemDate.getFullYear();
            case "Last Week":
              return now.getTime() - itemDate.getTime() <= 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
            case "Last Day":
              return now.getTime() - itemDate.getTime() <= 3 * 24 * 60 * 60 * 1000; // 1 day in milliseconds
            default:
              return true;
          }
        });
        //console.log("FilteredDATA: " , newdata);
        return newdata;
      }
    };

    const generateSeries = (data) => {
      return [
        {
          name: "Running Hours",
          data: filterData(data, timespan).map(item => ({ x: item.timestamp, y: Math.round((item.runninghours / 60) * 10) / 10 }))
        },
        {
          name: "Distance Traveled",
          data: filterData(data, timespan).map(item => ({ x: item.timestamp, y: item.distancetraveled }))
        },
        {
          name: "Lifts Done",
          data: filterData(data, timespan).map(item => ({ x: item.timestamp, y: item.liftsdone }))
        },
        {
          name: "Tons Lifted",
          data: filterData(data, timespan).map(item => ({ x: item.timestamp, y: item.tonslifted }))
        },
        {
          name: "Overloads",
          data: filterData(data, timespan).map(item => ({ x: item.timestamp, y: item.overloads }))
        },
        {
          name: "Emergency Stops",
          data: filterData(data, timespan).map(item => ({ x: item.timestamp, y: item.emergencystops }))
        },
        
      ];
    };

    let series = generateSeries(cranedata);
    
    React.useEffect(() => {
      series = generateSeries(cranedata);
  
      setChartOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          categories: series[0].data.map(item => formatDate(item.x))
        },
        // yaxis: {
        //   title: {
        //     text: getTitle(seriesNbr),
        //     offsetX: -5,
        //     style: {
        //       fontSize: '24px',
        //       fontWeight: 'bold',
        //       color: 'lightgrey'
             
        //     }
        //   },
        // }

      }));
  
      setChartSeries(series);
    }, [cranedata, timespan]);

    

    
    
    
    
    //console.log("Serises: ", series);
    //console.log(cranedata);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const formatDate = (dateString) => {
      if(timespan === "All Time")
        return dateString;

      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1; // Months are zero-indexed
      return `${day}/${month}`;
    };

    const getTitle = (seriesNbr) => {
      switch(seriesNbr) {
        case 0:
          return "Hours";
        case 1:
          return "Meters";
        case 2:
          return "Lifts";
        case 3:
          return "Tons";
        case 4:
          return "Overloads";
        case 5:
          return "Stops";
        default:
          return "";
      }
    };
    
    // Example Usage
    const yAxisTitle = getTitle(2);  // Returns "Title for Series 2"
    

    const [chartOptions, setChartOptions] = React.useState({
      chart: {
        type: 'bar',
        width: '100%'
      },
      xaxis: {
        categories: series[0].data.map(item => formatDate(item.x))
      },
      // yaxis: {
      //   title: {
      //     text: getTitle(seriesNbr),
      //     offsetX: -5,
      //     style: {
      //       fontSize: '24px',
      //       fontWeight: 'bold',
      //       color: 'lightgrey'
           
      //     }
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#254269", "#d77d1a"],
      plotOptions: {
          bar: {
              borderRadius: 4,
              horizontal: false,
              columnWidth: "65%",
          },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
     },
    });
    
    const [chartSeries, setChartSeries] = React.useState([]);
    
    
    
    
    return (
      <Box>
      <Box sx={{
        bgcolor: "#fff",
        mt: 3,
        borderRadius: '10px',
        width: '100%',
      
        p: 2,
        mb: 2,
      }}>
          <Stack direction="row-reverse" display="flex" justifyContent="space-between" alignItems="center">
              <Box >
                <FormControl variant="standard" sx={{ minWidth: 150, margin: 2 }}>
                <Select
                  value={timespan}
                  onChange={e => setTimespan(e.target.value)}
                  inputProps={{ disableUnderline: true }}
                  renderValue={(selected) => (
                    <span style={{ fontWeight: '600', fontSize: '22px' }}>{selected}</span>
                  )}
                >   
                  <MenuItem value="All Time">All Time</MenuItem>
                  <MenuItem value="Last Month">Last Month</MenuItem>
                  <MenuItem value="Last Week">Last Week</MenuItem>
                  <MenuItem value="Last Day">Last Day</MenuItem>
                </Select>
              </FormControl>
            </Box>
              <Box ml={5}>
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button type="button" onClick={() => setseriesNbr(0)} className={ "relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 " + (seriesNbr === 0 ? "bg-nsblue-300" : "")}>Running Hours</button>
                <button type="button" onClick={() => setseriesNbr(1)} className={"relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 " + (seriesNbr === 1 ? "bg-nsblue-300" : "")}>Distance Traveled</button>
                <button type="button" onClick={() => setseriesNbr(2)} className={"relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 " + (seriesNbr === 2 ? "bg-nsblue-300" : "")}>Lifts Done</button>
                <button type="button" onClick={() => setseriesNbr(3)} className={"relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 " + (seriesNbr === 3 ? "bg-nsblue-300" : "")}>Tons Lifted</button>
                <button type="button" onClick={() => setseriesNbr(4)} className={"relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 " + (seriesNbr === 4 ? "bg-nsblue-300" : "")}>Overloads</button>
                <button type="button" onClick={() => setseriesNbr(5)}  className={"relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 " + (seriesNbr === 5 ? "bg-nsblue-300" : "")}>Emergency Stops</button>
              </span>
              </Box>
          </Stack>
          <Box mt={5} width="100%">
            <ReactApexChart options={chartOptions} series={[{ name: 'Running Hours', data: series[seriesNbr].data.map(item => item.y) }]} type="bar" width="100%" />
          </Box>
        </Box>
        {/* <Box mt="20px" display="flex" flexWrap="wrap" gap={4}>
            <PieChart title= "PLC" value={98} series={[98, 100 - 98]} colors={['#475be8', '#e4e8ef']}/>
            <PieChart title= "Crane" value={87} series={[75, 25]} colors={['#475be8', '#e4e8ef']}/>
            <PieChart title= "Hook" value={65} series={[45, 55]} colors={['#475be8', '#e4e8ef']}/>
            <PieChart title= "Sensor" value={13} series={[13, 100 - 13]} colors={['#475be8', '#e4e8ef']}/>
          </Box> */}
      </Box>
    );
  }

  const PieChart = ({title, value, series, colorss}) => {

    const getColor = (value) => {
      if (value >= 45) {
        return '#008000';
      } else if (value >= 15 && value < 45) {
        return '#008000';
      } else {
        return '#FF0000';
      }
    };

    const color = getColor(value);
    const colors = [color, '#e4e8ef'];

    const chartOptions = {
      chart: { type: 'donut' },
      colors: colors,
      legend: { show: false },
      dataLabels: { enabled: false },
    };
  
    return (
      <Stack direction="column" display="flex" justifyContent="center">
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      
        <Box position="relative" id="chart">

          
          <Box 
            position="absolute" 
            top="50%" 
            left="50%" 
            style={{
              transform: 'translate(-45%, -55%)',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: '600'
            }}
          >
            {value}%
          </Box>
          <ReactApexChart
            options={chartOptions}
            series={series}
            type="donut"
            width={160}
          />
        </Box>
      </Stack>
    );
  };