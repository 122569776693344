import { useXR, useController } from '@react-three/xr';
import { Vector3 } from 'three';
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

export default function MovementController({
  hand = 'right',
  zeroY = true,
  horizontalSensitivity = 0.05,
  forwardSensitivity = 0.7,
  rotationSensitivity = 0.05,
  verticalSensitivity = 0.05, // Adjust sensitivity for vertical movement
  deadzone = 0.05,
  applyForward = true,
  applyHorizontal = false,
  applyRotation = true,
  applyVertical = true, // Option to apply vertical movement
}) {
  const { player, isPresenting } = useXR();
  const controller = useController(hand);
  React.useEffect(() => {
    if(isPresenting){
      
      player.position.set(-105, -80, -50);
      player.rotation.set(0, -20.3, 0);
    }
  }, [isPresenting]);
   // Zero out y-component of player position

  useFrame(() => {
    if (!controller || !player) return;

    const { axes, buttons } = controller.inputSource.gamepad;
    const forward = new Vector3(0, 0, -1); // Forward direction
    const up = new Vector3(0, 1, 0); // Up direction
    const right = new Vector3(1, 0, 0); // Right direction

    const move = buttons[0].pressed || buttons[1].pressed
    
    forward.applyQuaternion(player.quaternion); // Adjust forward direction based on player orientation
    if (zeroY) forward.y = 0; // Zero out y-component if required
    forward.normalize(); // Normalize to ensure consistent movement regardless of direction
    
    right.applyQuaternion(player.quaternion); // Adjust right direction based on player orientation
    right.y = 0; // Always zero out y for horizontal movement
    right.normalize();

    
    
    // Apply forward/backward movement
    if (applyForward && hand === "left" && Math.abs(axes[3] && !move) > deadzone) {
      player.position.addScaledVector(forward, axes[3] * -forwardSensitivity);
    }

    // Apply right/left movement
    if (applyHorizontal && Math.abs(axes[2]) > deadzone && !move) {
      player.position.addScaledVector(right, axes[2] * horizontalSensitivity);
    }

    // Apply vertical movement (up/down)
    if (applyVertical && hand === "left" && !move) {
      if (buttons[5].pressed) { // Assuming button index 3 moves up
        player.position.addScaledVector(up, verticalSensitivity);
      }
      if (buttons[4].pressed) { // Assuming button index 0 moves down
        player.position.addScaledVector(up, -verticalSensitivity);
      }
    }

    // Handle rotation if needed
    if (applyRotation && Math.abs(axes[2]) > deadzone && !move) {
      player.rotation.y -= axes[2] * rotationSensitivity;
    }
  });

  return null;
}
