import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Divider, Stack, Tabs, Tab, Paper, List, ListItem, ListItemText, Autocomplete } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { postProduct } from '../../components/WMS_Apis';
import { toast } from 'react-toastify';
const attributesOptions = ['length', 'thickness', 'width', 'height'];

const product = {
    name: '-',
    reference: '',
    barcode: '',
    salesPrice: '',
    cost: '',
    category: '',
};

function Newproductitem() {
    const [tabValue, setTabValue] = useState(0);
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [height, setHeight] = useState(0);
    const [value, setValue] = useState('');

    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleChange = (event) => {
        setName(event.target.value); // Update name when the text changes
    };
    const handleChangeAttribute = (event, newValue) => {
        setValue(newValue); // Update the state with the new value
      };

    const createProduct = async () => {
        const body = {
            "id": "",
            "productcode": "000",
            "name": name,
            "category": category,
            "batchnumber": 0,
            "storeat": "",
            "weight": 0,
            "width": width,
            "length": length,
            "height": height
          }
        const post = await postProduct(body);
        if(post){
            toast.success("Succefully created the new product");
            navigate(-1);
        }
        else{
            toast.error("Could not create the new product");
        }
        
    }

    return (
        <Box display="flex" justifyContent="center">
            <Box p={4} boxShadow={1} bgcolor="white" borderRadius={1} width="1200px" mt={5}>
                <Box display="flex" justifyContent="space-between">
                    <Stack>
                        <Typography variant="h5">Product Name</Typography>
                        <TextField
                            variant="outlined"
                            value={name}
                            onChange={handleChange}
                            
                            style={{ marginTop: '8px' }} 
                            />
                    </Stack>
                    <Box>
                        <img src="https://sudbury.legendboats.com/resource/defaultProductImage" alt={product.name} style={{ width: '100px', height: '100px', marginRight: '20px' }} />
                    </Box>
                </Box>

                

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        textTransform="none"
                        sx={{textTransform: 'none'}}
                    >
                        <Tab label="General Information" />
                        <Tab label="Attributes and Variants" />
                        <Tab label="Inventory" />
                    </Tabs>
                </Box>
                    {/* <Divider sx={{ my: 2 }} /> */}
                {tabValue === 0 && (
                    <Box mt={2} display="flex" gap={4} height={340}>
                        <Box flex={1} mt={2}>
                            <Typography variant="h5">Product Type</Typography>
                            {/* You can add more fields in this column if needed */}
                        </Box>
                        <Box flex={1}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Sales Price" secondary={product.salesPrice} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Cost" secondary={product.cost} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Product Category" secondary={product.category} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Internal Reference" secondary={product.reference} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Barcode" secondary={product.barcode} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                )}
                {tabValue === 1 && (
                    <Box mt={2} display="flex" gap={4} height={340}>
                    <Box flex={1} mt={2}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: "#ebeced" }}>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Attribute</Typography>
                                </th>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Value</Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' , width: '400px'}}>
                                <Autocomplete
                                    options={['Size', 'Variant 2', 'Variant 3']}
                                    value={value}
                                    onChange={handleChangeAttribute}
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                        variant="standard" 
                                        InputProps={{ ...params.InputProps, disableUnderline: true }} // Disable the underline
                                        sx={{
                                            '& .MuiInputBase-root': {
                                            border: 'none' // Remove border
                                            }
                                        }}
                                        />}
                                    />
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    {value == 'Size' && <Box sx={{ display: 'flex', gap: 2}}>
                                        <TextField
                                            label="Width"
                                            variant="standard"
                                            value={width}
                                            onChange={(e) => setWidth(e.target.value)}
                                            sx={{ '& .MuiInputBase-root': { border: 'none' } }}
                                        />
                                        <TextField
                                            label="Length"
                                            variant="standard"
                                            value={length}
                                            onChange={(e) => setLength(e.target.value)}
                                            sx={{ '& .MuiInputBase-root': { border: 'none' } }}
                                        />
                                        <TextField
                                            label="Height"
                                            variant="standard"
                                            value={height}
                                            onChange={(e) => setHeight(e.target.value)}
                                            sx={{ '& .MuiInputBase-root': { border: 'none' } }}
                                        />
                                        </Box>}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </Box>
                    </Box>
                )}
                {tabValue === 2 && (
                    <Box mt={2} height={340}>
                        {/* Content for "Inventory" tab */}
                    </Box>
                )}
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Stack direction={"row"} spacing={2}>
                        <Button variant='outlined' onClick={() => navigate(-1)}>Discard</Button>
                        <Button variant='contained' onClick={createProduct}>Create</Button>
                    </Stack>
                </Box>
            </Box>
            
        </Box>
    );
}

export default Newproductitem;

