import axios from 'axios';
import React, {useState, useEffect} from 'react';



export async function getAllParts() {
    try {
      const response = await axios.get('https://noswingcc.net/noswingcc/api/parts?page=0&size=1000', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('An error occurred while trying to get all parts:', error);
      return null;
    }
}
export async function getPartsWithCraneID(id) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneparts?craneId.equals=${id}&page=0&size=200`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get all parts:', error);
    return null;
  }
}
export async function getAllCraneparts(id) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneparts/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get all parts:', error);
    return null;
  }
}

export async function deleteCranePart(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/craneparts/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete part:', error);
    return false;
  }
}

export async function getCranePartIdToRemove(craneId, partId) {
  
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneparts?craneId.equals=${craneId}&partId.equals=${partId}&page=0&size=20`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get the part:', error);
    return null;
  }
}

export async function postPart(part) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/parts', part, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the part:', error);
    return false;
  }
}

export async function postCranePart(part) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/craneparts', part, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the part:', error);
    return false;
  }
}

export default function useFetchV2(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUrl = async () => {
      try {
        const res = await axios.get(url, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Accept': '*/*'
          }
        });
          setData(res.data);
          setLoading(false);
      } catch (error) {
          setError(error);
          setLoading(false);
      }
  };

  useEffect(() => {
      fetchUrl();
  }, [url]);

  return { data, loading, error, refetch: fetchUrl };
}

