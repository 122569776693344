import React, { useState, useEffect, useRef } from "react";
import useFetchV2 from "../../../../components/PartsApi";
import { FileUploader } from "react-drag-drop-files";
import {
  Stack,
  Typography,
  Slider,
  Button,
  Box,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { ArrowPathIcon, ArrowsPointingOutIcon, CheckCircleIcon, CursorArrowRippleIcon, MagnifyingGlassPlusIcon } from "@heroicons/react/20/solid";
import * as THREE from "three";
import {
  PhotoIcon,
  UserCircleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import {
  postFile,
  postModelconf,
  get3DFile,
  updateModelconf,
  checkFile,
  deleteFile,
} from "../../../../components/FilesApi";
import Scene2 from "../relatedComponents/3DobjV2Configuration";
import { CheckIcon } from "@heroicons/react/20/solid";
import { uploadfile } from "../documents";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";

import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { getCranePos } from "../../../../components/OrderApi";
import Library from "./library";
import Upload3D from "./upload3D";
import RequestFilesDialog from "./requestFilesDialog";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

function CustomSlider({ value, min, max, step, setValue }) {
  const [originalValue, setOriginalValue] = useState(value);
  const [isSliding, setIsSliding] = useState(false);

  const handleChange = (event, newValue) => {
    if (!isSliding) {
      setOriginalValue(value); // Store the original value when sliding starts
      setIsSliding(true);
    }
    setValue(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    setIsSliding(false);
    // Any action after the slider has stopped moving can be handled here
  };

  const handleRestore = (event) => {
    event.preventDefault();
    setValue(originalValue); // Restore the original value
  };

  return (
    <div className="flex grow flex-col">
      <Slider
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        min={min}
        max={max}
        step={step}
        sx={{ minWidth: "200px", width: "100%" }}
      />
      {/* Restore button can be included here or outside the slider component */}
      {/* {value != originalValue && <button onClick={handleRestore}>undo</button>} */}
    </div>
  );
}

const xlabel = "Cross rail axis";
const ylabel = "Along rail axis";
const zlabel = "Up / Down";

const initialConfig = [
  {
    name: "x",
    label: "Crane X pos",
    min: -500,
    max: 800,
    step: 1,
    initialValue: 0,
  },
  {
    name: "y",
    label: "Crane Y pos",
    min: -300,
    max: 300,
    step: 1,
    initialValue: 0,
  },
  {
    name: "z",
    label: "Crane Z pos",
    min: -350,
    max: 350,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Building_x",
    label: xlabel,
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Building_y",
    label: ylabel,
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Building_z",
    label: zlabel,
    min: -400,
    max: 300,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_x",
    label: xlabel,
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_y",
    label: ylabel,
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_z",
    label: zlabel,
    min: -150,
    max: 250,
    step: 0.5,
    initialValue: 0,
  },

  {
    name: "Trolley_x",
    label: xlabel,
    min: -300,
    max: 600,
    step: 2,
    initialValue: 0,
  },
  {
    name: "Trolley_y",
    label: ylabel,
    min: -400,
    max: 500,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Trolley_z",
    label: zlabel,
    min: -100,
    max: 100,
    step: 0.5,
    initialValue: 0,
  },
  {
    name: "Rope_x",
    label: xlabel,
    min: -150,
    max: 150,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Rope_y",
    label: ylabel,
    min: -150,
    max: 150,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Rope_z",
    label: `${zlabel} (Top of the rope)`,
    min: -150,
    max: 150,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Rope_length",
    label: "Rope Length",
    min: -50,
    max: 50,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Hook_x",
    label: xlabel,
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Hook_y",
    label: ylabel,
    min: -300,
    max: 600,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Hook_z",
    label: zlabel,
    min: -100,
    max: 200,
    step: 1,
    initialValue: 0,
  },
  {
    name: "Beem_spacing",
    label: "Distance between beems",
    min: -100,
    max: 100,
    step: 1,
    initialValue: 20,
  },
  {
    name: "Trolley_spacing",
    label: "Distance between trolleys",
    min: -100,
    max: 100,
    step: 1,
    initialValue: 10,
  },
];

const steps = [
  {
    name: "Select 3D files",
    description:
      "Upload custom models or choose from a library of common ones.",
    href: "0",
    status: "complete",
  },
  {
    name: "Create the crane",
    description: "Connect all the base parts of the crane.",
    href: "1",
    status: "complete",
  },

  {
    name: "Modify crane-structure",
    description:
      "After configuring the crane base you can add more beems or trolleys for special configurations.",
    href: "2",
    status: "upcoming",
  },
  {
    name: "Set end-limits",
    description: "Move the crane and set the end-limits.",
    href: "3",
    status: "upcoming",
  },
  {
    name: "Offset",
    description: "Configure the target offset.",
    href: "4",
    status: "upcoming",
  },
  {
    name: "Test and confirm",
    description:
      "Animate the crane movement and check that everything looks like it should.",
    href: "5",
    status: "upcoming",
  },
];

const ministeps = [
  {
    name: "Building",
    description: "Move the building into the center of the preview window.",
    href: "1",
    status: "complete",
    instruction:
      "Move the building into the center of the preview window so that you have a good visual of the whole crane-track.",
  },
  {
    name: "Beem",
    description: "Move the beem (or beems) into correct position on the rails.",
    href: "2",
    status: "current",
    instruction:
      "Move the beem into correct position on the track. Left-mouse button to rotate the view, Right-mouse button to move and Scroll to zoom in and out.",
  },
  {
    name: "Trolley",
    description: "Position the trolley in the correct position on the beem.",
    href: "3",
    status: "upcoming",
    instruction: "Move the trolley into correct position on the beem.",
  },
  {
    name: "Rope",
    description: "Position the rope in the middle under the trolley.",
    href: "4",
    status: "upcoming",
    instruction:
      "Position the rope in the middle under the trolley. Set the rope length to rougly half of the distance to the floor.",
  },
  {
    name: "Hook",
    description: "Position the hook on the end of the wire.",
    href: "5",
    status: "upcoming",
    instruction: "Position the hook on the end of the wire.",
  },
  {
    name: "Grabber",
    description: "If you have a grabber, position it on the hook.",
    href: "6",
    status: "upcoming",
    instruction: "Position the grabber on the hook.",
  },
];

const Realtime3D = ({
  id,
  fullMarkerData,
  data,
  files,
  objData,
  allDocs,
  step,
  setStep,
  refetchAllData,
}) => {
  const {
    data: cranedata,
    loading: craneloading,
    error: craneerror,
    refetch: refetchCraneData,
  } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${id}`);

  const [itemName, setItemName] = useState("Building");
  const [twobeems, setTwobeems] = useState(false);
  const [miniStep, setMiniStep] = useState(1);
  const [objFiles, setObjFiles] = useState(objData);
  const [doesBeem1HaveTwoTrolleys, setDoesBeem1HaveTwoTrolleys] = useState(
    false
  );
  const [doesBeem2HaveTwoTrolleys, setDoesBeem2HaveTwoTrolleys] = useState(
    false
  );
  const [initialStepSet, setInitialStepSet] = useState(false);
  const {
    data: modelconf,
    loading: modelconfdataloading,
    error: modelconfdataerror,
    refetch: refetchModelconf,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/modelconfs?craneId.equals=${id}&page=0&size=1`
  );
  let obj = null;
  const [opcdata, setOpcdata] = useState([]);
  const [configured, setConfigured] = useState(false);
  const [camera, setCamera] = useState([30, 90, 0]);
  const [lookAt, setLookAt] = useState([0, 0, 0]);
  const [doesUserHave3DFiles, setDoesUserHave3DFiles] = useState(null);
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const [isUpload3DOpen, setIsUpload3DOpen] = useState(false);
  const [filePaths, setFilePaths] = useState([]);
  const [latestModelConf, setLatestModelConf] = useState([]);
  const [centerArray, setCenterArray] = useState([
    { x: 0, y: 0, z: 0 },
    { x: 0, y: 0, z: 0 },
    { x: 0, y: 0, z: 0 },
  ]);
  const [values, setValues] = useState(() => {
    const initialState = {};
    initialConfig.forEach((item) => {
      initialState[item.name] = item.initialValue;
    });
    console.log("hep1 ", initialState);
    return initialState;
  });
  const [prevValues, setPrevValues] = useState(values);
  const [debug, setDebug] = useState(false);
  const [craneModelClicked, setCraneModelClicked] = useState("");
  const navigate = useNavigate();
  const [lock, setLock] = useState(false);
  const [downloadConf, setDownloadConf] = useState(false);
  const [openRequestFiles, setOpenRequestFiles] = useState(false);
  const [editModels, setEditModels] = useState(false);
  React.useEffect(() => {
    if (!modelconfdataloading && modelconf.length > 0) {
      const configObject = Object.keys(modelconf[0]).reduce((obj, key) => {
        // Exclude 'id' and 'crane' from the new object
        if (key !== "id" && key !== "crane") {
          // Capitalize the first letter of each key and replace it in the new object
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          obj[capitalizedKey] = modelconf[0][key];
        }
        return obj;
      }, {});
      //console.log(configObject);

      let updatedValues = { ...values };

      updatedValues = {
        ...updatedValues,
        ...configObject,
      };

      if (true) {
        console.log("UPDATE");
        // toast.info("Updated values from database");
        setValues(updatedValues);
      }
    }
  }, [modelconf, modelconfdataloading]);
  const configurate3Dmodel = async (event) => {
    event.preventDefault();
    const body = {
      id: "",
      building_x: 0,
      building_y: 0,
      building_z: 0,
      beem_x: 0,
      beem_y: 0,
      beem_z: 0,
      trolley_x: 0,
      trolley_y: 0,
      trolley_z: 0,
      hook_x: 0,
      hook_y: 0,
      hook_z: 0,
      rope_x: 0,
      rope_y: 0,
      rope_z: 0,
      cranexposmin: 0,
      cranexposmax: 0,
      craneyposmin: 0,
      craneyposmax: 0,
      cranezposmin: 0,
      cranezposmax: 0,
      crane: {
        id: id,
      },
    };
    const post = await postModelconf(body);
    if (post === false) {
      toast.error("Could not generate a configurations file");
    }

    refetchModelconf();
  };
  useEffect(() => {
    if (!modelconfdataloading && modelconf?.length > 0) {
      if (modelconf[0]?.configured == true) {
        setConfigured(false);
      } else {
        setConfigured(false);
      }
      if (modelconf[0]?.beems == 2) {
        setTwobeems(true);
      } else {
        setTwobeems(false);
      }

      setDoesBeem1HaveTwoTrolleys(modelconf[0]?.special?.charAt(0) == "2");
      setDoesBeem2HaveTwoTrolleys(modelconf[0]?.special?.charAt(1) == "2");
      setValue("Beem_spacing", modelconf[0]?.beemSpacing);
      setValue("Trolley_spacing", modelconf[0]?.trolleySpacing);
      setValue("Rope_length", modelconf[0]?.ropeLength);
      setValue("cranexposmin", modelconf[0]?.cranexposmin);
      setValue("cranexposmax", modelconf[0]?.cranexposmax);
      setValue("craneyposmin", modelconf[0]?.craneyposmin);
      setValue("craneyposmax", modelconf[0]?.craneyposmax);
      setValue("cranezposmin", modelconf[0]?.cranezposmin);
      setValue("cranezposmax", modelconf[0]?.cranezposmax);
      setValue("x", 0);
      setValue("y", 0);
      setValue("z", 0);
    }
  }, [modelconf, modelconfdataloading]);
 
  const handleChange = (itemName, fileType) => async (file) => {
    let body = {
      id: "",
      fileName: `${itemName}.${fileType}`,
      folderName: "3D",
      crane: {
        id: id,
      },
    };
    let data = "";

    const newFiles = { ...files };
    const reader = new FileReader();
    reader.onload = (evt) => {
      //newFiles[`${itemName}.${fileType}`] = evt.target.result;
      //setFiles(newFiles);
      data = evt.target.result;
    };
    reader.readAsBinaryString(file);
    console.log(body);
    //
    // const dataUpload = await postFileDataToDatabase(`${id}_${itemName}.${fileType}`, data);
    const check = await checkFile(id, body.fileName);
    if (check.length == 0) {
      const upload = await postFile(body);
    } else {
      //toast.info("File already exists in aws");
    }

    const db = await uploadfile(file, `${id}_${body.fileName}`);
    console.log(db, `${id}_${body.fileName}`);
    //console.log(file, " ", db);

    refetchAllData();
  };
  const setValue = (name, value) => {
    setPrevValues(values);
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //console.log(files);

  const handleSave = async (event) => {
    event.preventDefault();

    const body = {
      id: modelconf[0].id,
      building_x: values.Building_x,
      building_y: values.Building_y,
      building_z: values.Building_z,
      beem_x: values.Beem_x,
      beem_y: values.Beem_y,
      beem_z: values.Beem_z,
      trolley_x: values.Trolley_x,
      trolley_y: values.Trolley_y,
      trolley_z: values.Trolley_z,
      hook_x: values.Hook_x,
      hook_y: values.Hook_y,
      hook_z: values.Hook_z,
      rope_x: values.Rope_x,
      rope_y: values.Rope_y,
      rope_z: values.Rope_z,
      cranexposmin: values.Cranexposmin || 0,
      cranexposmax: values.Cranexposmax || 0,
      craneyposmin: values.Craneyposmin || 0,
      craneyposmax: values.Craneyposmax || 0,
      cranezposmin: values.Cranezposmin || 0,
      cranezposmax: values.Cranezposmax || 0,
      ropeLength: values.Rope_length,
      ropeType: 1,
      configured: step == 4 ? true : modelconf[0]?.configured,

      camera1: "angle1,0,0,0",
      camera2: "angle2,0,0,0",
      camera3: "angle3,0,0,0",
      beemSpacing: values.Beem_spacing || 0,
      trolleySpacing: values.Trolley_spacing || 0,
      beems: twobeems ? 2 : 1,
      trolleys:
        (doesBeem1HaveTwoTrolleys ? 2 : 1) + doesBeem2HaveTwoTrolleys ? 2 : 1,
      special:
        (doesBeem1HaveTwoTrolleys ? "2" : "1".toString) +
        (doesBeem2HaveTwoTrolleys ? "2" : "1").toString(),

      crane: {
        id: id,
      },
    };

    console.log("body ", body);

    const post = await updateModelconf(modelconf[0].id, body);
    console.log("paths ", filePaths);
    if (step == 4) {
      setConfigured(true);
    }

    if (post === false) {
      toast.error("Could not save the new values");
    } else {
      toast.success("Succefully saved new values");
      let count = objFiles["Grabber"] ? 6 : 5;
      if (step == 1 && miniStep < count) {
        setMiniStep((prev) => +prev + 1);
      } else {
        setStep((prev) => +prev + 1);
      }
    }

    refetchModelconf();
  };
  const handleBack = async (event) => {
    event.preventDefault();

    if (step == 1 && miniStep > 1) {
      setMiniStep((prev) => +prev - 1);
    } else {
      setStep((prev) => +prev - 1);
    }
  };

  useEffect(() => {
    if (objFiles.length == 0) {
      setObjFiles(objData);
    }
    console.log("objFiles", objFiles, objData);
    if (!initialStepSet) {
      if (objFiles.length == 0) {
        setStep(0);
        setInitialStepSet(true);
      }
      if (objData["Hook"]) {
        if (modelconf?.length > 0) {
          if (
            modelconf[0].building_x == 0 &&
            modelconf[0].building_y == 0 &&
            modelconf[0].building_z == 0
          ) {
            setStep(1);
            setMiniStep(1);
          } else if (
            modelconf[0].beem_x == 0 &&
            modelconf[0].beem_y == 0 &&
            modelconf[0].beem_z == 0
          ) {
            setStep(1);
            setMiniStep(2);
          } else if (
            modelconf[0].trolley_x == 0 &&
            modelconf[0].trolley_y == 0 &&
            modelconf[0].trolley_z == 0
          ) {
            setStep(1);
            setMiniStep(3);
          } else if (
            modelconf[0].rope_x == 0 &&
            modelconf[0].rope_y == 0 &&
            modelconf[0].rope_z == 0
          ) {
            setStep(1);
            setMiniStep(4);
          } else if (
            modelconf[0].hook_x == 0 &&
            modelconf[0].hook_y == 0 &&
            modelconf[0].hook_z == 0
          ) {
            setStep(1);
            setMiniStep(5);
          } else {
            //toast.info("Configured?");
          }
          setInitialStepSet(true);
        } else {
          setStep(5);
        }
      }
      if (modelconf?.length > 0 && modelconf[0]?.configured == true) {
        setStep(5);
        setInitialStepSet(true);
        //toast.info("Crane is configured");
      }
    }
  }, [objFiles, objData, modelconf]);

  const renderSlider = (name) => {
    const config = initialConfig.find((cfg) => cfg.name === name);
    //config && console.log(config.name)
    //config && console.log(modelconf[0][config.name.toLowerCase()]);
    let temp = 0;
    return (
      config && (
        <div key={config.name} className=" ">
          <Typography variant="h5">
            {config.label}
            {/* : {values[config.name]} */}
          </Typography>

          <Stack
            direction={"row"}
            display={"flex"}
            alignItems={"center"}
            spacing={2}
          >
            <div className="flex grow w-full">
              <CustomSlider
                value={values[config.name]}
                min={config.min}
                max={config.max}
                step={config.step}
                setValue={(value) => setValue(config.name, value)}
              />
            </div>
            <div className="flex flex-row gap-0">
              {/* <div className="text-xl hover:cursor-pointer bg-red-300" onClick={() => setValue(config.name, values[config.name] - 1)}> <ChevronDoubleLeftIcon className="block h-6 w-6" /> </div> */}
              <div
                className="text-xl hover:cursor-pointer hover:text-indigo-500"
                onClick={() => setValue(config.name, values[config.name] - 0.2)}
              >
                {" "}
                <ChevronLeftIcon className="block h-6 w-6" />{" "}
              </div>
              <div
                className="text-xl hover:cursor-pointer hover:text-indigo-500"
                onClick={() => setValue(config.name, values[config.name] + 0.2)}
              >
                {" "}
                <ChevronRightIcon className="block h-6 w-6" />{" "}
              </div>
              {/* <div className="text-xl hover:cursor-pointer" onClick={() => setValue(config.name, values[config.name] + 1)}> <ChevronDoubleRightIcon className="block h-6 w-6" /> </div> */}
            </div>
          </Stack>
        </div>
      )
    );
  };

  useEffect(() => {
    if (miniStep == 1) {
      setItemName("Building");
      if (
        values.Building_x == 0 &&
        values.Building_y == 0 &&
        values.Building_z == 0 &&
        step == 1
      ) {
        console.log(values);
        setLock(true);
      }
    } else {
      setLock(false);
    }
    if (miniStep == 2) {
      setItemName("Beem");
    }
    if (miniStep == 3) {
      setItemName("Trolley");
    }
    if (miniStep == 4) {
      setItemName("Rope");
    }
    if (miniStep == 5) {
      setItemName("Hook");
    }
    if (miniStep == 6) {
      //setItemName("Grabber");
      toast.info("This functionality is beeing developed // NoSwing Dev Team");
    }
    if (step == 3) {
      setConfigured(false);
      setValue("x", 0);
      setValue("y", 0);
      setValue("z", 0);
      setItemName("Limits");
    }
    if (step == 5) {
      setItemName("Test");
    }
  }, [step, miniStep]);

  const animateAxis = (axis, min, max, duration, interval) => {
    let currentValue = min;
    const step = (max - min) / (duration / interval);

    const intervalId = setInterval(() => {
      currentValue += step;
      if (currentValue >= max) {
        clearInterval(intervalId);
        currentValue = max; // Ensure it ends exactly at max
      }
      setValue(axis, currentValue);
    }, interval);
  };

  const animateCrane = async (event) => {
    event.preventDefault();
    //
    const xmin = parseFloat(document.getElementById("xmin").value);
    const xmax = parseFloat(document.getElementById("xmax").value);
    const ymin = parseFloat(document.getElementById("ymin").value);
    const ymax = parseFloat(document.getElementById("ymax").value);
    const zmin = parseFloat(document.getElementById("zmin").value);
    const zmax = parseFloat(document.getElementById("zmax").value);
    setValue("opcxmin", xmin);
    setValue("opcxmax", xmax);
    setValue("opcymin", ymin);
    setValue("opcymax", ymax);
    setValue("opczmin", zmin);
    setValue("opczmax", zmax);
    setConfigured(true);
    console.log("animateCrane", xmin, xmax, ymin, ymax, zmin, zmax);

    if (xmin !== xmax && ymin !== ymax && zmin !== zmax) {
      // Duration for each axis movement
      const durationPerAxis = 10000 / 3; // 10 seconds divided by 3 axes
      const interval = 100; // Update position every 100 ms
      setValue("x", xmin);
      setValue("y", ymin);
      setValue("z", zmin);
      // Animate each axis
      animateAxis("x", xmin, xmax, durationPerAxis, interval);
      setTimeout(
        () => animateAxis("y", ymin, ymax, durationPerAxis, interval),
        durationPerAxis / 2
      );
      setTimeout(
        () => animateAxis("z", zmin, zmax, durationPerAxis, interval),
        durationPerAxis / 2
      );
    } else {
      toast.error(
        "End-limit values cannot be the same, it needs to be a range."
      );
    }
  };
  const handleDeleteAllDocs = async (event) => {
    event.preventDefault();
    console.log(allDocs);
    for (const file of allDocs) {
      const deleteDoc = await deleteFile(file.id);
      if (deleteDoc == false) {
        toast.error("Could not delete file " + file.fileName);
      }
    }
  };
  // console.log(centerArray);

  const autoCenter = async (event) => {
    event.preventDefault();
    if (itemName == "Trolley") {
      const xdist = centerArray[1][0] - centerArray[0][0];
      const ydist = centerArray[1][1] - centerArray[0][1];
      const zdist = centerArray[1][2] - centerArray[0][2];

      //alert("newz " + zdist);

      setValue("Trolley_x", values.Trolley_x + xdist);
      setValue("Trolley_y", values.Trolley_y - ydist);
      setValue("Trolley_z", values.Trolley_z - zdist);

      console.log(centerArray[1][0], centerArray[0][0]);
    } else if (itemName == "Rope") {
      if (centerArray[3][0] == undefined) {
        toast.error("Error - No rope center data");
      } else {
        const xdist = centerArray[3][0] - centerArray[1][0];
        const ydist = centerArray[3][1] - centerArray[1][1];
        const zdist = centerArray[3][2] - centerArray[1][2];

        //alert("newz " + ydist);

        setValue("Rope_x", values.Rope_x + xdist);
        setValue("Rope_y", values.Rope_y - ydist);
        setValue("Rope_z", values.Rope_z - zdist - 30);

        //console.log(centerArray[1][0], centerArray[0][0]);
      }
    } else if (itemName == "Hook") {
      if (centerArray[3][0] == undefined) {
        toast.error("Error - No hook center data");
        const xdist = centerArray[2][0] - centerArray[1][0];
        const ydist = centerArray[2][1] - centerArray[1][1];
        const zdist = centerArray[2][2] - centerArray[1][2];

        setValue("Hook_x", values.Hook_x + xdist);
        setValue("Hook_y", values.Hook_y - ydist);
      } else {
        const xdist = centerArray[2][0] - centerArray[3][0];
        const ydist = centerArray[2][1] - centerArray[3][1];
        const zdist = centerArray[2][2] - centerArray[3][2];

        //alert("newz " + ydist);

        setValue("Hook_x", values.Hook_x + xdist);
        setValue("Hook_y", values.Hook_y - ydist);
      }
    } else {
      toast.error("Error");
    }
  };
  function downloadJsonAsTextFile(jsonData, fileName) {
    // Convert JSON to string

    delete jsonData.crane;
    delete jsonData.id;
    const jsonString = JSON.stringify(jsonData, null, 2); // Optional: use 2 for formatted output
    
    // Create a Blob with the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });
    
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    
    // Create an anchor element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.txt`; // You can change the extension to .json if you prefer
    document.body.appendChild(a); // Required for Firefox
    a.click();
    
    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    setLatestModelConf(modelconf);
    if(downloadConf){
      console.log("downloadConf", downloadJsonAsTextFile(modelconf[0], "ConfData"));
      setDownloadConf(false);
    }
  }, [modelconf])

  useEffect(() => {
    console.log(objData, files);
  }, [objData, files]);
  
  

  return (
    <div>
      <form>
        <div className="space-y-12">
          {
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-4">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Realtime 3D view of the crane
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  This is where you do the inital configuration of the 3D models
                  to match the real world facility and crane.
                </p>
              </div>

              {
                <div className="grid max-w-2xl h-6/12 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-3">
                  {modelconf?.length == 0 ? (
                    <div className="col-span-full">
                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <form className="flex items-center justify-center md:col-span-4 mt-10 ">
                          <button
                            onClick={configurate3Dmodel}
                            className="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400"
                          >
                            Configure
                          </button>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="col-span-2 mt-12"></div>
                  )}
                </div>
              }
            </div>
          }

          <div className="flex flex-col ">
            {/* <div>
              <HorizontalStepper />
            </div> */}
            {modelconfdataloading ? (
              <CircularProgress />
            ) : (
              modelconf?.length > 0 && (
                <div className="grid grid-cols-1 gap-x-40 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                  <div>
                    {/* <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Setup
                </h2> */}
                    {/* <p className="mt-1 text-sm leading-6 text-gray-600">
                  Build the 3D enviroment from multiple model-files.
                </p> */}

                    <div className="col-span-1 mt-4">
                      <Steps
                        objFiles={objFiles}
                        currentStep={step}
                        setStep={setStep}
                        miniStep={miniStep}
                        setMiniStep={setMiniStep}
                      />
                    </div>
                  </div>

                  <div className="  col-span-2">
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 md:col-span-full mt-0 ">
                      {modelconf?.length == 0 ? (
                        <div className="col-span-full">
                          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <form className="flex items-center justify-center md:col-span-4 mt-10 ">
                              <button
                                onClick={configurate3Dmodel}
                                className="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400"
                              >
                                Configure
                              </button>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <div className="col-span-full  w-full ">
                          <div aria-label="preview" className="col-span-full ">
                            {/* preview */}
                            <h2
                              className="text-base font-semibold leading-7 text-gray-900"
                              onClick={() => setDebug((prev) => !prev)}
                            >
                              Preview
                            </h2>
                            {/* <button onClick={handleDeleteAllDocs}>Delete all docs</button> */}
                            <div className="w-full h-96 relative">
                              {files !== null && objData !== null && <Scene2
                                lock={lock}
                                debug={debug}
                                objFiles={objFiles}
                                twobeems={twobeems}
                                confdata={cranedata?.craneconfiguration[0] || 1}
                                obj={obj}
                                opcdata={cranedata?.opc}
                                lookAt={[22, 2, 20]}
                                products={[]}
                                doesBeem1HaveTwoTrolleys={
                                  doesBeem1HaveTwoTrolleys
                                }
                                doesBeem2HaveTwoTrolleys={
                                  doesBeem2HaveTwoTrolleys
                                }
                                setCenterArray={setCenterArray}
                                files={files}
                                values={values}
                                camera={[50, 50, 10]}
                                className="my-component"
                                x={values?.x || 0}
                                y={values?.y || 0}
                                z={values?.z || 0}
                                view={1}
                                setMarker={() => {}}
                                setNewMarkerPosition={() => {}}
                                markerdata={fullMarkerData}
                                selectedMarker={[]}
                                setSelectedMarker={() => {}}
                                setTempMarker={() => {}}
                                tempMarker={[]}
                                cargoWeight={0}
                                crane={404}
                                configured={configured}
                                cargoProduct={111}
                              />}
                              {step != 0 && <div className='flex absolute top-0 w-full justify-start left-2 mt-2 items-center'>
                              <div className='flex flex-col text-sm'>
                                <div className='flex flex-row gap-2 items-center'>
                                    <MagnifyingGlassPlusIcon className='xldesktop:w-6 xldesktop:h-6 h-4 w-4'/>
                                    <p>Scroll to zoom in or out</p>
                                </div>
                                <div className='flex flex-row gap-2 items-center'>
                                    <ArrowPathIcon className='xldesktop:w-6 xldesktop:h-6 h-4 w-4'/>
                                    <p>Hold left mouse button to rotate</p>
                                </div>
                                <div className='flex flex-row gap-2 items-center'>
                                    <ArrowsPointingOutIcon className='xldesktop:w-6 xldesktop:h-6 h-4 w-4'/>
                                    <p>Hold right mouse button to move</p>
                                </div>
                               
                              </div>
                              </div>}
                            </div>
                          </div>
                          <h2 className="text-base font-semibold leading-7 text-gray-900 mt-4">
                            {/* Step {Number(step) + 1} |{" "}
                      {step > 0 && step < 2 && miniStep} */}
                          </h2>
                          <div className="w-full min-h-96  bg-white rounded-lg p-6 shadow-lg ">
                            {step == 0 && (
                              <div className="flex flex-col grow h-full p-2">
                                <div className="flex flex-col gap-4 justify-between px-10">

                                  {Object.keys(objData).length > 0 && 
                                  <div className="flex flex-col gap-4 font-semibold">
                                  <p>Current 3D models in use</p>
                                  
                                  <div className="flex flex-row gap-2 flex-wrap">
                                  
                                    {Object.entries(objData)?.reverse().map(([key, value]) => {
                                      //console.log(key, value); // Log the key and value to the console
                                      // Display the key name and log value. You might adjust this to fit into your UI rendering logic.
                                      return <div key={key} className="w-28 h-28 border hover:cursor-grab focus:cursor-pointer relative rounded-lg shadow-lg">
                                      <div className="absolute top-0 w-full text-center z-10">
                                      <p className="mt-0 font-bold text-sm rounded-lg cursor-default">{key}</p>
                                      </div>
                                      <ObjViewer type={key} obj={value.clone()} />
                                      </div>;
                                    })}
                                  </div>
                                  {(!editModels && Object.keys(objData).length > 0) && <p className="rounded-lg border px-3 py-2 text-center cursor-pointer" onClick={() => setEditModels(true)}>Edit</p>}
                                  </div>}
                                 { (editModels || Object.keys(objData).length === 0) && <div className="flex flex-col gap-4 mt-10">
                                  <h2 className="text-lg font-semibold">
                                    Do you already have custom 3D models to use?
                                  </h2>
                                  <div className="flex flex-row gap-5">
                                    <button
                                      onClick={(event) => {
                                        setDoesUserHave3DFiles(false);
                                        event.preventDefault();
                                      }}
                                      className={classNames(
                                        doesUserHave3DFiles ||
                                          doesUserHave3DFiles === null
                                          ? "bg-indigo-600 hover:bg-indigo-500"
                                          : "bg-indigo-300",
                                        "rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      )}
                                    >
                                      No
                                    </button>
                                    <Upload3D open={isUpload3DOpen} setOpen={setIsUpload3DOpen} setStep={setStep} refetchModels={refetchAllData} id={id} modelconf={modelconf} refetchModelconf={refetchModelconf} />
                                    <RequestFilesDialog open={openRequestFiles} setOpen={setOpenRequestFiles} />
                                    <button
                                      onClick={(event) => {
                                        setDoesUserHave3DFiles(true);
                                        event.preventDefault();
                                      }}
                                      className={classNames(
                                        doesUserHave3DFiles ||
                                          !doesUserHave3DFiles === null
                                          ? "bg-indigo-300"
                                          : "bg-indigo-600 hover:bg-indigo-500",
                                        "rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      )}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                  {doesUserHave3DFiles != null && (
                                    <div className="flex flex-col gap-5 mt-5 bg-grey-100 rounded-lg ">
                                      {doesUserHave3DFiles === false && (
                                        <div
                                          onClick={() =>
                                            setOpenRequestFiles(true)
                                          }
                                          className="w-96 rounded-lg border flex place-items-center p-4  hover:border-indigo-500 hover:cursor-pointer hover:shadow-lg"
                                        >
                                          <p className="text-lg">
                                            Order custom 3D models from
                                            NoSwing
                                          </p>
                                        </div>
                                      )}

                                      {doesUserHave3DFiles === false && (
                                        <div
                                          onClick={() => setIsLibraryOpen(true)}
                                          className="w-96 rounded-lg border flex place-items-center p-4  hover:border-indigo-500 hover:cursor-pointer hover:shadow-lg"
                                        >
                                          <p className="text-lg">
                                            Choose from a library of common
                                            crane-types
                                          </p>
                                          <Library
                                            id={id}
                                            open={isLibraryOpen}
                                            setOpen={setIsLibraryOpen}
                                            obj={obj}
                                            setStep={setStep}
                                            setObjFiles={setObjFiles}
                                            load={true}
                                            setFilePaths={setFilePaths}
                                            modelconf={modelconf}
                                            reloadCraneFiles={refetchAllData}
                                          />
                                        </div>
                                      )}

                                      {doesUserHave3DFiles === true && (
                                        <div
                                          onClick={() =>
                                            setIsUpload3DOpen(true)
                                          }
                                          className="w-96 rounded-lg border-2 flex place-items-center p-4 hover:border-indigo-500 hover:cursor-pointer hover:shadow-lg "
                                        >
                                          <p className="text-lg">
                                            I have 3D files I want to upload and
                                            use
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  </div>}
                                </div>
                              </div>
                            )}
                            {step == 1 && (
                              <div className="flex flex-col grow h-full">
                                <div className="flex flex-col gap-4 justify-between p-2">
                                  <h2 className="text-xl font-semibold px-10">
                                    Position the {itemName}
                                  </h2>
                                  <h3 className="text-lg mx-10 text-gray-600 bg-slate-100 rounded-lg p-3">
                                    {ministeps[miniStep - 1].instruction}
                                  </h3>
                                  <div className="flex flex-col  px-10 mt-8 gap-3 ">
                                    {debug && (
                                      <div className="flex flex-col ">
                                        <span>
                                          {centerArray[2][0]} -{" "}
                                          {centerArray[3][0]}
                                        </span>
                                        <span>
                                          {centerArray[2][1]} -{" "}
                                          {centerArray[3][1]}
                                        </span>
                                        <span>
                                          {centerArray[2][2]} -{" "}
                                          {centerArray[3][2]}
                                        </span>{" "}
                                      </div>
                                    )}
                                    {renderSlider(`${itemName}_x`)}
                                    {renderSlider(`${itemName}_y`)}
                                    {renderSlider(`${itemName}_z`)}
                                    {itemName == "Rope" &&
                                      renderSlider(`Rope_length`)}
                                    <div className="flex flex-row gap-5 ">
                                      {(itemName === "Trolley" ||
                                        itemName === "Hook" ||
                                        itemName === "Rope") &&
                                        !(
                                          itemName == "Rope" &&
                                          centerArray[3][0] == undefined
                                        ) && (
                                          <button
                                            className="rounded-md w-32 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={(event) =>
                                              autoCenter(event)
                                            }
                                          >
                                            Auto center
                                          </button>
                                        )}
                                      {/* {values !== prevValues && <button  className="rounded-md w-32 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={(event) => {event.preventDefault(); setValues(prevValues)}}>Restore</button>} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex grow justify-end align-baseline  h-full p-6">
                                  <div className="mt-6 flex items-center justify-end gap-x-6 h-10">
                                    <button
                                      type="button"
                                      onClick={handleBack}
                                      className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      Back
                                    </button>
                                    <button
                                      onClick={handleSave}
                                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {step == 2 && (
                              <div className="flex flex-col grow h-full">
                                <div className="flex flex-col gap-4 justify-between ">
                                  <div className="">
                                    <div className="flex flex-col gap-4 justify-between p-2">
                                      <h2 className="text-xl font-semibold px-10">
                                        Make changes to the structure
                                      </h2>
                                      <h3 className="text-lg mx-10 text-gray-600 bg-slate-100 rounded-lg p-3">
                                        If your crane has two beems or multiple
                                        trolleys or a grabber that uses two
                                        hooks, this is where you configure that.
                                      </h3>
                                    </div>
                                    <div className="flex flex-col w-full gap-4 px-12 mt-5">
                                      <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                          <input
                                            id="twobeems"
                                            aria-describedby="twobeems-description"
                                            name="twobeems"
                                            type="checkbox"
                                            defaultChecked={twobeems}
                                            onChange={(event) =>
                                              setTwobeems(event.target.checked)
                                            }
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm leading-6">
                                          <label
                                            htmlFor="twobeems"
                                            className="font-medium text-gray-900"
                                          >
                                            Add a second Beem
                                          </label>
                                        </div>
                                      </div>
                                      <div>
                                        {twobeems &&
                                          renderSlider(`Beem_spacing`)}
                                      </div>
                                      <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                          <input
                                            id="twobeems"
                                            aria-describedby="twobeems-description"
                                            name="twobeems"
                                            type="checkbox"
                                            defaultChecked={
                                              doesBeem1HaveTwoTrolleys
                                            }
                                            onChange={(event) =>
                                              setDoesBeem1HaveTwoTrolleys(
                                                event.target.checked
                                              )
                                            }
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm leading-6">
                                          <label
                                            htmlFor="twobeems"
                                            className="font-medium text-gray-900"
                                          >
                                            Add a second trolley on beem 1
                                          </label>
                                        </div>
                                      </div>
                                      {twobeems && (
                                        <div className="relative flex items-start">
                                          <div className="flex h-6 items-center">
                                            <input
                                              id="twobeems"
                                              aria-describedby="twobeems-description"
                                              name="twobeems"
                                              type="checkbox"
                                              defaultChecked={
                                                doesBeem2HaveTwoTrolleys
                                              }
                                              onChange={(event) =>
                                                setDoesBeem2HaveTwoTrolleys(
                                                  event.target.checked
                                                )
                                              }
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                          </div>
                                          <div className="ml-3 text-sm leading-6">
                                            <label
                                              htmlFor="twobeems"
                                              className="font-medium text-gray-900"
                                            >
                                              Add a second trolley on beem 2
                                            </label>
                                          </div>
                                        </div>
                                      )}

                                      <div>
                                        {(doesBeem1HaveTwoTrolleys ||
                                          doesBeem2HaveTwoTrolleys) &&
                                          renderSlider(`Trolley_spacing`)}
                                      </div>
                                      <span className="text-sm text-gray-500">
                                        (Option for special Grabber conf coming
                                        soon)
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex grow justify-end align-baseline  h-full p-6">
                                  <div className="mt-6 flex items-center justify-end gap-x-6 h-10">
                                    <button
                                      onClick={handleBack}
                                      className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      Back
                                    </button>
                                    <button
                                      onClick={handleSave}
                                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {step == 3 && (
                              <div className="flex flex-col grow h-full">
                                <div className="flex flex-col gap-4 justify-between p-2">
                                  <h2 className="text-xl font-semibold px-10">
                                    Set the physical limits of the crane
                                  </h2>
                                  <h3 className="text-lg mx-10 text-gray-600 bg-slate-100 rounded-lg p-3">
                                    Move the crane to the known physical end
                                    limits and set the values, for each axis.
                                  </h3>
                                </div>
                                <div className="flex flex-row gap-4 justify-between p-1">
                                  <Stack
                                    spacing={2}
                                    display={
                                      itemName == "Rope" ? "none" : "flex"
                                    }
                                  >
                                    <Box width={600} mt={4}>
                                      <div
                                        className="flex flex-row gap-6 items-end "
                                        
                                      >
                                        <Box width={"300px"}>
                                          {renderSlider("x")}
                                        </Box>
                                        <Box display={"flex"} gap={2}>
                                          <p
                                            className="border px-2 py-1 rounded-lg cursor-pointer"
                                            
                                            onClick={() =>
                                              setValue("Cranexposmin", values.x)
                                            }
                                          >
                                            Set min limit
                                          </p>
                                          {/* {values.Cranexposmin} */}
                                          <p
                                            className="border px-2 py-1 rounded-lg cursor-pointer"
                                            
                                            onClick={() =>
                                              setValue("Cranexposmax", values.x)
                                            }
                                          >
                                            Set max limit
                                          </p>
                                          {/* {values.Cranexposmax} */}
                                        </Box>
                                      </div>

                                      <div
                                        className="flex flex-row gap-6 items-end "
                                      >
                                        <Box width={"300px"}>
                                          {renderSlider("y")}
                                        </Box>
                                        <Box display={"flex"} gap={2}>
                                          <p
                                            className="border px-2 py-1 rounded-lg cursor-pointer"
                                            
                                            onClick={() =>
                                              setValue("Craneyposmin", values.y)
                                            }
                                          >
                                            Set min limit
                                          </p>
                                          {/* {values.Craneyposmin} */}
                                          <p
                                            className="border px-2 py-1 rounded-lg cursor-pointer"
                                            
                                            onClick={() =>
                                              setValue("Craneyposmax", values.y)
                                            }
                                          >
                                            Set max limit
                                          </p>
                                          {/* {values.Craneyposmax} */}
                                        </Box>
                                      </div>

                                      <div
                                        className="flex flex-row gap-6 items-end "
                                      >
                                        <Box width={"300px"}>
                                          {renderSlider("z")}
                                        </Box>
                                        <Box display={"flex"} gap={2}>
                                          <p
                                            className="border px-2 py-1 rounded-lg cursor-pointer"
                                            
                                            onClick={() =>
                                              setValue("Cranezposmin", values.z)
                                            }
                                          >
                                            Set min limit
                                          </p>
                                          {/* {values.Cranezposmin} */}
                                          <p
                                            className="border px-2 py-1 rounded-lg cursor-pointer"
                                            
                                            onClick={() =>
                                              setValue("Cranezposmax", values.z)
                                            }
                                          >
                                            Set max limit
                                          </p>
                                          {/* {values.Cranezposmax} */}
                                        </Box>
                                      </div>
                                    </Box>
                                  </Stack>
                                </div>
                                <div className="flex grow justify-end align-baseline  h-full p-6">
                                  <div className="mt-6 flex items-center justify-end gap-x-6 h-10">
                                    <button
                                      onClick={handleBack}
                                      className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      Back
                                    </button>
                                    <button
                                      onClick={handleSave}
                                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {step == 4 && (
                              <div className="flex flex-col grow h-full">
                                <div className="flex flex-col gap-4 justify-between ">
                                  <div className="">
                                    <div className="flex flex-col gap-4 justify-between p-2">
                                      <h2 className="text-xl font-semibold px-10">
                                        Configure the offset
                                      </h2>
                                      <h3 className="text-lg mx-10 text-gray-600 bg-slate-100 rounded-lg p-3">
                                        Move the crane so that it is centered on
                                        the dot.
                                      </h3>
                                    </div>

                                    <div className="flex justify-center mt-5 text-lg">
                                      *Under developement, just skip this step
                                      for now*
                                    </div>
                                    {/* <div className="flex flex-col">
                                      {cranedata?.opc.xposlimitmin}
                                      {cranedata?.opc.xposlimitmax}
                                      {cranedata?.opc.y1poslimitmin}
                                      {cranedata?.opc.y1poslimitmax}
                                      {cranedata?.opc.z1poslimitmin}
                                      {cranedata?.opc.z1poslimitmax}
                                     </div> */}
                                  </div>
                                </div>
                                <div className="flex grow justify-end align-baseline  h-full p-6">
                                  <div className="mt-6 flex items-center justify-end gap-x-6 h-10">
                                    <button
                                      onClick={handleBack}
                                      className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      Back
                                    </button>
                                    <button
                                      onClick={handleSave}
                                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {step == 5 && (
                              <div className="flex flex-col grow h-full">
                                <div className="flex flex-col gap-4 justify-between p-1 grow">
                                  <form onSubmit={handleSave}>
                                    <div className="">
                                      <div className="flex flex-col gap-4 justify-between p-2">
                                        <h2 className="text-xl font-semibold px-10">
                                          PLC end limit values
                                        </h2>
                                        <h3 className="text-lg mx-10 text-gray-600 bg-slate-100 rounded-lg p-3">
                                          In the previous step you set the 3D
                                          enviroments end limits. Now enter the
                                          real life end limit data from the PLC.
                                          If OPC is configured correctly, the
                                          values will be pre-filled. Otherwise
                                          enter values for testing.
                                        </h3>

                                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mx-10 mt-5">
                                          <div className="sm:col-span-2">
                                            {
                                              <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                                    x_min:
                                                  </span>
                                                  <input
                                                    type="text"
                                                    name="xmin"
                                                    id="xmin"
                                                    defaultValue={
                                                      cranedata?.opc
                                                        ?.xposlimitmin || 100
                                                    }
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="10000"
                                                  />
                                                </div>
                                              </div>
                                            }
                                            {
                                              <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                                    x_max:
                                                  </span>
                                                  <input
                                                    type="number"
                                                    name="xmax"
                                                    id="xmax"
                                                    defaultValue={
                                                      cranedata?.opc
                                                        ?.xposlimitmax || 10000
                                                    }
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="10000"
                                                  />
                                                </div>
                                              </div>
                                            }
                                          </div>
                                          <div className="sm:col-span-2">
                                            {
                                              <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                                    y_min:
                                                  </span>
                                                  <input
                                                    type="number"
                                                    name="ymin"
                                                    id="ymin"
                                                    defaultValue={
                                                      cranedata?.opc
                                                        ?.y1poslimitmin || 100
                                                    }
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="10000"
                                                  />
                                                </div>
                                              </div>
                                            }
                                            {
                                              <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                                    y_max:
                                                  </span>
                                                  <input
                                                    type="number"
                                                    name="ymax"
                                                    id="ymax"
                                                    defaultValue={
                                                      cranedata?.opc
                                                        ?.y1poslimitmax || 10000
                                                    }
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="10000"
                                                  />
                                                </div>
                                              </div>
                                            }
                                          </div>
                                          <div className="sm:col-span-2">
                                            {
                                              <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                                    z_min:
                                                  </span>
                                                  <input
                                                    type="number"
                                                    name="zmin"
                                                    id="zmin"
                                                    defaultValue={
                                                      cranedata?.opc
                                                        ?.z1poslimitmin || 100
                                                    }
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="10000"
                                                  />
                                                </div>
                                              </div>
                                            }
                                            {
                                              <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                                                    z_max:
                                                  </span>
                                                  <input
                                                    type="number"
                                                    name="zmax"
                                                    id="zmax"
                                                    defaultValue={
                                                      cranedata?.opc
                                                        ?.z1poslimitmax || 10000
                                                    }
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="10000"
                                                  />
                                                </div>
                                              </div>
                                            }
                                          </div>
                                          <div className="mt-0 sm:col-span-full flex flex-row gap-6 ">
                                            <div className="flex flex-col">
                                              <div className="flex flex-col">
                                                <span className="flex justify-center w-full">
                                                  {values.x}
                                                </span>
                                                <div className="flex flex-row justify-center gap-3 items-center">
                                                  {values.opcxmin}
                                                  <CustomSlider
                                                    value={values.x}
                                                    min={values.opcxmin || 0}
                                                    max={
                                                      values.opcxmax || 10000
                                                    }
                                                    step={1}
                                                    setValue={(value) =>
                                                      setValue("x", value)
                                                    }
                                                  />
                                                  {values.opcxmax}
                                                </div>
                                              </div>
                                              <div className="flex flex-col">
                                                <span className="flex justify-center w-full">
                                                  {values.y}
                                                </span>
                                                <div className="flex flex-row justify-center gap-3 items-center">
                                                  {values.opcymin}
                                                  <CustomSlider
                                                    value={values.y}
                                                    min={values.opcymin || 0}
                                                    max={
                                                      values.opcymax || 10000
                                                    }
                                                    step={1}
                                                    setValue={(value) =>
                                                      setValue("y", value)
                                                    }
                                                  />
                                                  {values.opcymax}
                                                </div>
                                              </div>
                                              <div className="flex flex-col">
                                                <span className="flex justify-center w-full">
                                                  {values.z}
                                                </span>
                                                <div className="flex flex-row justify-center gap-3 items-center">
                                                  {values.opczmin}
                                                  <CustomSlider
                                                    value={values.z}
                                                    min={values.opczmin || 0}
                                                    max={values.opczmax || 200}
                                                    step={1}
                                                    setValue={(value) =>
                                                      setValue("z", value)
                                                    }
                                                  />
                                                  {values.opczmax}
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              onClick={animateCrane}
                                              className="my-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                              Simulate movement
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="flex grow justify-end align-baseline  h-full p-6">
                                  <div className="mt-6 flex items-center justify-end gap-x-6 h-10">
                                    <button
                                      onClick={handleBack}
                                      className="text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      Back
                                    </button>
                                    <button
                                      onClick={handleSave}
                                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Finish
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {step == 6 && (
                              <div className="flex flex-col grow h-full">
                                <div className="flex flex-col gap-4 justify-between p-1 grow">
                                  <form>
                                    <div className="">
                                      <div className="grid grid-cols-1 gap-x-8 gap-y-2  md:grid-cols-3">
                                        <div className="col-span-full flex flex-col">
                                          <h2 className="text-base font-semibold leading-7 col-span-full text-gray-900">
                                            Congrats! You just built a Crane!
                                          </h2>
                                          <div className="mt-6 flex items-center justify-center gap-x-6 h-10">
                                            <button
                                              onClick={(event) => {
                                                toast.info(
                                                  "This functionality is beeing developed // NoSwing Dev Team"
                                                );
                                                event.preventDefault();
                                              }}
                                              className="text-sm font-semibold leading-6 text-gray-900"
                                            >
                                              Clear all values and start from
                                              scratch
                                            </button>
                                            <button
                                              onClick={() => {
                                                navigate(`/cranes/${id}`);
                                                toast.info(
                                                  "Cannot yet display cranes with multiple beems or trolleys // NoSwing Dev Team"
                                                );
                                              }}
                                              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                              Check it out in the crane overview
                                            </button>
                                          </div>
                                          {debug && <div onClick={() => {refetchModelconf(); setDownloadConf(true);}} className="cursor-pointer mt-10 underline">Export ConfData</div>}
                                        </div>

                                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2"></div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="flex grow justify-end align-baseline  h-full p-6"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {/* <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default Realtime3D;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Steps({ setStep, currentStep, miniStep, setMiniStep, objFiles }) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              "relative"
            )}
          >
            {step.href < currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-nsblue-400"
                    aria-hidden="true"
                  />
                ) : null}
                <a
                  onClick={() => setStep(step.href)}
                  className="group relative flex items-start cursor-pointer"
                >
                  <span className="flex h-9 items-center">
                    <span className="relative  flex h-8 w-8 items-center justify-center rounded-full bg-nsblue-400 group-hover:bg-nsblue-800">
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </a>
              </>
            ) : step.href == currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a
                  className="group relative flex items-start cursor-pointer"
                  aria-current="step"
                  onClick={() => setStep(step.href)}
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative  flex h-8 w-8 items-center justify-center rounded-full border-2 border-nsblue-400 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-nsblue-400" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-nsblue-400">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                    {step.href == "1" && (
                      <div>
                        <MiniSteps
                          currentStep={miniStep}
                          setMiniStep={setMiniStep}
                          objFiles={objFiles}
                        />
                      </div>
                    )}
                  </span>
                </a>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a
                  onClick={() => setStep(step.href)}
                  className="group relative flex items-start cursor-pointer"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

function MiniSteps({ setMiniStep, currentStep, objFiles }) {
  let stepdata = ministeps;
  if (!objFiles["Grabber.obj"]) {
    stepdata = stepdata.filter((step) => step.name != "Grabber");
  }
  return (
    <div className="px-5 py-7 sm:px-5 lg:px-5 ">
      <nav className="flex justify-start" aria-label="Progress">
        <ol role="list" className="space-y-3">
          {stepdata.map((step) => (
            <li key={step.name}>
              {step.href < currentStep ? (
                <a onClick={() => setMiniStep(step.href)} className="group">
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-nsblue-400 group-hover:text-nsblue-800"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </a>
              ) : step.href == currentStep ? (
                <a
                  onClick={() => setMiniStep(step.href)}
                  className="flex items-start"
                  aria-current="step"
                >
                  <span
                    className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                    aria-hidden="true"
                  >
                    <span className="absolute h-4 w-4 rounded-full bg-nsblue-200" />
                    <span className="relative block h-2 w-2 rounded-full bg-nsblue-400" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-nsblue-400">
                    {step.name}
                  </span>
                </a>
              ) : (
                <a onClick={() => setMiniStep(step.href)} className="group">
                  <div className="flex items-start">
                    <div
                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                      aria-hidden="true"
                    >
                      <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900 ">
                      {step.name}
                    </p>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}


const ObjViewer = ({ obj, type }) => {
  const [target, setTarget] = useState(null);
  const orbitControlsRef = useRef();
  const [theType, setTheType] = useState("");
  // Function to reset OrbitControls
  const resetOrbitControls = () => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.reset();
    }
  };

  React.useEffect(() => {
    if (obj) {
      // console.log("Object loaded:");
      obj.rotation.set(0, 0, 0);
      obj.position.set(0, 0, 0);
      obj.scale.set(1, 1, 1);
      if (type == "Building") {
        // console.log("Building");
        // Apply rotation directly to the object
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, -400, 0);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z - 200]);
      } else if (type == "Beem") {
        // Apply rotation directly to the object
        // console.log("Beem");
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, 0, 0);
        obj.scale.set(0.1, 0.1, 0.1);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z]);
      } else {
        // console.log("Other");
        // Apply rotation directly to the object
        obj.rotation.set(Math.PI / 2, Math.PI, 0);
        obj.position.set(0, 0, 0);
        obj.scale.set(3, 3, 3);
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(obj);
        const center = boundingBox.getCenter(new THREE.Vector3());
        setTarget([center.x, center.y, center.z]);
      }
      if(type != theType){
        resetOrbitControls();
        setTheType(type);
      }
      
    }
    return () => {
     // setTarget(null);
    };
  }, [obj]);

  return (
    <Canvas className="w-full h-full rounded-lg">
      <ambientLight intensity={0.3} />
      <directionalLight position={[0, 1, 0]} intensity={1} />
      <OrbitControls
        ref={orbitControlsRef}
        target={target}
        autoRotate={true}
        autoRotateSpeed={0.9}
      />
      {/* <axesHelper args={[5]} /> */}
      <group>
        {target !== null && obj && (
          <mesh position={[0, 0, 0]}>
            <primitive object={obj} />
          </mesh>
        )}
      </group>
    </Canvas>
  );
}
