import React from "react";
import useFetchV2 from "../../../../components/PartsApi";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { deleteContainerAtId, deleteMarker, deleteOrder, getAllContainersAtLocation, getOrdersFromLocationId, getOrdersToDelete } from "../../../../components/OrderApi";
import { toast } from "react-toastify";

const Locations = ({ id }) => {
  const {
    data: cranedata,
    loading: craneloading,
    error: craneerror,
    refetch: refetchCraneData,
  } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${id}`);
  const {
    data: markerData,
    loading: markerDataLoading,
    error: markerDataError,
    refetch: refetchAllMarkers,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${id}&page=0&size=100`
  );
  //console.log(markerData);
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [containers, setContainers] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState(null);

  // function DeleteConfirmation(locationToDelete) {
  //   const getData = async () => {
  //     let orders = await getOrdersFromLocationId(id, locationToDelete);
  //     const containers = await getAllContainersAtLocation(locationToDelete);
  //     setOrders(orders);
  //     setContainers(containers);
  //   }
  //   React.useEffect(() => {
  //     getData();
      
      
  //   }, [])
    
  
  //   return (
  //     <Transition.Root show={open} as={Fragment}>
  //       <Dialog as="div" className="relative z-10" onClose={setOpen}>
  //         <Transition.Child
  //           as={Fragment}
  //           enter="ease-out duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="ease-in duration-200"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //         >
  //           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  //         </Transition.Child>
  
  //         <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
  //           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
  //             <Transition.Child
  //               as={Fragment}
  //               enter="ease-out duration-300"
  //               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //               enterTo="opacity-100 translate-y-0 sm:scale-100"
  //               leave="ease-in duration-200"
  //               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
  //               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //             >
  //               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
  //                 <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
  //                   <button
  //                     type="button"
  //                     className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  //                     onClick={() => setOpen(false)}
  //                   >
  //                     <span className="sr-only">Close</span>
  //                     <XMarkIcon className="h-6 w-6" aria-hidden="true" />
  //                   </button>
  //                 </div>
  //                 <div className="sm:flex sm:items-start">
  //                   <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
  //                     <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
  //                   </div>
  //                   <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
  //                     <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
  //                       Deactivate account {orders?.length} {containers?.length}
  //                     </Dialog.Title>
  //                     <div className="mt-2">
  //                       <p className="text-sm text-gray-500">
  //                         Are you sure you want to deactivate your account? All of your data will be permanently removed
  //                         from our servers forever. This action cannot be undone.
  //                       </p>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
  //                   <button
  //                     type="button"
  //                     className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
  //                     onClick={() => setOpen(false)}
  //                   >
  //                     Deactivate
  //                   </button>
  //                   <button
  //                     type="button"
  //                     className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
  //                     onClick={() => setOpen(false)}
  //                   >
  //                     Cancel
  //                   </button>
  //                 </div>
  //               </Dialog.Panel>
  //             </Transition.Child>
  //           </div>
  //         </div>
  //       </Dialog>
  //     </Transition.Root>
  //   )
  // }

  const handleDeleteMarker = async (locationId) => {
    let orders = await getOrdersFromLocationId(id, locationId);
    const containers = await getAllContainersAtLocation(locationId);
    
    console.log(containers);
    console.log(orders);
    if(orders?.length > 0 || containers?.length > 0){
      toast.info("Deleting orders and containers before deleting marker, pls wait...");
      let length = containers?.length ;
      let ordersToDelete = [];
      for (const container of containers){
        const containerOrders = await getOrdersToDelete(id, container.id);
        console.log(containerOrders);
        ordersToDelete = [...ordersToDelete, ...containerOrders];
        
      }
      
      console.log(ordersToDelete);
      orders = [...orders, ...ordersToDelete];
      length = orders?.length ;
      for (const order of orders){
        const deleted = await deleteOrder(order.id);
        if(deleted){
          //toast.success("Order deleted");
          length--;
          console.log(length);
        }
        else{
          //toast.error("Could not delete order");
        }
        
      }
      for (const container of containers){
       
        const deleted = await deleteContainerAtId(container.id);
        console.log(container.id);
        if(deleted){
          //toast.success("Order deleted");
          length--;
          console.log(length);
        }
        else{
          toast.error("Could not delete order, pls try again");
        }
        
      }
      const deleted = await deleteMarker(locationId); //id of selected marker
      console.log(deleted);
      if(deleted){
        refetchAllMarkers();
        toast.success("Marker deleted");
      }
      else{
        toast.error("Could not delete marker. Possible because it has have been used for orders.");
        
      }
    }
    else{
      const deleted = await deleteMarker(locationId); //id of selected marker
      console.log(deleted);
      if(deleted){
        refetchAllMarkers();
        toast.success("Marker deleted");
      }
      else{
        toast.error("Could not delete marker. Possible because it has have been used for orders.");
        
      }
    }
  };
  const Map = ({ locations }) => {
    // Define the size of the map
    const width = 700;
    const height = 350;
  
    // Define a margin
    const margin = 50; // Adjust this value as needed
  
    // Adjusted dimensions considering margin
    const adjustedWidth = width - 2 * margin;
    const adjustedHeight = height - 2 * margin;
  
    // Find the minimum and maximum values for x and y
    const minX = Math.min(...locations.map(loc => loc.x));
    const minY = Math.min(...locations.map(loc => loc.y));
    const maxX = Math.max(...locations.map(loc => loc.x));
    const maxY = Math.max(...locations.map(loc => loc.y));
  
    // Compute scale factors
    const xScale = adjustedWidth / (maxX - minX);
    const yScale = adjustedHeight / (maxX - minX);
  
    // Function to normalize, scale, and add margin to a location
    const scaleLocation = (location) => ({
      x: margin + (location.x - minX) * xScale,
      y: margin + (location.y - minY) * yScale
    });
  
    return (
      <svg width={width} height={height} style={{ border: '1px solid black' , backgroundColor: "white" }}>
        {locations.map((location, index) => {
          const scaledLocation = scaleLocation(location);
          return (
            <g key={index} onClick={() => toast.info(`${location.locationcode}`)} className="hover:cursor-pointer">
              <circle
                cx={scaledLocation.x}
                cy={scaledLocation.y}
                r="5"
                
                fill="red"
                
              />
              <text
                x={scaledLocation.x + 10}
                y={scaledLocation.y + 5}
                style={{ fontSize: '10px', fill: 'black' }}
              >
                {location.locationcode}
              </text>
            </g>
          );
        })}
      </svg>
    );
  };
 
  
  

  return (
    <div className="flex justify-center ">
    {/* <DeleteConfirmation /> */}
      {markerData?.length > 0 ? <div className="flex flex-row  gap-10">
      <div className="w-96 flex flex-col">
        <div className="flex flex-row gap-5 p-4">
          <span className="w-52 font-bold">Code</span>
          <span className="w-32 font-bold">x</span>
          <span className="w-32 font-bold">y</span>
          <span className="w-32 font-bold">z</span>
          <span className="w-32 font-bold"></span>
        </div>
        {markerData?.map((marker, index) => (
          <div
            className={`group flex flex-row gap-5 p-4 ${
              index % 2 === 0 ? "bg-white" : "bg-gray-200"
            }`}
            key={marker?.id}
          >
            <span className="w-52 font-semibold">{marker?.locationcode}</span>
            <span className="w-32">{marker?.x}</span>
            <span className="w-32">{marker?.y}</span>
            <span className="w-32">{marker?.z}</span>
            <button className="w-32 text-red-500 invisible group-hover:visible" onClick={() => handleDeleteMarker(marker.id)}>Delete</button>
          </div>
        ))}
      </div>
      <div className=" flex flex-col">
        <span className="font-bold p-4">Map</span>
        {markerData?.length > 0 && <Map locations={markerData}/>}
        </div>
    </div> : <div className="flex flex-col items-center justify-center"> <span className="font-bold text-2xl">No locations found</span></div>}
    </div>
  );
};

export default Locations;
