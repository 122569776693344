import React from "react";
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

async function getCraneData() {
  try {
    const response = await fetch(
      `https://noswingcc.net/noswingcc/api/cranes/4`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "*/*",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json(); // This parses the JSON body of the response
    return data;
  } catch (error) {
    console.error("An error occurred while trying to get data:", error);
    return null;
  }
}
const navigation = [
  {
    name: "Getting started",
    href: "#",
    current: true,
    children: [{ name: "Get cranedata", href: "#" }],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ApiDemo = () => {
  const [craneData, setCraneData] = React.useState(null);

  const getData = async () => {
    const data = await getCraneData();
    setCraneData(data);
  };

  return (
    <div className="flex flex-row w-full">
      <div className="w-3/12 pl-5 pt-5 border-r">
        <nav className="flex flex-1 flex-col ">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className=" space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {!item.children ? (
                      <a
                        href={item.href}
                        className={classNames(
                          item.current ? "bg-gray-50" : "hover:bg-gray-50",
                          "group flex gap-x-3 p-2 text-sm leading-6 font-semibold text-gray-700"
                        )}
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Disclosure as="div">
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "bg-gray-50"
                                  : "hover:bg-gray-50",
                                "flex items-center w-full text-left p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700"
                              )}
                            >
                              {item.name}
                              <ChevronRightIcon
                                className={classNames(
                                  open
                                    ? "rotate-90 text-gray-500"
                                    : "text-gray-400",
                                  "ml-auto h-5 w-5 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                              {item.children.map((subItem) => (
                                <li key={subItem.name}>
                                  {/* 44px */}
                                  <Disclosure.Button
                                    as="a"
                                    href={subItem.href}
                                    className={classNames(
                                      subItem.current
                                        ? "bg-gray-50"
                                        : "hover:bg-gray-50",
                                      "block py-2 pr-2 pl-9 text-sm leading-6 text-gray-700"
                                    )}
                                  >
                                    {subItem.name}
                                  </Disclosure.Button>
                                </li>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div className="w-7/12 p-10 ">
        <div className="mb-10">
          <p className="text-3xl">Get cranedata</p>
          {/* <p>{localStorage.getItem("token")}</p> */}
          <p className="mt-3">:Api address for the Vaasa Crane:  https://noswingcc.net/noswingcc/api/cranes/4.
          We have provided a Javascript function example below that you can use to fetch the data.
          </p>
        </div>
        <div className="flex flex-col gap-10">
          <div>
            <p className="font-bold">Javascript function</p>
            <CopyBlock
              text={codeString}
              language={"javascript"}
              showLineNumbers={true}
              theme={dracula}
              codeBlock
            />
          </div>
          {/* <div>
            <p className="font-bold">BEARER_TOKEN:</p>
            <div className="rounded-lg">
              <CopyBlock
                text={`Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJub3N3aW5nIiwiYXV0aCI6IlJPTEVfQURNSU4sUk9MR
  V9MSVNUX1VTRVJTLFJPTEVfTUFOQUdFX0NDLFJPTEVfTUFOQUdFX0NSQU5FUyxST0xFX01BTkFHRV9VU0VSUyxST0
  xFX01BTkFHRV9XTVMsUk9MRV9VU0VSIiwiZXhwIjoxNzQzODQ3Nzc2fQ.yqshlnihY-mLG5-BvWT9UMbQpt7DjlnYc
  DYV8d8vSVacVLTXF_f7tegPU5OXdWAbf-zhx5SyccaleKH7iKyZZQ`}
                language={"text"}
                showLineNumbers={false}
                theme={dracula}
              />
            </div>
          </div> */}
          <div>
            <p className="font-bold">Example response:</p>
            <CodeBlock
              text={codeResponse}
              language={"json"}
              showLineNumbers={false}
              theme={dracula}
            />
          </div>
          <div id="test" className="flex flex-col gap-2 items-start">
          <p className="font-bold">Test</p>
          <button onClick={getData} className="px-3 py-1 rounded-lg bg-nsorange-500 text-white ">Get Data</button>
          <div className="p-5 text-white bg-gray-900 rounded-lg h-[1000px] overflow-y-auto w-full">
            <pre>{JSON.stringify(craneData, null, 2)}</pre>
          </div>
          </div>
        </div>
      </div>
      <div className="w-2/12 border-l p-5">
        <p>On this page</p>
        <div className="p-2">
          <ul className="flex flex-col gap-2 text-sm">
            <li>
              <a href="#getting-started">Getting started</a>
            </li>
            <li>
              <a href="#get-cranedata">Authentication</a>
            </li>
            <li>
              <a href="#get-cranedata">Get cranedata</a>
            </li>
            <li>
              <a href="#get-cranedata">Example response</a>
            </li>
            <li>
              <a href="#test">Test</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ApiDemo;

const codeResponse = `
{
  "id": 4,
  "craneconfiguration": "0000",
  "cranestop": true,
  "cranestatus": "0000",
  "x": 4561,
  "y": 2908,
  "z": 1,
  "country": "Finland",
  "city": "Vaasa",
  "name": "Overhead Crane",
  "location": "Technobotnia",
  "timestamp": "2023-08-10T14:50:00Z",
  "opcIP": "localhost:4841",
  "wms_configured": null,
  "building": null,
  "opc": { ... },
}`.trim();

const codeString = `
async function getCraneData() {
  try {
    const response = await fetch(\`https://noswingcc.net/noswingcc/api/cranes/4\`, {
      method: 'GET', 
      headers: {
        'Authorization': \`Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkaW5nbGl2ciIsI
        mF1dGgiOiJST0xFX1VTRVIiLCJleHAiOjE3NDQ0N
        TY3NTd9.WP_2xv5sQHfnjGzzIxSQ_SOGssCxjKaBqji4N5El7gIsU1
        kagg7KQS-7BRVKuCvK_Oz7fxO8R-6k8uQPV6bw6A\`,
        'Accept': '*/*',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('An error occurred while trying to get data:', error);
    return null;
  }
}
`.trim();
