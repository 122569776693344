import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Box,
  Typography,
  IconButton,
  Stack,
  InputAdornment,
  TextField,
  Modal,
  Tooltip,
} from "@mui/material";
import useFetch from "../../components/fetch";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "../../components/DELETE";
import {
  deleteUser,
  getAllUsers,
  updateUser,
  createUser,
  deleteUserAccess,
} from "../../components/UserApi";
import useFetchV2 from "../../components/UserApi";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "3px",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const registerBody = {
  id: "",
  login: "",
  firstName: "Test",
  lastName: "User",
  email: "testuser1@gmail.com",
  imageUrl: "",
  activated: true,
  langKey: "en",
  createdBy: "admin",
  createdDate: "",
  lastModifiedBy: "admin",
  lastModifiedDate: "",
  authorities: ["ROLE_USER"],
  password: "password",
};

export default function DataTable({
  cranesAllowed,
  setCranesAllowed,
  userRoles,
  user
}) {
  const {
    data: cranesdata,
    loading: cranesloading,
    error: craneserror,
  } = useFetch("https://noswingcc.net/noswingcc/api/cranes?page=0&size=200");
  const {
    data: accountsdata,
    loading: accountsloading,
    error: accountserror,
    refetch: refetchAccounts,
  } = useFetchV2(
    "https://noswingcc.net/noswingcc/api/admin/users?page=0&size=1000"
  );
  const {
    data: useraccessdata,
    loading: useraccessloading,
    error: useraccesserror,
    refetch: refetchuseraccessdata,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/craneusers?page=0&size=200`
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const { t } = useTranslation();
  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rows, setRows] = React.useState([]);


  console.log(accountsdata);

  React.useEffect(() => {
    const rowsData =
    (accountsdata?.length > 0 &&
    userRoles.some((role) => role === "ROLE_LIST_USERS" || role === "ROLE_ADMIN"))  ?
    accountsdata.map((user, index) => ({
      id: index + 1,
      name: user.login,
      activated: user.activated,
      options:
        cranesdata?.length > 0 &&
        cranesdata.map((crane) => ({
          name: `${crane.country} / ${crane.location} / ${crane.name}`,
          value: crane.id,
        })),
      authorities: user.authorities,
    })) : [];
    
    setRows(rowsData);
    
  }, [accountsdata, userRoles])

  const columns = [
    { field: "id", headerName: t("pages.users.index"), width: 70 },
    { field: "name", headerName: 'Username', width: 130 },
    {
      field: "activated",
      headerName: t("pages.users.activated"),
      width: 130,
      renderCell: (params) =>
        params.row.activated ? (
          <Tooltip title={t("pages.users.deactivate")} placement="right">
            <IconButton
              sx={{ ml: -1 }}
              onClick={() => handleDeactivate(params.row.name)}
            >
              <CheckIcon color="success" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("pages.users.activate")} placement="right">
            <IconButton
              sx={{ ml: -1 }}
              onClick={() => handleActivate(params.row.name)}
            >
              <CloseIcon color="error" />
            </IconButton>
          </Tooltip>
        ),
    },
    {
      field: "manageCrane",
      headerName: "Manage Cranes",
      width: 160,
      renderCell: (params) => (
        <Checkbox
          color="info"
          checked={
            params.row.authorities?.some(
              (role) => role === "ROLE_MANAGE_CRANES" 
            ) || false
          }
          onChange={() => handleUpdateRole(params.row, "ROLE_MANAGE_CRANES")}
        />
      ),
    },
    {
      field: "manageWMS",
      headerName: "Manage WMS",
      width: 160,
      renderCell: (params) => (
        <Checkbox
          color="info"
          checked={
            params.row.authorities?.some(
              (role) => role === "ROLE_MANAGE_WMS" 
            ) || false
          }
          onChange={() => handleUpdateRole(params.row, "ROLE_MANAGE_WMS")}
        />
      ),
    },
    {
      field: "manageCC",
      headerName: "Manage CC",
      width: 160,
      renderCell: (params) => (
        <Checkbox
          color="info"
          checked={
            params.row.authorities?.some((role) => role === "ROLE_MANAGE_CC" ) ||
            false
          }
          onChange={() => handleUpdateRole(params.row, "ROLE_MANAGE_CC")}
        />
      ),
    },
    {
      field: "listUsers",
      headerName: "List Users",
      width: 160,
      renderCell: (params) => (
        <Checkbox
          color="info"
          checked={params.row.authorities?.some(
            (role) => role === "ROLE_LIST_USERS" 
          )}
          onChange={() => handleUpdateRole(params.row, "ROLE_LIST_USERS")}
        />
      ),
    },
    {
      field: "manageUsers",
      headerName: t("pages.users.manageusers"),
      width: 160,
      renderCell: (params) => (
        <Checkbox
          color="info"
          checked={params.row.authorities?.some(
            (role) => role === "ROLE_MANAGE_USERS" 
          )}
          onChange={() => handleUpdateRole(params.row, "ROLE_MANAGE_USERS")}
        />
      ),
    },
    // {
    //   field: 'options',
    //   headerName: t("pages.users.cranesallowed"),
    //   width: 160,
    //   renderCell: (params) => (
    //     <MultiSelectDropdown options={params.row.options} />
    //   ),
    // },
    {
      field: "delete",
      headerName: t("pages.users.deleteuser"),
      width: 130,
      renderCell: (params) => (
        params.row.authorities.some(role => role === "ROLE_ADMIN") ? <div>Admin</div> : <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete(params.row.name)}
        >
          {t("pages.users.delete")}
        </Button>
      ),
    },
  ];

  if (craneserror && accountsdata) {
    return <div>Error: {craneserror.message}</div>; // or some error component
  }

  function MultiSelectDropdown({ options }) {
    const [selectedOptions, setSelectedOptions] = React.useState(
      cranesAllowed.map(Number)
    );

    const handleChange = (event) => {
      setSelectedOptions(event.target.value);
      // setCranesAllowed(selectedOptions);
    };

    const handleSave = () => {
      setCranesAllowed(selectedOptions.map(String));
    };

    return (
      <div>
        <Select
          multiple
          value={selectedOptions}
          onChange={handleChange}
          onClose={handleSave}
          renderValue={(selected) => selected.join(", ")}
          sx={{ height: "30px" }}
        >
          {options?.length > 0 &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  checked={selectedOptions.indexOf(option.value) > -1}
                />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
        </Select>
      </div>
    );
  }
  async function handleActivate(login) {
    // console.log(accountsdata);
    const item = accountsdata.find((item) => item.login === login);
    if (
      !userRoles?.some((userrole) => userrole === "ROLE_MANAGE_USERS" || userrole === "ROLE_ADMIN")
    ) {
      toast.error("You do not have permission to manage users");
      return;
    }
    item.activated = true;
    await updateUser(item);
    refetchAccounts();
    toast.success("Succefully activated user!");
  }
  async function handleDeactivate(login) {
    // console.log(accountsdata);
    const item = accountsdata.find((item) => item.login === login);
    if (
      !userRoles?.some((userrole) => userrole === "ROLE_MANAGE_USERS" || userrole === "ROLE_ADMIN")
    ) {
      toast.error("You do not have permission to manage users");
      return;
    }
    if(item.authorities.some(role => role === "ROLE_ADMIN")){
      toast.error("You can not deactivate an Admin");
      return;
    }
    item.activated = false;
    await updateUser(item);
    refetchAccounts();
    toast.success("Succefully deactivated user!");
  }
  async function handleUpdateRole(user, role) {
    // console.log(accountsdata);
    // console.log(user);
    console.log(userRoles);
    let item = accountsdata.find((item) => item.login === user.name);
    if (
      !userRoles?.some((userrole) => userrole === "ROLE_MANAGE_USERS" || userrole === "ROLE_ADMIN")
    ) {
      toast.error("You do not have permission to manage users");
      return;
    }

    if (item.authorities?.some((userrole) => userrole === "ROLE_ADMIN")) {
      toast.error("Admins have full access");
      return;
    }

    if (item.authorities?.some((userrole) => userrole === role)) {
      item.authorities = item.authorities.filter(
        (userRole) => userRole !== role
      );
    } else {
      item.authorities = [...item.authorities, role];
    }

    console.log(item.authorities);
    await updateUser(item);
    
    toast.success("Succefully updated permission");
    refetchAccounts();
  }

  async function getAllRoles() {
    // console.log(accountsdata);
    // console.log(user);
    let item = accountsdata.find((item) => item.login === user);
    item.authorities = [
      ...item.authorities,

      "ROLE_LIST_USERS",

      "ROLE_MANAGE_CC",

      "ROLE_MANAGE_CRANES",

      "ROLE_MANAGE_USERS",

      "ROLE_MANAGE_WMS",

      "ROLE_USER",
    ];
    console.log(item.authorities);
    await updateUser(item);
    refetchAccounts();
    toast.success("Succefully gave you all roles for testing purposes");
  }

  async function handleDelete(login) {
    // const usersToDelete = useraccessdata?.filter((u) => u.user.login === login);
    //       console.log("users to del : " , usersToDelete);

    //       if(usersToDelete.length > 0) {
    //         for (const user of usersToDelete) {
    //           console.log("deleting ", user.user.login, " " , user.id);
    //           const success = await deleteUserAccess(user.id);
    //           if( success) {
    //             console.log("deleted : " , user.user.login);

    //           }
    //           else {
    //             toast.error('Failed to delete user access!');
    //           }
    //         }
    //       }
    let item = accountsdata.find((item) => item.login === login);
    if (
      item.authorities?.some((userrole) => userrole === "ROLE_ADMIN")
    ) {
      toast.error("You can not delete a Admin account");
      return;
    }

    const success = await deleteUser(login);

    if (success) {
      // Fetch data again or do whatever is needed to reflect the deletion
      refetchAccounts();
      //console.log("DELETED : " , accountsdata);
      toast.success("Succefully deleted user!");
    } else {
      toast.error("Failed to delete user!");
    }
  }

  
  
  

  function CustomNoRowsOverlay() {
    return (
      <div className="w-full h-full items-center justify-center flex">
        {userRoles.some((role) => role === "ROLE_LIST_USERS")
          ? "No data"
          : "You do not have permission to list users"}
      </div>
    );
  }

  function searchUsers(searchTerm) {
    if (!Array.isArray(rows)) {
      return [];
    }

    const filteredUsers = rows.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredUsers;
  }

  

  console.log(columns);

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box p={5}>
        <Box></Box>
        <Box mt={3} bgcolor="#fff" p={2}>
          <Box display="flex" justifyContent="space-between">
            <div className="flex flex-row items-center">
              <p className="text-xl font-semibold ">
                {t("pages.users.userlist")}
              </p>
              <IconButton
                sx={{ color: "grey", cursor: "pointer" }}
                onClick={handleOpen}
              >
                <AddOutlinedIcon sx={{ fontSize: 28 }} />
              </IconButton>
            </div>
            <Box
              mr="10px"
              sx={{
                width: "170px",
              }}
              borderRadius={"20px"}
              height={"40px"}
              display="flex"
              alignItems="center"
            >
              <TextField
                placeholder={t("pages.users.searchuser")}
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  width: "100%",
                  border: "none",
                  ml: "10px",
                  my: "25px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box height="500px" mt="30px">
            {
              <DataGrid
                rows={searchUsers(searchTerm)}
                columns={columns}
                pageSize={5}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            }
          </Box>
          <div className="w-full flex justify-center mt-10">
            <button
              className="bg-orange-500 font-semibold px-3 p-2 rounded-lg cursor-pointer"
              onClick={() => getAllRoles()}
            >
              GET ALL ROLES
            </button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CreateUserForm
                refetchAccounts={refetchAccounts}
                accountsdata={accountsdata}
              />
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}

const CreateUserForm = ({ refetchAccounts, accountsdata }) => {
  const [firstName, setFirstName] = React.useState(registerBody.firstName);
  const [lastName, setLastName] = React.useState(registerBody.lastName);
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState(registerBody.email);

  function emailExists(email) {
    return accountsdata.some(
      (user) => user.email.toLowerCase() === email.toLowerCase()
    );
  }
  function usernameExists(username) {
    return accountsdata.some(
      (user) => user.login.toLowerCase() === username.toLowerCase()
    );
  }

  React.useEffect(() => {
    const generatedUsername = `${firstName}${lastName}`;
    setUsername(generatedUsername);
    usernameExists(username);
  }, [firstName, lastName]);

  async function handleSubmit(e) {
    e.preventDefault();
    registerBody.firstName = firstName;
    registerBody.lastName = lastName;
    registerBody.login = username;
    registerBody.email = email;
    console.log(registerBody);
    const success = await createUser(registerBody);

    if (success) {
      // Fetch data again or do whatever is needed to reflect the deletion
      refetchAccounts();

      //console.log("DELETED : " , accountsdata);
      //console.log(`Creating user: ${firstName} ${lastName}`);
      toast.success("Succefully created user!");
    }
  }

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <TextField
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <TextField
        error={usernameExists(username)}
        helperText={usernameExists(username) && "Username already exists"}
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        error={emailExists(email)}
        helperText={emailExists(email) && "Email already exists"}
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Box>
        <Typography>Default password: password</Typography>
      </Box>
      <Button disabled={emailExists(email)} type="submit" variant="contained">
        Create User
      </Button>
    </Box>
  );
};
