import axios from 'axios';
import React, { useState, useEffect } from 'react';

function Post(url, requestData) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (requestData === null) {
        // If request data is null, do not make the request
        return;
    }

    setLoading(true);
    setData(null);
    setError(null);

    axios.post(url, requestData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      }
    })
      .then(response => setData(response.data))
      .catch(error => {
        setError(error.response ? error.response.data : error);
      })
      .finally(() => setLoading(false));
      
  }, [url, requestData]);

  return { data, loading, error };
}

export default Post;
