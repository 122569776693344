import React, { useState } from 'react'
import Scene2 from '../cranesV2/TabPages/relatedComponents/3DobjV2'
import useFetchV2 from '../../components/PartsApi';
import { MTLLoader, OBJLoader } from 'three-stdlib';
import { getFileContent } from '../../components/FilesApi';
import { toast } from 'react-toastify';



const CraneSim = ({crane, x,y,z, setVRcontrols, boxReady, setBoxReady, pickupBox, setPickupBox}) => {
    const {
        data: cranedata,
        loading: craneloading,
        error: craneerror,
        refetch: refetchCraneData,
      } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${crane}`);
      const {
        data,
        loading: dataIsLoading,
        error: dateError,
        refetch: refetchAllData,
      } = useFetchV2(
        `https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${crane}&folderName.equals=3D&page=0&size=50`
      );

      const {
        data: modelconf,
        loading: modelconfdataloading,
        error: modelconfdataerror,
        refetch: refetchModelconf,
      } = useFetchV2(
        `https://noswingcc.net/noswingcc/api/modelconfs?craneId.equals=${crane}&page=0&size=1`
      );

    const lock = false;
    const debug = false;
    const [objFiles, setObjFiles] = useState([]);
    const twobeems = false;
    const obj = {};
    const doesBeem1HaveTwoTrolleys = false;
    const doesBeem2HaveTwoTrolleys = false;
  
    const [files, setFiles] = useState([]);
    const [centerArray, setCenterArray] = useState([
        { x: 0, y: 0, z: 0 },
        { x: 0, y: 0, z: 0 },
        { x: 0, y: 0, z: 0 },
      ]);
      const [values, setValues] = useState([]);
    const [modelLoading, setModelLoading] = useState(0);
 
    const fullMarkerData = [];
    const configured = false;

    React.useEffect(() => {
        if (!modelconfdataloading && modelconf.length > 0) {
          const configObject = Object.keys(modelconf[0]).reduce((obj, key) => {
            // Exclude 'id' and 'crane' from the new object
            if (key !== "id" && key !== "crane") {
              // Capitalize the first letter of each key and replace it in the new object
              const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
              obj[capitalizedKey] = modelconf[0][key];
            }
            return obj;
          }, {});
          //console.log(configObject);
    
          let updatedValues = { ...values };
    
          updatedValues = {
            ...updatedValues,
            ...configObject,
          };
    
          if (true) {
            console.log("UPDATE, ", updatedValues);
            // toast.info("Updated values from database");
            setValues(updatedValues);
          }
        }
      }, [modelconf, modelconfdataloading]);

    function preRender(mtlUrl, objUrl) {
        if (mtlUrl != undefined && mtlUrl != "") {
        } else {
          return null;
        }
        //console.log("URL: ", mtlUrl);
        const mtlLoader = new MTLLoader();
        const mtl = mtlLoader.parse(mtlUrl);
        mtl.preload();
    
        //console.log(data2);
        const objLoader = new OBJLoader();
        objLoader.setMaterials(mtl);
        const rawObjText = `${objUrl}`;
        const obj = objLoader.parse(rawObjText);
    
        return obj;
      }
    
      async function fetchModelFiles() {
        // toast.info("Loading 3D model files...");
        let modelFiles = {};
        // console.log("data : ", data);
        if (!dataIsLoading && data?.length > 0) {
          const entries = await Promise.all(
            data.map(async (item) => {
              try {
                //toast.info(`Loading ${item.fileName}...`);
                const fileData = await getFileContent(item.document.id);
                const text = await fileData.text(); // Await the text promise directly
                //console.log("TEXT: ", text);
      
                // Increment the model loading counter
                setModelLoading((prev) => prev + 1);
      
                // Return a tuple/array with the file name and its content
                return [item.fileName, text];
              } catch (error) {
                console.error(`Error loading file ${item.fileName}:`, error);
                return [item.fileName, ""]; // Return empty string in case of error
              }
            })
          );
      
          // Convert the array of entries into an object
          modelFiles = Object.fromEntries(entries.filter(entry => entry)); // Filter out undefined entries, if any
        }
      
        // console.log("Model Files: ", modelFiles);
        return modelFiles;
      }
      
    
      async function createObjFiles(type, example, files) {
        const objFileName = `${type}.obj`;
        const mtlFileName = `${type}.mtl`;
    
        // console.log("create obj ",files);
      
        const objFileData = files[type + ".obj"];
        const mtlFileData = files[type + ".mtl"];
    
        // console.log("OBJ FILE DATA: ", objFileData);
        // console.log("MTL FILE DATA: ", mtlFileData);
      
        if (!objFileData || !mtlFileData) {
          console.error(`Files for ${type} not found.`);
          return null;
        }
      
        const objFile = objFileData;
        const mtlFile = mtlFileData;
      
        if (objFile && mtlFile) {
          const obj = preRender(mtlFile, objFile);
          if (!obj) {
            toast.error("Could not load 3D model!");
            return null;
          }
          //console.log(obj);
          return { [type]: obj }; // Return an object with dynamic key
        }
        return null;
      }
      
      React.useEffect(() => {
        async function fetchData() {
          const files = await fetchModelFiles();
      
          setFiles(files);
        //   console.log("FILES:" , files);
          const objFileTypes = ["Hook", "Trolley", "Beem", "Building", "Grabber"];
        //   console.log(data);
          const objFilesPromises = objFileTypes.map(type => createObjFiles(type, "example", files));
          const objFilesArray = await Promise.all(objFilesPromises);
      
          const objFiles = objFilesArray.reduce((acc, current) => {
            return { ...acc, ...current }; // Merge objects
          }, {});
        //    console.log("objfiles",objFiles);
          setObjFiles(objFiles);
        }
      
       
        fetchData();
      }, [data, dataIsLoading]);

      //console.log(cranedata);
  return (
    <div className='w-full h-full'>
    {/* <Scene2Configurator
                                lock={lock}
                                debug={debug}
                                objFiles={objFiles}
                                twobeems={twobeems}
                                confdata={cranedata?.craneconfiguration[0] || 1}
                                obj={obj}
                                opcdata={cranedata?.opc}
                                lookAt={[22, 2, 20]}
                                products={[]}
                                doesBeem1HaveTwoTrolleys={
                                  doesBeem1HaveTwoTrolleys
                                }
                                doesBeem2HaveTwoTrolleys={
                                  doesBeem2HaveTwoTrolleys
                                }
                                setCenterArray={setCenterArray}
                                files={files}
                                values={values}
                                camera={[50, 50, 10]}
                                className="my-component"
                                x={x || 0}
                                y={y || 0}
                                z={z || 0}
                                view={1}
                                setMarker={() => {}}
                                setNewMarkerPosition={() => {}}
                                markerdata={fullMarkerData}
                                selectedMarker={[]}
                                setSelectedMarker={() => {}}
                                setTempMarker={() => {}}
                                tempMarker={[]}
                                cargoWeight={0}
                                crane={404}
                                configured={configured}
                                cargoProduct={111}
                              /> */}
                              <Scene2
                              setVRcontrols={setVRcontrols}
                        lock={false}
                        debug={debug}
                        objFiles={objFiles}
                        twobeems={false}
                        setCraneModelClicked={() => {}}
                        doesBeem1HaveTwoTrolleys={false}
                        doesBeem2HaveTwoTrolleys={false}
                        setCenterArray={setCenterArray}
                        confdata={cranedata?.craneconfiguration[0] || 1}
                        configured={
                          modelconfdataloading
                            ? true
                            : modelconf.length > 0
                            ? true
                            : false
                        }
                        opcData={cranedata?.opc}
                        xposSim={x}
                        yposSim={y}
                        zposSim={z}
                        createBlockedZone={{}}
                        tempZone={[]}
                        Zone={[]}
                        obj={obj}
                        files={files}
                        values={values}
                        className="my-component"
                        view={1}
                        setMarker={() => {}}
                                setNewMarkerPosition={() => {}}
                                markerdata={fullMarkerData}
                                selectedMarker={[]}
                                setSelectedMarker={() => {}}
                                setTempMarker={() => {}}
                                tempMarker={[]}
                                cargoWeight={0}
                                crane={404}
                        
                        cargoProduct={
                          111
                        }
                        boxReady={boxReady}
                        setBoxReady={setBoxReady}
                        pickupBox={pickupBox}
                        setPickupBox={setPickupBox}
                        vr={true}
                      />
    </div>
  )
}

export default CraneSim