import React from "react";
import { SidePanel } from "./SidePanel";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import Settings from "./settings";
import { useParams } from "react-router-dom";
import UserAccess from "./access";
import Realtime3D from "./realtime3D";
import Locations from "./locations";
import Zones from "./zones";
import OPCserver from "./opcserver";
import useFetchV2 from "../../../../components/PartsApi";
import { OBJLoader, MTLLoader } from "three-stdlib";
import { get3DFile, getContent, getFileContent } from "../../../../components/FilesApi";
import { toast } from "react-toastify";
import Products from "./products";

function populateStack(markerData, containerData) {
  // Make a deep copy of markerData to avoid mutating the original array
  const newMarkerData = JSON.parse(JSON.stringify(markerData));

  newMarkerData.forEach((marker) => {
    // Initialize an empty stack array for each marker item
    marker.stack = [];

    // Filter containerData for matching cranelocation.id
    const matchingContainers = containerData.filter(
      (container) => container.cranelocation.id === marker.id
    );

    matchingContainers.forEach((container) => {
      // Set the containercode at the position specified by rowindex (subtract 1 to convert to 0-based index)
      marker.stack[container.rowindex - 1] = container.product.productcode;
    });
  });

  return newMarkerData;
}

const ConfigurationsPageTailwind = ({tier, userRoles}) => {
  const [view, setView] = useState("settings");
  const { id } = useParams();
  const [modelLoading, setModelLoading] = useState(0);
  const [obj, setObj] = useState([]);
  const [files, setFiles] = useState("");
  const [step, setStep] = useState(0);
  const [objFiles, setObjFiles] = useState([]);
  const {
    data: markerData,
    loading: markerDataLoading,
    error: markerDataError,
    refetch: refetchAllMarkers,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${id}&page=0&size=100`
  );

  const {
    data: containerData,
    loading: containerDataLoading,
    error: containerDataError,
    refetch: refetchAllContainers,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/containers?page=0&size=100`
  );
  const {
    data,
    loading: dataIsLoading,
    error: dateError,
    refetch: refetchAllData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&folderName.equals=3D&page=0&size=50`
  );

  function preRender(mtlUrl, objUrl) {
    if (mtlUrl != undefined && mtlUrl != "") {
    } else {
      return null;
    }
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    const obj = objLoader.parse(rawObjText);

    return obj;
  }

  async function fetchModelFiles() {
    // toast.info("Loading 3D model files...");
    let modelFiles = {};
    // console.log("data : ", data);
    if (!dataIsLoading && data?.length > 0) {
      const entries = await Promise.all(
        data.map(async (item) => {
          try {
            //toast.info(`Loading ${item.fileName}...`);
            const fileData = await getFileContent(item.document.id);
            const text = await fileData.text(); // Await the text promise directly
            //console.log("TEXT: ", text);
  
            // Increment the model loading counter
            setModelLoading((prev) => prev + 1);
  
            // Return a tuple/array with the file name and its content
            return [item.fileName, text];
          } catch (error) {
            console.error(`Error loading file ${item.fileName}:`, error);
            return [item.fileName, ""]; // Return empty string in case of error
          }
        })
      );
  
      // Convert the array of entries into an object
      modelFiles = Object.fromEntries(entries.filter(entry => entry)); // Filter out undefined entries, if any
    }
  
    // console.log("Model Files: ", modelFiles);
    return modelFiles;
  }
  

  async function createObjFiles(type, example, files) {
    const objFileName = `${type}.obj`;
    const mtlFileName = `${type}.mtl`;

    console.log("create obj ",files);
  
    const objFileData = files[type + ".obj"];
    const mtlFileData = files[type + ".mtl"];

    // console.log("OBJ FILE DATA: ", objFileData);
    // console.log("MTL FILE DATA: ", mtlFileData);
  
    if (!objFileData || !mtlFileData) {
      console.error(`Files for ${type} not found.`);
      return null;
    }
  
    const objFile = objFileData;
    const mtlFile = mtlFileData;
  
    if (objFile && mtlFile) {
      const obj = preRender(mtlFile, objFile);
      if (!obj) {
        toast.error("Could not load 3D model!");
        return null;
      }
      //console.log(obj);
      return { [type]: obj }; // Return an object with dynamic key
    }
    return null;
  }
  
  React.useEffect(() => {
    async function fetchData() {
      const files = await fetchModelFiles();
  
      setFiles(files);
      // console.log("FILES:" , files);
      const objFileTypes = ["Hook", "Trolley", "Beem", "Building", "Grabber"];
      // console.log(data);
      const objFilesPromises = objFileTypes.map(type => createObjFiles(type, "example", files));
      const objFilesArray = await Promise.all(objFilesPromises);
  
      const objFiles = objFilesArray.reduce((acc, current) => {
        return { ...acc, ...current }; // Merge objects
      }, {});
      // console.log(objFiles);
      setObjFiles(objFiles);
    }
  
    if (data?.length > 0 && step === 0) {
      setStep(getStep(data));
    }
    fetchData();
  }, [data, dataIsLoading]);


  
  let fullMarkerData = null;
  if (!markerDataLoading && !containerDataLoading) {
    fullMarkerData = populateStack(markerData, containerData);
    // const test = containerData.filter(item => item.cranelocation.locationcode == selectedMarker[0] && item.rowindex == 1).map(item => item.product.productcode)[0];
    // console.log(test);
  }
 

  const getStep = (files) => {
    // console.log(modelconf);
    // //console.log(files);
    // const endlimitsConfigured = (values["cranexposmin"] == 0 && values["cranexposmax"] == 0) || (values["craneyposmin"] == 0 && values["craneyposmax"] == 0) || (values["cranezposmin"] == 0 && values["cranezposmax"] == 0);
    // if(endlimitsConfigured){
    //   return 7;
    // }

    // if (
    //   files?.some((file) => file.fileName.includes("Hook.obj")) ||
    //   files?.some((file) => file.fileName.includes("Hook.mtl"))
    // ) {
    //   return 4;
    // }
    // if(files.some(file => file.fileName.includes("Rope.obj")) || files.some(file => file.fileName.includes("Rope.mtl"))){
    //   return 4;
    // }
    // if (
    //   files?.some((file) => file.fileName.includes("Trolley.obj")) ||
    //   files?.some((file) => file.fileName.includes("Trolley.mtl"))
    // ) {
    //   return 1;
    // }
    // if (
    //   files?.some((file) => file.fileName.includes("Beem.obj")) ||
    //   files?.some((file) => file.fileName.includes("Beem.mtl"))
    // ) {
    //   return 1;
    // }
    // if (
    //   files?.some((file) => file.fileName.includes("Building.obj")) ||
    //   files?.some((file) => file.fileName.includes("Building.mtl"))
    // ) {
    //   return 1;
    // }
    return 0;
  };

  
  return (
    <Box mt={-2} display={"flex"} flexDirection={"row"}>
      <SidePanel setView={setView} view={view} id={id} tier={tier} />
      <Box p={4} sx={{ width: 1 }} ml={30}>
        {view === "settings" && <Settings id={id} userRoles={userRoles}/>}
        {view === "realtime3d" && <Realtime3D objData={objFiles} id={id} fullMarkerData={fullMarkerData} obj={obj} files={files} step={step} setStep={setStep} refetchAllData={refetchAllData} allDocs={data}/>}
        {view === "useraccess" && <UserAccess id={id} />}
        {view === "opcserver" && <OPCserver id={id} />}
        {tier > 1 && view === "products" && <Products id={id} />}
        {tier > 1 && view === "locations" && <Locations id={id} />}
        {tier > 1 && view === "zones" && <Zones id={id} />}
      </Box>
    </Box>
  );
};

export default ConfigurationsPageTailwind;
