import axios from 'axios';
import React, {useState, useEffect} from 'react';



export async function getAllMarkers() {
    try {
      const response = await axios.get('https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=4&page=0&size=20', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('An error occurred while trying to get all markers:', error);
      return null;
    }
}

export async function deleteMarker(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/cranelocations/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete marker:', error.response.data);
    return false;
  }
}
export async function deleteOrder(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/craneorders/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete order:', error);
    return false;
  }
}
export async function deleteContainerAtId(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/containers/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete order:', error);
    return false;
  }
}

  
export async function postLocation(crane) {
  
  try {
    const response = await axios.post('https://noswingcc.net/noswingcc/api/cranelocations', crane, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response;
  } catch (error) {
    console.error('An error occurred while creating the location:', error);
    return error.response.data;
  }                   
}

export async function postOrder(order) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/craneorders', order, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the order:', error);
    return false;
  }
}
export async function updateOrder(id, order) {
  
  try {
    await axios.put(`https://noswingcc.net/noswingcc/api/craneorders/${id}`, order, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the order:', error);
    return false;
  }
}

export async function updateOpc(id, body) {
 
  try {
    await axios.put(`https://noswingcc.net/noswingcc/api/opcs/${id}`, body, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while updating the opc:', error);
    return false;
  }
}

export async function getCranePos(opcid) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/opcs/${opcid}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get opc data:', error);
    return null;
  }
}

export async function updateLocation(id, data) {
  
  try {
    await axios.put(`https://noswingcc.net/noswingcc/api/cranelocations/${id}`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while updating the location:', error);
    return false;
  }
}

export async function getAllOrders(id) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneorders?craneId.equals=${id}&page=0&size=1000`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get opc data:', error);
    return false;
  }
}
export async function getOrdersToDelete(id, containerid) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneorders?containerId.equals=${containerid}&craneId.equals=${id}&page=0&size=1000`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get opc data:', error);
    return false;
  }
}
export async function getOrder(id) {
  try {
const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneorders?craneId.equals=4&page=0&size=1000&id.equals=${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get opc data:', error);
    return false;
  }
}
export async function getOrdersFromLocationId(craneId, locationId) {
  try {
const response = await axios.get(`https://noswingcc.net/noswingcc/api/craneorders?craneId.equals=${craneId}&to_cranelocationId.equals=${locationId}&page=0&size=2000`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get order data:', error.response.data);
    return false;
  }
}


export async function getAllContainersAtLocation(id) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/containers?cranelocationId.equals=${id}&page=0&size=1000`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get top container:', error);
    return [];
  }
}

export async function getContainer(code) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/containers?containercode.equals=${code}&page=0&size=20`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get top container:', error);
    return [];
  }
}
export async function patchContainer(id, data) {
  try {
    const response = await axios.put(`https://noswingcc.net/noswingcc/api/containers/${id}`, data,  {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to patch container:', error);
    return [];
  }
}


export async function getContainerCount(id) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/containers/count?cranelocationId.notEquals=89&productId.equals=${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get container count:', error);
    return [];
  }
}