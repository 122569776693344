import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import bg from './images/cranesimbg.png';
import { ArrowDownLeftIcon, ArrowUpRightIcon } from '@heroicons/react/20/solid';

const HmiDisplay = ({action, setX, setY, setZ, x,y,z, mini, setMiniHmi, stopAnimation, setStopAnimation}) => {
    
    const [position, setPosition] = useState({ x: x, y: y, z: z });
    const [targetPosition, setTargetPosition] = useState({ x: 5000, y: 5000, z: 100 });

    useEffect(() => {
        setStopAnimation(false);
        const interval = setInterval((stopAnimation) => {
            setPosition(prevPosition => {
                const speed = 0.005; // Adjust this value to change the speed of the animation
                const deltaX = targetPosition.x - prevPosition.x;
                const deltaY = targetPosition.y - prevPosition.y;
                const deltaZ = targetPosition.z - prevPosition.z;

                // Calculate the new position, moving closer to the target by a fraction of the remaining distance
                const newX = prevPosition.x + speed * deltaX;
                const newY = prevPosition.y + speed * deltaY;
                const newZ = prevPosition.z + speed * deltaZ;

                setX(newX);
                setY(newY);
                setZ(newZ);

                // If the new position is very close to the target, consider it as reaching the target
                if (Math.abs(deltaX) < 0.1 && Math.abs(deltaY) < 0.1 && Math.abs(deltaZ) < 0.1 ) {
                    clearInterval(interval); // Stop the animation
                    
                    return targetPosition; // Snap to the target position
                }

                return { x: newX, y: newY, z: newZ };
            });
        }, 16); // Run approximately 60 times per second
        
        return () => clearInterval(interval); // Cleanup on unmount or target change
    }, [targetPosition]);

    const moveToPosition = (x, y, z) => {
        setTargetPosition({ x, y, z });
    };

    // <div>Current Position: X={position.x.toFixed(2)}, Y={position.y.toFixed(2)}, Z={position.z.toFixed(2)}</div>
  
  return (
    <div>
        {mini == true ? <div className='w-[1050px] h-[500px] rounded-lg shadow-lg border-4 border-black relative'>
        <div className='absolute -right-3 -top-3 z-10'>                    <ArrowUpRightIcon className='h-10 w-10 text-white cursor-pointer bg-black rounded-full border-2 border-white z-10 ' onClick={() => setMiniHmi(false)}/>
</div>
        <div className='w-full h-full bg-gray-200 flex flex-col gap-5 grow mb-40 border-gray-300 rounded-lg relative'>
    <img src={bg} alt="bg" className='w-full h-full absolute overflow-hidden object-cover object-center'/>
    <div className='flex flex-row justify-between px-14 items-center mb-10 absolute top-0 w-full bg-gray-900 py-2'>
                    <h1 className='text-3xl font-bold bg-[#17787b] text-white px-2 py-0'>SIEMENS</h1>
                    <p className='text-4xl text-white font-normal'>SIMATIC HMI</p>
                </div>
    {<p className='text-2xl text-black font-bold p-5'>{action}</p>}
   
    <div className='absolute h-full flex flex-col items-center  justify-center left-5'>
    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => moveToPosition(1000,1000,100)}>
        <p className='text-white font-semibold text-lg'>Pos 1</p>
        
    </div>
    
    </div>
    <div className='absolute h-full flex flex-col items-center  justify-center right-5 rounded-b-lg'>
    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => moveToPosition(15000,5000,1000)}>
        <p className='text-white font-semibold text-lg'>Pos 2</p>
        
    </div>
    
    </div>

    <div className='absolute bottom-0 h-20 bg-gray-200 w-full flex flex-row gap-5 items-center pl-2'>
    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => toast.info("Click")}>
        <p className='text-white font-semibold'>Settings</p>
        
    </div>
    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => toast.info("Click")}>
        <p className='text-white font-semibold'>Error log</p>
        
    </div>
    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => toast.info("Click")}>
        <p className='text-white font-semibold'>Controls</p>
        
    </div>
    {/* <div>Current Position: X={position.x.toFixed(2)}, Y={position.y.toFixed(2)}, Z={position.z.toFixed(2)}</div> */}
    </div>
</div> </div>: <div className='w-[1050px] h-[800px] bg-gradient-to-br from-[#bfc7ca] to-[#7f878c] rounded-xl p-5 shadow-lg border-2 border-[#70787b relative'>
<div className='absolute -right-3 -top-3 z-10'>                    <ArrowDownLeftIcon className='h-10 w-10 text-white cursor-pointer bg-black rounded-full border-2 border-white z-10 ' onClick={() => setMiniHmi(true)}/>
</div>
        <div className='border-4 w-full h-full rounded-lg border-black bg-[#6a7478] px-20 pb-20 relative' >
            <div className=' bg-[#8192a3] h-full w-full pt-3 flex flex-col rounded-b-sm'>
                <div className='flex flex-row justify-between px-14 items-center mb-10'>
                    <h1 className='text-3xl font-bold bg-[#17787b] text-white px-2 py-0'>SIEMENS</h1>
                    <p className='text-4xl text-white font-normal'>SIMATIC HMI</p>
                </div>
                <div className='bg-gray-200 flex flex-col gap-5 grow mx-14 mb-40 border-gray-300 rounded-sm border-2 relative'>
                    <img src={bg} alt="bg" className='w-full h-full absolute overflow-hidden object-cover object-center'/>
                    {<p className='text-2xl text-black font-bold p-5'>{action}</p>}
                   
                    <div className='absolute h-full flex flex-col items-center  justify-center left-5'>
                    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => moveToPosition(0,0,0)}>
                        <p className='text-white font-semibold'>Pos 1</p>
                        
                    </div>
                    
                    </div>
                    <div className='absolute h-full flex flex-col items-center  justify-center right-5'>
                    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => moveToPosition(15000,5000,1000)}>
                        <p className='text-white font-semibold'>Pos 2</p>
                        
                    </div>
                    
                    </div>

                    <div className='absolute bottom-0 h-20 bg-gray-200 w-full flex flex-row gap-5 items-center pl-2'>
                    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => toast.info("Click")}>
                        <p className='text-white font-semibold'>Settings</p>
                        
                    </div>
                    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => toast.info("Click")}>
                        <p className='text-white font-semibold'>Error log</p>
                        
                    </div>
                    <div className=' p-4 bg-[#848793] h-14 w-32 flex items-center rounded-sm shadow-xl cursor-pointer hover:bg-[#969da4]' onClick={() => toast.info("Click")}>
                        <p className='text-white font-semibold'>Controls</p>
                        
                    </div>
                    {/* <div>Current Position: X={position.x.toFixed(2)}, Y={position.y.toFixed(2)}, Z={position.z.toFixed(2)}</div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>}
    </div>
  )
}

export default HmiDisplay