import React from 'react'
import useFetchV2 from '../../../../components/PartsApi';

import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'

const Zones = ({id}) => {
const { data: cranedata, loading: craneloading, error: craneerror, refetch: refetchCraneData } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranes/${id}`);
  return (
    <div>
  
       <span>Coming soon</span>
    </div>
  )
}

export default Zones