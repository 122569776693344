import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CardMedia,
} from "@mui/material";
import image from "./nslogo.png";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

function Login({ setIsLogin, setUser, setUserRoles }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [x, setX] = useState(-70);

  useEffect(() => {
    const interval = setInterval(() => {
      setX((prevX) => (prevX >= 70 ? -70 : prevX + 0.2));
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const Authenticate = async (username, password, rememberMe) => {
    try {
      const response = await axios.post(
        "https://noswingcc.net/noswingcc/api/authenticate",
        {
          username: username,
          password: password,
          rememberMe: rememberMe,
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.id_token) {
        // If the request is successful and the server responds with a token,
        // store the token and set the isLoggedIn state to true.
        //setToken(response.data.token);

        localStorage.setItem("token", response.data.id_token);
        localStorage.setItem("user", username);

        setUser(username);
        setLoading(false);
        setIsLogin(true);
        navigate("/cranes");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      if (error.message === "Request failed with status code 401") {
        setError(true);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Authenticate(username, password, true);
    console.log(
      `Logging in with username: ${username} and password: ${password}`
    );
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ display: "flex" }}>
      {/* <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh"  >
      <Box mb={6} >
      <img alt="pictur" src={image} width="300px"></img>
      </Box>
      <Box backgroundColor="#fefefe" borderRadius={"5px"} p={3} mb={15} sx={{bgcolor: 'background.paper',
          boxShadow: 1, }}>
        
        <p className='text-5xl'>
          Login
        </p>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {error && <Typography variant="subtitle1" color="#FF0000">
            Wrong username or password
          </Typography>
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
            {!loading ? "Login" : "Loading..."}
          </Button>
          </Box>
        </Box>
      </Box> */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto w-auto" src={image} alt="Logo" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Username
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  onChange={e => setUsername(e.target.value)}
                  value={username}
                  autoComplete="username"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {!loading ? "Sign in" : "Signing in..."}
              </button>
            </div>
            <div>
              {error && (
                <p className="text-center text-sm text-red-500">
                  Wrong username or password
                </p>
              )}
            </div>
          </form>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
             
            </a>
          </p> */}
        </div>
      </div>
    </Container>
  );
}

export default Login;
