import React from "react";

import {
  CalendarIcon,
  Cog6ToothIcon,
  AdjustmentsHorizontalIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  UserGroupIcon,
  HomeIcon,
  ServerIcon,
  UsersIcon,
  RectangleStackIcon,
  TruckIcon,
  CloudIcon,
  StopIcon,
  CubeTransparentIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { BackspaceIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";


const navigation2 = [
  {
    name: "Return to crane",
    href: "-1",
    icon: ChevronLeftIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const SidePanel = ({ setView, view, id, tier }) => {
  const navigate = useNavigate();
  const getNavLinkClass = (path) => {
    return view === path
      ? "bg-white-50 text-nsorange-500"
      : "text-gray-700 hover:text-nsorange-300 hover:bg-white-50";
  };
  const getNavLinkClassIcon = (path) => {
    return view === path
      ? "text-nsorange-500"
      : "text-gray-400 group-hover:text-nsorange-300";
  };

  const navigation = [
    { name: "General", href: "settings", icon: Cog6ToothIcon, current: false, enabled: true, },
    
    {
      name: "User access",
      href: "useraccess",
      icon: UserGroupIcon,
  
      current: false,
      enabled: true,
    },
    {
      name: "OPC Server",
      href: "opcserver",
      icon: ServerIcon,
      current: false,
      enabled: true,
    },
    {
      name: "Realtime 3D",
      href: "realtime3d",
      icon: AdjustmentsHorizontalIcon,
      enabled: true,
      current: false,
    },
    {
      name: "Locations",
      href: "locations",
      icon: RectangleGroupIcon,
      current: false,
      enabled: tier > 1,
    },
    {
      name: "Products",
      href: "products",
      icon: CubeTransparentIcon,
      current: false,
      enabled: tier > 1,
    },
    {
      name: "Zones",
      href: "zones",
      icon: StopIcon,
      current: false,
      enabled: tier > 1,
    },
    {
      name: "WMS",
      href: "wms",
      icon: TruckIcon,
      current: false,
      enabled: tier > 1,
    },
  ];

  return (
    <div className="h-screen w-56 bg-slate-400 fixed ">
      <div className="flex w-56 grow flex-col gap-y-5  border-r border-gray-200 bg-white border-t px-6 pt-6  h-full">
        {/* <span className="text-lg font-bold">Crane Configuration</span> */}
        <nav className="flex flex-1 flex-col mt-5">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.filter(item => item.enabled === true).map((item) => (
                  <li key={item.name}>
                    <button
                      onClick={() => setView(item.href)}
                      className={classNames(
                        getNavLinkClass(item.href),
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-44"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          getNavLinkClassIcon(item.href),
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                      {item?.count ? (
                        <span
                          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                          aria-hidden="true"
                        >
                          {item?.count}
                        </span>
                      ) : null}
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
        <nav className="flex flex-1 flex-col  place-content-center ">
          <ul
            role="list"
            className="flex flex-col"
          >
            <li className="">
              <ul role="list" className="-mx-2 space-y-1">
                {navigation2.map((item) => (
                  <li key={item.name}>
                    <button
                      onClick={() => navigate(`/cranes/${id}`)}
                      className={classNames(
                        getNavLinkClass(item.href),
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-44"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          getNavLinkClassIcon(item.href),
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                      {item?.count ? (
                        <span
                          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                          aria-hidden="true"
                        >
                          {item?.count}
                        </span>
                      ) : null}
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
