import React from 'react'
import { Box, Typography, Stack, Button, IconButton, Divider, ButtonBase, TextField, InputAdornment, Rating } from '@mui/material'
import { Link } from 'react-router-dom';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SearchIcon from '@mui/icons-material/Search';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GroupIcon from '@mui/icons-material/Group';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import useFetchV2 from '../../components/PartsApi';

import Long from './Long.png';
import Short from './Short.png';

function ToolbarActions() {
  return (
    <Stack direction="row" spacing={2} ml={3}>
      <Button
        startIcon={<FilterAltOutlinedIcon />}
        
      >
        Filter
      </Button>
      
      <Button
        startIcon={<LayersOutlinedIcon />}
        
      >
        Group by
      </Button>
      
      <Button
        startIcon={<StarBorderIcon />}
        
      >
        Favorites
      </Button>
    </Stack>
  );
}

//     {
//       id: 11,
//       title: 'Steel ST ',
//       price: '$137.83',
//       inventory: 140,
//       imageUrl: 'path_to_fir_image.jpg'
//     },
    
// ];


const Products = () => {
  const { data: products, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/products?id.greaterThanOrEqual=12&page=0&size=20`);
  console.log(products);
  
  return (
    <Box display="flex" justifyContent="center">
    <Stack mt={3}>
    
        <Box ><Typography variant='h4'>Products</Typography></Box>
        <Stack direction="row" justifyContent="space-between">
        <Button component={Link} to="/wms/products/new" sx={{display: "flex" , justifySelf: "flex-start", width: 30, mt: 2}} variant='contained'>New</Button>
        <Stack direction="row" spacing={3}>
        <ToolbarActions/>
        <Box mr="10px" border={1} sx={{
            width: "170px",
          }} borderRadius={"20px"} height={"40px"} display="flex" alignItems="center">
          <TextField
            placeholder="Search"
            variant="outlined"
          
            sx={{
              width: '100%',
              border: 'none',
              ml: "10px",
              my: '25px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          
          </Box>
          </Stack>
        </Stack>
        
        <Divider sx={{mt: 2}}/>
        
        <Box mt={4} display="flex" flexWrap="wrap" maxWidth="1200px" gap={2}>
            {!dataIsLoading && products.map(product => (
                <ButtonBase 
                    component={Link} 
                    to={`/wms/products/${product.id}`} 
                    key={product.id}
                    sx={{
                        display: 'flex', 
                        justifyContent: "space-evenly", 
                        bgcolor: 'white',
                        mb: 2, 
                        p: 2, 
                        border: '1px solid lightgray', 
                        borderRadius: '8px',
                        width: '250px'  // approximately 1/3 width minus margin for 3 items in a row
                    }}
                >
                    <Box >
                        <img src={product.id == 12 ? Long : product.id == 13 ? Short : "https://sudbury.legendboats.com/resource/defaultProductImage"} alt={'product'} style={{ width: '100px', height: '100px', marginRight: '20px' }} />
                        
                   </Box>
                    <Box>
                        <Typography variant="h5" fontWeight="bold">{product.name}</Typography>
                        {/* <Typography>Price: {product.price}</Typography> */}
                        {/* <Typography>{product.inventory} in inventory</Typography> */}
                    </Box>
                </ButtonBase>
            ))}
        </Box>
    </Stack>
</Box>

  )
}

export default Products