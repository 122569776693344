import React, {useState, useEffect} from "react";
import useFetchV2 from "../../../../components/PartsApi";

import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { CircularProgress } from "@mui/material";

import { toast } from "react-toastify";
import { createUserAccess, deleteUserAccess, updateUserAccess } from "../../../../components/UserApi";

function transformUserData(userData) {
  return {
    id: userData.user_login,
    login: userData.user_login,
    permissions: {
      "Configure": userData.configure,
      "Crane access": userData.craneAccess,
      "Create locations": userData.createLocations,
      "Create orders": userData.createOrders,
      "Toggle functions": userData.disableFunctions,
      "Filesystem access level": userData.filepermissionlevel 
    }
  };
}

const UserAccess = ({ id }) => {
  const { data: userdata, loading: userloading, error: usererror } = useFetchV2(
    "https://noswingcc.net/noswingcc/api/users?page=0&size=50"
  );
  const { data: useraccessdata, loading: useraccessloading, error: useraccesserror , refetch: refetchuseraccessdata} = useFetchV2(
    `https://noswingcc.net/noswingcc/api/craneusers?craneAccess.equals=true&craneId.equals=${id}&page=0&size=50`
  );
  
  

  

  const [usersWithAccess, setUsersWithAccess] = useState([]);
  const [saving, setSaving] = useState(false);

    //console.log("access ", usersWithAccess);
    //console.log("useraccessdata ", userdata);
    useEffect(() => {
      if (useraccessdata && useraccessdata.length > 0) {
        const initialUsersWithAccess = useraccessdata.map(transformUserData);
        setUsersWithAccess(initialUsersWithAccess);
      }
    }, [useraccessdata]);

  const changeUserAccess = (event, user, permission) => {
    if (permission) {
      // Handle permission changes
      setUsersWithAccess((prevUsers) => {
        return prevUsers.map((u) => {
          if (u.id === user.id) {
            return {
              ...u,
              permissions: {
                ...u.permissions,
                [permission]: event.target.checked,
              },
            };
          }
          return u;
        });
      });
    } else {
      // Handle user access changes
      console.log(user)
      if (event.target.checked) {
        setUsersWithAccess((prevUsers) => [
          ...prevUsers, 
          { ...user, id: user.login, permissions: { 
              "Crane access": true, 
              "Configure": false, 
              "Create orders": false, 
              "Create locations": false, 
              "Toggle functions": false, 
              "Filesystem access level": 0 
            }
          }
        ]);
      
      } else {
        setUsersWithAccess((prevUsers) => prevUsers.filter((u) => u.id !== user.login));
      }
    }
  };
  

    const saveUserAccess = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        console.log(usersWithAccess);
        setSaving(true);
        //toast.info("Saving is not implemented yet");
        

        
        try {
          for (const user of usersWithAccess) {

            let body = {
              "id": "",
              "craneAccess": true,
              "configure": user.permissions ? user.permissions["Configure"] : false,
              "createOrders": user.permissions ? user.permissions["Create orders"] : false,
              "createLocations": user.permissions ? user.permissions["Create locations"] : false,
              "disableFunctions": user.permissions ? user.permissions["Toggle functions"] : false,
              "filepermissionlevel": user.filepermissionlevel ,
              "extra1": false,
              "extra2": false,
              "extra3": false,
              "crane": {
                "id": id,
              },
              "user_login": user.id
            }
            console.log(useraccessdata);
            console.log(usersWithAccess);
            const existingUserId = useraccessdata?.filter((u) => u.user_login === user.id);
            //console.log(existingUserId);

            const usersToDelete = useraccessdata?.filter((u) => !usersWithAccess.some((user) => user.id === u.user_login));
            console.log("users to del : " , usersToDelete);

            if(usersToDelete.length > 0) {
              for (const user of usersToDelete) {
                console.log("deleting ", user.user_login, " " , user.id);
                const success = await deleteUserAccess(user.id);
              }
            }
            if(existingUserId.length > 0) {
              body.id = existingUserId[0].id;
              //console.log(user.login, " exists in database ", body.id)
              const response = await updateUserAccess(existingUserId[0].id, body);
              //console.log(response);
              
              
            } else {
              body.id = "";
              //console.log(user.login, "dont exists in database ", body.id)
              const response = await createUserAccess(body);
              //console.log(response);
              
            }
          }
          refetchuseraccessdata();
          toast.success("Saved");
        } catch (error) {
          console.error(error);
          toast.error("Failed to save user access");
        }
        setSaving(false);
    }

  return (
    <div>
      <form onSubmit={saveUserAccess}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                User access
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Select users that you want to give access to this crane.
              </p>
            </div>

            <div className="max-w-2xl space-y-10 md:col-span-2">
              <fieldset>
                <legend className="text-base font-semibold leading-6 text-gray-900">
                  Users
                </legend>
                <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 h-96 overflow-auto">
                  {userloading ? <CircularProgress/> : userdata?.map((person, personIdx) => (
                    <div
                      key={personIdx}
                      className="relative flex items-start py-4"
                    >
                      <div className="min-w-0 flex-1 text-sm leading-6">
                        <label
                          htmlFor={`person-${person.id}`}
                          className="select-none font-medium text-gray-900"
                        >
                          {person.login}
                        </label>
                      </div>
                      <div className="ml-3 flex h-6 items-center mr-20">
                        <input
                          id={`person-${person.id}`}
                          name={`person-${person.id}`}
                          onChange={(event) => changeUserAccess(event, person)}
                          checked={usersWithAccess?.some( (u) => u.id === person.login)}
                          
                          type="checkbox"
                          className="h-5 w-5 rounded border-gray-300 text-nsblue-500 focus:ring-nsblue-500"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1 ">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 ">
                User permissions
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Configure what users with access are allowed to do.
              </p>
            </div>

            <div className="w-full space-y-10 overflow-auto pl-10 mt-5">
              <fieldset>
                <legend className="text-base font-semibold leading-6 text-gray-900">
                  <div className="flex flex-row gap-5">
                    <span className="w-52">User</span>
                    <span className="w-32">Configure</span>
                    <span className="w-32">Create orders</span>
                    <span className="w-32">Create locations</span>
                    <span className="w-32">Toggle functions</span>
                    <span className="w-32">Filesystem access</span>
                    
                  </div>
                </legend>
                <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 ">
                  {!useraccessloading && usersWithAccess?.length == 0 ? <div className="p-10"><span>No users with access yet</span></div> :  usersWithAccess?.map((person, personIdx) => (
                    <div
                      key={personIdx}
                      className="relative flex items-start py-4"
                    >
                      <div className="min-w-0 flex-1 text-sm leading-6">
                        <label
                          htmlFor={`person-${person.id}`}
                          className="select-none font-medium text-gray-900"
                        >
                          {person.login}
                        </label>
                      </div>
                      <div className="ml-3 flex h-6 items-centerjustify-start w-full">
                        
                        <div className="flex flex-row gap-5 ml-52">
                            
                        {["Configure", "Create orders", "Create locations", "Toggle functions"].map(access => (
                          <div className="w-32 flex justify-start">
                            <input
                              id={`${person.id}-${access}`}
                              name={`${person.id}-${access}`}
                              checked={person.permissions[access]}
                              onChange={(event) => changeUserAccess(event, person, access)}
                              type={access === "Filesystem access" ? "number" : "checkbox"}
                              className="h-5 w-5 rounded border-gray-300 text-nsblue-500 focus:ring-nsblue-500 ml-2"
                            />
                            
                          </div>
                        ))}
                        {["Filesystem access level"].map(access => (
                          <div className="w-32 flex justify-start">
                            <input
                              id={`${person.id}-${access}`}
                              name={`${person.id}-${access}`}
                              value={person.permissions[access]}
                              defaultValue={1}
                              style={{width: "3rem"}}
                              type={"number"}
                              className="h-5 w-5 rounded border-gray-300 text-nsblue-500 focus:ring-nsblue-500 ml-2"
                            />
                            
                          </div>
                        ))}
                            
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={"rounded-md bg-nsblue-700 px-3 w-20 py-2 text-sm font-semibold text-white shadow-sm hover:bg-nsblue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-nsblue-600" + (saving ? " opacity-50 cursor-progress" : "")}
          >
            {saving ? "Saving" : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserAccess;
