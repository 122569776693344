import React, { useEffect, useRef } from "react";

import forward from "./images/j_forward.png";
import backward from "./images/j_backward.png";
import left from "./images/j_left.png";
import right from "./images/j_right.png";
import relaxed from "./images/j_relaxed.png";

const Joystick = ({ action, setY, setX, setZ }) => {
  let image = "";
  const actionInterval = useRef(null);
  const actionTimeout = useRef(null);

  const updatePosition = () => {
    // Example of updating x, y, z based on the action
    switch (action) {
      case "Move forward":
        setY((prevY) => prevY - 30);
        break;
      case "Move backward":
        setY((prevY) => prevY + 30);
        break;
      case "Move left":
        setX((prevX) => prevX - 30);
        break;
      case "Move right":
        setX((prevX) => prevX + 30);
        break;
      case "Up":
        setZ((prevZ) => prevZ - 5);
        break;
      case "Down":
        setZ((prevZ) => prevZ + 5);
        break;

      // Add more cases as needed
      default:
        // Do nothing if no action is set
        break;
    }
  };

  useEffect(() => {
    // Clear previous interval
    if (actionInterval.current) {
      clearInterval(actionInterval.current);
    }

    if (action) {
      // Set a new interval to continuously update position based on the action
      actionInterval.current = setInterval(updatePosition, 20); // Update every 100 ms
    }
  }, [action]);

  switch (action) {
    case "Move forward":
      image = forward;

      break;
    case "Move backward":
      image = backward;
      break;
    case "Up":
      image = forward;
      break;
    case "Down":
      image = backward;
      break;

    case "Move left":
      image = left;
      break;
    case "Move right":
      image = right;
      break;
    default:
      image = relaxed;
      break;
  }

  return (
    <div className="w-[300px] h-[300px] overflow-hidden flex justify-center items-center pb-10">
      <img
        src={image}
        alt="Joystick"
        className="w-[800px] h-[600px] object-cover object-center"
      />
    </div>
  );
};

export default Joystick;
