

import { Box, Button, Typography, Stack, CircularProgress } from '@mui/material';
import React from 'react'
import useFetchV2 from '../../../components/PartsApi';
import { deleteContent, deleteDocument, deleteFile, deleteFileFromDB, getContent, getDocument, getFile, getFileContent, getFileMimeType, postFile, uploadContent, uploadDocument } from '../../../components/FilesApi';
import { useFetchBlob } from '../../../components/FilesApi';
import { FileUploader } from "react-drag-drop-files";
import { pdfjs } from 'react-pdf';



import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'


import pdf from './images/pdf.png';
import image from './images/image.png';

import { toast } from 'react-toastify';

import mtl from  './images/mtl.png';
import obj from  './images/obj2.png';
import xml from './images/xml.png'
import unknown from './images/unknown.png';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export async function uploadfile(file, filename) {
  try {
    const data = new FormData() // eslint-disable-line no-undef
    data.append('file', file, filename)

    return window
      .fetch(
        `https://noswingcc.net/noswingcc/api/files`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token') || ''
        },
        body: data
      }
      )
      .then(res => res.json())
    
  } catch (error) {
    console.error('An error occurred while trying to upload file:', error);
    return false;
  }
}

// async function fetchModelFiles() {
//   let modelFiles = [];

//   if (!dataIsLoading) {
//     const entries = await Promise.all(data.map(async item => {
//       const fileData = await getFile(`${id}_${item.fileName}`);
//       return [item.fileName, fileData];
//     }));
    

//     modelFiles = Object.fromEntries(entries);
//   }
  
//   return modelFiles;
// }

function createDisplayFiles(files, filterCategories = null) {
  const categoryOrder = ['pdf', 'image', '3D', 'xml'];
  let categorizedFiles = { pdf: [], image: [], '3D': [], xml: [], others: [] };

  // Sort files into categories
  files.forEach(file => {
      const { folderName } = file;
      if (categoryOrder.includes(folderName)) {
          categorizedFiles[folderName].push(file);
      } else {
          categorizedFiles.others.push(file);
      }
  });

  // Combine files in order, filtered if necessary
  let displayFiles = [];
  categoryOrder.forEach(category => {
      if (!filterCategories || filterCategories.includes(category)) {
          displayFiles = [...displayFiles, ...categorizedFiles[category]];
      }
  });

  // Add 'others' if not filtering or if 'others' is explicitly included
  if (!filterCategories || filterCategories.includes('others')) {
      displayFiles = [...displayFiles, ...categorizedFiles.others];
  }

  return displayFiles;
}


const Documents = ({id}) => {
    const { data: files, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&page=0&size=100`);
    
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null); 
  const [tags, setTags] = useState([]);
  //console.log(files);
  const [isXML, setIsXML] = useState(false);  
  console.log(file);
  const [filter, setFilter] = useState(["pdf", "image", "3D" , "xml", "all"]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [fileSelectedForDownload, setFileSelectedForDownload] = useState();
  const [hover, setHover] = useState(false);
  const [fileAsBase64, setFileAsBase64] = useState(null);

  const handleFileSelect = (e) => {
    if (!e.target.files) {
      return;
    }
    if (e.target.files[0].size > 100000000) {
      toast.error("File is too large > 100MB");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function() {
      setFile(e.target.files[0]);
      setFileAsBase64(reader.result.split(",")[1]);
      setFileName(e.target.files[0].name);

      const type = e.target.files[0].name.split(".");
      if (type[1] == "xml") {
        setIsXML(true);
      }
      return reader.result;
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
      return;
    };
  };

  React.useEffect(() => {
    if(files?.length > 0){
      let filterCategories = filter;
      setFilteredFiles(createDisplayFiles(files, filterCategories));
    }
  }, [files,filter])

  const downloadFile = async (filename, crane) => {
    toast.info("Downloading...")
    if (filename) {
      try {
        console.log(files);
        const cranedoc = files.find(
          (f) => f.fileName === filename && f.crane.id === crane
        );
        // const cranedoc2 = await getFile(cranedoc.id);
        // console.log(cranedoc2)
        if (cranedoc.document === null) {
          toast.error("File has no document");
          console.log(cranedoc);
          return;
        }

        const blob = await getFileContent(cranedoc.document.id);
        console.log("BLOB: _-------- : ", blob);
        if (!blob) {
          console.error("No data received for download");
          toast.error("Could not find the file to download.");
          return;
        }

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Ensure this includes the file extension
        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(url);
        a.remove();
      } catch (error) {
        console.error("Error downloading file:", error);
        toast.error("Error, could not download the file.");
      }
      console.log(filename);
    }
  };

  const deleteSelectedFile = async (file, crane) => {
    console.log(files);
    console.log(file, crane);
    console.log(files.find((f) => f.fileName === file && f.crane.id === crane));
    toast.info("Deleting file...");
   
    try {
      const cranedoc = files.find(
        (f) => f.fileName === file && f.crane.id === crane
      );
      if (cranedoc.document === null) {
        toast.error("File has no document");
        return ;
      } else {
        console.log("cranedoc id: ", cranedoc.id);

        const document = await getDocument(cranedoc.document.id);
        if(document.title.includes("MEX_")){
          const deleteCranedoc = await deleteFile(cranedoc.id);
          toast.info("This file is linked to example file.");
          refetchAllData();
          return;
        }
        const deleteCranedoc = await deleteFile(cranedoc.id);
        console.log("Deletecranedoc: ", deleteCranedoc);
        const deleteDocumentData = await deleteDocument(document.id);
        console.log("Deletedocument: ", deleteDocumentData);
        const content = await getContent(document.content.id);
        if (content) {
          toast.info("Found content data...");
        }

       
        const deleteContentData = await deleteContent(content.id);
        console.log("Deletecontent: ", deleteContentData);
      }

      

      // console.log(cranedoc, document);

      toast.success("Successfully deleted the file");
      refetchAllData();
    } catch (error) {
      toast.error("Something went wrong with the deletion...");
      console.log(error);
    }

    // const deleteFile = await deleteFileFromDB(file);

    // if(deleteFile){

    //   const id = files.filter(item => item.fileName == file && item.crane.id == crane)[0].id;
    //   const deleted = await deleteFile(id);
    //   if(deleted){
    //   toast.success("Successfully deleted the file");
    //   refetchAllData();

    //   }
    //   else {
    //     toast.error("Something went wrong with the deletion...");
    //   }
    // }
    // else{
    //   toast.error("Something went wrong with the deletion...");
    // }
  };
  

  const extractTags = () => {
      
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const xmlString = e.target.result;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "text/xml");
            const uaVariables = xmlDoc.getElementsByTagName("UAVariable");

            let extractedTags = [];
            for (let variable of uaVariables) {
                const displayNameNode = variable.getElementsByTagName("DisplayName")[0];
                const dataTypeNode = variable.getAttribute("DataType");

                if (displayNameNode && dataTypeNode) {
                    const displayName = displayNameNode.textContent;
                    const dataType = dataTypeNode;

                    // Filter out specific tags
                    if (!["Icon", "InterfaceUuid", "InterfaceVersion"].includes(displayName)) {
                        extractedTags.push({
                            name: displayName,
                            type: dataType
                        });
                    }
                }
            }
            setTags(extractedTags);
        };
        reader.readAsText(file);
    }
};
  
console.log(files);
    

const uploadFile = async () => {
  if (!file) {
    return;
  }

  const getFileType = () => {
    const type = fileName.split(".");
    console.log(type);
    switch (type[1]) {
      case "pdf":
        return "pdf";
      case "png":
        return "image";
      case "img":
        return "image";
      case "jpg":
        return "image";
      case "obj":
        return "3D";
      case "mtl":
        return "3D";
      case "xml":
        return "xml";
      default:
        return "Unknown";
    }
  };

  let body = {
    id: "",
    fileName: fileName,
    folderName: getFileType(),
    crane: {
      id: id,
    },
  };

  const fileMimeType = getFileMimeType(fileName);
  // const dataUpload = await postFileDataToDatabase(`${id}_${itemName}.${fileType}`, data);
  try {
    toast.info("Uploading file...");
    // const db = await uploadfile(file, `4_${body.fileName}`);
    let upload;
    await uploadContent({
      dataContentType: fileMimeType,
      data: fileAsBase64,
    })
      .then((content) =>
        uploadDocument(
          //Adding content to document
          {
            title: file.name,
            documentSize: file.size,
            mimeType: fileMimeType,
            content: content,
          }
        )
      )
      .then((document) =>
        postFile(
          //Adding document to cranedoc body
          {
            ...body,
            document: document,
          }
        )
      )
      .catch((error) => {
        toast.error(error);
        // If any of the Content - Document - Cranedoc requests fail post cranedoc without document
        // upload = postFile(body);
      });

    toast.success("File uploaded successfully!");
  } catch (error) {
    toast.error("Something went wrong with the upload...");
  }

  //console.log(file , " ",  db);
  setFile();
  setFileName();
  refetchAllData();
};

    const handleChange = (file) => {
      setFile(file);
      
    };

    function GetFileImg ({fileName, fileType, crane}) {
      const type = fileName.split('.');
      let theImage = "";

      switch (fileType) {
        case "pdf":
          theImage = pdf;
          break ;
        case "image":
          theImage = image;
          break ;
        case "3D":

          theImage = type[1] === "obj" ? obj : mtl;
          break ;

        case "xml":
          theImage = xml;
          break ;
        default:
          theImage = unknown;
          break ;
      }

      return (
        <Box
        width={"100px"}
        sx={{ cursor: "pointer", position: "relative" }}
        onMouseEnter={() => setHover(`${crane}_${fileName}`)}
        onMouseLeave={() => setHover()}
      >
        {hover == `${crane}_${fileName}` && (
          <CloudDownloadOutlinedIcon
            onClick={() => downloadFile(fileName, crane)}
            sx={{ position: "absolute", top: -30, left: 15 }}
            fontSize="large"
            className="hover:text-gray-400"
          />
        )}
        {hover == `${crane}_${fileName}` && (
          <DeleteOutlineOutlinedIcon
            onClick={() => deleteSelectedFile(fileName, crane)}
            sx={{ position: "absolute", top: -30, right: 15 }}
            fontSize="large"
            className="hover:text-gray-400"
          />
        )}
        <img src={theImage} alt="fileTypeImage" width={"100px"} className={" " + (fileType === "3D" ? "px-1.5 " : "")}/>
        <p className='text-sm'>
          {fileName}
        </p>
      </Box>
      )
    }

    function Filter() {
      const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    
      return (
        <div className="w-fullflex justify-start">
          <div>
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
              <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
    
                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setMobileFiltersOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
    
                      {/* Filters */}
                      <form className="mt-4">
                        {filters.map((section) => (
                          <Disclosure as="div" key={section.name} className="border-t border-gray-200 pb-4 pt-4">
                            {({ open }) => (
                              <fieldset>
                                <legend className="w-full px-2">
                                  <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                    <span className="text-sm font-medium text-gray-900">{section.name}</span>
                                    <span className="ml-6 flex h-7 items-center">
                                      <ChevronDownIcon
                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Disclosure.Button>
                                </legend>
                                <Disclosure.Panel className="px-4 pb-2 pt-4">
                                  <div className="space-y-6">
                                    {section.options.map((option, optionIdx) => (
                                      <div key={option.value} className="flex items-center">
                                        <input
                                          id={`${section.id}-${optionIdx}-mobile`}
                                          name={`${section.id}[]`}
                                          defaultValue={option.value}
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={`${section.id}-${optionIdx}-mobile`}
                                          className="ml-3 text-sm text-gray-500"
                                        >
                                          {option.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </fieldset>
                            )}
                          </Disclosure>
                        ))}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
    
            <main className="max-w-6xl px-4 py-16 sm:px-2 sm:py-5 w-full lg:px-2 ">
              {/* <div className="border-b border-gray-200 pb-10">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">New Arrivals</h1>
                <p className="mt-4 text-base text-gray-500">
                  Checkout out the latest release of Basic Tees, new and improved with four openings!
                </p>
              </div> */}
    
              <div className="pt-10 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 ">
                <aside>
                  <h2 className="sr-only">Filters</h2>
    
                  <button
                    type="button"
                    className="inline-flex items-center lg:hidden"
                    onClick={() => setMobileFiltersOpen(true)}
                  >
                    <span className="text-sm font-medium text-gray-700">Filters</span>
                    <PlusIcon className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  </button>
    
                  <div className="hidden lg:block">
                    <form className="space-y-10 divide-y divide-gray-200">
                    {filters.map((section, sectionIdx) => (
                        <div key={section.name} className={sectionIdx === 0 ? null : 'pt-10'}>
                          <fieldset>
                            <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                            <div className="space-y-3 pt-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center cursor-pointer">
                                  <input
                                    id={`${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    
                                    defaultChecked={filter.includes(option.value) ? true : false}
                                    onChange={(e) => setFilter(e.target.checked ? [...filter, option.value] : filter.filter(item => item !== option.value))}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                                  />
                                  <label htmlFor={`${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600 cursor-pointer hover:text-nsorange-500" onClick={() => (option.value === "all" ? setFilter(["pdf" , "image" , "3D" , "xml", "all"]) : setFilter(option.value))}>
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                        </div>
                      ))}
                    </form>
                    <div className='mt-10 w-32'>
                    
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Stack>
                    <Button
                              variant="contained"
                              component="label"
                            >
                              Select File
                              <input
                                type="file"
                                hidden
                                onChange={handleFileSelect} 
                              />
                            </Button>
                            
                          </Stack>
                          {file && <Button
                            variant="contained"
                            component="label"
                            onClick={uploadFile}
                          >
                            Upload file
                          </Button>}
                        </Box>
                        <Box>{file ? file.name : ""}</Box>
                    </div>
                  </div>
                </aside>
    
                {/* Product grid */}
                <div className="w-full mt-6 grid grid-cols-7 col-span-3  lg:mt-0 gap-x-20 gap-y-6 -ml-32 p-6">
                 
                {filteredFiles?.map(item => <div><GetFileImg key={item.id} fileName={item.fileName} fileType={item.folderName} crane={item.crane.id}/></div>)}
          
                </div>
              </div>
            </main>
          </div>
        </div>
      )
    }

  return (
    // <Box
    //   sx={{
    //     bgcolor: "#fff",
    //     mt: 3,
    //     borderRadius: '10px',
    //     width: '100%',
        
    //     boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    //     p: 2,
    //     mb: 2,
    //   }}
    // >
    //   <Typography variant='h3'>Stored files: </Typography>
    //   {dataIsLoading ? <Box display={"flex"} justifyContent={"center"}><CircularProgress/></Box> :
      
    //   <Stack direction={"column"} spacing={5} mt={2}>
    //     {pdfs?.length > 0 && <Box><Typography>PDF's</Typography><Stack direction={"row"} border={1} bgcolor={"white"} borderRadius={"5px"} p={2} spacing={3} overflow={"auto"}>{pdfs.map(item => <GetFileImg key={item.id} fileName={item.fileName} fileType={item.folderName}/>)}</Stack></Box>}
    //     {images?.length > 0 && <Box><Typography>Images</Typography><Stack direction={"row"} border={1} bgcolor={"white"} borderRadius={"5px"} p={2} spacing={3} overflow={"auto"}>{images.map(item => <GetFileImg key={item.id} fileName={item.fileName} fileType={item.folderName}/>)}</Stack></Box>}
    //     {xmls?.length > 0 && <Box><Typography>XML's</Typography><Stack direction={"row"} border={1} bgcolor={"white"} borderRadius={"5px"} p={2} spacing={3} overflow={"auto"}>{xmls.map(item => <GetFileImg key={item.id} fileName={item.fileName} fileType={item.folderName}/>)}</Stack></Box>}
    //     {models?.length > 0 && <Box><Typography>3D models</Typography><Stack direction={"row"} border={1} bgcolor={"white"} borderRadius={"5px"} p={2} spacing={3} overflow={"auto"}>{models.map(item => <GetFileImg key={item.id} fileName={item.fileName} fileType={item.folderName}/>)}</Stack></Box>}
    //     {others?.length > 0 && <Box><Typography>Other files</Typography><Stack direction={"row"} border={1} bgcolor={"white"} borderRadius={"5px"} p={2} spacing={3} overflow={"auto"}>{others.map(item => <GetFileImg key={item.id} fileName={item.fileName} fileType={item.folderName}/>)}</Stack></Box>}
    //   </Stack>
      
    //   }
    //       <Box mt={10} >
    //       <Typography variant='h4'>Upload a file</Typography>
    //       {/* <FileUploader
    //           multiple={false}
    //           handleChange={handleChange}
    //           name="file"
    //           hoverTitle="Drop here"
    //           types={["JPEG", "PNG", "GIF"]}
    //         />
    //         <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p> */}
    //       <Box mt={2}>
    //         <Box display={"flex"} flexDirection={"row"} gap={2}>
    //           <Stack>
    //             <Button
    //               variant="contained"
    //               component="label"
    //             >
    //               Select File
    //               <input
    //                 type="file"
    //                 hidden
    //                 onChange={handleFileSelect} 
    //               />
    //             </Button>
                
    //           </Stack>
    //           <Button
    //             variant="contained"
    //             component="label"
    //             onClick={uploadFile}
    //           >
    //             Upload file
    //           </Button>
    //         </Box>
    //         <Box>{file ? file.name : ""}</Box>
    //     </Box>
    //   </Box>
    //       {/* {data2 &&<Box mt={4}><MyApp data2={data2}/></Box>}
    //       {data2 && <App data2={data2}/>} */}
    //       <Box mt={3} display={isXML ? "flex" : "none"} flexDirection={"column"} >
    //         {tags.length == 0 && <Typography>XML-file detected, do you want to extract OPC data tags?</Typography>}
    //         <Button onClick={extractTags} sx={{width: "100px"}} variant='contained'>Extract</Button>
    //         <Stack mt={1}>
    //           {tags.map((tag, index) => (
    //                     <li key={index}>{tag.name} (Type: {tag.type})</li>
    //           ))}
    //         </Stack>
    //       </Box>
    //       {/* <Box mt={10}>
    //         <XMLParserComponent/>
    //       </Box> */}
    // </Box>
    <div>
      <Filter/>
    </div>
  )
}

export default Documents;




function XMLParserComponent() {
  const [tags, setTags] = useState([]);

  const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
              const xmlString = e.target.result;
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(xmlString, "text/xml");
              const uaVariables = xmlDoc.getElementsByTagName("UAVariable");

              let extractedTags = [];
              for (let variable of uaVariables) {
                  const displayNameNode = variable.getElementsByTagName("DisplayName")[0];
                  const dataTypeNode = variable.getAttribute("DataType");

                  if (displayNameNode && dataTypeNode) {
                      const displayName = displayNameNode.textContent;
                      const dataType = dataTypeNode;

                      // Filter out specific tags
                      if (!["Icon", "InterfaceUuid", "InterfaceVersion"].includes(displayName)) {
                          extractedTags.push({
                              name: displayName,
                              type: dataType
                          });
                      }
                  }
              }
              setTags(extractedTags);
          };
          reader.readAsText(file);
      }
  };

  return (
      <div>
          <input type="file" onChange={handleFileChange} accept=".xml" />
          <div>
              <h3>Extracted Tags:</h3>
              <ul>
                  {tags.map((tag, index) => (
                      <li key={index}>{tag.name} (Type: {tag.type})</li>
                  ))}
              </ul>
          </div>
      </div>
  );
}


const filters = [
  {
    id: 'type',
    name: 'File Type',
    options: [
      { value: 'all', label: 'All Types' },
      { value: 'pdf', label: 'PDF' },
      { value: 'image', label: 'Image' },
      { value: 'xml', label: 'XML' },
      { value: '3D', label: '3D-Model' },
      
    ],
  },
  
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
