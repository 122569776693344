import React, { useState, useEffect, useRef } from "react";

import { Canvas, useLoader, useThree, useFrame } from "@react-three/fiber";
import { OBJLoader, MTLLoader } from "three-stdlib";
import {
  CameraControls,
  OrbitControls,
  PerspectiveCamera,
  Text,
} from "@react-three/drei";
import { Box, Slider, Typography, Button, Grid, Stack } from "@mui/material";
import * as THREE from "three";
import { Vector3 } from "three";
import { getCranePos } from "../../../../components/OrderApi";
import useFetchV2 from "../../../../components/PartsApi";
import { useNavigate } from "react-router-dom";

function LineBetweenTwoPoints({ point1, point2 }) {
  const points = [point1, point2].map((p) => new Vector3(...p));
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  return (
    <line geometry={geometry}>
      <lineBasicMaterial color={"black"} />
    </line>
  );
}

function PulsatingTarget({ radius = 2.5, x, y, speed = 2 }) {
  const meshRef1 = useRef();
  const meshRef2 = useRef();
  const meshRef3 = useRef();

  useFrame((state) => {
    // Create the pulsating effect by modifying the scale
    const scale = 1 + Math.sin(state.clock.elapsedTime * speed) * 0.1;
    meshRef1.current.scale.set(scale, scale, scale);
    meshRef2.current.scale.set(scale, scale, scale);
    meshRef3.current.scale.set(scale, scale, scale);
  });

  return (
    <>
      {/* <Text
            position={[y, x + 10, -73]} // Position of the text
            fontSize={5} // Font size
            color="black" // Font color
          >
            Target
      </Text> */}
      <group position={[y, x, -73]}>
        <mesh ref={meshRef1}>
          <ringGeometry args={[0.4, 1, 32]} />
          <meshBasicMaterial color={"red"} />
        </mesh>
        <mesh ref={meshRef2}>
          <ringGeometry args={[2.3, 3, 42]} />
          <meshBasicMaterial color={"red"} />
        </mesh>
        <mesh ref={meshRef3}>
          <ringGeometry args={[4.5, 5.5, 52]} />
          <meshBasicMaterial color={"red"} />
        </mesh>
      </group>
    </>
  );
}

const Crane = React.memo(function Crane({
  debug,
  setBeemCenter,
  obj,
  values,
  objUrl,
  mtlUrl,
  x,
}) {
  // console.log("crane is rendered");

  let object;

  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }
  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());

  if (setBeemCenter != undefined) {
    setBeemCenter([center.x, center.z, center.y]);
  }

  return (
    <group>
      {" "}
      <mesh position={[values.Beem_x, values.Beem_y + x, values.Beem_z]}>
        <primitive object={object} />
        {debug && <boxHelper args={[object, 0xff0000]} />}
      </mesh>
      {debug && (
        <mesh position={[-center.x, center.z, center.y]}>
          <sphereGeometry args={[10, 32, 32]} />
          <meshBasicMaterial color={"blue"} />
        </mesh>
      )}
    </group>
  );
});

const CraneLift = React.memo(function CraneLift({
  debug,
  setTrolleyCenter,
  obj,
  values,
  objUrl,
  mtlUrl,
  x,
  y,
  cargoWeight,
}) {
  // console.log("cranelift rendered"); 
  let object;

  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }
  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());

  if (setTrolleyCenter != undefined) {
    setTrolleyCenter([center.x, center.z, center.y]);
  }

  return (
    <>
      <mesh
        position={[
          values.Trolley_x + y,
          values.Trolley_y + x,
          values.Trolley_z,
        ]}
      >
        <primitive object={object} />
        {debug && <boxHelper args={[object, 0xff0000]} />}
      </mesh>
      {debug && (
        <mesh position={[-center.x, center.z, center.y]}>
          <sphereGeometry args={[10, 32, 32]} />
          <meshBasicMaterial color={"green"} />
        </mesh>
      )}

      {/* {cargoWeight > 0 && 
      <group position={[y - 1, x - 3, -50]}>
              <mesh position={[0, 0, 0.5]}>
                <boxGeometry args={[25, 15, 0.7]} />
                <meshBasicMaterial color={'yellow'} />
              </mesh>
              <mesh position={[0, 0, 1]}>
                <boxGeometry args={[25, 15, 0.2]} />
                <meshBasicMaterial color={'black'} />
              </mesh>
      </group>
    } */}
    </>
  );
});

const ZoneBox = React.memo(function ZoneBox({
  Zone,
  tempZone,
  createBlockedZone,
}) {
  let data = Zone;

  if (createBlockedZone) {
    data = tempZone;
    data[6] = true;
  }

  // console.log(tempZone);
  // console.log("Zone: ", data);
  let [xpos, ypos, zpos, width, length, height, active] = data;

  if (!active) {
    // console.log("Zone hidden and unactive");
    return <></>;
  }

  //console.log(xpos);
  const x = translateCordinates(xpos);
  const y = translateWagonCordinates(ypos);
  const z = translateWireCordinates(zpos, 0, 120, 11, -108);

  // console.log(z + height); 
 
  return (
    <>
      <group position={[y, x, z + height / 2]}>
        <mesh position={[0, 0, 0]}>
          <boxGeometry args={[width, length, height]} />
          <meshBasicMaterial color={"red"} transparent={true} opacity={0.6} />
        </mesh>
        <Text
          position={[0, 0, height / 2 + 1]}
          fontSize={5} // Font size
          color="white" // Font color
          maxWidth={10}
        >
          Blocked Zone
        </Text>
      </group>
    </>
  );
});
function CylinderBetweenPoints({ point1, point2 }) {
  const [x1, y1, z1] = point1;
  const [x2, y2, z2] = point2;

  // Calculate the position of the cylinder (midpoint between two points)
  const position = [(x1 + x2) / 2, (y1 + y2) / 2, (z1 + z2) / 2];

  // Calculate the height of the cylinder (distance between two points)
  const height = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2 + (z2 - z1) ** 2);

  // Calculate the rotation of the cylinder to align with the line defined by the two points
  const direction = new THREE.Vector3(x2 - x1, y2 - y1, z2 - z1);
  const up = new THREE.Vector3(0, 1, 0);
  const rotation = new THREE.Quaternion().setFromUnitVectors(
    up,
    direction.normalize()
  );

  return (
    <mesh position={position} quaternion={rotation}>
      <cylinderGeometry args={[0.15, 0.15, height, 32]} />
      <meshBasicMaterial color={"black"} />
    </mesh>
  );
}

const Wire = React.memo(function Wire({
  realCenter,
  setCenterOffset = null,
  centerOffset,
  debug,
  setHookCenter,
  setWireCenter,
  files,
  obj,
  values,
  objUrl,
  mtlUrl,
  x,
  y,
  z,
  cargoWeight,
  normal,
  constant,
  cargoProduct,
  setTarget,
}) {
  //console.log("Wire rendered ");
  const groupRef = useRef();
  // const Long = () => Product(files["Long.mtl"], files["Long.obj"], "Long");
  // const Short = () => Product(files["Short.mtl"], files["Short.obj"], "Short");
  const [center2, setCenter2] = useState([0, 0, 0]);

  useEffect(() => {
    if (groupRef.current) {
      const boundingBox = new THREE.Box3();
      boundingBox.setFromObject(groupRef.current);
      const center = boundingBox.getCenter(new THREE.Vector3());
      // console.log("Center of group:", center); 
      setCenter2(center);
    }
  }, [groupRef, values]);

  let object;

  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }

  

  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());

  //console.log(center);
  if (setHookCenter != undefined) {
    setHookCenter([center.x, center.z, center.y]);
  }

  setWireCenter([center2?.x, center2?.z, center2?.y]);

  setTarget([-center.x, center.z, center.y]);
  if (setCenterOffset != null) {
    setCenterOffset((prev) => [center.z - x, (-center.x - y) * -1, prev[2]]);
  }

  return (
    <>
      <mesh
        position={[y + values.Hook_x, x + values.Hook_y, z + values.Hook_z]}
      >
        <primitive object={object} />
        {debug && <boxHelper args={[object, 0xff0000]} />}
      </mesh>
      {debug && (
        <group>
          <mesh position={[-center.x, center.z, center.y]}>
            <sphereGeometry args={[10, 32, 32]} />
            <meshBasicMaterial color={"red"} />
          </mesh>
          <mesh position={[-center.x, center.z, centerOffset[2]]}>
            <sphereGeometry args={[2, 32, 32]} />
            <meshBasicMaterial color={"red"} />
          </mesh>
          <mesh position={[realCenter[1], realCenter[0], centerOffset[2]]}>
            <sphereGeometry args={[2, 32, 32]} />
            <meshBasicMaterial color={"blue"} />
          </mesh>
          <mesh position={[-center2?.x, center2?.z, center2?.y]}>
            <sphereGeometry args={[5, 32, 32]} />
            <meshBasicMaterial color={"yellow"} />
          </mesh>
        </group>
      )}

      <group ref={groupRef}>
        <CylinderBetweenPoints
          point1={[
            y + 4.9 + values.Rope_x,
            x - 4.3 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.Rope_length,
          ]}
          point2={[
            y + 4.9 + values.Rope_x,
            x - 4.3 + 2 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
        <CylinderBetweenPoints
          point1={[
            y + 4.9 + values.Rope_x,
            x - 1.2 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.Rope_length,
          ]}
          point2={[
            y + 4.9 + values.Rope_x,
            x - 1.2 + 8 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
        <CylinderBetweenPoints
          point1={[
            y + 2.2 + values.Rope_x,
            x - 4.3 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.Rope_length,
          ]}
          point2={[
            y + 2.1 + values.Rope_x,
            x - 4.3 + 2 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
        <CylinderBetweenPoints
          point1={[
            y + 2.2 + values.Rope_x,
            x - 1.2 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.Rope_length,
          ]}
          point2={[
            y + 2.1 + values.Rope_x,
            x - 1.2 + 8 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
      </group>

      <group position={[y + 14, x + 300, z + 171]}>
        {/* <mesh position={[0, 0, dimensions2[2]]}>
                <boxGeometry args={dimensions2} />
                <meshBasicMaterial color={'black'} />
              </mesh>
              <mesh position={[0, 0, dimensions[2]]}>
                <boxGeometry args={dimensions} />
                <meshBasicMaterial color={color} />
              </mesh> */}
        {cargoProduct == 2 && (
          <group position={[89, 26, 0]}>
            {/* <Short /> */}
          </group>
        )}
        {cargoProduct == 1 && (
          <group position={[-29, 0, 0]}>
            {/* <Long /> */}
          </group>
        )}
        {/* {cargoProduct == "2" && <Long/>} */}
      </group>
    </>
  );
});

const cameraPositions = [
  { position: [-2, 210, 0] }, // top view
  { position: [85, 133, 82] }, // front view
  { position: [-76, 133, 0] }, // front view
  { position: [36, 0, -135] }, // front view

  // Add more custom views as needed
];

const CameraController = React.memo(function CameraController({
  position,
  lookAt,
}) {
  //console.log("Rendering camera");
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(...position);

    camera.updateProjectionMatrix();
  }, [position, camera]);

  return null;
});

const Square = React.memo(function Square({
  x = 0,
  y = 0,
  z = 0,
  obj,
  objUrl,
  mtlUrl,
  setMarker,
  setTempMarker,
  setNewMarkerPosition,
  tempMarker,
}) {
  let object;
  // console.log("Rendering building"); 
  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }

  // const obj = useLoader(OBJLoader, objUrl, loader => loader.setMaterials(mtl));

  const handlePointerDown = (event) => {
    console.log(
      "Clicked on",
      event.point.x,
      " ",
      " ",
      event.point.z,
      "   ",
      event.point.y,
      " Translated: ",
      reverseTranslateWagonCordinates(-event.point.x),
      ",",
      reverseTranslateCordinates(event.point.z)
    );

    if (setMarker) {
      setTempMarker([
        ...tempMarker,
        [-event.point.x, event.point.z, event.point.y],
      ]);
      setNewMarkerPosition([
        reverseTranslateCordinates(event.point.z),
        reverseTranslateWagonCordinates(-event.point.x),
        ReverseTranslateWireCordinates(event.point.y, 0, 120, 11, -108),
      ]);
      //console.log(markers);
    }
  };
  return (
    <mesh position={[x, y, z]} onClick={handlePointerDown}>
      <primitive object={object} />
    </mesh>
  );
});

const RenderProducts = React.memo(function RenderProducts({ files, products }) {
  // console.log("Render products: ", products);
  let models = [];
  products?.map((item) => {
    //console.log(item.id);
    if (item.id == 12) {
      models = Product(files["Long.mtl"], files["Long.obj"], item.name);
    }
    if (item.id == 13) {
      Product(files["Short.mtl"], files["Short.obj"], item.name);
    }
  });

  function Product(mtlUrl, objUrl, name) {
    //console.log("Rendering 3D model for product: ", name);
    if (mtlUrl != undefined && mtlUrl != "") {
    } else {
      return <></>;
    }
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    const obj = objLoader.parse(rawObjText);
    // const obj = useLoader(OBJLoader, objUrl, loader => loader.setMaterials(mtl));

    // console.log(models); 
    return (
      <mesh position={[0, 0, 0]}>
        <primitive object={obj} />
      </mesh>
    );
  }

  return (
    <group position={[12, 140, 70]}>
      {Product(files["Short.mtl"], files["Short.obj"], "Short")}
      {Product(files["Long.mtl"], files["Long.obj"], "Long")}
    </group>
  );
});

const Scene2 = React.memo(function Scene2({
  debug,
  setCenterArray,
  lock,
  objFiles,
  doesBeem1HaveTwoTrolleys,
  doesBeem2HaveTwoTrolleys,
  twobeems,
  lookAt,
  opcdata,
  camera,
  confdata,
  x,
  y,
  z,
  configured,
  createBlockedZone,
  tempZone,
  Zone,
  obj,
  products,
  values,
  view,
  setMarker,
  setNewMarkerPosition,
  markerdata,
  selectedMarker,
  setSelectedMarker,
  setTempMarker,
  tempMarker,
  cargoWeight,
  crane,
  cargoProduct,
}) {
  const navigate = useNavigate();
  const [target, setTarget] = useState([0, 0, 0]);
  const [position, setPosition] = useState([-2, 210, 0]);
  // [-2, 210, 0]
  const [normal, setNormal] = useState({ x: 0, y: -1, z: 0 });
  const [constant, setConstant] = useState(-10);
  const files = [{}];
  const [trolleyCenter, setTrolleyCenter] = useState([0, 0, 0]);
  const [beemCenter, setBeemCenter] = useState([0, 0, 0]);
  const [hookCenter, setHookCenter] = useState([0, 0, 0]);
  const [wireCenter, setWireCenter] = useState([0, 0, 0]);
  const [centerOffset, setCenterOffset] = useState([-14.17, 60.46, -158]);
  let realCenter = [0, 0, 0];

  useEffect(() => {
    setCenterArray([beemCenter, trolleyCenter, hookCenter, wireCenter]);
  }, [trolleyCenter, beemCenter, hookCenter]);

  //console.log("Scene ", files);
  if (configured) {
    //console.log(opcdata);
    // console.log(
    //   "!!!!!!!!!!!!!!!!!!!!!!!",
    //   opcdata.z1poslimitmin,
    //   opcdata.z1poslimitmax,
    //   values.Cranezposmin,
    //   values.Cranezposmax
    // );
    //z = translateWireCordinates(z,opcdata.z1posmin, opcdata.z1posmax, values.Cranezposmin, values.Cranezposmax);
    x = translateCordinates(
      x,
      values.opcxmin,
      values.opcxmax,
      values.Cranexposmin,
      values.Cranexposmax
    );
    //console.log(x);
    y = translateCordinates(
      y,
      values.opcymin,
      values.opcymax,
      values.Craneyposmin,
      values.Craneyposmax
    );
    z = translateWireCordinates(
      z,
      values.opczmin,
      values.opczmax,
      values.Cranezposmin,
      values.Cranezposmax
    );
    realCenter = [x + 14.17, y - 160, z];
  }
  // console.log("Center: ", centerOffset);

  //console.log("\n\nRendering Scene component");

  const handleNormalChange = (newNormal) => {
    setNormal(newNormal);
    // Update the plane in your Three.js scene here with the new normal
  };

  const handleConstantChange = (newConstant) => {
    setConstant(newConstant);
    // Update the plane in your Three.js scene here with the new constant
  };
  //console.log(markerdata);

  const handleSliderChange = (index) => (event, newValue) => {
    setPosition((prev) => {
      const newPosition = [...prev];
      newPosition[index] = newValue;
      return newPosition;
    });
  };

  useEffect(() => {
    setPosition([-2, 210, 0]);

    return () => {};
  }, [view]);

  // console.log(confdata[0]);

  //142 //7192
  return (
    <Box
      border={4}
      sx={{
        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
        cursor: "pointer",
      }}
      height="100%"
      boxShadow={4}
    >
      {true ? (
        <Canvas>
          <PerspectiveCamera makeDefault position={lookAt} />

          <CameraController position={position} lookAt={lookAt} />
          <OrbitControls
            enableZoom={!lock}
            enablePan={!lock}
            enableRotate={!lock}
          />
          {/* <ambientLight/> */}
          {/* <axesHelper args={[1000]} /> */}

          <group rotation={[Math.PI / 2, Math.PI, 0]}>
            <pointLight position={[10, -40, 90]} intensity={1} />
            <Square
              debug={debug}
              x={values?.Building_x}
              y={values?.Building_y}
              z={values?.Building_z}
              obj={objFiles["Building"]}
              objUrl={files["Building.obj"]}
              mtlUrl={files["Building.mtl"]}
              setTempMarker={setTempMarker}
              tempMarker={tempMarker}
              setNewMarkerPosition={setNewMarkerPosition}
              setMarker={setMarker}
            />
            <CraneLift
              debug={debug}
              setTrolleyCenter={setTrolleyCenter}
              obj={objFiles["Trolley"]}
              values={values}
              objUrl={files["Trolley.obj"]}
              mtlUrl={files["Trolley.mtl"]}
              x={x}
              y={y}
              cargoWeight={cargoWeight}
            />

            <Wire
              realCenter={realCenter}
              setCenterOffset={setCenterOffset}
              centerOffset={centerOffset}
              debug={debug}
              setWireCenter={setWireCenter}
              setHookCenter={setHookCenter}
              setTarget={setTarget}
              files={files}
              obj={objFiles["Hook"]}
              values={values}
              objUrl={files["Hook.obj"]}
              mtlUrl={files["Hook.mtl"]}
              x={x}
              y={y - 4}
              z={z}
              cargoWeight={cargoWeight}
              normal={normal}
              constant={constant}
              cargoProduct={cargoProduct}
            />
            <Crane
              debug={debug}
              setBeemCenter={setBeemCenter}
              obj={objFiles["Beem"]}
              values={values}
              objUrl={files["Beem.obj"]}
              mtlUrl={files["Beem.mtl"]}
              x={x}
            />
            {doesBeem1HaveTwoTrolleys && (
              <group position={[values.Trolley_spacing, 0, 0]}>
                <CraneLift
                  obj={objFiles["Trolley"]?.clone()}
                  values={values}
                  objUrl={files["Trolley.obj"]}
                  mtlUrl={files["Trolley.mtl"]}
                  x={x}
                  y={y}
                  cargoWeight={cargoWeight}
                />
                <Wire
                  centerOffset={centerOffset}
                  setWireCenter={setWireCenter}
                  setTarget={setTarget}
                  files={files}
                  obj={objFiles["Hook"]?.clone()}
                  values={values}
                  objUrl={files["Hook.obj"]}
                  mtlUrl={files["Hook.mtl"]}
                  x={x}
                  y={y - 4}
                  z={z}
                  cargoWeight={cargoWeight}
                  normal={normal}
                  constant={constant}
                  cargoProduct={cargoProduct}
                />
              </group>
            )}
            {twobeems && (
              <group position={[0, values.Beem_spacing, 0]}>
                <Crane
                  debug={debug}
                  obj={objFiles["Beem"]?.clone()}
                  values={values}
                  objUrl={files["Beem.obj"]}
                  mtlUrl={files["Beem.mtl"]}
                  x={x}
                />
                <CraneLift
                  debug={debug}
                  obj={objFiles["Trolley"]?.clone()}
                  values={values}
                  objUrl={files["Trolley.obj"]}
                  mtlUrl={files["Trolley.mtl"]}
                  x={x}
                  y={y}
                  cargoWeight={cargoWeight}
                />
                <Wire
                  centerOffset={centerOffset}
                  debug={debug}
                  setWireCenter={setWireCenter}
                  setTarget={setTarget}
                  files={files}
                  obj={objFiles["Hook"]?.clone()}
                  values={values}
                  objUrl={files["Hook.obj"]}
                  mtlUrl={files["Hook.mtl"]}
                  x={x}
                  y={y - 4}
                  z={z}
                  cargoWeight={cargoWeight}
                  normal={normal}
                  constant={constant}
                  cargoProduct={cargoProduct}
                />
                {doesBeem2HaveTwoTrolleys && (
                  <group position={[values.Trolley_spacing, 0, 0]}>
                    <CraneLift
                      debug={debug}
                      obj={objFiles["Trolley"]?.clone()}
                      values={values}
                      objUrl={files["Trolley.obj"]}
                      mtlUrl={files["Trolley.mtl"]}
                      x={x}
                      y={y}
                      cargoWeight={cargoWeight}
                    />
                    <Wire
                      centerOffset={centerOffset}
                      debug={debug}
                      setWireCenter={setWireCenter}
                      setTarget={setTarget}
                      files={files}
                      obj={objFiles["Hook"]?.clone()}
                      values={values}
                      objUrl={files["Hook.obj"]}
                      mtlUrl={files["Hook.mtl"]}
                      x={x}
                      y={y - 4}
                      z={z}
                      cargoWeight={cargoWeight}
                      normal={normal}
                      constant={constant}
                      cargoProduct={cargoProduct}
                    />
                  </group>
                )}
              </group>
            )}

            {/* <PulsatingTarget x={-24} y={11}/> */}
            {Object.keys(files).length > 0 && false && (
              <ZoneBox
                Zone={Zone}
                createBlockedZone={createBlockedZone}
                tempZone={tempZone}
              />
            )}
            {setMarker && false && (
              <>
                <CylinderBetweenPoints
                  point1={[
                    translateWagonCordinates(9200),
                    translateCordinates(15000),
                    -70,
                  ]}
                  point2={[
                    translateWagonCordinates(1000),
                    translateCordinates(15000),
                    -70,
                  ]}
                />
                <CylinderBetweenPoints
                  point1={[
                    translateWagonCordinates(9200),
                    translateCordinates(5000),
                    -70,
                  ]}
                  point2={[
                    translateWagonCordinates(1000),
                    translateCordinates(5000),
                    -70,
                  ]}
                />

                <CylinderBetweenPoints
                  point1={[
                    translateWagonCordinates(1000),
                    translateCordinates(15000),
                    -70,
                  ]}
                  point2={[
                    translateWagonCordinates(1000),
                    translateCordinates(1000),
                    -70,
                  ]}
                />
                <CylinderBetweenPoints
                  point1={[
                    translateWagonCordinates(9200),
                    translateCordinates(15000),
                    -70,
                  ]}
                  point2={[
                    translateWagonCordinates(9200),
                    translateCordinates(1000),
                    -70,
                  ]}
                />
              </>
            )}
            <CreateTempMarker
              files={files}
              tempMarker={tempMarker}
              setSelectedMarker={setSelectedMarker}
              selectedMarker={selectedMarker}
            />
            <CreateMarkers
              files={files}
              markerdata={markerdata}
              setSelectedMarker={setSelectedMarker}
              selectedMarker={selectedMarker}
            />
            {products?.length > 0 && (
              <RenderProducts files={files} products={products} />
            )}
          </group>
        </Canvas>
      ) : (
        <Box display="flex" justifyContent="center" mt={25} alignItems="center">
          <Stack display="flex" justifyContent="center" alignItems="center">
            <Typography fontSize="34px">
              Realtime 3D model not configured.
            </Typography>
            <Button
              variant="outlined"
              sx={{ maxWidth: "90px" }}
              onClick={() => navigate("configuration")}
            >
              Configure
            </Button>
          </Stack>
        </Box>
      )}
      {/* <PlaneAdjuster 
        normal={normal}
        constant={constant}
        onNormalChange={handleNormal Change}
        onConstantChange={handleConstantChange}
      /> */}
      {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {/* <Typography id="x-slider" gutterBottom>
          X: {position[0]}
        </Typography>
        <Slider
          aria-labelledby="x-slider"
          value={position[0]}
          min={-1000}
          max={1000}
          onChange={handleSliderChange(0)}
        />
        <Typography id="y-slider" gutterBottom>
          Y: {position[1]}
        </Typography>
        <Slider
          aria-labelledby="y-slider"
          value={position[1]}
          min={-1000}
          max={1000}
          onChange={handleSliderChange(1)}
        />
        <Typography id="z-slider" gutterBottom>
          Z: {position[2]}
        </Typography>
        <Slider
          aria-labelledby="z-slider"
          value={position[2]}
          min={-1000}
          max={1000}
          onChange={handleSliderChange(2)}
        /> 
        
      </Box> */}
    </Box>
  );
});

export default Scene2;

function reverseTranslateCordinates(y) {
  // const x1 = 15000;
  // const x2 = 1000;
  // const y1 = 66;
  // const y2 = -130;
  const x1 = -144;
  const x2 = 56;
  const y1 = 1000;
  const y2 = 15000;

  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * y + b;
}

function reverseTranslateWagonCordinates(y) {
  // const x1 = 9200;
  // const x2 = 1000;
  // const y1 = 50;
  // const y2 = -45;

  const x1 = -62;
  const x2 = 40;
  const y1 = 1000;
  const y2 = 9200;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * y + b;
}

function translateCordinates(value, realmin, realmax, modelmin, modelmax) {
  // Define the start and end of both ranges
  const x1 = realmax;
  const x2 = realmin;
  const y1 = modelmax;
  const y2 = modelmin;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  // console.log(m * value + b, realmax, realmin, modelmin, modelmax);
  return m * value + b;
}

function translateWagonCordinates(value) {
  // Define the start and end of both ranges
  const x1 = 9200;
  const x2 = 1000;
  const y1 = 40;
  const y2 = -62;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}
function translateWireCordinates(value, realmin, realmax, modelmin, modelmax) {
  // Define the start and end of both ranges
  const x1 = realmin;
  const x2 = realmax; //6780
  const y1 = modelmin;
  const y2 = modelmax;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}
function ReverseTranslateWireCordinates(
  value,
  realmin,
  realmax,
  modelmin,
  modelmax
) {
  // Define the start and end of both ranges
  // const x1 = realmin;
  // const x2 = realmax; //6780
  // const y1 = modelmin;
  // const y2 = modelmax;

  const y1 = realmin;
  const y2 = realmax; //6780
  const x1 = modelmin;
  const x2 = modelmax;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}

function Product(mtlUrl, objUrl, name) {
  //console.log("Rendering 3D model for product: ", name);
  if (mtlUrl != undefined && mtlUrl != "") {
  } else {
    return <></>;
  }
  //console.log("URL: ", mtlUrl);
  const mtlLoader = new MTLLoader();
  const mtl = mtlLoader.parse(mtlUrl);
  mtl.preload();

  //console.log(data2);
  const objLoader = new OBJLoader();
  objLoader.setMaterials(mtl);
  const rawObjText = `${objUrl}`;
  const obj = objLoader.parse(rawObjText);
  // const obj = useLoader(OBJLoader, objUrl, loader => loader.setMaterials(mtl));

  return (
    <mesh position={[0, 0, 0]}>
      <primitive object={obj} />
    </mesh>
  );
}

const CreateMarkers = React.memo(function CreateMarkers({
  markerdata,
  setSelectedMarker,
  selectedMarker,
  files,
}) {
  //console.log("Rendering markers");
  const Short = Product(files["Short.mtl"], files["Short.obj"], "Short");
  const Long = Product(files["Long.mtl"], files["Long.obj"], "Long");
  return markerdata?.map((marker, index) => {
    return (
      <Marker
        files={files}
        Short={Short}
        Long={Long}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
        key={index}
        x={translateCordinates(marker.y)}
        y={translateWagonCordinates(marker.x)}
        z={translateWireCordinates(marker.z, 0, 120, 11, -108)}
        text={marker.locationcode}
        id={marker.id}
        stack={marker.stack}
      />
    );
  });
});
const CreateTempMarker = React.memo(function CreateTempMarker({
  tempMarker,
  setSelectedMarker,
  selectedMarker,
  files,
}) {
  //console.log("Rendering temp marker");
  return tempMarker?.map((marker, index) => {
    const [y, x, z] = marker;
    return (
      <Marker
        files={files}
        key={index}
        x={x}
        y={y}
        z={z}
        i={index}
        stack={[]}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
      />
    );
  });
});

const Marker = React.memo(function Marker({
  files,
  x,
  y,
  z,
  i,
  text,
  id,
  stack,
  setSelectedMarker,
  selectedMarker,
}) {
  const meshRef1 = useRef();
  //console.log("Marker gets rendered");
  //console.log("Marker: ", x,y,text);

  if (Object.keys(files).length == 0) {
    return <></>;
  }

  const handlePointerDown = (event) => {
    //console.log('Clicked on', event.point);
    setSelectedMarker([
      text,
      id,
      Math.round(reverseTranslateCordinates(x)),
      Math.round(reverseTranslateWagonCordinates(y)),
      stack,
    ]);
    //console.log(stackAmount);
  };
  const Long = () => Product(files["Long.mtl"], files["Long.obj"], "Long");
  const Short = () => Product(files["Short.mtl"], files["Short.obj"], "Short");

  const stacks = [];

  let height = 0;

  for (let i = 0; i < stack?.length; i++) {
    //console.log(text, " ", height);
    stacks.push(
      <group key={i} position={[0, 0, height]}>
        {stack[i] == 1 ? (
          <group key={i} position={[0, 0, 0]}>
            <Long />
          </group>
        ) : stack[i] == 2 ? (
          <group key={i} position={[119, 26, 0]}>
            <Short />
          </group>
        ) : (
          <group key={i} position={[19, -300, -167]}>
            <mesh position={[0, 0, 0]}>
              <boxGeometry args={[30, 30, 5]} />
              <meshBasicMaterial color={"gray"} />
            </mesh>
          </group>
        )}
      </group>
    );

    if (stack[i] == 1) {
      height = height + 6;
    } else if (stack[i] == 2) {
      height = height + 17;
    } else {
      height = height + 7;
    }
  }

  //console.log(text," ",stacks);
  return (
    <>
      <Text
        position={[y, x + 12, z + 1]} // Position of the text
        fontSize={5} // Font size
        color="black" // Font color
        onClick={handlePointerDown}
      >
        {text ? text : ""}
      </Text>
      <group
        position={[y, x, z + 1]}
        ref={meshRef1}
        onClick={handlePointerDown}
      >
        <mesh>
          <ringGeometry args={[0, 5, 62]} />
          <meshBasicMaterial
            color={
              selectedMarker && selectedMarker[1] === id ? "orange" : "red"
            }
          />
        </mesh>
        <group position={[-20, 300, 170]}>{stacks}</group>
      </group>
    </>
  );
});
