import axios from 'axios';
import React, { useState, useEffect } from 'react';

function Delete(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(url !== ''){
      setLoading(true);
      setData(null);
      setError(null);

      axios.delete(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        }
      })
        .then(response => setData(response.data))
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [url]);

  return { data, loading, error };
}

export default Delete;
