import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Radio, FormControlLabel, FormGroup, FormLabel, TextField, Button, Stack, Modal, IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import useFetchV2 from '../../../components/PartsApi';
import { toast } from 'react-toastify';
import { postPart, postCranePart, deleteCranePart, getCranePartIdToRemove } from '../../../components/PartsApi';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExcelImportModal from '../../global/excelimport';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

 
  bgcolor: "#fff",
  borderRadius: "5px",
  
  p: 2,
};



const cranePartsBody = {
  "id": "",
  "replaceddate": "2023-06-27T05:09:01.588Z",
  "crane": {
    "id": ""
  },
  "part": {
    "id": ""
  }
} 

export default function Components({inputSearchTerm = "", clearInputSearchTerm = {}, id}) {
  const { data: cranepartsdata, loading: partsloading, error: partserror, refetch: refetchAllParts } = useFetchV2(`https://noswingcc.net/noswingcc/api/craneparts?craneId.equals=${id}&page=0&size=50`);
  const [radioValue, setRadioValue] = useState('option1');
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [searchTerm, setSearchTerm] = useState(inputSearchTerm);
  const [craneParts, setCraneParts] = React.useState(null);

  //add parts window (modal)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  //console.log("Partslistcomponent updating");

  // console.log(
  //   cranepartsdata && 
  //   cranepartsdata.map(item => ({...item.part, replaceddate: item.replaceddate}))
  // );
  useEffect(() => {
    // This code runs when the component mounts
  
    return () => {
      // This code runs when the component unmounts
      if(inputSearchTerm !== ''){
        clearInputSearchTerm('');
      }
          // Call your state clearing function here
    };
  }, []);  // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  function searchComponents(searchTerm) {
    const filteredComponents = cranepartsdata.map(item => ({...item.part, replaceddate: item.replaceddate})).filter(component => 
      component.componentname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredComponents;
  }
  

  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 60 },
    { field: 'componentname', headerName: 'Component Name', minWidth: 250 },
    { field: 'replaceddate', headerName: 'Replaced', minWidth: 100, renderCell: (params) => {
      return convertISOtoDMY(params.value);
    } },
    { field: 'partnumber', headerName: 'Part Number', minWidth: 250 },
    { field: 'parttype', headerName: 'Type', minWidth: 200 },
    { field: 'manufacturer', headerName: 'Manufacturer', minWidth: 150 },
    { field: 'note', headerName: 'Note', minWidth: 250 },
    { field: 'more', headerName: '', width: 20,
        renderCell: (params) => (
          
             <MoreMenu partId={params.id} craneId={id} refetchParts={refetchAllParts}/>
        ), 
        }
  ];

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        mt: 3,
        borderRadius: '10px',
        width: '75%',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
        p: 2,
        mb: 2,
      }}
    >
      <Stack direction="row" gap={10}> 
      <Box mt={0} width="600px">
        {/* <input placeholder='Search for parts' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>  */}
        <TextField
          label="Search for parts"
          value={searchTerm}
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <Box>
        <Button variant='outlined' onClick={handleOpen}>
        Add Parts
        </Button>
      </Box>
      <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
              <Step4 craneParts={craneParts} setCraneParts={setCraneParts} cranepartsdata={cranepartsdata && cranepartsdata} id={id} refetchAllParts={refetchAllParts} doneAddingParts={handleClose}/>
              </Box>
            </Modal>
      
      </Stack>
      <Box mt={2} height="600px">
        <DataGrid
          rows={cranepartsdata ? searchComponents(searchTerm) : []}
          
          columns={columns}
          pageSize={10}
          
          
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () => {
              return <div>No matching components found.</div>;
            },
          }}
          style={{ borderColor: '#c4c4c4' }}
        />
      </Box>
    </Box>
      );
    }

    const Step4 = ({setCraneParts, craneParts, cranepartsdata, id, refetchAllParts, doneAddingParts}) => {
      const { data: partsdata, loading: partsloading, error: partserror, refetch: refetchParts } = useFetchV2('https://noswingcc.net/noswingcc/api/parts?page=0&size=1000');
      const [searchTerm, setSearchTerm] = React.useState('');
      const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
      const [formValues, setFormValues] = React.useState(initialFormValues);
    
      console.log("row selection model", rowSelectionModel);
      
      if(id){cranePartsBody.crane.id = id;}
      
      //console.log("craneparts: ",craneParts);
      //console.log("partsData: " , partsdata);
      //console.log("cranepartsdata: ",cranepartsdata);
      
      let resultArray = partsdata?.filter(firstItem => !cranepartsdata.some(secondItem =>
          secondItem.part.componentname === firstItem.componentname &&
          secondItem.part.id === firstItem.id
        )
        );
    
    
      //modal
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
      
      //excel import
      const [isOpen, setIsOpen] = useState(false);
      const [dataArr, setDataArr] = useState([]);

      const handleSubmit = async (data, file) => {
        // Log the data
        console.log(data.validData);
    
        // Iterate through each item in the data array
        for (let item of data.validData) {
            // Create a new object with the desired format
            let part = {
                id: item.id || '',
                componentname: item.componentname || '',
                partnumber: item.partnumber || '',
                parttype: item.type || '',
                manufacturer: item.manufacturer || '',
                note: item.note || '',
                lifetime: item.lifetime || 0,
            };
    
            // Set the form values
            setFormValues(part);
    
            // Log the form values
            console.log('Form Values:', part);
    
            // Post the data
            const success = await postPart(part);
    
            if (success) {
                //toast.success('Successfully saved part!');
                refetchParts();
            } else {
                toast.error('Could not save part!');
            }
        }
    
        // Reset form values
        setFormValues(initialFormValues);
    };

      async function handlePostCranePart() {
        //console.log("SelectedRows: " , rowSelectionModel, " ", cranePartsBody);
        let today = new Date();
        cranePartsBody.replaceddate = today.toISOString();
        
        for (let item of rowSelectionModel) {
          let cranePart = { ...cranePartsBody, part: { id: item } };
          console.log("hep", cranePartsBody);
          const success = await postCranePart(cranePart);
          if (success) {
         
            // toast.success('Succefully saved part!');
            refetchAllParts();
            doneAddingParts();
          }
          else{
             toast.error('Could not save part!');
          }
        }
        
    
      }
    
      const columns = [
      
        { field: 'componentname', headerName: 'Component Name', width: 150 },
        { field: 'partnumber', headerName: 'Part Number', width: 130 },
        { field: 'parttype', headerName: 'Part Type', width: 120 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 150 },
        { field: 'note', headerName: 'Note', width: 200 },
        { field: 'lifetime', headerName: 'Lifetime', width: 120 },
      ];
      
      const rows = resultArray || [];
    
      function searchParts(searchTerm) {
        if (!Array.isArray(rows)) {
          return [];
        }
        
        const filteredParts = rows.filter((item) =>
          item.componentname.toLowerCase().includes(searchTerm.toLowerCase())
        );
        //console.log(filteredParts);
        return filteredParts;
      }
      //console.log('selectedRows: ', selectedRows);
      //console.log('DataGrid rows: ', rows);
    
      return (
        <Box pl={2} display="flex" justifyContent="space-between" gap={8} >
          <Box>
            <Typography variant='h5' mb={1} fontWeight="bold">Select components from the database to add to the crane</Typography>
            <Box display="flex" justifyContent="flex-end" gap={2} mb={2}>
            <BasicMenu openPartModal={handleOpen} setIsOpen={setIsOpen}/>
            <Box mr="10px" sx={{
                width: "170px",
              }}  bgcolor={"grey"} borderRadius={"20px"} height={"40px"} display="flex" alignItems="center">
              <TextField
                placeholder="Search"
                variant="outlined"    
                onChange={e => setSearchTerm(e.target.value)} 
                sx={{
                  width: '100%',
                  border: 'none',
                  ml: "10px",
                  my: '25px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <PartFormComponent refetchParts={refetchParts} closeModal={handleClose}/>
              </Box>
            </Modal>
              </Box>
              </Box>
            <Box height="600px" width="1200px">
              <DataGrid rows={searchParts(searchTerm)} columns={columns} pageSize={5} disableRowSelectionOnClick checkboxSelection onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
              
            }}
            rowSelectionModel={rowSelectionModel}/>
            </Box>
            <Box display="flex" justifyContent="flex-end" mr="5px" mt="10px"><Button variant='outlined' onClick={handlePostCranePart}>Add</Button></Box>
            
          </Box> 
          <ExcelImportModal isOpen={isOpen} setIsOpen={setIsOpen} handleSubmit={handleSubmit}/>
        </Box>
      );
    };

    const PartFormComponent = ({refetchParts}) => {
      const [formValues, setFormValues] = React.useState(initialFormValues);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Values:', formValues);
        handlePost();
        setFormValues(initialFormValues);
      };
    
      async function handlePost() {
        const success = await postPart(formValues);
    
        if (success) {
         
          //toast.success('Succefully saved part!');
          refetchParts();
        }
        else{
          toast.error('Could not save part!');
        }
      }
    
      return (
        <Box component="form" onSubmit={handleSubmit} display="flex" gap={2}>
          
          <TextField
            label="Component Name"
            name="componentname"
            value={formValues.componentname}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Part Number"
            name="partnumber"
            value={formValues.partnumber}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Part Type"
            name="parttype"
            value={formValues.parttype}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Manufacturer"
            name="manufacturer"
            value={formValues.manufacturer}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Note"
            name="note"
            value={formValues.note}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Lifetime"
            name="lifetime"
            type="number"
            value={formValues.lifetime}
            onChange={handleChange}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      );
    };

    const initialFormValues = {
      id: '',
      componentname: '',
      partnumber: '',
      parttype: '',
      manufacturer: '',
      note: '',
      lifetime: 0,
    };

    function getDate () {
      let today = new Date();

      let day = String(today.getDate()).padStart(2, '0');
      let month = String(today.getMonth() + 1).padStart(2, '0'); 
      let year = today.getFullYear();

      let formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    }

    function convertISOtoDMY(isoDateString) {
      let date = new Date(isoDateString);
  
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based
      let year = date.getFullYear();
  
      return `${day}-${month}-${year}`;
  }

  function BasicMenu({openPartModal, setIsOpen}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleAdd = () => {
      setAnchorEl(null);
      openPartModal();
    };
    const handleImport = () => {
      setAnchorEl(null);
      setIsOpen(true);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant='outlined'
        >
          Add new part
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleAdd}>Add manually</MenuItem>
          <MenuItem onClick={handleImport}>Import from Excel</MenuItem>
          
        </Menu>
      </div>
    );
  }

  function MoreMenu({craneId, partId, refetchParts}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleDelete = async () => {
      setAnchorEl(null);
      console.log(craneId, partId);
      const data = await getCranePartIdToRemove(craneId,partId);

      //console.log(data[0].id);
      if(data){
        const success = await deleteCranePart(data[0].id);
      
        if (success) {
          toast.success('Succefully removed part from crane');
          refetchParts();
        }
        else{
          toast.error('Could not remove part from crane!');
        }
      }
     
    };
    const handleEdit = () => {
      setAnchorEl(null);
     
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <IconButton onClick={handleClick}><MoreVertOutlinedIcon/></IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          
        </Menu>
      </div>
    );
  }