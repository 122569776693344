import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./scenes/dashboard";

import Sidebar from "./scenes/global/Sidebar";
import CranePage from "./scenes/cranesV2/listOfCranes";
import Home from "./scenes/wms/home";
import UserContext from "./UserContext";
import React, { useState } from "react";
import Box from "@mui/material/Box";

import Login from "./scenes/login/Login";
import DataGridDemo from "./scenes/users/userpage";
import axios from "axios";

import CraneData from "./scenes/cranesV2/craneItemPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Received from "./scenes/wms/received";
import Delivered from "./scenes/wms/delivered";
import Returns from "./scenes/wms/returns";
import Products from "./scenes/wms/products";
import Productitem from "./scenes/wms/productitem";
import Newproductitem from "./scenes/wms/newproduct";
import Orderinfo from "./scenes/wms/orderinfo";
import Certificate from "./scenes/wms/certificate";
import DeliveredOrderinfo from "./scenes/wms/deliveryorderinfo";
import ConfigurationsPage from "./scenes/cranesV2/TabPages/configuration";
import NewDeliveredOrder from "./scenes/wms/newdeliveredorder";
import NewReceivedOrder from "./scenes/wms/newreceivedorder";
import NewVersionPopup from "./scenes/global/VersionDialog";
import ConfigurationsPageTailwind from "./scenes/cranesV2/TabPages/configuration/main";
import Navbar from "./scenes/global/Navbar";
import Documents from "./scenes/documents";
import Support from "./scenes/support/Support";
import FAQ from "./scenes/support/FAQ";
import Sales from "./scenes/support/Sales";
import Technical from "./scenes/support/Technical";
import Personaldash from "./scenes/dashboard/customdash/Personaldash";
import Components from "./scenes/cranesV2/TabPages/componentList";
import MainPartsList from "./scenes/craneParts/mainPartsList";
import Courses from "./scenes/courses/home";
import ApiDemo from "./scenes/api/apiDemo";
import CraneSim from "./scenes/courses/CraneSim";

function App() {
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [drawerButton, setDrawerButton] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMediaQuery("(max-width:1020px)");
  const isSmallScreen = useMediaQuery("(max-width:1100px)");
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("token") && localStorage.getItem("user") ? true : false
  );
  const location = useLocation();
  const [userRoles, setUserRoles] = useState([]);
  const [cranesAllowed, setCranesAllowed] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
  ]);
  const softwareVersion = "0.8.0";

  const [lastVersionSeen, setLastVersionSeen] = useState(localStorage.getItem("version"));
  //console.log(softwareVersion, " == " , lastVersionSeen);
  const [showNewVersion, setShowNewVersion] = useState(false);
  const [sidePanel, setSidepanel] = useState(
    localStorage.getItem("panel") == "top" ? false : true
  );
  const [tier, setTier] = useState(localStorage.getItem("tier") || 2);

  React.useEffect(() => {
    console.log(softwareVersion, " == ", lastVersionSeen);
    if (softwareVersion !== lastVersionSeen && isLogin) {
      // setShowNewVersion(true);
      //localStorage.setItem('version', softwareVersion);
    }
  }, [isLogin, lastVersionSeen, softwareVersion]);

  const toggleDrawer = () => {
    setDrawerButton(!drawerButton);
  };

  React.useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      axios
        .get(`https://noswingcc.net/noswingcc/api/account`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "*/*",
          },
        })
        .then((response) => {
          setIsLogin(true);
          const userRoles = response.data.authorities;
          setUserRoles(userRoles);
          console.log("User roles: " + userRoles);
        })
        .catch((error) => {
          setIsLogin(false);
          console.error("Error:", error);
        });
    }
  }, []);

  // React.useEffect(() => {
  //   if (location.pathname === '/cranes') {
  //     setIsCollapsed(true);
  //   } else {
  //     if (!isSmallScreen) { // only open the sidebar if it's not mobile view
  //       setIsCollapsed(false);
  //     }
  //   }
  // }, [location, setIsCollapsed]);
  console.log(localStorage.getItem("user"));

  return (
    <>
      <CssBaseline />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="app h-full bg-yello-100" style={{ display: "flex" }}>
        {!isLogin ? (
          <Login
            setIsLogin={setIsLogin}
            setUser={setUser}
            setUserRoles={setUserRoles}
          />
        ) : (
          <>
            {/* <Sidebar userRoles={userRoles} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed } drawerButton={drawerButton} toggleDrawer={toggleDrawer} isSmallScreen={isSmallScreen}/> */}

            <main className="content relative">
              {/* <Topbar user={user} setUser={setUser} isMobile={isMobile} toggleDrawer={toggleDrawer} setIsLogin={setIsLogin}/> */}
              {/* <ResponsiveAppBar user={user} setIsLogin={setIsLogin} setShowNewVersion={setShowNewVersion}/> */}

              {sidePanel && !isMobile ? (
                <Navbar
                  user={user}
                  setIsLogin={setIsLogin}
                  setShowNewVersion={setShowNewVersion}
                  version={softwareVersion}
                  toggleSidePanel={setSidepanel}
                  isSidePanel={sidePanel}
                  setTier={setTier}
                  tier={tier}
                  userRoles={userRoles}
                />
              ) : (
                <Sidebar
                  tier={tier}
                  toggleSidePanel={setSidepanel}
                  user={user}
                  setIsLogin={setIsLogin}
                  setShowNewVersion={setShowNewVersion}
                  version={softwareVersion}
                  setTier={setTier}
                  userRoles={userRoles}
                />
              )}
              <Box
                width="100%"
                mt={sidePanel ? "80px" : "-80px"}
                pl={sidePanel || isMobile ? "0px" : "256px"}
              >
                <NewVersionPopup
                  open={showNewVersion}
                  setOpen={setShowNewVersion}
                  softwareVersion={softwareVersion}
                  lastVersionSeen={lastVersionSeen}
                  setLastVersionSeen={setLastVersionSeen}
                />
                <Routes>
                  {/* <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/dashboard/custom" element={<Personaldash/>}/> */}
                  <Route
                    path="/"
                    element={
                      <CranePage
                        user={user}
                        setIsCollapsed={setIsCollapsed}
                        cranesAllowed={cranesAllowed}
                        isSidePanel={!sidePanel}
                      />
                    }
                  />
                  <Route
                    path="/cranes"
                    element={
                      <CranePage
                        isSidePanel={!sidePanel}
                        user={user}
                        setIsCollapsed={setIsCollapsed}
                        cranesAllowed={cranesAllowed}
                        userRoles={userRoles}
                      />
                    }
                  />
                  <Route
                    path="/cranes/:id?"
                    element={<CraneData user={user} tier={tier} />}
                  />
                  {/* <Route path="/cranes/:id/configuration" element={<ConfigurationsPage/>}/> */}
                  <Route
                    path="/cranes/:id/configuration"
                    element={
                      <ConfigurationsPageTailwind
                        tier={tier}
                        userRoles={userRoles}
                      />
                    }
                  />
                  <Route
                    path="/craneparts"
                    element={<MainPartsList id={4} />}
                  />
                  <Route path="/api" element={<ApiDemo />} />
                  <Route path="/simulator" element={<Courses />} />
                  <Route path="/documents" element={<Documents id={4} />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/support/FAQ" element={<FAQ />} />
                  <Route path="/support/Sales" element={<Sales />} />
                  <Route path="/support/Technical" element={<Technical />} />
                  <Route path="/wms/overview" element={<Home />} />
                  <Route path="/wms/received" element={<Received />} />
                  <Route
                    path="/wms/received/new"
                    element={<NewReceivedOrder />}
                  />
                  <Route
                    path="/wms/received/order/:ref"
                    element={<Orderinfo />}
                  />
                  <Route
                    path="/wms/delivered/order/:ref"
                    element={<DeliveredOrderinfo />}
                  />
                  <Route path="/wms/delivered" element={<Delivered />} />
                  <Route
                    path="/wms/delivered/new"
                    element={<NewDeliveredOrder />}
                  />
                  <Route path="/wms/returns" element={<Returns />} />
                  <Route path="/wms/products" element={<Products />} />
                  <Route
                    path="/wms/products/new"
                    element={<Newproductitem />}
                  />
                  <Route path="/wms/products/:id?" element={<Productitem />} />
                  <Route path="/certificate" element={<Courses />} />
                  <Route path="/wms/configurations" element={<Certificate />} />

                  {/* {userRoles.includes('ROLE_ADMIN') && */}
                  <Route
                    path="/users"
                    element={
                      <DataGridDemo
                        cranesAllowed={cranesAllowed}
                        setCranesAllowed={setCranesAllowed}
                        userRoles={userRoles}
                        user={user}
                      />
                    }
                  />
                </Routes>
              </Box>
            </main>
            {softwareVersion !== lastVersionSeen && isLogin && !showNewVersion && <div className="fixed bottom-0 right-0 m-5  rounded-lg shadow-2xl border">
              <div className="border-4 rounded-xl border-white bg-white ">
              <div className="bg-gradient-to-r from-nsorange-400 to-nsblue-400 h-52 w-96 rounded-t-lg flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                    <p className="text-white text-3xl font-semibold">NoSwing Control Center</p>
                    <p className="text-white mt-4 border rounded-full py-2 px-4 cursor-pointer">Version {softwareVersion}</p>
                    </div>

                   
              </div>
              <div className="p-5 w-96">
              <p className="text-lg font-semibold">What's new in CC version {softwareVersion}</p>
              <p className="mt-2">
                This update contains new features and updates to features that you already know and like. Want to know more?
              </p>
              <div className="flex flex-row gap-3 mt-5">
                <button className="rounded-lg bg-nsblue-500 text-white px-4 py-2 " onClick={() => setShowNewVersion(true)}>
                  Show me
                </button>
                <button className="rounded-lg  text-black px-4 py-2 " onClick={() => {localStorage.setItem("version", softwareVersion); setLastVersionSeen(softwareVersion);}}>
                  No thanks
                </button>
              </div>
              </div>
                
              </div>
            </div>}
          </>
        )}
      </div>
    </>
  );
}

export default App;
