
import React from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  Squares2X2Icon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  WrenchIcon,
  CloudIcon,
  TruckIcon,
  MoonIcon,
  SunIcon,
  WindowIcon,
} from '@heroicons/react/24/outline'

import { useLocation } from 'react-router-dom'


const teams = [
  { id: 2, name: 'TopPanel', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Version', href: '#', initial: 'W', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ user, setIsLogin, setShowNewVersion, version, toggleSidePanel, isSidePanel, tier }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
    const [mode, setMode] = useState(localStorage.getItem('mode') || "light");
    const navigations = [
      // { name: 'Dashboard', href: '/dashboard', icon: Squares2X2Icon, current: false },
      { name: 'Cranes', href: '/cranes', icon: CloudIcon, current: false, enabled: true },
      { name: 'WMS', href: '/wms/overview', icon: TruckIcon, current: false, enabled: tier > 1 ? true : false},
      { name: 'Users', href: '/users', icon: UsersIcon, current: false, enabled: true },
      { name: 'Craneparts', href: '/craneparts', icon: WrenchIcon, current: false, enabled: true },
      { name: 'Documents', href: '/documents', icon: DocumentDuplicateIcon, current: false, enabled: true },
      { name: 'Help', href: '/support', icon: QuestionMarkCircleIcon, current: false, enabled: true },
    ]
    const [navigation, setNavigation] = useState(navigations);
  const location = useLocation();

  React.useEffect(() => {
    const updatedNavigation = navigation.map(item => ({
      ...item,
      current: location.pathname.includes(item.name === "WMS" ? "wms" : item.href),
    }));

    setNavigation(updatedNavigation);
  }, []);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center mt-2">
                    <img
                      alt="logo"
                      height="28px"
                      src={`../../assets/NSLOGOV2.png`}
                     
                      className="hover:cursor-pointer"
                    />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.filter(item => item.enabled === true).map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400"></div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  {/* <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span> */}
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {mode === "dark" ? <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center mt-2">
                    <img
                      alt="logo"
                      height="28px"
                      src={`../../assets/NSLOGOV2.png`}
                     
                      className="hover:cursor-pointer"
                    />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.filter(item => item.enabled === true).map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                
                <div className='flex flex-grow items-end'>
                <div className='flex flex-row gap-5 text-white items-center'>
                    <SunIcon className='h-6 w-6 text-white hover:text-gray-400 cursor-pointer' onClick={() => { setMode("light"); localStorage.setItem('mode', "light");}} />
                    <WindowIcon className='h-6 w-6 text-white hover:text-gray-400 cursor-pointer' onClick={() => {toggleSidePanel(true); localStorage.setItem('panel', "side");}}/>
                    <p onClick={() => setShowNewVersion(true)} className='text-lg cursor-pointer' >v0.6.0</p>
                </div>
                </div>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-800"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">{user}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          : <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center mt-2">
                        <img
                        alt="logo"
                        height="28px"
                        src={`../../assets/NSLOGOV2.png`}
                        
                        className="hover:cursor-pointer"
                        />
                </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.filter(item => item.enabled === true).map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-nsorange-500'
                              : 'text-gray-700 hover:text-nsorange-500 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-nsorange-500' : 'text-gray-400 group-hover:text-nsorange-500',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                
                <div className='flex flex-grow items-end'>
                <div className='flex flex-row gap-5 text-black items-center'>
                    <MoonIcon className='h-6 w-6 text-black hover:text-gray-600 cursor-pointer' onClick={() => { setMode("dark"); localStorage.setItem('mode', "dark");}} />
                    <WindowIcon className='h-6 w-6 text-black hover:text-gray-600 cursor-pointer' onClick={() => {toggleSidePanel(true); localStorage.setItem('panel', "side");}}/>
                    <p onClick={() => setShowNewVersion(true)} className='text-lg cursor-pointer' >v0.6.0</p>
                </div>
                </div>
                
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">{user}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>}
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">{navigation?.find(item => item.current === true)?.name }</div>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <div className='flex flex-row gap-2 items-center'>
            <div
              className="h-8 w-8 rounded-full bg-gray-800 text-white flex justify-center items-center text-lg "
              
            >{user.slice(0,1).toUpperCase()}</div>
            <p className='text-base text-white'>{user}</p>
            </div>
          </a>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{/* Your content */}</div>
        </main>
      </div>
    </>
  )
}
