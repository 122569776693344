import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import {
  Box,
  Slider,
  Stack,
  Typography,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Popover,
  Tab,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Fab,
  Divider,
  ClickAwayListener,
  Menu,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
// import { ClickAwayListener } from '@mui/base/ClickAwayLisimport Switch from '@mui/material/Switch';

import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetch from "../../../components/fetch";
import { max, set, sortBy } from "lodash";
import AntiSwayIcon from "../images/antiswayV2.png";
import HookCenteringIcon from "../images/hookcentering.png";
import AntiSnagIcon from "../images/antisnag.png";
import FollowMeIcon from "../images/followme.png";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Switch } from "@headlessui/react";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import useFetchV2 from "../../../components/PartsApi";
import {
  postLocation,
  deleteMarker,
  postOrder,
  deleteOrder,
  updateLocation,
  updateOrder,
  getOrder,
  getAllContainersAtLocation,
  getContainer,
  patchContainer,
} from "../../../components/OrderApi";
import { getCranePos, patchCrane } from "../../../components/CraneApi";
import { getCurrentUser } from "../../../components/UserApi";

import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import CameraswitchOutlinedIcon from "@mui/icons-material/CameraswitchOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import ReactFullpage from "@fullpage/react-fullpage";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import {
  ArrowBack,
  ArrowForward,
  CheckBox,
  ChevronLeftOutlined,
} from "@mui/icons-material";
import { last } from "lodash";

import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import { toast } from "react-toastify";

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import LocationSearchingOutlinedIcon from "@mui/icons-material/LocationSearchingOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";

import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DirectionsRunOutlinedIcon from "@mui/icons-material/DirectionsRunOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import MoveDownOutlinedIcon from "@mui/icons-material/MoveDownOutlined";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import liftIcon from "../../../icons/lifts.png";
import overloadIcon from "../../../icons/overload.png";
import stopIcon from "../../../icons/stop.png";
import distanceIcon from "../../../icons/distance.png";

import { useTranslation, Trans } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import "./fullscreen.css";
import greenImage from "./images/Green.png";
import yellowImage from "./images/Yellow.png";
import blueImage from "./images/Blue.png";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import GetScreenSize from "../../../components/screensizeTest";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { OBJLoader, MTLLoader } from "three-stdlib";
import Scene2 from "./relatedComponents/3DobjV2";
import {
  postReceivedOrder,
  postDeliveredOrder,
} from "../../../components/WMS_Apis";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Long from "./images/Long.png";
import Short from "./images/Short.png";

import { updateZone, getZone } from "../../../components/CraneApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const craneFunctionInfo = {
  AntiSway:
    "AntiSway limits load swing by controlling the bridge and trolley acceleration and deceleration. Sway Control allows faster load handling and more precise positioning. This feature also reduces the risk of damage to the load, crane and surrounding area.",
  AntiSnag:
    "AntiSnag is designed to stop all crane movement if the hook, sling or load accidentally gets caught on something. This safety function reduces the risk of hazardous situations while moving loads and helps to prevent damage to the load, crane and surrounding area.",
  HookCentering:
    "HookCentering is designed to eliminate side pull during lifting by positioning the bridge and trolley automatically directly over the load. This feature means less wear and tear on your crane’s components, faster load cycle times and ease of operation.",
  FollowMe:
    "FollowMe makes lifting operations much faster because crane can be moved above the load by simply guiding the hook by hand. This feature reduces load cycle times by making it much easier and faster to load and unload the crane.",
};

// const values = {
//   Balk_x: -20,
//   Balk_y: 0,
//   Balk_z: 24,
//   Building_x: 366,
//   Building_y: 10,
//   Building_z: -109,
//   Hook_x: 31,
//   Hook_y: 9.5,
//   Hook_z: 145.5,
//   Wagon_x: -14,
//   Wagon_y: -0.5,
//   Wagon_z: 52.5,
//   Wire_length: 42,
//   Wire_x: -1,
//   Wire_y: -1.5,
//   Wire_z: 0,
//   x: 27239,
//   y: -727,
//   z: 119,
//   xmax: 400,
//   xmin: -186,
//   ymax: -107,
//   ymin: 19,
//   zmax: -97,
//   zmin: 11,
// };

const MiniOrderTable = ({ data, refetchOrders, opcData }) => {
  const [dataArray, setData] = useState(data || []);

  useEffect(() => {
    setData(data);
  }, [data]);

  const groupByTimestampAndContainer = (data) => {
    return data.reduce((acc, item) => {
      const key = item.timestamp + item.Container;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const formatGroup = (group) => {
    // Sorting to make sure "Pick Up" comes before "Leave Cargo"
    group.sort((a, b) => a.ordertype.localeCompare(b.ordertype));

    if (group[0].ordertype === "Move") {
      return `Move to ${group[0].to_cranelocation_locationcode}`;
    }

    let fromLocation = "";
    let toLocation = "";
    let container = group[0].container;

    group.forEach((order) => {
      if (order.ordertype === "Pick Up") {
        fromLocation = order.to_cranelocation_locationcode;
      } else if (order.ordertype === "Leave Cargo") {
        toLocation = order.to_cranelocation_locationcode;
      }
    });
    if (fromLocation == "") {
      return `Move ${container} to ${toLocation}`;
    }
    return `Move ${container} from ${fromLocation} to ${toLocation}`;
  };
  const groupedData = groupByTimestampAndContainer(dataArray);
  const result = Object.values(groupedData).map(formatGroup);

  //console.log(result);

  return (
    <Box>
      <TransitionGroup>
        {result.map((item) => (
          <Collapse key={item.id}>
            <Box
              borderRadius={"40px"}
              bgcolor={"rgba(0, 0, 0, 0.7)"}
              mb={1}
              p={0.5}
              pl={3}
              pr={3}
            >
              {" "}
              <Typography fontSize={"40px"} color={"white"}>
                {item}
              </Typography>
            </Box>
          </Collapse>
        ))}
      </TransitionGroup>
    </Box>
  );
};

const Table = ({
  data,
  refetchOrders,
  opcData,
  simulation,
  setSimOrders,
  simOrders,
}) => {
  const [dataArray, setData] = useState(data || []);
  const [opcdataArray, opcsetData] = useState(opcData || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [rowIdSelected, setRowIdSelected] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setData(data);
  }, [data]);
  useEffect(() => {
    opcsetData(opcData);
  }, [opcData]);

  const customHeaders = [
    t("pages.craneitem.ordernumber"),
    t("pages.craneitem.timestamp"),
    t("pages.craneitem.ordertype"),
    t("pages.craneitem.orderstatus"),
    // t("pages.craneitem.orderpriority"),
    t("pages.craneitem.location"),
    "Container",
  ];

  const handleClick = (event, index, id) => {
    setAnchorEl(event.currentTarget);
    setRowIdSelected(id);
    console.log(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRowIdSelected(null);
  };

  const handleDelete = async () => {
    if (simulation) {
      setSimOrders((prev) =>
        prev.filter((order) => order.id !== rowIdSelected)
      );
    } else {
      const deleted = await deleteOrder(rowIdSelected); //id of selected marker
      //console.log(deleted);
      if (deleted) {
        refetchOrders();
      } else {
        alert("Error");
      }
    }
    handleClose();
  };

  const handleCancel = async () => {
    console.log("Cancel order at index:", currentRowIndex);
    if (simulation) {
      let updatedArray = simOrders.map((order) => {
        if (order.id === rowIdSelected) {
          return { ...order, ordercancelled: true };
        }
        return order;
      });
      setSimOrders(updatedArray);
      handleClose();
      console.log("Order cancelled in simulation");
    } else {
      const order = await getOrder(rowIdSelected);
      order[0].ordercancelled = true;
      //console.log(order[0]);
      const updated = await updateOrder(rowIdSelected, order[0]);

      if (updated) {
        toast.success("Cancelled order!");
        refetchOrders();
      } else {
        alert("Error");
      }
      // Perform your cancel action here
      handleClose();
    }
  };

  return (
    <Box>
      {/* Header */}

      <Box display="flex" borderBottom={1} borderColor="grey.500">
        {customHeaders.map((header, index) => (
          <Box
            key={index}
            p={1}
            flexBasis={0}
            flexGrow={1}
            borderColor="grey.500"
            minWidth={index === 0 ? "115px" : undefined}
          >
            <Typography fontSize="24">{header}</Typography>
          </Box>
        ))}
      </Box>

      {dataArray.length == 0 && (
        <Typography fontSize="20" mt={2} ml={2}>
          {t("pages.craneitem.noorders")}
        </Typography>
      )}
      {/* Data */}
      <TransitionGroup>
        {dataArray.map((row, rowIndex) => (
          <Collapse key={rowIndex}>
            <Box
              display="flex"
              mt={1}
              bgcolor={rowIdSelected === row.id ? "#d3d3d3" : "#fff"}
              borderRadius="10px"
              onClick={(event) => handleClick(event, rowIndex, row.id)}
              sx={{ cursor: "pointer" }}
            >
              {Object.values(row).map((cell, cellIndex) =>
                cellIndex == 0 ? (
                  <Box
                    key={cellIndex}
                    p={1}
                    flexBasis={0}
                    flexGrow={1}
                    m={1}
                    minWidth={cellIndex === 1 ? "100px" : undefined}
                  >
                    <Typography
                      color={row.cancelled ? "red" : "black"}
                      style={{
                        textDecoration: row.cancelled && "line-through",
                      }}
                    >
                      {cellIndex === 3
                        ? row.id == opcdataArray.workordernbr
                          ? getOrderStatus(+opcdataArray.workorderstatus)
                          : cell
                        : cell}
                    </Typography>
                    {/* <Typography variant="body1">{opcData.workordernbr}</Typography>  */}
                  </Box>
                ) : cellIndex !== 5 ? (
                  <Box
                    key={cellIndex}
                    p={1}
                    flexBasis={0}
                    flexGrow={1}
                    m={1}
                    minWidth={cellIndex === 1 ? "100px" : undefined}
                  >
                    {cellIndex === 3
                      ? row.id == opcdataArray.workordernbr
                        ? getOrderStatus(+opcdataArray.workorderstatus)
                        : cell
                      : cell}
                    {/* <Typography variant="body1">{opcData.workordernbr}</Typography>  */}
                  </Box>
                ) : null
              )}
            </Box>
            <Box></Box>
          </Collapse>
        ))}
      </TransitionGroup>

      {/* Row Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={handleCancel}>Cancel</MenuItem>
      </Menu>
    </Box>
  );
};

const RealtimeTab = ({
  tier,
  objFiles,
  values,
  modelconf,
  modelconfdataloading,
  modelsLoading,
  totalAmountOfFiles,
  containerInHook,
  refetchZones,
  tempZone,
  Zone,
  obj,
  opc,
  cranedata,
  user,
  files,
  markerData,
  markerDataLoading,
  refetchAllMarkers,
  fullMarkerData,
  containerData,
  refetchAllContainers,
}) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [wagonPosition, setWagonPosition] = useState(0);
  const { t, i18n } = useTranslation();
  const [demand, setDemand] = useState(1);
  const [dataArray, setData] = useState();
  const [sortedData, setSortedData] = useState(dataArray);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [value, setValue] = useState(0);
  const [view, setView] = useState(0);
  const [lastCommunicationstatus, setLastCommunicationstatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [newMarkerPosition, setNewMarkerPosition] = useState([]);
  const [openConfirmMenu, setOpenConfirmMenu] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [tempMarker, setTempMarker] = useState([]);
  const [wireLength, setWireLength] = useState(0);
  const [orderstatus, setOrderstatus] = useState(0);
  const [cargoWeight, setCargoWeight] = useState(0);
  const [infoPanel, setInfoPanel] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = GetScreenSize() == "xs" || GetScreenSize() == "sm";
  const [fullscreen, setFullscreen] = useState(false);
  const [pickupEnabled, setPickupEnabled] = useState(true);
  const [leaveEnabled, setLeaveEnabled] = useState(true);
  const [newOrder, setNewOrder] = useState([]);
  const [orderStepperActiveStep, setOrderStepperActiveStep] = useState(0);
  const [createOrderMode, setCreateOrderMode] = useState(false);
  const [showPosInfo, setShowPosInfo] = useState(false);
  const [cranePos, setCranePos] = useState([0, 0, 0]);
  const [createBlockedZone, setCreateBlockedZone] = useState(false);
  const [centerArray, setCenterArray] = useState([
    { x: 0, y: 0, z: 0 },
    { x: 0, y: 0, z: 0 },
    { x: 0, y: 0, z: 0 },
  ]);
  const [slide, setSlide] = useState(1);
  const [orderPos1, setOrderPos1] = useState("");
  const [orderPos2, setOrderPos2] = useState("");
  const [orderPos3, setOrderPos3] = useState("");
  const [craneModelClicked, setCraneModelClicked] = useState("");
  const [container, setContainer] = useState(null);

  const [noContainerError, setNoContainerError] = useState(false);
  //console.log("Screensize ", GetScreenSize(), " mobile? " , isMobile, "fullscreen? ", fullscreen);
  const [stackInfo, setStackInfo] = useState([]);

  const [containersToRelocate, setContainersToRelocate] = useState([]);
  const [makeReturnOrders, setMakeReturnOrders] = useState(false);

  const [demo, setDemo] = useState(cranedata.id == 4504);

  const [isDisabled, setIsDisabled] = useState(false);
  const [xposSim, setXposSim] = useState(5000);
  const [yposSim, setYposSim] = useState(0);
  const [zposSim, setZposSim] = useState(0);
  const [simOrders, setSimOrders] = useState([]);
  const [simOrderDone, setSimOrderDone] = useState(0);
  const [simOrderNbr, setSimOrderNbr] = useState(1);
  const [simXYspeed, setSimXYspeed] = useState(500);
  const [simZspeed, setSimZspeed] = useState(100);
  const [simRunningHours, setSimRunningHours] = useState(0);
  const [simDistanceTraveled, setSimDistanceTraveled] = useState(0);
  const [fullMarkerDataV2, setFullMarkerDataV2] = useState([]);
  const handleDisable = () => {
    setIsDisabled(true);

    setTimeout(() => {
      setIsDisabled(false);
    }, 6000); // 2000 milliseconds = 2 seconds
  };
  // console.log(modelconf);
  //console.log("render");

  const handle = useFullScreenHandle();

  const loggeddata = [
    {
      runninghours: "Running time",
      distancetraveled: "Meters traveled",
      liftsdone: "Lifts",
      tonslifted: "Tons lifted",
      overloads: "Weight overloads",
      emergencystops: "Emergency stops",
    },
  ];

  const smallDevice = useMediaQuery("(min-width:1100px)");
  let tempTimer = 0;

  const {
    data: pickuporders,
    loading: pickupdataIsLoading,
    error: pickupdateError,
    refetch: refetchAllData,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/receivedorders?page=0&size=20`
  );

  useEffect(() => {
    if (cranedata.id === 4504) {
      console.log("orig ", fullMarkerData);
      let data = fullMarkerData?.map((marker) => {
        marker.x = marker.x + 800;
        marker.y = marker.y + 100;
        marker.z = marker.z + 320;
        return marker;
      });
      setFullMarkerDataV2(data);
    } else {
      setFullMarkerDataV2(fullMarkerData);
    }
  }, [fullMarkerData]);

  const postWMSOrder = async (productid, type) => {
    const currentUser = await getCurrentUser();
    //console.log(currentUser);

    const body = {
      id: "",
      reference: "ref",
      scheduleddate: "2023-11-13T08:09:20.217Z",
      demand: demand,
      done: 0,
      status: "Ready",
      product: {
        id: productid,
      },
      user: {
        id: currentUser.id,
        login: currentUser.login,
      },
    };

    //console.log(body);
    handleDisable();

    if (type === "recieve") {
      const posted = await postReceivedOrder(body);
      if (posted == null) {
        toast.error("Failed to make order...");
        setIsDisabled(false);
      } else {
        toast.success(`${demand} items to store in warehouse.`);
      }
    }
    if (type === "deliver") {
      const posted = await postDeliveredOrder(body);
      if (posted == null) {
        toast.error("Failed to make order...");
        setIsDisabled(false);
      } else {
        toast.success(`${demand} items to deliver from warehouse.`);
      }
    }
  };

  const postZone = async (active, changedZone = []) => {
    console.log("Updating Zone");
    const [xpos, ypos, zpos, length, width, height] = Zone;

    const body = {
      id: 1,
      name: "ForbiddenZone",
      length: length,
      width: width,
      height: height,
      x: xpos,
      y: ypos,
      z: zpos,
      forbiddenzone: active,
    };

    const posted = await updateZone(body);

    refetchZones();
    setCreateBlockedZone(false);
  };
  //const updatedMarkerData = populateStack(markerData, containerData);
  //console.log(updatedMarkerData);

  const [Live, setLive] = useState(true);
  const [simulation, setSimulation] = useState(false);
  const [opcData, setOpcData] = useState([]);
  const [debug, setDebug] = useState(false);
  const [clickedAntisway, setClickedAntisway] = useState(false);
  // const { data: cranesdata, loading: cranesloading, error: craneserror , refetch: refetchCraneData} = useFetchV2('https://noswingcc.net/noswingcc/api/cranes/4');

  const handlePopoverOpen = (event, content) => {
    setPopoverContent(content);
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const openPopover = Boolean(popoverAnchor);

  const prevCommunicationStatusRef = useRef();
  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      let data = await getCranePos(cranedata.opc.id);

      if (data) {
        if (simulation) {
          data.runninghours = simRunningHours;
          data.distancetraveled = simDistanceTraveled;
        }

        setOpcData(data);
        setCurrentPosition(data.y1actpos);
        if (!simulation) {
          setXposSim(null);
          setYposSim(null);
          setZposSim(null);
        }

        setWagonPosition(data.xactpos);
        setWireLength(data.z1actpos);
        setCargoWeight(data.z1weight);
        setOrderstatus(getOrderStatus(+data.workorderstatus));
        //console.log(markerData);
        refetchAllContainers();

        if (data.workorderstatus > 0) {
          refetchOrders();
          //refetchAllMarkers();
        }
        const communicationStatusInt = parseInt(data.communicationstatus, 10);

        // Compare the current communication status with the previous one
        if (
          prevCommunicationStatusRef.current !== undefined &&
          prevCommunicationStatusRef.current === communicationStatusInt
        ) {
          setLive(false); // No change, so set live to false
        } else {
          setLive(true); // Change detected, so set live to true
        }

        // Save the current communication status as the previous one for the next call
        prevCommunicationStatusRef.current = communicationStatusInt;
      }
    };

    // Call fetchData every second
    const intervalId = setInterval(fetchData, 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [simulation, simDistanceTraveled, simRunningHours]);

  if (
    opcData.workordernbr > opcData.workorderdone &&
    cranedata.id == 4504 &&
    opcData.workorderstatus <= 1
  ) {
    //console.log(opcData.workordernbr , " > " , opcData.workorderdone);
  }

  useEffect(() => {
    const newData = sortBy(dataArray, "OrderPriority");
    setSortedData(newData);
  }, [dataArray]);

  const {
    data: cranelocationdata,
    loading: dataIsLoading,
    error: errorInData,
    refetch: refetchLocationdata,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${cranedata.id}&page=0&size=20`
  );

  const {
    data: orderdata,
    loading: orderdataIsLoading,
    error: errorInorderData,
    refetch: refetchOrders,
  } = useFetchV2(
    `https://noswingcc.net/noswingcc/api/craneorders?orderstatus.lessThan=5&craneId.equals=${cranedata.id}&page=0&size=20`
  );

  //const { data: files, loading: fileloading, error: efileerror} = useFetchV2('https://noswingcc.net/noswingcc/api/files/test.txt');
  //console.log(!fileloading && files);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeCommitted = (event, newValue) => {};
  const handleClickAway = () => {
    alert("click");
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleDeleteMarker = async () => {
    const deleted = await deleteMarker(selectedMarker[1]); //id of selected marker
    console.log(deleted);
    if (deleted) {
      refetchAllMarkers();
      setSelectedMarker(null);
    } else {
      alert("Error");
    }
  };
  const handleClick = (value) => {
    setView(value);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenConfirmMenu(false);
    setSelectedMarker(null);
    setNewMarkerPosition([]);
    setTempMarker([]);
    setStackInfo([]);
  };

  const handleSaveMarkerAtCranePos = () => {
    console.log("Pos at crane: ", wagonPosition, "  ", currentPosition);

    setNewMarkerPosition([currentPosition, wagonPosition]);
  };

  const handleSaveMarker = async () => {
    let [y, x, z] = newMarkerPosition;

    if (cranedata.id == 4) {
      z = 6374;
    }

    const locationBody = {
      id: "",
      locationcode: inputValue,
      x: x,
      y: y,
      z: z,
      rowmax: 1,
      inuse: true,
      crane: {
        id: cranedata.id,
      },
    };
    const data = await postLocation(locationBody);
    if (data) {
      refetchAllMarkers();
      setOpenConfirmMenu();
      handleClose();
      setNewMarkerPosition([]);
    }
    //console.log(data);
    //console.log("Marker? ", newMarkerPosition);
  };
  const handleUpdateMarker = async () => {
    const locationBody = {
      id: selectedMarker[1],
      locationcode: selectedMarker[0],
      x: selectedMarker[3],
      y: selectedMarker[2],
      z: 6374,
      rowmax: 1,
      inuse: false,
      crane: {
        id: cranedata.id,
      },
    };
    const data = await updateLocation(selectedMarker[1], locationBody);
    if (data) {
      refetchAllMarkers();
      setOpenConfirmMenu();
      handleClose();
      setNewMarkerPosition([]);
    }
    //console.log(data);
    //console.log("Marker? ", newMarkerPosition);
  };

  function getContainerLocations(orders) {
    const containerLocations = {};

    // Assuming the orders array is sorted based on the timestamp
    // If not, you might want to sort it first.

    orders.forEach((order) => {
      // Check if the order type is 'leave'
      if (order.ordertype === 2) {
        const containerCode = order.container.containercode;
        const locationCode = order.to_cranelocation.locationcode;
        containerLocations[containerCode] = locationCode;
      }
    });

    return containerLocations;
  }

  const getContainerToMove = async (
    containerItems,
    locations,
    location = "D2"
  ) => {
    function getLatestMatch(obj, value) {
      const entries = Object.entries(obj).reverse();

      for (let [key, val] of entries) {
        if (val == value) {
          return { [key]: val };
        }
      }
      return null;
    }

    // Step 1: Sort the container items based on rowIndex
    const sortedItems = [...containerItems].sort(
      (b, a) => a.rowindex - b.rowindex
    );

    console.log(
      "sorted: ",
      sortedItems,
      " containeritems: ",
      containerItems,
      "locations: ",
      locations
    );
    // Step 2: Iterate through the sorted container items
    for (let item of sortedItems) {
      // Step 3: Check if its containercode exists in the locations object
      const containerLocation = locations[item.containercode];
      console.log("location ", containerLocation);
      // Step 4: If the container code does not exist in the locations object
      // or if the cranelocation.locationcode of the container matches the location in the locations object
      if (Object.values(locations).includes(item.cranelocation.locationcode)) {
        console.log("Includes!");
        const top = getLatestMatch(locations, item.cranelocation.locationcode);
        const key = Object.keys(top)[0];
        console.log("top: ", key);
        const cont = await getContainer(key);
        console.log("Cont", cont[0]);
        return cont[0];
      } else {
        console.log("NOT Includes!");
        if (
          !containerLocation ||
          item.cranelocation.locationcode === containerLocation
        ) {
          console.log("item ", item);
          return item;
        }
      }
    }
    if (sortedItems.length === 0) {
      if (Object.values(locations).includes(location)) {
        console.log("Includes!");
        const top = getLatestMatch(locations, location);
        const key = Object.keys(top)[0];
        console.log("top: ", key);
        const cont = await getContainer(key);
        console.log("Cont", cont[0]);
        return cont[0];
      }
    }

    // If no matching container found, return null or undefined
    return null;
  };

  useEffect(() => {
    const execute = async () => {
      const containers = await getAllContainersAtLocation(selectedMarker[1]);

      const futureContainerLocations = await getContainerLocations(orderdata);

      if (orderStepperActiveStep == 0) {
        const topcontainer = await getContainerToMove(
          containers,
          futureContainerLocations,
          "D2"
        );
        setContainersToRelocate([]);
        if (topcontainer === null) {
          //alert("No items to move");
          console.log(
            "Future: ",
            futureContainerLocations,
            "Cont: ",
            containers,
            " top: ",
            topcontainer
          );
          setNoContainerError(true);
        } else {
          setNoContainerError(false);
          console.log(topcontainer.containercode);
          setContainer(topcontainer);
          setOrderPos1([
            selectedMarker[0],
            selectedMarker[1],
            topcontainer.containercode,
          ]);
          setOrderStepperActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }

      if (orderStepperActiveStep == 1) {
        console.log(container.containercode);
        setOrderPos2([
          selectedMarker[0],
          selectedMarker[1],
          container.containercode,
        ]);
        setOrderStepperActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (orderStepperActiveStep == 2 && containersToRelocate.length > 0) {
        console.log("Step3?");
        setOrderPos3([
          selectedMarker[0],
          selectedMarker[1],
          container.containercode,
        ]);
        setOrderStepperActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };

    if (createOrderMode && selectedMarker) {
      //console.log(locations);

      execute();
    } else {
      setShowPosInfo(true);
    }
  }, [selectedMarker]);

  const handleMoveBottomStackItem = (container) => {
    console.log(container);
    setOrderPos1([
      container.cranelocation.locationcode,
      container.cranelocation.id,
      container.containercode,
    ]);
    setOrderStepperActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStackInfo([]);
    setContainer(container);
    let containersAbove = containerData.filter(
      (item) =>
        item.cranelocation.id == container.cranelocation.id &&
        item.containercode != container.containercode &&
        item.rowindex > container.rowindex
    );

    if (containersAbove.length > 0) {
      if (containersAbove.length == 2) {
        containersAbove = [...containersAbove].sort(
          (b, a) => a.rowindex - b.rowindex
        );
      }
      setContainersToRelocate(containersAbove);
    }
    console.log("Containeers: ", containersAbove);
  };

  const cancelCreateOrderMode = () => {
    setCreateOrderMode(false);
    setOrderStepperActiveStep(0);
    setSelectedMarker(null);
    setNoContainerError(false);
    setContainersToRelocate([]);
    setMakeReturnOrders(false);
  };

  const createOrders = async () => {
    const currentUser = await getCurrentUser();

    // console.log(containersToRelocate.length);
    // //id, orderType, currentUser, today, topcontainer)
    // if(containersToRelocate.length >= 1){
    //   let today = new Date();
    //   await handleNewOrder(orderPos1[1], 1, currentUser, today, containersToRelocate[0]);

    //   await handleNewOrder(orderPos3[1], 2, currentUser, today, containersToRelocate[0]);
    // }

    // if(containersToRelocate.length == 2){
    //   let today = new Date();
    //   await handleNewOrder(orderPos1[1], 1, currentUser, today, containersToRelocate[1]);

    //   await handleNewOrder(orderPos3[1], 2, currentUser, today, containersToRelocate[1]);
    // }

    let today = new Date();
    await handleNewOrder(orderPos1[1], 1, currentUser, today, container);

    await handleNewOrder(orderPos2[1], 2, currentUser, today, container);

    // if(makeReturnOrders && containersToRelocate.length > 0){
    //   let containers = containersToRelocate.reverse();
    //   if(containersToRelocate.length >= 1){
    //     let today = new Date();
    //     await handleNewOrder(orderPos3[1], 1, currentUser, today, containers[0]);

    //     await handleNewOrder(orderPos1[1], 2, currentUser, today, containers[0]);
    //   }

    //   if(containersToRelocate.length == 2){
    //     let today = new Date();
    //     await handleNewOrder(orderPos3[1], 1, currentUser, today, containers[1]);

    //     await handleNewOrder(orderPos1[1], 2, currentUser, today, containers[1]);
    //   }
    // }

    cancelCreateOrderMode();
  };
  const createPickupOrder = async () => {
    function findHighestRowindexItem(items) {
      return items.reduce((highest, item) => {
        return highest.rowindex > item.rowindex ? highest : item;
      }, items[0]); // Starting with the first item as the initial highest
    }

    const currentUser = await getCurrentUser();
    let today = new Date();

    let containers = await getAllContainersAtLocation(selectedMarker[1]);
    const container = findHighestRowindexItem(containers);
    //console.log(container);
    await handleNewOrder(selectedMarker[1], 1, currentUser, today, container);
  };
  const createLeaveOrder = async () => {
    const currentUser = await getCurrentUser();
    let today = new Date();

    const container = containerInHook;
    //console.log(container);
    await handleNewOrder(selectedMarker[1], 2, currentUser, today, container);
  };
  const createMoveOrder = async () => {
    const currentUser = await getCurrentUser();
    let today = new Date();

    let emptyContainer = await getContainer("-");

    await handleNewOrder(
      selectedMarker[1],
      3,
      currentUser,
      today,
      emptyContainer[0]
    );
  };

  const handleNewOrder = async (
    id,
    orderType,
    currentUser,
    today,
    topcontainer
  ) => {
    //pickup container
    //check for container at the top at to_cranelocation

    //leave container
    //conta

    //if empty, disable pickup button

    // console.log(topcontainer);

    // console.log(selectedMarker);
    const orderBody = {
      id: "",
      timestamp: today.toISOString(),
      ordertype: orderType,
      orderstatus: 1,
      orderpriority: 1,
      haswaypoint: false,
      to_cranelocation_row: 1, // orderType == 1 ? topcontainer.rowindex : 1 ,
      ordercancelled: false,
      container: topcontainer,

      from_cranelocation: {
        id: 1,
        locationcode: "A1",
      },
      to_cranelocation: {
        id: id,
        locationcode: selectedMarker[0],
      },
      product: topcontainer.product,
      crane: {
        id: cranedata.id,
      },
      user: {
        id: currentUser.id,
        login: currentUser.login,
      },
    };
    if (simulation) {
      orderBody.id = simOrderNbr;
      setSimOrderNbr((prev) => prev + 1);
      setSimOrders((prev) => [...prev, orderBody]);
      //toast.success('Simulated order created!');
      handleClose();
    } else {
      const data = await postOrder(orderBody);

      if (data) {
        console.log("Success:", orderBody);
        refetchOrders();
        handleClose();
      } else {
        console.log("Error:", orderBody);
      }
    }
  };

  const enterFullscreen = () => {
    setFullscreen(true);
    handle.enter();
  };

  const exitFullscreen = () => {
    setFullscreen(false);
    handle.exit();
  };

  const saveNewStackedAmount = async (value) => {
    const locationBody = markerData.find(
      (item) => item.id === selectedMarker[1]
    );
    locationBody.rowmax = value + 1;

    const data = await updateLocation(locationBody.id, locationBody);
    if (data) {
      refetchAllMarkers();
      handleClose();
    } else {
      console.log("Error:", locationBody);
    }
  };
  const handleNameChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleStackClick = (container, location, index) => {
    let info = [];

    if (container === undefined) {
      console.log("Manual entry");
      info = ["Empty", index];
    } else {
      console.log("Pickup");

      let product = container.product.productcode;

      info = [product, location[4], container];
    }
    setStackInfo(info);
  };

  const handleInsertProduct = async (index, container) => {
    console.log(index, " ", container);
    container.rowindex = index + 1;
    container.cranelocation = {
      id: selectedMarker[1],
    };
    console.log(selectedMarker);
    console.log(container);
    const patch = await patchContainer(container.id, container);
    setStackInfo([]);
    refetchAllContainers();
  };

  useEffect(() => {
    if (true) {
      setLoadingPercentage((100 / 4) * modelsLoading);
      //setLoadingPercentage(100);
      console.log(
        "Loading percentage: ",
        Object.keys(objFiles).length,
        " ",
        modelsLoading
      );
      if (modelsLoading === 4) {
        // Wait for 400 milliseconds before adding the remaining 10%
        const timeoutId = setTimeout(() => {
          setLoadingPercentage(100);
        }, 300);

        // Clear the timeout if the component unmounts or if the dependencies change
        return () => clearTimeout(timeoutId);
      }
    }
  }, [modelsLoading, objFiles]);

  useEffect(() => {
    switch (craneModelClicked) {
      case "Building":
        toast.info(
          "Building clicked. Displaying some cool info somewhere on the page in the future"
        );
        break;
      case "Trolley":
        toast.info(
          "Trolley clicked. Displaying some cool info somewhere on the page in the future"
        );
        break;
      case "Grabber":
        toast.info(
          "Grabber clicked. Displaying some cool info somewhere on the page in the future"
        );
        break;
      case "Beem":
        toast.info(
          "Beem clicked. Displaying some cool info somewhere on the page in the future"
        );
        break;
      case "":
        break;

      default:
        break;
    }
    setCraneModelClicked("");
  }, [craneModelClicked]);

  useEffect(() => {
    console.log(simOrders);
    if (simOrders[0]?.id > simOrderDone && simOrders[0]?.orderstatus === 1) {
      toast.info("New order received!");

      let updatedArray = simOrders.map((order) => {
        if (order.id === simOrders[0].id) {
          return { ...order, orderstatus: 2 };
        }
        return order;
      });
      setSimOrders(updatedArray);

      setTimeout(() => {
        const location = markerData.find(
          (item) => item.id === simOrders[0].to_cranelocation.id
        );
        console.log(location);

        // Speeds in millimeters per minute (converted from meters per minute)
        const speedXY = 1000 * simXYspeed;
        const speedZ = 1000 * simZspeed;

        // Calculate distances to travel
        const distanceX = Math.abs(location.x - xposSim);
        const distanceY = Math.abs(location.y - yposSim);
        const distanceZ = Math.abs(cranedata.opc.z1poslimitmax - zposSim);

        // Fixed time per step (e.g., 1 second)
        const stepTime = 10; // 1 second in milliseconds

        // Calculate the number of steps based on the duration and fixed step time
        const stepsX = Math.ceil(((distanceX / speedXY) * 60000) / stepTime);
        const stepsY = Math.ceil(((distanceY / speedXY) * 60000) / stepTime);
        const stepsZ = Math.ceil(((distanceZ / speedZ) * 60000) / stepTime);

        // Calculate deltas based on speed and fixed step time
        let deltaX = (speedXY / 60000) * stepTime;
        let deltaY = (speedXY / 60000) * stepTime;
        let deltaZ = (speedZ / 60000) * stepTime;

        // Adjust the direction of movement based on target location
        if (location.x < xposSim) deltaX = -deltaX;
        if (location.y < yposSim) deltaY = -deltaY;
        if (cranedata.opc.z1poslimitmax < zposSim) deltaZ = -deltaZ;

        // Start X movement
        let currentStepX = 0;
        const intervalX = setInterval(() => {
          if (currentStepX < stepsX && simulation) {
            setXposSim((prev) => prev + deltaX);
            currentStepX++;
          } else {
            clearInterval(intervalX);

            // Start Y movement
            let currentStepY = 0;
            const intervalY = setInterval(() => {
              if (currentStepY < stepsY) {
                setYposSim((prev) => prev + deltaY);
                currentStepY++;
              } else {
                clearInterval(intervalY);

                let updatedArray = simOrders.map((order) => {
                  if (order.id === simOrders[0].id) {
                    return { ...order, orderstatus: 4 };
                  }
                  return order;
                });
                setSimOrders(updatedArray);

                // Start Z movement
                let currentStepZ = 0;
                const intervalZ = setInterval(() => {
                  if (currentStepZ < stepsZ && simulation) {
                    setZposSim((prev) => prev + deltaZ);
                    currentStepZ++;
                  } else {
                    clearInterval(intervalZ);

                    const updatedSimOrders = simOrders.filter(
                      (order) => order.id !== simOrders[0].id
                    );
                    console.log("Status: ", updatedSimOrders);
                    setSimOrderDone(simOrders[0].id);
                    setSimOrders(updatedSimOrders);
                    setSimRunningHours((prev) => prev + 1);
                    setSimDistanceTraveled((prev) => prev + 10);
                  }
                }, stepTime);
              }
            }, stepTime);
          }
        }, stepTime);
      }, 100); // Initial delay
    } else {
      //toast.info('No more orders to simulate!');
    }
  }, [simOrders]);

  const onSlideLoaded = (origin, destination, direction) => {
    console.log("Slide: ", destination.index + 1);
    setSlide(destination.index + 1);
  };

  return (
    <div className="h-screen ">
      <ClickAwayListener onClickAway={handleClose}>
        <Stack direction={!smallDevice ? "column" : "row"} gap={5}>
          <Box mt={3} p={2} minHeight="310px">
            <div style={{ position: "relative", width: "100%" }}>
              {open && (
                <div
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    zIndex: 1, // Ensure the backdrop appears below your content
                  }}
                />
              )}
              <div style={{ zIndex: 2, position: "relative" }}>
                <Box
                  display={"flex"}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                  borderRadius={5}
                  width="80px"
                  p={1}
                  gap={0.5}
                  position="absolute"
                  top={8}
                  right={13}
                  zIndex={1}
                >
                  <div className={"flex flex-row gap-2 items-center " + (Live ? "text-red-500" : "text-gray-500")}>
                  <SensorsOutlinedIcon
                    
                    
                  />
                  </div>
                  <p
                    className={"font-semibold " + (Live ? "fff" : "text-gray-500")}
                    
                  >
                    {t(`pages.craneitem.live`)}
                  </p>
                </Box>

                {modelconfdataloading
                  ? true
                  : modelconf.length > 0 && (
                      <Box
                        display={
                          loadingPercentage == 100 &&
                          Object.keys(objFiles).length > 0
                            ? "none"
                            : "flex"
                        }
                        p={1}
                        gap={0.5}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                        position="absolute"
                        height={"100%"}
                        width={"100%"}
                        zIndex={1}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          width={"100%"}
                        >
                          <Box width={"50%"}>
                            <Typography
                              fontWeight="600"
                              color={"white"}
                              fontSize={"30px"}
                            >
                              Loading 3D files
                            </Typography>
                            <Box
                              mt={2}
                              position="relative"
                              width="100%"
                              height="8px"
                              borderRadius={1}
                              bgcolor="rgba(0, 0, 0, 0.8)"
                            >
                              <Box
                                width={`${loadingPercentage}%`}
                                bgcolor={"white"}
                                position="absolute"
                                height="100%"
                                borderRadius={1}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}

                {/* <Box display="flex" gap={2} position="absolute" top={5} left={15} zIndex={1} >
                      <Typography fontSize="15px">Y:{opcData.y1actpos} X:{opcData.xactpos} Z:{wireLength}</Typography>
                    </Box> */}
                <Box
                  width="100%"
                  display={!smallDevice ? "none" : "flex"}
                  justifyContent="center"
                  position="absolute"
                  top={-1}
                >
                  <Box
                    display="flex"
                    p={1.5}
                    justifyContent="space-evenly"
                    top={-10}
                    zIndex={1}
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      width: smallDevice ? "400px" : "60%",
                      height: "40px",
                      clipPath:
                        "polygon(100% 5%, 100% 15%, 90% 100%, 10% 100%, 0% 15%, 0% 5%)",
                    }}
                  >
                    <div className=" h-full w-full flex justify-evenly text-gray-300">
                    <p className="cursor-pointer" onClick={() => handleClick(0)}>
                      {t(`pages.craneitem.top`)}
                    </p>
                    <p className="cursor-pointer" onClick={() => handleClick(2)}>
                      {t(`pages.craneitem.side`)}
                    </p>
                    <p className="cursor-pointer" onClick={() => handleClick(1)}>
                      {t(`pages.craneitem.corner`)}
                    </p>
                    <p className="cursor-pointer" onClick={() => handleClick(3)}>
                      {t(`pages.craneitem.floor`)}
                    </p>
                    </div>
                  </Box>
                </Box>
                {/* <Box display="flex" gap={2} position="absolute" top={45} left={15} zIndex={1} >
                      <Typography fontSize="35px">Order status: </Typography>
                      <Typography fontSize="35px">{orderstatus}</Typography>
                    </Box>
                    <Box display="flex" gap={2} position="absolute" top={85} left={15} zIndex={1} >
                      <Typography fontSize="35px">Weight: </Typography>
                      <Typography fontSize="35px">{cargoWeight}</Typography>
                    </Box> */}
                <Box
                  width="100%"
                  display={!smallDevice ? "none" : "flex"}
                  justifyContent="center"
                  position="absolute"
                  bottom={0}
                >
                  <Box
                    display="flex"
                    gap={2}
                    position="absolute"
                    bottom={2}
                    zIndex={1}
                    sx={{ cursor: "pointer" }}
                  >
                    <Collapse in={infoPanel} collapsedSize={15}>
                      <Box
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.8)",
                          width: "700px",
                          height: "80px",
                          clipPath:
                            "polygon(20% 0%, 80% 0%, 100% 85%, 100% 100%, 0% 100%, 0% 85%)",
                        }}
                        display="flex"
                        justifyContent="center"
                      >
                        {/* {!infoPanel && <Box position="absolute" top={3} zIndex={2} bgcolor="#d3d3d3" width={30} height={0.5} borderRadius={0.5}></Box>} */}

                        <div
                          className=" w-full h-full flex flex-row gap-10 justify-center pt-5"
                          onClick={() => setInfoPanel((prev) => !prev)}
                          onMouseEnter={() => setInfoPanel((prev) => true)}
                        >
                          {tier > 1 && <span
                            className="bg-white w-10 h-10 z-10 rounded-full hover:bg-gray-400 flex justify-center items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              if(tier > 1){
                                setCreateOrderMode(true);
                              }
                            }}
                          >
                            <MoveDownOutlinedIcon />
                          </span>}
                          {tier > 1 &&<span
                            className="bg-white w-10 h-10 z-10 rounded-full hover:bg-gray-400 flex justify-center items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpen();
                            }}
                          >
                            <AddLocationAltOutlinedIcon />
                          </span>}
                          {tier > 1 &&<span
                            className="bg-white w-10 h-10 z-10 rounded-full hover:bg-gray-400 flex justify-center items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              setCreateBlockedZone(true);
                            }}
                          >
                            
                            <BlockOutlinedIcon />
                          </span>}
                          <span
                            className="bg-white w-10 h-10 z-10  rounded-full hover:bg-gray-400 flex justify-center items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              enterFullscreen();
                            }}
                          >
                            <FullscreenOutlinedIcon />
                          </span>
                          <span
                            className="bg-white w-10 h-10 z-10 rounded-full hover:bg-gray-400 flex justify-center items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              toast.info(
                                "3D enviroment help info panel coming soon // NoSwing Dev Team"
                              );
                            }}
                          >
                            <QuestionMarkOutlinedIcon />
                          </span>
                        </div>
                      </Box>
                    </Collapse>
                  </Box>
                </Box>

                <FullScreen handle={handle}>
                  <Box
                    display={createBlockedZone ? "flex" : "none"}
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                    borderRadius={5}
                    p={2}
                    gap={0.5}
                    position="absolute"
                    bottom={8}
                    left={130}
                    zIndex={1}
                  >
                    <Button
                      onClick={() => postZone(true, [])}
                      variant="contained"
                      color="secondary"
                    >
                      {Zone[6] == false ? "Activate Zone" : "Update Zone"}
                    </Button>
                    <Button
                      onClick={() => postZone(false, [])}
                      variant="contained"
                      color="secondary"
                      disabled={!Zone[6]}
                    >
                      Disable zone
                    </Button>
                  </Box>
                  {simulation && (
                    <div className="absolute bottom-10 w-full flex justify-center ">
                      <h1 className="font-bold text-5xl animate-[pulse_5s_ease-in-out_infinite] opacity-3 z-20 text-red-600">
                        SIMULATION MODE ACTIVE
                      </h1>
                    </div>
                  )}

                  {stackInfo.length > 0 && (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        display="flex"
                        justifyContent={"center"}
                        position="absolute"
                        gap={1}
                        bottom={fullscreen ? 700 : 180}
                        left={fullscreen ? 700 : 250}
                        width={"300px"}
                        height={createOrderMode ? "250px" : "200px"}
                        zIndex={1}
                        borderRadius="5px"
                        p={1}
                        bgcolor="rgba(0, 0, 0, 0.8)"
                        sx={{
                          transform: fullscreen ? "scale(2)" : "none",
                          transformOrigin: "top left",
                        }}
                      >
                        <Box
                          position="absolute"
                          top={-1}
                          right={-1}
                          onClick={() => setStackInfo([])}
                          sx={{ cursor: "pointer" }}
                        >
                          <CloseOutlinedIcon
                            color="secondary"
                            fontSize="medium"
                          />
                        </Box>
                        {stackInfo[0] !== "Empty" ? (
                          <Box>
                            <Typography color={"white"} variant="h3">
                              Selected Product
                            </Typography>
                            <Stack
                              direction={"row"}
                              mt={4}
                              justifyContent={"space-evenly"}
                              spacing={3}
                            >
                              <Stack spacing={1}>
                                <Stack direction={"row"} spacing={1}>
                                  <Typography
                                    color={"white"}
                                    fontWeight={"600"}
                                  >
                                    Productcode:{" "}
                                  </Typography>
                                  <Typography color={"white"}>
                                    {stackInfo[0]}
                                  </Typography>
                                </Stack>
                                <Stack direction={"column"} spacing={0}>
                                  <Typography
                                    color={"white"}
                                    fontWeight={"600"}
                                  >
                                    Description:{" "}
                                  </Typography>
                                  <Typography color={"white"}>
                                    This is a{" "}
                                    {stackInfo[0] == "333"
                                      ? "green"
                                      : stackInfo[0] == "111"
                                      ? "yellow"
                                      : "blue"}{" "}
                                    box
                                  </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1}>
                                  <Typography
                                    color={"white"}
                                    fontWeight={"600"}
                                  >
                                    Size:{" "}
                                  </Typography>
                                  <Typography color={"white"}>
                                    {stackInfo[0] == "333"
                                      ? "400x400"
                                      : stackInfo[0] == "111"
                                      ? "600x400"
                                      : "700x300"}
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Box
                                width="120px"
                                height={"110px"}
                                bgcolor={"rgba(0, 0, 0, 0.8)"}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                borderRadius={"5px"}
                              >
                                {getImageBasedOnNumber(stackInfo[0], 100)}
                              </Box>
                            </Stack>
                            {createOrderMode && (
                              <Box display={"flex"} justifyContent={"center"}>
                                <Button
                                  onClick={() =>
                                    handleMoveBottomStackItem(stackInfo[2])
                                  }
                                  variant="contained"
                                  color="secondary"
                                  sx={{ mt: 2.5 }}
                                  size="small"
                                >
                                  Move this product
                                </Button>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Box>
                            <Typography color={"white"} variant="h3">
                              Edit Stack Database
                            </Typography>
                            <Typography color={"white"} variant="h5" mt={5}>
                              Choose product to insert
                            </Typography>
                            <Stack direction={"row"} spacing={2} mt={1}>
                              {containerData
                                .filter((item) => item.containercode !== "-")
                                .map((item) => (
                                  <Box
                                    width="70px"
                                    height={"60px"}
                                    bgcolor={"rgba(0, 0, 0, 0.7)"}
                                    borderRadius={"5px"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleInsertProduct(stackInfo[1], item)
                                    }
                                  >
                                    {getImageBasedOnNumber(
                                      item.product.productcode
                                    )}
                                    <Typography
                                      color={"white"}
                                      sx={{
                                        position: "absolute",
                                        marginTop: "-13px",
                                      }}
                                    ></Typography>
                                  </Box>
                                ))}
                            </Stack>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}

                  {/* {fullscreen && (
                    <Box
                      position={"absolute"}
                      right={40}
                      top={80}
                      zIndex={1}
                      bgcolor={"rgba(0, 0, 0, 0.8)"}
                      p={3}
                      borderRadius={"5px"}
                    >
                      <Box>
                        <Typography variant="h4" color={"white"}>
                          WMS Simulator
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={4}
                        display={"flex"}
                        justifyContent={"space-around"}
                        alignItems={"center"}
                        mt={1}
                      >
                        <Stack>
                          <Box
                            bgcolor={"white"}
                            borderRadius={"5px"}
                            width={"100px"}
                            height={"105px"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            mt={2}
                          >
                            <img
                              src={Long}
                              alt="product"
                              width={"100px"}
                              height={"100px"}
                            />
                          </Box>
                          <Stack
                            display={"flex"}
                            spacing={0}
                            direction={"row"}
                            mt={1}
                            ml={-1}
                          >
                            <IconButton
                              disabled={
                                orderdata?.length > 0 || !Live || isDisabled
                              }
                              onClick={() => postWMSOrder(12, "recieve")}
                              color="black"
                              size="large"
                            >
                              <AddOutlinedIcon
                                fontSize="large"
                                sx={{ color: "white" }}
                              />
                            </IconButton>
                            <IconButton
                              disabled={
                                orderdata?.length > 0 || !Live || isDisabled
                              }
                              onClick={() => postWMSOrder(12, "deliver")}
                              color="black"
                              size="large"
                            >
                              <LocalShippingOutlinedIcon
                                fontSize="large"
                                sx={{ color: "white" }}
                              />
                            </IconButton>
                          </Stack>
                        </Stack>
                        <Stack>
                          <Box
                            bgcolor={"white"}
                            borderRadius={"5px"}
                            width={"100px"}
                            height={"105px"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            mt={2}
                          >
                            <img
                              src={Short}
                              alt="product"
                              width={"100px"}
                              height={"100px"}
                            />
                          </Box>
                          <Stack
                            display={"flex"}
                            spacing={0}
                            direction={"row"}
                            mt={1}
                            ml={-1}
                          >
                            <IconButton
                              disabled={
                                orderdata?.length > 0 || !Live || isDisabled
                              }
                              onClick={() => postWMSOrder(13, "recieve")}
                              color="black"
                              size="large"
                            >
                              <AddOutlinedIcon
                                fontSize="large"
                                sx={{ color: "white" }}
                              />
                            </IconButton>
                            <IconButton
                              disabled={
                                orderdata?.length > 0 || !Live || isDisabled
                              }
                              onClick={() => postWMSOrder(13, "deliver")}
                              color="black"
                              size="large"
                            >
                              <LocalShippingOutlinedIcon
                                fontSize="large"
                                sx={{ color: "white" }}
                              />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  )} */}

                  {opcData.parameter2 != 0 && !createOrderMode && (
                    <Box
                      display="flex"
                      alignItems="center"
                      position="absolute"
                      gap={1}
                      bottom={25}
                      left={25}
                      zIndex={1}
                      borderRadius="5px"
                      p={1}
                    >
                      {opcData.parameter2}
                    </Box>
                  )}
                  {open && newMarkerPosition.length == 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      position="absolute"
                      gap={1}
                      bottom={25}
                      right={385}
                      zIndex={1}
                      borderRadius="5px"
                      p={1}
                    >
                      <Button
                        variant="contained"
                        onClick={handleSaveMarkerAtCranePos}
                        sx={{ bgcolor: "rgba(0, 0, 0, 0.8)" }}
                      >
                        Set At Crane Position
                      </Button>
                      <Button
                        sx={{ bgcolor: "rgba(0, 0, 0, 0.8)" }}
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {newMarkerPosition.length > 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      position="absolute"
                      gap={1}
                      bottom={25}
                      right={385}
                      zIndex={1}
                      bgcolor="#d3d3d3"
                      borderRadius="5px"
                      p={1}
                      border={1}
                    >
                      <TextField
                        label="Location name"
                        variant="outlined"
                        value={inputValue}
                        onChange={handleNameChange}
                      />
                      <Button variant="outlined" onClick={handleSaveMarker}>
                        {t("pages.craneitem.save")}
                      </Button>
                      <Button variant="outlined" onClick={handleClose}>
                        {t("pages.craneitem.discard")}
                      </Button>
                    </Box>
                  )}
                  {selectedMarker && (
                    <Box
                      display="flex"
                      alignItems="center"
                      position="absolute"
                      gap={1}
                      bottom={
                        fullscreen && isMobile ? 12 : fullscreen ? 300 : 55
                      }
                      right={isMobile ? 140 : 125}
                      zIndex={1}
                      bgcolor="rgba(0, 0, 0, 0.8)"
                      borderRadius="5px"
                      p={1}
                      border={1}
                      sx={{
                        transform:
                          isMobile && fullscreen
                            ? "scale(0.8)"
                            : fullscreen
                            ? "scale(2)"
                            : "none",
                        transformOrigin: "bottom right",
                      }}
                    >
                      <Box
                        position="absolute"
                        top={-1}
                        right={-1}
                        onClick={handleClose}
                        sx={{ cursor: "pointer" }}
                      >
                        <CloseOutlinedIcon
                          color="secondary"
                          fontSize="medium"
                        />
                      </Box>
                      <Stack display="flex" gap={1} justifyContent="center">
                        <Stack direction="column" alignItems="center">
                          <Tooltip
                            arrow
                            sx={{ cursor: "pointer" }}
                            placement="top"
                            title={`Y: ${selectedMarker[2]} X: ${selectedMarker[3]} Z:6374`}
                          >
                            <Typography color="#FFFFFF" fontSize="24px">
                              {selectedMarker[0]}
                            </Typography>
                          </Tooltip>

                          {/* <Button onClick={handleUpdateMarker}>Hide</Button> */}
                          <Button
                            startIcon={
                              <DeleteOutlineOutlinedIcon fontSize="small" />
                            }
                            sx={{ mt: 0.5 }}
                            onClick={handleUpdateMarker}
                            size="small"
                            variant="text"
                            style={{ color: "white" }}
                          >
                            {"Delete"}
                          </Button>
                        </Stack>

                        <Stack
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={0}
                          width={"100px"}
                          spacing={1}
                        >
                          {/* <Typography color="#FFFFFF" fontSize="14px">Make order:</Typography> */}
                          <Button
                            sx={{ width: "100%" }}
                            color="secondary"
                            variant="contained"
                            onClick={() => createMoveOrder()}
                          >
                            {"Go here"}
                          </Button>

                          {!containerInHook &&
                            fullMarkerData.filter(
                              (item) => item.locationcode == selectedMarker[0]
                            )[0].stack.length > 0 && (
                              <Button
                                sx={{ width: "100%" }}
                                color="secondary"
                                variant="contained"
                                onClick={createPickupOrder}
                              >
                                {t("pages.craneitem.pickupitem")}
                              </Button>
                            )}

                          {containerInHook && (
                            <Button
                              color="secondary"
                              variant="contained"
                              sx={{ width: "100%" }}
                              onClick={createLeaveOrder}
                            >
                              {t("pages.craneitem.leaveitem")}
                            </Button>
                          )}
                        </Stack>
                        <Divider />
                        <Stack
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={1}
                        >
                          <Typography
                            color="#FFFFFF"
                            fontWeight="600"
                            fontSize="19px"
                          >
                            {"Stack"}
                          </Typography>
                          {/* <Typography fontSize="24px">{selectedMarker[4] - 1}</Typography> */}
                          {/* <NumberScroller initialValue={selectedMarker[4].length} onSave={saveNewStackedAmount} /> */}
                          {/* Load in stack from api */}
                          <Stack gap={1} mt={2} mb={1}>
                            <Box
                              width="70px"
                              height={"60px"}
                              bgcolor={"rgba(0, 0, 0, 0.7)"}
                              borderRadius={"5px"}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleStackClick(
                                  containerData
                                    .filter(
                                      (item) =>
                                        item.cranelocation.locationcode ==
                                          selectedMarker[0] &&
                                        item.rowindex == 3
                                    )
                                    .map((item) => item)[0],
                                  selectedMarker,
                                  2
                                )
                              }
                            >
                              {getImageBasedOnNumber(
                                containerData
                                  .filter(
                                    (item) =>
                                      item.cranelocation.locationcode ==
                                        selectedMarker[0] && item.rowindex == 3
                                  )
                                  .map((item) => item.product.productcode)[0]
                              )}
                              <Typography
                                color={"white"}
                                sx={{
                                  position: "absolute",
                                  marginTop: "-13px",
                                }}
                              ></Typography>
                            </Box>
                            <Box
                              width="70px"
                              height={"60px"}
                              bgcolor={"rgba(0, 0, 0, 0.7)"}
                              borderRadius={"5px"}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleStackClick(
                                  containerData
                                    .filter(
                                      (item) =>
                                        item.cranelocation.locationcode ==
                                          selectedMarker[0] &&
                                        item.rowindex == 2
                                    )
                                    .map((item) => item)[0],
                                  selectedMarker,
                                  1
                                )
                              }
                            >
                              {getImageBasedOnNumber(
                                containerData
                                  .filter(
                                    (item) =>
                                      item.cranelocation.locationcode ==
                                        selectedMarker[0] && item.rowindex == 2
                                  )
                                  .map((item) => item.product.productcode)[0]
                              )}
                              <Typography
                                color={"white"}
                                sx={{
                                  position: "absolute",
                                  marginTop: "-13px",
                                }}
                              ></Typography>
                            </Box>
                            <Box
                              width="70px"
                              height={"60px"}
                              bgcolor={"rgba(0, 0, 0, 0.7)"}
                              borderRadius={"5px"}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleStackClick(
                                  containerData
                                    .filter(
                                      (item) =>
                                        item.cranelocation.locationcode ==
                                          selectedMarker[0] &&
                                        item.rowindex == 1
                                    )
                                    .map((item) => item)[0],
                                  selectedMarker,
                                  0
                                )
                              }
                            >
                              {getImageBasedOnNumber(
                                containerData
                                  .filter(
                                    (item) =>
                                      item.cranelocation.locationcode ==
                                        selectedMarker[0] && item.rowindex == 1
                                  )
                                  .map((item) => item.product.productcode)[0]
                              )}
                              <Typography
                                color={"white"}
                                sx={{
                                  position: "absolute",
                                  marginTop: "-13px",
                                }}
                              ></Typography>
                            </Box>
                          </Stack>

                          {/* {selectedMarker[4].map(number => (
                          <Typography key={number}>{number}</Typography>
                        ))} */}
                        </Stack>
                      </Stack>
                    </Box>
                  )}

                  {fullscreen && orderdata && (
                    <Box
                      position={"absolute"}
                      top={isMobile ? 15 : 60}
                      left={createOrderMode ? (isMobile ? 220 : 540) : 40}
                      zIndex={1}
                      sx={{
                        transform: isMobile ? "scale(0.4)" : "none",
                        transformOrigin: "top left",
                      }}
                    >
                      <MiniOrderTable
                        data={transformResponseData(orderdata, true)}
                      />
                    </Box>
                  )}
                  {/* new order stepper */}
                  <Box
                    display={createOrderMode ? "wrap" : "none"}
                    borderRadius={5}
                    p={1}
                    gap={0.5}
                    position="absolute"
                    top={isMobile ? 3 : 28}
                    left={13}
                    zIndex={1}
                    sx={{
                      transform: fullscreen && !isMobile ? "scale(2)" : "none",
                      transformOrigin: "top left",
                    }}
                  >
                   
                    <CustomStepper
                      setMakeReturnOrders={setMakeReturnOrders}
                      containersToRelocate={containersToRelocate}
                      noContainerError={noContainerError}
                      orderStepperActiveStep={orderStepperActiveStep}
                      setOrderStepperActiveStep={setOrderStepperActiveStep}
                      orderPos1={orderPos1}
                      orderPos2={orderPos2}
                      orderPos3={orderPos3}
                      createOrders={createOrders}
                    />
                    <Button
                      variant="outlined"
                      sx={{ mt: isMobile ? 1 : 5 }}
                      onClick={cancelCreateOrderMode}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Box
                    bgcolor="#fff"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={fullscreen ? "100%" : "500px"}
                    width={fullscreen ? "100%" : "950px"}
                  >
                    <Box
                      mt={2}
                      display={"flex"}
                      gap={1}
                      position="absolute"
                      bottom={15}
                      right={15}
                      zIndex={1}
                      sx={{
                        transform:
                          fullscreen && !isMobile ? "scale(2)" : "none",
                        transformOrigin: "bottom right",
                      }}
                    >
                      {/* {isMobile ? <Button variant='contained' onClick={() => setCreateOrderMode(true)}>Create Order</Button> : 
                         <div className='z-30'>
                         <BasicSpeedDial setView={setView} dimBackground={handleOpen} enterFullscreen={enterFullscreen} setCreateOrderMode={setCreateOrderMode} setCreateBlockedZone={setCreateBlockedZone}/>
                         </div>
                        } */}
                    </Box>

                    <div
                      className="w-full h-full "
                      onMouseEnter={(event) => {
                        event.stopPropagation();
                      }}
                      onDoubleClick={
                        fullscreen ? exitFullscreen : enterFullscreen
                      }
                    >
                      <Scene2
                        lock={false}
                        debug={debug}
                        objFiles={objFiles}
                        twobeems={false}
                        setCraneModelClicked={setCraneModelClicked}
                        doesBeem1HaveTwoTrolleys={false}
                        doesBeem2HaveTwoTrolleys={false}
                        setCenterArray={setCenterArray}
                        confdata={cranedata?.craneconfiguration[0] || 1}
                        configured={
                          modelconfdataloading
                            ? true
                            : modelconf.length > 0
                            ? true
                            : false
                        }
                        opcData={cranedata.opc}
                        xposSim={xposSim}
                        yposSim={yposSim}
                        zposSim={zposSim}
                        createBlockedZone={createBlockedZone}
                        tempZone={tempZone}
                        Zone={Zone}
                        obj={obj}
                        files={files}
                        values={values}
                        className="my-component"
                        view={1}
                        setMarker={open}
                        setNewMarkerPosition={setNewMarkerPosition}
                        markerdata={fullMarkerDataV2}
                        selectedMarker={selectedMarker}
                        setSelectedMarker={setSelectedMarker}
                        setTempMarker={setTempMarker}
                        tempMarker={tempMarker}
                        cargoWeight={cargoWeight}
                        crane={cranedata.id}
                        cargoProduct={
                          containerInHook
                            ? containerInHook?.product?.productcode
                            : 0
                        }
                      />
                    </div>

                    {fullscreen && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          height: "40px",
                          width: "40px",
                          top: 15,
                          right: 15,
                          border: 1,
                        }}
                        onClick={exitFullscreen}
                      >
                        <CloseOutlinedIcon size="large"></CloseOutlinedIcon>
                      </IconButton>
                    )}
                  </Box>
                </FullScreen>
              </div>
            </div>
            {((opcData?.xposlimitmin == 0 && opcData?.xposlimitmax == 0) ||
              (opcData?.y1poslimitmin == 0 && opcData?.y1poslimitmax == 0) ||
              (opcData?.z1poslimitmin == 0 && opcData?.z1poslimitmax == 0)) && (
              <div className="mt-2">
                <WarningMessage />
              </div>
            )}
            {/* {Live == false && cranedata.id == 4504 && <Typography variant='h4' mt={1}>Backend for the simulation is offline. </Typography>}
              {(cranedata.id == 4504 || cranedata.id == 4507) && 
                <Accordion sx={{mt: 4 , borderRadius: "5px"}} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography variant='h4'>WMS simulator</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Box p={2}>
                

                
                <Stack direction={"row"} spacing={5}>
                    <Stack>
                      <Box p={1} border={1} borderRadius={"5px"} width={"210px"} height={"205px"} display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2}>
                          <img src={Short} alt='product' width={"200px"} height={"200px"} />
                      </Box>
                      <Box mt={1} width={"210px"} display={"flex"} justifyContent={"center"}>
                        <NumberScroller demand={demand} setDemand={setDemand}/>
                      </Box>
                      <Stack display={"flex"} spacing={2} direction={"row"} mt={1}>
                        <Button disabled={orderdata?.length > 0 || !Live || isDisabled || containerInHook} startIcon={<AddOutlinedIcon />} onClick={() => postWMSOrder(13, "recieve")} variant='contained'>Create</Button>
                        <Button disabled={orderdata?.length > 0 || !Live || isDisabled || containerInHook} startIcon={<LocalShippingOutlinedIcon/>} onClick={() => postWMSOrder(13, "deliver")} variant='contained'>Deliver</Button>
                      </Stack>
                      <Typography display={containerInHook && orderdata?.length == 0 ? "flex" : "none"} mt={1} color={"red"}>Cargo In Hook</Typography>
                    </Stack>
                    <Box>
                      
                      <Typography fontSize={"20px"} fontWeight={"600"} mt={1}>Rules for storing product in warehouse</Typography>
                      <Typography>- Choose a location on the "A" side</Typography>
                      <Typography>- Choose closest available location</Typography>
                      <Typography>- Number of products per stack: 2</Typography>
                      <Typography fontSize={"20px"} fontWeight={"600"} mt={2}>Rules for selecting product for deliver</Typography>
                      <Typography>- Get closest available product</Typography>
                    </Box>
                </Stack>

                

                <Stack direction={"row"} spacing={5} mt={7}>
                    <Stack>
                      <Box p={1} border={1} borderRadius={"5px"} width={"210px"} height={"205px"} display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2}>
                          <img src={Long} alt='product' width={"200px"} height={"200px"} />
                      </Box>
                      <Box mt={1} width={"210px"} display={"flex"} justifyContent={"center"}>
                        <NumberScroller demand={demand} setDemand={setDemand}/>
                      </Box>
                      <Stack display={"flex"} spacing={2} direction={"row"} mt={1}>
                        <Button disabled={orderdata?.length > 0 || !Live || isDisabled} startIcon={<AddOutlinedIcon/>} onClick={() => postWMSOrder(12, "recieve")} variant='contained'>Create</Button>
                        <Button disabled={orderdata?.length > 0 || !Live || isDisabled} startIcon={<LocalShippingOutlinedIcon/>} onClick={() => postWMSOrder(12, "deliver")} variant='contained'>Deliver</Button>
                      </Stack>
                      
                    <Typography display={containerInHook && orderdata?.length == 0 ? "flex" : "none"} mt={1} color={"red"}>Cargo In Hook</Typography>
                    
                    </Stack>
                    <Box>
                      
                      <Typography fontSize={"20px"} fontWeight={"600"} mt={1}>Rules for storing product in warehouse</Typography>
                      <Typography>- Choose a location on the "B" side</Typography>
                      <Typography>- Choose closest available location</Typography>
                      <Typography>- Number of products per stack: 4</Typography>
                      <Typography fontSize={"20px"} fontWeight={"600"} mt={2}>Rules for selecting product for deliver</Typography>
                      <Typography>- Get closest available product</Typography>
                    </Box>
                </Stack>
                
                
              </Box>
                </AccordionDetails>
              </Accordion>
              } */}
            {tier > 1 && (
              <Box borderRadius={1} p={1} mt={4}>
                <Box display="flex" mb={2}>
                  <p className="text-xl font-semibold">
                    {t(`pages.craneitem.orders`)}
                  </p>
                  {/* <FormDialog setData={setData}/> */}
                </Box>
                <Table
                  simOrders={simOrders}
                  setSimOrders={setSimOrders}
                  simulation={simulation}
                  data={
                    simulation
                      ? transformResponseData(simOrders, false)
                      : orderdata
                      ? transformResponseData(orderdata, false)
                      : []
                  }
                  refetchOrders={refetchOrders}
                  opcData={opcData}
                />
              </Box>
            )}
            {/* end of column */}
          </Box>
          <Stack
            borderLeft={0}
            p={1}
            borderColor="#d3d3d3"
            mt={1}
            flexDirection={"row"}
            gap={2}
            sx={{ display: { xs: "none", md: "none", lg: "flex" } }}
          >
            {/* <CircleStack  selected={slide}/> */}

            <div className="w-full  flex grow justify-end ">
              <div className=" overflow-auto snap-y snap-mandatory p-2 flex flex-col h-[50rem]  w-72 ">
                {/* <ScrollableSidepanel /> */}
                <div className="section h-[50rem] flex flex-col snap-start grow ">
                  <div className=" flex flex-col h-[50rem]  ">
                    <Box>
                      <p className="text-xl font-semibold mb-3">
                        {t("pages.craneitem.today")}
                      </p>
                      {Object.keys(loggeddata[0]).map((key, index) => (
                        <Box
                          key={index}
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          width="200px"
                          borderRadius="5px"
                          mb={3}
                          gap={3}
                          ml={1}
                        >
                          <Box
                            height={50}
                            width={50}
                            borderRadius={25}
                            bgcolor="#d3d3d3"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {getIcon(index)}
                          </Box>
                          <Stack
                            direction="column"
                            display="flex"
                            justifyContent="left"
                          >
                            <p className="text-2xl font-bold">
                              {index == 3 || index == 4
                                ? "-"
                                : index == 0
                                ? opcData[key] < 60
                                  ? +opcData[key] + " min"
                                  : parseInt(opcData[key] / 60) +
                                    " h " +
                                    (opcData[key] % 60) +
                                    " min"
                                : opcData[key]}
                            </p>
                            <p className="text-gray-500">
                              {loggeddata[0][key]}
                            </p>
                          </Stack>
                        </Box>
                      ))}
                    </Box>
                    {
                      <Box borderRadius={1} p={1} mt={5}>
                        <p className="text-xl font-semibold mb-3">
                          {t("pages.craneitem.functionsenabled")}
                        </p>
                        <div onClick={() => setClickedAntisway(true)}>
                          <CraneFunction
                            functionName="AntiSway"
                            functionImage={AntiSwayIcon}
                            enabled={opcData.antiswaystatus == 1}
                            id={cranedata.id}
                          />
                          <AntiSwayDialog
                            open={clickedAntisway}
                            setOpen={setClickedAntisway}
                          />
                        </div>
                        {/* <CraneFunction functionName="AntiSnag" functionImage={AntiSnagIcon} enabled={opcData.antisnagstatus == 1}/>
                          <CraneFunction functionName="HookCentering" functionImage={HookCenteringIcon} enabled={false}/>
                          <CraneFunction functionName="FollowMe" functionImage={FollowMeIcon} enabled={false}/> */}
                      </Box>
                    }
                  </div>
                </div>

                <div className="">
                  <div className=" h-[50rem] flex flex-col snap-start  grow ">
                    <p className="text-lg font-semibold mb-2">OPC data</p>
                    <div className="flex flex-col bg-nsblue-50 rounded-lg gap-0 mr-10">
                      <div className=" p-3">
                        <p className="text-lg font-bold">X axis </p>
                        <div className="p-1 pl-2">
                          <p>
                            Actual position:{" "}
                            {simulation ? parseInt(xposSim) : opcData?.xactpos}
                          </p>
                          <p>Endlimit min: {opcData?.xposlimitmin}</p>
                          <p>Endlimit max: {opcData?.xposlimitmax}</p>
                          <p>Speed: {simulation ? "-" : opcData?.xactspd}</p>
                          {/* <div className='flex flex-row'>
                            <p>{opcData?.xposlimitmin}</p>
                            <div className='border-b-2 border-black w-full '></div>
                            {opcData?.xposlimitmax}
                          </div> */}
                        </div>
                      </div>

                      <div className=" p-3">
                        <p className="text-lg font-bold">Y axis </p>
                        <div className="p-1 pl-2">
                          <p>
                            Actual position:{" "}
                            {simulation ? parseInt(yposSim) : opcData?.y1actpos}
                          </p>
                          <p>Endlimit min: {opcData?.y1poslimitmin}</p>
                          <p>Endlimit max: {opcData?.y1poslimitmax}</p>
                          <p>Speed: {simulation ? "-" : opcData?.y1actspd}</p>
                        </div>
                      </div>

                      <div className=" p-3">
                        <p className="text-lg font-bold">Z axis </p>
                        <div className="p-1 pl-2">
                          <p>
                            Actual position:{" "}
                            {simulation ? parseInt(zposSim) : opcData?.z1actpos}
                          </p>
                          <p>Endlimit min: {opcData?.z1poslimitmin}</p>
                          <p>Endlimit max: {opcData?.z1poslimitmax}</p>
                          <p>Speed: {simulation ? "-" : opcData?.z1actspd}</p>
                        </div>
                      </div>
                    </div>

                    {/* <div className='mt-2'>
                        <p>OPC communication: {simulation ? "-" : opcData?.communicationstatus}</p>
                        </div> */}

                    <div className="flex flex-col bg-nsblue-50 rounded-lg gap-0 mr-10 mt-5">
                      <div className="p-3">
                        <p className="text-lg font-bold">Order </p>
                        <div className="p-1 pl-2">
                          <p>
                            WorkOrderNbr:{" "}
                            {simulation ? simOrderNbr : opcData?.workordernbr}
                          </p>
                          <p>
                            WorkOrderDone:{" "}
                            {simulation ? simOrderDone : opcData?.workorderdone}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <button onClick={() => fullpageApi.moveSectionDown()}>
                          Click me to move down
                        </button> */}
                  </div>
                </div>
                <div className="flex flex-col snap-start  ">
                  {modelconf?.length > 0 && Object.keys(objFiles).length > 0 && (
                    <div className="px-5 h-[50rem]">
                      <Stack>
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-between gap-2"
                        >
                          <span className="flex flex-grow flex-col">
                            <Switch.Label
                              onDoubleClick={() => setDebug((prev) => !prev)}
                              as="span"
                              className="text-sm font-medium leading-6 text-gray-900"
                              passive
                            >
                              Simulation mode
                            </Switch.Label>
                          </span>
                          <Switch
                            checked={simulation}
                            label="Simulation"
                            onChange={() => setSimulation((prev) => !prev)}
                            className={classNames(
                              simulation ? "bg-indigo-600" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                simulation ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                        <span className="p-2 rounded-lg bg-slate-200 mt-3">
                          With the simulation mode active you can create orders
                          and simulate crane movement without affecting the real
                          crane.
                        </span>

                        <div className="flex grow flex-col mt-4">
                          X: {parseInt(yposSim)}
                          <Slider
                            value={
                              yposSim == null
                                ? cranedata?.opc.y1actpos
                                : yposSim || 0
                            }
                            onChange={(event, value) => {
                              setYposSim(value);
                              setSimulation(true);
                              xposSim == null &&
                                setXposSim(cranedata?.opc.xactpos);
                              zposSim == null &&
                                setZposSim(cranedata?.opc.z1actpos);
                            }}
                            min={cranedata?.opc.y1poslimitmin}
                            max={cranedata?.opc.y1poslimitmax}
                            step={1}
                            sx={{ width: "100%" }}
                          />
                        </div>
                        <div className="flex grow flex-col mt-4">
                          Y: {parseInt(xposSim)}
                          <Slider
                            value={
                              xposSim == null
                                ? cranedata?.opc.xactpos
                                : xposSim || 0
                            }
                            onChange={(event, value) => {
                              setXposSim(value);
                              setSimulation(true);
                              yposSim == null &&
                                setYposSim(cranedata?.opc.y1actpos);
                              zposSim == null &&
                                setZposSim(cranedata?.opc.z1actpos);
                            }}
                            min={cranedata?.opc.xposlimitmin}
                            max={cranedata?.opc.xposlimitmax}
                            step={1}
                            sx={{ width: "100%" }}
                          />
                        </div>
                        <div className="flex grow flex-col mt-4">
                          Z: {parseInt(zposSim)}
                          <Slider
                            value={
                              zposSim == null
                                ? cranedata?.opc.z1actpos
                                : zposSim || 0
                            }
                            onChange={(event, value) => {
                              setZposSim(value);
                              setSimulation(true);
                              xposSim == null &&
                                setXposSim(cranedata?.opc.xactpos);
                              yposSim == null &&
                                setYposSim(cranedata?.opc.y1actpos);
                            }}
                            min={cranedata?.opc.z1poslimitmin}
                            max={cranedata?.opc.z1poslimitmax}
                            step={1}
                            sx={{ width: "100%" }}
                          />
                        </div>
                        <div className="sm:col-span-2 mt-5">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            XY speed in meters/minute
                          </label>
                          <div className="mt-2">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              defaultValue={simXYspeed}
                              onChange={(event) =>
                                setSimXYspeed(event.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-2 mt-5">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Z speed in meters/minute
                          </label>
                          <div className="mt-2">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              defaultValue={simZspeed}
                              onChange={(event) =>
                                setSimZspeed(event.target.value)
                              }
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </Stack>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Stack>
        </Stack>
      </ClickAwayListener>
    </div>
  );
};

export default RealtimeTab;

const CraneFunction = ({ functionName, functionImage, enabled, id }) => {
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [loading, setLoading] = useState(false);
  let body = {
    id: id,
    cranestop: true,
  };

  const handlePopoverOpen = (event, content) => {
    event.stopPropagation();
    setPopoverContent(content);
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    event.stopPropagation();
    setPopoverAnchor(null);
  };

  useEffect(() => {
    setLoading(false);
  }, [enabled]);

  const toggleAntisway = async () => {
    setLoading(true);

    if (enabled) {
      console.log("Off");
      body.cranestop = false;
    } else {
      console.log("On");
      body.cranestop = true;
    }
    const patch = await patchCrane(body);
    //
  };

  const openPopover = Boolean(popoverAnchor);
  return (
    <Box display={"flex"} flexDirection={"row"} gap={1}>
      <Box
        borderRadius="10px"
        display="flex"
        justifyContent="space-between"
        p={1.5}
        alignItems="center"
        mb={1}
        boxShadow={1}
        bgcolor={"#fff"}
        sx={{ cursor: "pointer" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={3}
        >
          <img
            src={functionImage}
            alt="AntiSwayIcon"
            style={{
              width: "45px",
              height: "45px",
              borderRadius: "5px",
            }}
          />
          <Stack>
            {" "}
            <Typography mb={1} variant="h3" fontSize="22px" color={"#000"}>
              {" "}
              {functionName}
            </Typography>
          </Stack>
          {/* <Box bgcolor={enabled ? "green" : "red"} width={15} height={15} borderRadius={8} mr={5} ml={-2}></Box> */}
        </Box>
        <IconButton
          onClick={(event) =>
            handlePopoverOpen(event, craneFunctionInfo[functionName])
          }
          sx={{
            mb: 0.5,
            fontSize: "24px",
            color: "grey",
            cursor: "pointer",
            ml: 2,
          }}
        >
          <HelpOutlineOutlinedIcon />
        </IconButton>
        <Popover
          open={openPopover}
          anchorEl={popoverAnchor}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Typography sx={{ p: 2 }} maxWidth="200px">
            {popoverContent}
          </Typography>
        </Popover>
        {/* <Switch
              checked={enabled}
              onChange={toggleAntisway}
              inputProps={{ 'aria-label': 'controlled' }}
            /> */}
      </Box>

      <Box></Box>
      {/* <LoadingButton loading={loading} sx={{flex: 1}} size='medium' onClick={toggleAntisway}>
                {enabled ? "Disable" : "Enable"}
                </LoadingButton> */}
    </Box>
  );
};

const FormDialog = ({ setData }) => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    Timestamp: getCurrentDateTime(),
    Ordertype: "1",
    Orderstatus: "2",
    OrderPriority: "1",
    From: "A1",
    To: "A2",
  });

  const [postFormData, setPostFormData] = React.useState({
    Timestamp: getCurrentDateTime(),
    Ordertype: "1",
    Orderstatus: "2",
    OrderPriority: "1",
    From: "A1",
    To: "A2",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Append formData to the data array
    setData((prevData) => [...prevData, formData]);
    console.log(formData);
    handleClose();
  };

  return (
    <div>
      <Box ml={1} mt={-0.6}>
        <IconButton
          sx={{ color: "grey", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          <AddOutlinedIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter data</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {Object.keys(formData).map((key) => (
              <TextField
                key={key}
                name={key}
                label={key}
                value={formData[key]}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 3 }}
              />
            ))}
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function getCurrentDateTime() {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date
    .getFullYear()
    .toString()
    .substr(-2); // Get last two digits of year
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month} ${hours}:${minutes}`;
}

const actions = [
  { name: "Create Order", value: 8, icon: <MoveDownOutlinedIcon /> },
  {
    name: "New position marker",
    value: 7,
    icon: <AddLocationAltOutlinedIcon />,
  },
  { name: "Configure a forbidden zone", value: 6, icon: <BlockOutlinedIcon /> },
  // { name: 'Show order path', value: 5 , icon: <RouteOutlinedIcon />},
  { name: "Fullscreen", value: 4, icon: <FullscreenOutlinedIcon /> },
];
const ViewActions = [
  { name: "Floor", value: 3, icon: <FileCopyIcon /> },
  { name: "Bird", value: 1, icon: <FileCopyIcon /> },
  { name: "Side", value: 2, icon: <FileCopyIcon /> },
  { name: "Top", value: 0, icon: <FileCopyIcon /> },
];

function BasicSpeedDial({
  setView,
  dimBackground,
  enterFullscreen,
  setCreateOrderMode,
  setCreateBlockedZone,
}) {
  const handleClick = (value) => () => {
    if (value < 4) {
      setView(value);
    } else {
      if (value == 8) {
        setCreateOrderMode(true);
      }
      if (value == 7) {
        dimBackground();
      }
      if (value == 6) {
        setCreateBlockedZone(true);
      }
      if (value == 4) {
        enterFullscreen();
      }
    }
  };

  return (
    <Box
      sx={{
        height: 320,
        transform: "translateZ(0px)",
        flexGrow: 1,
        zIndex: 30,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        direction="up"
        sx={{ position: "absolute", bottom: 0, right: 0, zIndex: 30 }}
        icon={<AddOutlinedIcon color="secondary" fontSize="large" />}
        FabProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
      >
        {actions.map((action, index) => {
          if (action.type === "divider") {
            return <Divider key={index} orientation="vertical" />; // Custom divider component
          }
          if (action.type === "spacer") {
            return <div key={index} style={{ height: action.size }} />; // Custom spacer
          }
          return (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={handleClick(action.value)}
            />
          );
        })}
      </SpeedDial>
    </Box>
  );
}

function translateWireCordinates(value) {
  // Define the start and end of both ranges
  const x1 = 0;
  const x2 = 6374; //6780
  const y1 = 8.2;
  const y2 = -63.6;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}

//z low -69.6, high 19.2

function transformResponseData(responseData, rawTime) {
  function formatTime(dateString) {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Get the hours and minutes, and ensure they are two digits
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return the desired format
    return `${hours}:${minutes}`;
  }

  function getOrderType(orderTypeNumber) {
    switch (orderTypeNumber) {
      case 1:
        return "Pick Up";
      case 2:
        return "Leave Cargo";
      case 3:
        return "Move";
      case 4:
        return "Waypoint";
      case 5:
        return "Other";
      default:
        return "Unknown Order Type";
    }
  }

  // Map over each item in the responseData array
  return responseData.map((item) => {
    // Extract desired properties for each item
    return {
      id: item.id,
      timestamp: rawTime ? item.timestamp : formatTime(item.timestamp),
      ordertype: getOrderType(item.ordertype),
      orderstatus: getOrderStatus(item.orderstatus),
      // orderpriority: item.orderpriority,
      to_cranelocation_locationcode:
        item.to_cranelocation?.locationcode || null,
      cancelled: item.ordercancelled,
      container: item.container.containercode,
    };
  });
}
function getOrderStatus(statusNumber) {
  switch (statusNumber) {
    case 0:
      return "Paused";
    case 1:
      return "In Queue";
    case 2:
      return "Received";
    case 3:
      return "In Progress";
    case 4:
      return "Arrived";
    case 5:
      return "Complete";
    case 6:
      return "Canceled";
    case 7:
      return "Error";
    default:
      return "Unknown Status";
  }
}

function NumberScroller({ demand, setDemand }) {
  const handleIncrement = () => {
    setDemand((prevValue) => prevValue + 1);
  };

  const handleDecrement = () => {
    setDemand((prevValue) => prevValue - 1);
  };

  return (
    <Stack display="flex" justifyContent="center">
      <Box display="flex" alignItems="center" gap={1}>
        <IconButton onClick={handleDecrement} disabled={demand <= 1}>
          <RemoveOutlinedIcon />
        </IconButton>
        <Typography fontSize="24px">{demand}</Typography>
        <IconButton onClick={handleIncrement} disabled={demand >= 10}>
          <AddOutlinedIcon />
        </IconButton>
      </Box>
    </Stack>
  );
}

const getIcon = (nbr) => {
  switch (nbr) {
    case 0:
      return <QueryBuilderOutlinedIcon fontSize="large" />;
    case 1:
      return (
        <img
          src={distanceIcon}
          alt="My Icon"
          style={{ width: "26px", height: "26px" }}
        />
      );
    case 2:
      return (
        <img
          src={liftIcon}
          alt="My Icon"
          style={{ width: "26px", height: "26px" }}
        />
      );
    case 3:
      return (
        <img
          src={overloadIcon}
          alt="My Icon"
          style={{ width: "26px", height: "26px" }}
        />
      );
    case 4:
      return <WarningOutlinedIcon fontSize="large" />;
    case 5:
      return (
        <img
          src={stopIcon}
          alt="My Icon"
          style={{ width: "26px", height: "26px" }}
        />
      );
    case 6:
      return null;
    default:
      return null; // or some default icon
  }
};

function CustomStepper({
  setMakeReturnOrders,
  orderStepperActiveStep,
  setOrderStepperActiveStep,
  orderPos1,
  orderPos2,
  orderPos3,
  createOrders,
  noContainerError,
  containersToRelocate,
}) {
  const [successfulSteps, setSuccessfulSteps] = React.useState([]);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setMakeReturnOrders(true);
  };

  const handleNext = () => {
    setSuccessfulSteps((prev) => [...prev, orderStepperActiveStep]);
    setOrderStepperActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setOrderStepperActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: "Select pickup position",
      description: "No items to pickup",
      completedDescription: orderPos1[0] + "     [" + orderPos1[2] + "]",
    },
    {
      label: "Select dropdown position",
      description: "",
      completedDescription: orderPos2[0] + "     [" + orderPos2[2] + "]",
    },
    ...(containersToRelocate.length > 0
      ? [
          {
            label: "Select relocation position",
            description: containersToRelocate.length,
            completedDescription: orderPos3[0],
          },
        ]
      : []),
    {
      label: "Confirm",
      description: "",
    },
  ];

  const handleConfirm = () => {
    console.log("Confirmed");
    createOrders();
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={orderStepperActiveStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {step.label}

              {orderStepperActiveStep > index && step.completedDescription ? (
                <Typography
                  variant="caption"
                  display="block"
                  color="textSecondary"
                >
                  {step.completedDescription}
                </Typography>
              ) : null}
            </StepLabel>
            <StepContent>
              {noContainerError && (
                <Typography color={"red"}>{step.description}</Typography>
              )}
              {/* {index == 2 && containersToRelocate.length > 0 && <Typography >{step.description}</Typography>} */}
              <Box sx={{ mb: 2 }}>
                <div>
                  {index === steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleConfirm}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Confirm
                    </Button>
                  )}
                  {index === 2 && containersToRelocate.length > 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label="Return items"
                    />
                  )}
                  {index !== 0 && (
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const getImageBasedOnNumber = (number, width = "60px") => {
  let imageSrc;

  switch (number) {
    case 111:
      imageSrc = yellowImage;
      break;
    case 222:
      imageSrc = blueImage;
      break;
    case 333:
      imageSrc = greenImage;
      break;
    default:
      imageSrc = null;
  }

  return (
    <img width={width} src={imageSrc} style={{ marginTop: "-2px" }} alt="" />
  );
};

const CircleStack = ({ selected }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {[1, 2, 3].map((number) => (
        <div
          key={number}
          className={`my-1 h-2 w-2 rounded-full ${
            selected === number ? "bg-nsblue-500" : "bg-gray-300"
          }`}
        />
      ))}
    </div>
  );
};

function WarningMessage() {
  return (
    <div className="rounded-md bg-nsorange-100 p-4 mt-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-nsorange-300"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <div className="-mt-0.5 text-lg text-nsorange-700">
            <p>
              OPC-data for end limits are not properly configured, cannot show
              the correct position in the 3D enviroment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AntiSwayDialog({ open, setOpen }) {
  const [enabled, setEnabled] = useState(true);
  const [degrees, setDegrees] = useState(0);
  const [maxDegrees, setMaxDegrees] = useState(0);
  const [minDegrees, setMinDegrees] = useState(0);
  const [swings, setSwings] = useState(0);
  const [swingedPositive, setSwingedPositive] = useState(false);
  const [time, setTime] = useState(0);
  const [tests, setTests] = useState([]);
  const [recording, setRecording] = useState(false);
  const [simulateSwing, setSimulateSwing] = useState(false);
  const [intervalID, setIntervalID] = useState("");
  const [checkForStable, setCheckForStable] = useState(false);
  const [stableTimer, setStableTimer] = useState(0);

  const LineDrawer = ({ degrees, length, max, min }) => {
    // Function to calculate line style
    const calculateLineStyle = (angle) => {
      const sanitizedAngle = Math.max(-90, Math.min(90, angle)) + 180;
      return {
        height: `${length}px`, // Line length
        transform: `rotate(${sanitizedAngle}deg)`,
        transformOrigin: "bottom", // Pivot point at the bottom of the line
      };
    };

    return (
      <div className="relative h-40 w-full ">
        {" "}
        {/* Adjust container size as needed */}
        <div
          className="absolute left-1/2 bottom-full w-0.5 bg-yellow-500 z-10"
          style={calculateLineStyle(degrees)}
        />
        <div
          className="absolute left-1/2 bottom-full w-1 bg-black"
          style={calculateLineStyle(min)}
        />
        <div
          className="absolute left-1/2 bottom-full w-1 bg-black"
          style={calculateLineStyle(max)}
        />
        <div
          className="absolute left-1/2 bottom-full w-1 bg-green-500"
          style={calculateLineStyle(-10)}
        />
        <div
          className="absolute left-1/2 bottom-full w-1 bg-green-500"
          style={calculateLineStyle(10)}
        />
      </div>
    );
  };

  useEffect(() => {
    if (degrees > maxDegrees) {
      setMaxDegrees(degrees);
    }
    if (degrees < minDegrees) {
      setMinDegrees(degrees);
    }
    if (degrees > 10 && !swingedPositive) {
      setSwings((prev) => prev + 1);

      setSwingedPositive(true);
    }
    if (degrees < -10 && swingedPositive) {
      setSwingedPositive(false);
    }

    if (tests.length > 0 && tests[tests.length - 1].active && !recording) {
      setRecording(true);

      const interval = startRecording();
      setIntervalID(interval);
    }
  }, [degrees, tests]);

  useEffect(() => {
    // Only start the simulation if `running` is true
    if (simulateSwing) {
      const stopSimulation = simulatePendulumMovement();

      return stopSimulation;
    }
  }, [simulateSwing]);

  useEffect(() => {
    if (recording) {
      setTests((prev) => [
        ...prev.slice(0, prev.length - 1),
        {
          antisway: enabled ? "On" : "Off",
          time: time,
          swings: swings,
          range: maxDegrees + -1 * minDegrees,
          active: recording,
        },
      ]);
    } else {
      // setTests(prev => [...prev, {antisway: enabled ? "On" : "Off", time: time, swings: swings, range: 0 , active: recording}]);
    }
    if (isSwingStable(degrees)) {
      setStableTimer((prev) => prev + 0.1);
    } else {
      setStableTimer(0);
    }
    if (stableTimer > 4.0) {
      clearInterval(intervalID);
      setRecording(false);
      setStableTimer(0);
      setTests((prev) => [
        ...prev.slice(0, prev.length - 1),
        {
          antisway: enabled ? "On" : "Off",
          time: time,
          swings: swings,
          range: maxDegrees + -1 * minDegrees,
          active: false,
        },
      ]);
      setSimulateSwing(false);
    }
  }, [time]);

  function simulatePendulumMovement() {
    // Constants for the pendulum
    const g = 9.81; // acceleration due to gravity (m/s^2)
    const l = 6; // length of the pendulum (m)
    const theta_0 =
      ((degrees < 35 ? (degrees > -35 ? 35 : degrees) : degrees) * Math.PI) /
      180; // initial angle in radians (35 degrees)
    const b = enabled ? 0.12 : 0.05; // damping factor
    const interval = 100; // milliseconds between updates
    let elapsedTime = 0;

    const intervalId = setInterval(() => {
      elapsedTime += interval / 1000; // convert to seconds
      const theta =
        theta_0 *
        Math.exp(-b * elapsedTime) *
        Math.cos(Math.sqrt(g / l) * elapsedTime);
      const thetaDegrees = (theta * 180) / Math.PI;
      setDegrees(parseInt(thetaDegrees)); // Update degrees state
    }, interval);

    return () => clearInterval(intervalId); // Return a cleanup function to clear interval
  }

  // Helper function to check if swing is within the stable zone
  function isSwingStable(degrees) {
    return degrees >= -10 && degrees <= 10;
  }
  const startRecording = () => {
    setTime(0);
    setSwings(1);
    setMaxDegrees(0);
    setMinDegrees(0);

    const intervalId = setInterval(() => {
      setTime((prev) => (prev += 100 / 1000));
    }, 100);

    return intervalId;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      AntiSway
                    </Dialog.Title>
                    <div className="mt-2 ">
                      <Switch
                        checked={true}
                        label="Simulation"
                        onChange={() => {}}
                        className={classNames(
                          true ? "bg-indigo-600" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            true ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>

                    <div className="border-t-2 mt-5 ">
                      <div className="mt-5 font-semibold">
                        AntiSway Data Analys and Visualization
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className=" mt-5 flex flex-row justify-around gap-5">
                        <div className="mt-10 px-5 bg-gray-100 rounded-lg basis-1/2">
                          <p className="text-lg font-semibold mb-2 mt-4">
                            NS Sensor Data from PLC
                          </p>
                          <div>
                            <span className="text-xl font-bold -mt-1">
                              {"0"} °
                            </span>
                          </div>
                        </div>

                        <div className="mt-10 px-5 pb-3 bg-gray-100 rounded-lg basis-1/2">
                          <p className="text-lg font-semibold mb-2 mt-4">
                            NS Sensor Data Simulation
                          </p>
                          <Slider
                            value={degrees}
                            onChange={(event, value) => setDegrees(value)}
                            min={-50}
                            max={50}
                            step={1}
                            width={"90%"}
                          />
                          <span className="text-xl font-bold -mt-1">
                            {degrees} °
                          </span>

                          <div className="flex flex-row gap-5 items-center ">
                            <div className="flex flex-col gap-1 ">
                              <div className="text-sm font-semibold">
                                AntiSway
                              </div>
                              <div>
                                <Switch
                                  checked={enabled}
                                  label="Simulation"
                                  onChange={() => setEnabled((prev) => !prev)}
                                  className={classNames(
                                    enabled ? "bg-indigo-600" : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                                  )}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      enabled
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                              </div>
                            </div>
                            <div className="flex justify-center w-full ">
                              <button
                                className="text-sm font-semibold leading-6 text-white outline-2 px-3 py-2 rounded-md outline-indigo-500 bg-indigo-500 shadow-sm"
                                onClick={() => {
                                  setSimulateSwing((prev) => !prev);
                                }}
                              >
                                {simulateSwing
                                  ? "Stop Swing"
                                  : "Simulate swing"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full px-10 h-96 mt-10 flex  flex-col bg-gray-100 rounded-lg shadow-md">
                        <div className="p-4 flex flex-row justify-evenly bg-white rounded-lg mt-10">
                          <div className="">
                            <div className="text-xl text-gray-400 tracking-wide">
                              MAX
                            </div>
                            <div className="text-2xl font-bold">
                              {maxDegrees}°
                            </div>
                          </div>
                          <div className="">
                            <div className="text-xl text-gray-400 tracking-wide">
                              RANGE
                            </div>
                            <div className="text-2xl font-bold">
                              {-1 * minDegrees + maxDegrees}°
                            </div>
                          </div>
                          <div className="">
                            <div className="text-xl text-gray-400 tracking-wide">
                              MIN
                            </div>
                            <div className="text-2xl font-bold">
                              {minDegrees}°
                            </div>
                          </div>
                        </div>

                        <div className="mt-10 pb-10 ">
                          <LineDrawer
                            degrees={degrees}
                            length={150}
                            min={minDegrees}
                            max={maxDegrees}
                          />
                          <div className="flex flex-col gap-2 -mt-6">
                            <div className="flex flex-row gap-2 items-center">
                              <div className="w-3 h-3 bg-black rounded-sm"></div>
                              <div className="text-xs text-gray-400">
                                Highest Swings
                              </div>
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                              <div className="w-3 h-3 bg-green-500 rounded-sm"></div>
                              <div className="text-xs text-gray-400">
                                Stable Zone
                              </div>
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                              <div className="w-3 h-3 bg-nsorange-500 rounded-sm"></div>
                              <div className="text-xs text-gray-400">Wire</div>
                            </div>
                          </div>
                          <div className="w-full flex justify-end -mt-10">
                            <div>
                              <div>
                                <button
                                  type="button"
                                  className={
                                    "inline-flex justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 " +
                                    (recording
                                      ? "bg-indigo-300 hover:bg-indigo-300 cursor-wait"
                                      : "bg-indigo-600")
                                  }
                                  onClick={() =>
                                    setTests((prev) => [
                                      ...prev,
                                      {
                                        antisway: enabled ? "On" : "Off",
                                        time: 0,
                                        swings: 0,
                                        range: 0,
                                        active: true,
                                      },
                                    ])
                                  }
                                >
                                  <div className="flex items-center flex-row-reverse gap-1">
                                    Record data
                                    <div className="bg-red-500 w-3 h-3 rounded-full "></div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-center -mt-6">
                            {stableTimer > 2 && (
                              <div className="animate-pulse text-xl2">
                                Stable in {(5 - stableTimer).toFixed(0)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1 mt-5 ">
                        <div className="flex flex-row gap-5 items-baseline justify-between ">
                          <p className="text-xl font-semibold">Results</p>
                          <div className="pr-3">
                            <button
                              onClick={() => {
                                setMaxDegrees(0);
                                setMinDegrees(0);
                                setDegrees(0);
                                setTests([]);
                                setSimulateSwing(false);
                                clearInterval(intervalID);
                                setRecording(false);
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        </div>

                        <div className="rounded-lg p-2 flex flex-col gap-2">
                          <div className=" flex flex-row items-center gap-7 p-3 rounded-lg">
                            <div className="flex flex-row gap-2 items-center p-2 w-24">
                              <div className="text-lg text-blue-700 tracking-wide">
                                TEST #
                              </div>
                            </div>
                            <div className="">
                              <div className="text-lg text-blue-700 tracking-wide w-20">
                                ANTISWAY
                              </div>
                            </div>
                            <div className="">
                              <div className="text-lg text-blue-700 tracking-wide w-16">
                                TIME
                              </div>
                            </div>

                            <div className="">
                              <div className="text-lg text-blue-700 tracking-wide w-16">
                                SWINGS
                              </div>
                            </div>

                            <div className="">
                              <div className="text-lg text-blue-700 tracking-wide w-16">
                                RANGE
                              </div>
                            </div>
                          </div>

                          {tests.length > 0 ? (
                            tests.map((test, index) => (
                              <div className=" flex flex-row items-center gap-7 px-3 rounded-lg bg-gray-100">
                                <div className="flex flex-row gap-2 items-center p-2 w-24">
                                  <div className="text-xl font-semibold">
                                    Test {index + 1}
                                  </div>
                                  {test.active && (
                                    <div className="w-4 h-4 rounded-full bg-red-500 animate-pulse"></div>
                                  )}
                                </div>
                                <div className="w-20">
                                  <div>
                                    <div>
                                      <Switch
                                        checked={
                                          test.antisway == "On" ? true : false
                                        }
                                        label="Simulation"
                                        className={classNames(
                                          test.antisway == "On"
                                            ? "bg-indigo-600"
                                            : "bg-gray-200",
                                          "relative inline-flex cursor-default h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                                        )}
                                      >
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            test.antisway == "On"
                                              ? "translate-x-5"
                                              : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-16">
                                  <div className="text-2xl font-bold">
                                    {test.time.toFixed(1)}s
                                  </div>
                                </div>

                                <div className="w-16">
                                  <div className="text-2xl font-bold">
                                    {test.swings}
                                  </div>
                                </div>

                                <div className="w-16">
                                  <div className="text-2xl font-bold">
                                    {test.range}°
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>No recordings yet</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
