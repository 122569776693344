import React, { useState, useEffect, useRef } from "react";
import { Physics, useBox, usePlane } from "@react-three/cannon";
import { TeleportationPlane } from '@react-three/xr';
import {
  VRButton,
  ARButton,
  XR,
  Controllers,
  Hands,
  useController,
} from "@react-three/xr";
import {
  Canvas,
  useLoader,
  useThree,
  useFrame,
  extend,
} from "@react-three/fiber";
import { OBJLoader, MTLLoader } from "three-stdlib";
import {
  CameraControls,
  OrbitControls,
  PerspectiveCamera,
  Text,
} from "@react-three/drei";
import { Box, Slider, Typography, Button, Grid, Stack } from "@mui/material";
import * as THREE from "three";
import { Vector3 } from "three";
import { getCranePos } from "../../../../components/OrderApi";
import useFetchV2 from "../../../../components/PartsApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import MovementController from "./MovementController";

function LineBetweenTwoPoints({ point1, point2 }) {
  const points = [point1, point2].map((p) => new Vector3(...p));
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  return (
    <line geometry={geometry}>
      <lineBasicMaterial color={"black"} />
    </line>
  );
}

function PulsatingTarget({ radius = 2.5, x, y, speed = 2 }) {
  const meshRef1 = useRef();
  const meshRef2 = useRef();
  const meshRef3 = useRef();

  useFrame((state) => {
    // Create the pulsating effect by modifying the scale
    const scale = 1 + Math.sin(state.clock.elapsedTime * speed) * 0.1;
    meshRef1.current.scale.set(scale, scale, scale);
    meshRef2.current.scale.set(scale, scale, scale);
    meshRef3.current.scale.set(scale, scale, scale);
  });

  return (
    <>
      {/* <Text
            position={[y, x + 10, -73]} // Position of the text
            fontSize={5} // Font size
            color="black" // Font color
          >
            Target
      </Text> */}
      <group position={[y, x, -73]}>
        <mesh ref={meshRef1}>
          <ringGeometry args={[0.4, 1, 32]} />
          <meshBasicMaterial color={"red"} />
        </mesh>
        <mesh ref={meshRef2}>
          <ringGeometry args={[2.3, 3, 42]} />
          <meshBasicMaterial color={"red"} />
        </mesh>
        <mesh ref={meshRef3}>
          <ringGeometry args={[4.5, 5.5, 52]} />
          <meshBasicMaterial color={"red"} />
        </mesh>
      </group>
    </>
  );
}

const Crane = React.memo(function Crane({
  setCraneModelClicked,
  debug,
  setBeemCenter,
  obj,
  values,
  objUrl,
  mtlUrl,
  x,
}) {
  // console.log("crane is rendered");

  let object;

  if (mtlUrl != undefined && mtlUrl != "") {
    //  //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();
    alert("You should not see this alert. 2");
    // //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }
  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());

  if (setBeemCenter != undefined) {
    setBeemCenter([center.x, center.z, center.y]);
  }

  return (
    <group>
      <mesh position={[values?.Beem_x, values?.Beem_y + x, values?.Beem_z]}>
        <primitive
          object={object}
          onClick={() => setCraneModelClicked("Beem")}
        />
        {debug && <boxHelper args={[object, 0xff0000]} />}
      </mesh>
      {debug && (
        <mesh position={[-center.x, center.z, center.y]}>
          <sphereGeometry args={[10, 32, 32]} />
          <meshBasicMaterial color={"blue"} />
        </mesh>
      )}
    </group>
  );
});
const Crane2 = React.memo(function Crane({ values, objUrl, mtlUrl, x }) {
  // console.log("crane is rendered");

  if (mtlUrl != undefined && mtlUrl != "") {
  } else {
    return <></>;
  }
  // //console.log("URL: ", mtlUrl);
  const mtlLoader = new MTLLoader();
  const mtl = mtlLoader.parse(mtlUrl);
  mtl.preload();

  // //console.log(data2);
  const objLoader = new OBJLoader();
  objLoader.setMaterials(mtl);
  const rawObjText = `${objUrl}`;
  const obj = objLoader.parse(rawObjText);

  return (
    <mesh position={[values.Beem_x, values.Beem2_y + x, values.Beem_z]}>
      <primitive object={obj} />
    </mesh>
  );
});
const CraneLift = React.memo(function CraneLift({
  setCraneModelClicked,
  debug,
  setTrolleyCenter,
  obj,
  values,
  objUrl,
  mtlUrl,
  x,
  y,
  cargoWeight,
}) {
  // console.log("cranelift rendered");
  let object;

  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();
    alert("You should not see this alert. 3");
    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }

  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());

  if (setTrolleyCenter != undefined) {
    setTrolleyCenter([center.x, center.z, center.y]);
  }

  return (
    <>
      <mesh
        position={[
          values.Trolley_x + y,
          values.Trolley_y + x,
          values.Trolley_z,
        ]}
      >
        <primitive
          object={object}
          onClick={() => setCraneModelClicked("Trolley")}
        />
        {debug && <boxHelper args={[object, 0xff0000]} />}
      </mesh>
      {debug && (
        <mesh position={[-center.x, center.z, center.y]}>
          <sphereGeometry args={[10, 32, 32]} />
          <meshBasicMaterial color={"green"} />
        </mesh>
      )}

      {/* {cargoWeight > 0 && 
      <group position={[y - 1, x - 3, -50]}>
              <mesh position={[0, 0, 0.5]}>
                <boxGeometry args={[25, 15, 0.7]} />
                <meshBasicMaterial color={'yellow'} />
              </mesh>
              <mesh position={[0, 0, 1]}>
                <boxGeometry args={[25, 15, 0.2]} />
                <meshBasicMaterial color={'black'} />
              </mesh>
      </group>
    } */}
    </>
  );
});

const ZoneBox = React.memo(function ZoneBox({
  Zone,
  tempZone,
  createBlockedZone,
}) {
  let data = Zone;

  if (createBlockedZone) {
    data = tempZone;
    data[6] = true;
  }

  console.log(tempZone);
  console.log("Zone: ", data);
  let [xpos, ypos, zpos, width, length, height, active] = data;

  if (!active) {
    console.log("Zone hidden and unactive");
    return <></>;
  }

  //console.log(xpos);
  const x = translateCordinates(xpos);
  const y = translateWagonCordinates(ypos);
  const z = translateWireCordinates(zpos, 0, 120, 11, -108);

  console.log(z + height);

  return (
    <>
      <group position={[y, x, z + height / 2]}>
        <mesh position={[0, 0, 0]}>
          <boxGeometry args={[width, length, height]} />
          <meshBasicMaterial color={"red"} transparent={true} opacity={0.6} />
        </mesh>
        <Text
          position={[0, 0, height / 2 + 1]}
          fontSize={5} // Font size
          color="white" // Font color
          maxWidth={10}
        >
          Blocked Zone
        </Text>
      </group>
    </>
  );
});
function CylinderBetweenPoints({ point1, point2 }) {
  const [x1, y1, z1] = point1;
  const [x2, y2, z2] = point2;

  // Calculate the position of the cylinder (midpoint between two points)
  const position = [(x1 + x2) / 2, (y1 + y2) / 2, (z1 + z2) / 2];

  // Calculate the height of the cylinder (distance between two points)
  const height = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2 + (z2 - z1) ** 2);

  // Calculate the rotation of the cylinder to align with the line defined by the two points
  const direction = new THREE.Vector3(x2 - x1, y2 - y1, z2 - z1);
  const up = new THREE.Vector3(0, 1, 0);
  const rotation = new THREE.Quaternion().setFromUnitVectors(
    up,
    direction.normalize()
  );

  return (
    <mesh position={position} quaternion={rotation}>
      <cylinderGeometry args={[0.15, 0.15, height, 32]} />
      <meshBasicMaterial color={"black"} />
    </mesh>
  );
}

const Wire = React.memo(function Wire({
  setCraneModelClicked,
  debug,
  setHookCenter,
  setWireCenter,
  files,
  obj,
  values,
  objUrl,
  mtlUrl,
  x,
  y,
  z,
  cargoWeight,
  normal,
  constant,
  cargoProduct,
  setTarget,
}) {
  //console.log("Wire rendered ");
  const groupRef = useRef();
  const Long = () => Product(files["Long.mtl"], files["Long.obj"], "Long");
  const Short = () => Product(files["Short.mtl"], files["Short.obj"], "Short");
  const [center2, setCenter2] = useState([0, 0, 0]);
  useEffect(() => {
    if (groupRef.current) {
      const boundingBox = new THREE.Box3();
      boundingBox.setFromObject(groupRef.current);
      const center = boundingBox.getCenter(new THREE.Vector3());
      console.log("Center of group:", center);

      setCenter2(center);
    }
  }, [groupRef, values]);

  let object;

  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }

  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());

  //console.log(center);
  if (setHookCenter != undefined) {
    setHookCenter([center.x, center.z, center.y]);
  }

  setWireCenter([center2?.x, center2?.z, center2?.y]);

  setTarget([-center.x, center.z, center.y]);

  //console.log("HEEP", values?.Rope_x, x + values?.Rope_y, z + values?.Rope_z + values?.RopeLength)
  return (
    <>
      <mesh
        position={[y + values.Hook_x, x + values.Hook_y, z + values.Hook_z]}
        onClick={() => setCraneModelClicked("Grabber")}
      >
        <primitive object={object} />
        {debug && <boxHelper args={[object, 0xff0000]} />}
      </mesh>
      {debug && (
        <group>
          <mesh position={[-center.x, center.z, center.y]}>
            <sphereGeometry args={[10, 32, 32]} />
            <meshBasicMaterial color={"red"} />
          </mesh>
          <mesh position={[-center.x, center.z, -108]}>
            <sphereGeometry args={[2, 32, 32]} />
            <meshBasicMaterial color={"blue"} />
          </mesh>
          <mesh position={[-center2?.x, center2?.z, center2?.y]}>
            <sphereGeometry args={[5, 32, 32]} />
            <meshBasicMaterial color={"yellow"} />
          </mesh>
        </group>
      )}

      <group ref={groupRef}>
        <CylinderBetweenPoints
          point1={[
            y + 4.9 + values.Rope_x,
            x - 4.3 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.RopeLength,
          ]}
          point2={[
            y + 4.9 + values.Rope_x,
            x - 4.3 + 2 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
        <CylinderBetweenPoints
          point1={[
            y + 4.9 + values.Rope_x,
            x - 1.2 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.RopeLength,
          ]}
          point2={[
            y + 4.9 + values.Rope_x,
            x - 1.2 + 8 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
        <CylinderBetweenPoints
          point1={[
            y + 2.2 + values.Rope_x,
            x - 4.3 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.RopeLength,
          ]}
          point2={[
            y + 2.1 + values.Rope_x,
            x - 4.3 + 2 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
        <CylinderBetweenPoints
          point1={[
            y + 2.2 + values.Rope_x,
            x - 1.2 + 5 + values.Rope_y,
            z + 2 + values.Rope_z + values.RopeLength,
          ]}
          point2={[
            y + 2.1 + values.Rope_x,
            x - 1.2 + 8 + values.Rope_y,
            27.9 + values.Rope_z,
          ]}
        />
      </group>

      <group position={[y + 14, x + 300, z + 171]}>
        {/* <mesh position={[0, 0, dimensions2[2]]}>
                <boxGeometry args={dimensions2} />
                <meshBasicMaterial color={'black'} />
              </mesh>
              <mesh position={[0, 0, dimensions[2]]}>
                <boxGeometry args={dimensions} />
                <meshBasicMaterial color={color} />
              </mesh> */}
        {/* {cargoProduct == 2 && (
          <group position={[89, 26, 0]}>
            <Short />
          </group>
        )}
        {cargoProduct == 1 && (
          <group position={[-29, 0, 0]}>
            <Long />
          </group>
        )} */}
        {/* {cargoProduct == "2" && <Long/>} */}
      </group>
    </>
  );
});

const cameraPositions = [
  { position: [-2, 210, 0] }, // top view
  { position: [85, 133, 82] }, // front view
  { position: [-76, 133, 0] }, // front view
  { position: [36, 0, -135] }, // front view

  // Add more custom views as needed
];

const CameraController = React.memo(function CameraController({
  position,
  lookAt,
}) {
  //console.log("Rendering camera");
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(...position);

    camera.updateProjectionMatrix();
  }, [position, camera]);

  return null;
});

const Square = React.memo(function Square({
  opcData,
  values,
  x = 0,
  y = 0,
  z = 0,
  obj,
  objUrl,
  mtlUrl,
  setMarker,
  setTempMarker,
  setNewMarkerPosition,
  tempMarker,
}) {
  let object;
  //console.log("Rendering building");
  if (mtlUrl != undefined && mtlUrl != "") {
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();
    alert("You should not see this alert. 1");
    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    object = objLoader.parse(rawObjText);
  } else {
    if (obj) {
      object = obj;
    } else {
      return <></>;
    }
  }

  // const obj = useLoader(OBJLoader, objUrl, loader => loader.setMaterials(mtl));

  const handlePointerDown = (event) => {
    // console.log(values.Cranexposmin, values.Cranexposmax , opcData?.xposlimitmin, opcData?.xposlimitmax);
    console.log(
      values.Craneyposmin,
      values.Craneyposmax,
      opcData?.y1poslimitmin,
      opcData?.y1poslimitmax
    );
    console.log(
      "O: ",
      parseInt(-event.point.x),
      ",",
      parseInt(event.point.z),
      ",",
      parseInt(event.point.y),
      " T: ",
      parseInt(
        translateCordinates(
          event.point.x,
          values.Craneyposmin,
          values.Craneyposmax,
          opcData?.y1poslimitmin,
          opcData?.y1poslimitmax
        )
      ),
      ",",
      parseInt(
        translateCordinates(
          event.point.z,
          values.Cranexposmin,
          values.Cranexposmax,
          opcData?.xposlimitmin,
          opcData?.xposlimitmax
        )
      ),
      " , ",
      parseInt(
        translateWireCordinates(
          event.point.y,
          values.Cranezposmin,
          values.Cranezposmax,
          opcData?.z1poslimitmin,
          opcData?.z1poslimitmax
        )
      )
    );

    if (setMarker) {
      setTempMarker([
        ...tempMarker,
        [-event.point.x, event.point.z, event.point.y],
      ]);
      setNewMarkerPosition([
        translateCordinates(
          -event.point.x,
          values.Craneyposmin,
          values.Craneyposmax,
          opcData?.y1poslimitmin,
          opcData?.y1poslimitmax
        ),
        translateCordinates(
          event.point.z,
          values.Cranexposmin,
          values.Cranexposmax,
          opcData?.xposlimitmin,
          opcData?.xposlimitmax
        ),
        translateWireCordinates(
          event.point.y,
          values.Cranezposmin,
          values.Cranezposmax,
          opcData?.z1poslimitmin,
          opcData?.z1poslimitmax
        ),
      ]);
      //console.log(markers);
    }
  };
  return (
    <mesh position={[x, y, z]} onClick={handlePointerDown}>
      <primitive object={object} />
    </mesh>
  );
});

const RenderProducts = React.memo(function RenderProducts({ files, products }) {
  // console.log("Render products: ", products);
  let models = [];
  products?.map((item) => {
    // //console.log(item.id);
    if (item.id == 12) {
      models = Product(files["Long.mtl"], files["Long.obj"], item.name);
    }
    if (item.id == 13) {
      Product(files["Short.mtl"], files["Short.obj"], item.name);
    }
  });

  function Product(mtlUrl, objUrl, name) {
    // //console.log("Rendering 3D model for product: ", name);
    if (mtlUrl != undefined && mtlUrl != "") {
    } else {
      return <></>;
    }
    // //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    // //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    const obj = objLoader.parse(rawObjText);
    // const obj = useLoader(OBJLoader, objUrl, loader => loader.setMaterials(mtl));

    // console.log(models);
    return (
      <mesh position={[0, 0, 0]}>
        <primitive object={obj} />
      </mesh>
    );
  }

  return (
    <group position={[12, 140, 70]}>
      {Product(files["Short.mtl"], files["Short.obj"], "Short")}
      {Product(files["Long.mtl"], files["Long.obj"], "Long")}
    </group>
  );
});

const Scene2 = React.memo(function Scene2({
  xposSim,
  yposSim,
  zposSim,
  setCraneModelClicked,
  debug,
  setCenterArray,
  lock,
  objFiles,
  doesBeem1HaveTwoTrolleys,
  doesBeem2HaveTwoTrolleys,
  twobeems,
  lookAt,
  opcData,
  camera,
  confdata,
  configured,
  createBlockedZone,
  tempZone,
  Zone,
  obj,
  products,
  values,
  view,
  setMarker,
  setNewMarkerPosition,
  markerdata,
  selectedMarker,
  setSelectedMarker,
  setTempMarker,
  tempMarker,
  cargoWeight,
  crane,
  cargoProduct,
  setVRcontrols = () => {},
  setBoxReady = () => {},
  boxReady = false,
  setPickupBox = () => {},
  pickupBox = false,
  vr = false,
}) {
  const navigate = useNavigate();
  const [target, setTarget] = useState([0, 0, 0]);
  const [position, setPosition] = useState([-2, 210, 0]);
  // [-2, 210, 0]
  const [normal, setNormal] = useState({ x: 0, y: -1, z: 0 });
  const [constant, setConstant] = useState(-10);
  const files = [{}];
  const [trolleyCenter, setTrolleyCenter] = useState([0, 0, 0]);
  const [beemCenter, setBeemCenter] = useState([0, 0, 0]);
  const [hookCenter, setHookCenter] = useState([0, 0, 0]);
  const [wireCenter, setWireCenter] = useState([0, 0, 0]);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [z, setZ] = useState(0);

  const [boxPos, setBoxPos] = useState([0, 0, 0]);
  const [boxes, setBoxes] = useState([{ x: 0, y: 0, z: 0, type: "long", placed: false}]);

  //console.log(opcData);
  // console.log(hookCenter);

  // [120.8535022652182, -72.72981736122118, -75.43874359130855]
  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      const data = await getCranePos(opcData?.id);
      // console.log(opcData?.id)
      // console.log("DATA: ", data);
      if (data != null || data != undefined) {
        //console.log("VALUES: ", values);
        setX(
          translateCordinates(
            data.xactpos,
            opcData?.xposlimitmin,
            opcData?.xposlimitmax,
            values.Cranexposmin,
            values.Cranexposmax
          )
        );
        setY(
          translateCordinates(
            data.y1actpos,
            opcData?.y1poslimitmin,
            opcData?.y1poslimitmax,
            values.Craneyposmin,
            values.Craneyposmax
          )
        );
        setZ(
          translateWireCordinates(
            data.z1actpos,
            opcData?.z1poslimitmin || 0,
            opcData?.z1poslimitmax || 1000,
            values.Cranezposmin,
            values.Cranezposmax
          )
        );
      }
    };
    let intervalId;
    if (opcData != undefined && values != undefined) {
      if (xposSim != null || yposSim != null || zposSim != null) {
        // Call fetchData every second
        clearInterval(intervalId);
        const xtemp = translateCordinates(
          xposSim || opcData?.xposlimitmin,
          opcData?.xposlimitmin,
          opcData?.xposlimitmax,
          values.Cranexposmin,
          values.Cranexposmax
        );
        const ytemp = translateCordinates(
          yposSim || opcData?.y1poslimitmin,
          opcData?.y1poslimitmin,
          opcData?.y1poslimitmax,
          values.Craneyposmin,
          values.Craneyposmax
        );
        const ztemp = translateWireCordinates(
          zposSim || opcData?.z1poslimitmin,
          opcData?.z1poslimitmin || 0,
          opcData?.z1poslimitmax || 1000,
          values.Cranezposmin,
          values.Cranezposmax
        );
        //console.log("Simulated values: ", xtemp, ytemp);
        //console.log(hookCenter[0] - ytemp);
        setX(xtemp);
        setY(ytemp);
        setZ(ztemp);
      } else {
        //console.log("Interval");
        intervalId = setInterval(fetchData, 300);
      }
    }

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [opcData, values, xposSim, yposSim, zposSim]);

  useEffect(() => {
    setCenterArray([beemCenter, trolleyCenter, hookCenter, wireCenter]);
  }, [trolleyCenter, beemCenter, hookCenter]);

  // //console.log("Scene ", files);

  let beem2 = objFiles["Beem"]?.clone();
  let trolley2 = objFiles["Trolley"]?.clone();

  // //console.log("\n\nRendering Scene component");

  const handleNormalChange = (newNormal) => {
    setNormal(newNormal);
    // Update the plane in your Three.js scene here with the new normal
  };

  const handleConstantChange = (newConstant) => {
    setConstant(newConstant);
    // Update the plane in your Three.js scene here with the new constant
  };
  // //console.log(markerdata);

  const handleSliderChange = (index) => (event, newValue) => {
    setPosition((prev) => {
      const newPosition = [...prev];
      newPosition[index] = newValue;
      return newPosition;
    });
  };

  useEffect(() => {
    setPosition([-2, 210, 0]);

    return () => {};
  }, [view]);

  // console.log(confdata[0]);

  //142 //7192

  useEffect(() => {
    if (
      boxPos[0] > y - 10 &&
      boxPos[0] < y + 10 &&
      boxPos[1] > x - 10 &&
      boxPos[1] < x + 10 &&
      boxPos[2] > z - 15 &&
      boxPos[2] < z + 10
    ) {
      console.log("Box is in the zone");
      setBoxReady(true);

      if (pickupBox) {
        setBoxPos([y - 3.5, x, z - 9]);
      } else {
        setBoxPos([boxPos[0], boxPos[1], -52]);
      }
    } else {
      setBoxReady(false);
    }
    console.log("");
    console.log(parseInt(x - 10), " < ", boxPos[0], " < ", parseInt(x + 10));
    console.log(parseInt(y - 10), " < ", boxPos[1], " < ", parseInt(y + 10));
    console.log(parseInt(z - 10), " < ", boxPos[2], " < ", parseInt(z + 10));
    console.log("");
  }, [boxPos, x, y, z, pickupBox]);

  useEffect(() => {
    
  }, [boxes])
  

  function Joystick() {
    const rightController = useController("right");
    const leftController = useController("left");

    const processControllerInput = (controller, hand) => {
      if (
        controller &&
        controller.inputSource &&
        controller.inputSource.gamepad
      ) {
        const { gamepad } = controller.inputSource;

        // Access joystick values
        const joystickX = gamepad.axes[2];
        const joystickY = gamepad.axes[3];
        // Assuming the left controller doesn't have axes[2] and axes[3] for joystick
        // If it does, adjust accordingly

        // Access button states
        const trigger = gamepad.buttons[0].pressed;
        const grip = gamepad.buttons[1].pressed;
        // Adjust button indices based on the controller hand if necessary
        const buttonAorX = gamepad.buttons[4].pressed; // A for right, X for left typically
        const buttonBorY = gamepad.buttons[5].pressed; // B for right, Y for left typically

        // Example of setting state or logging
        // console.log(
        //   `${hand} Joystick X: ${joystickX}, Joystick Y: ${joystickY}`,
        //   gamepad
        // );

        // if (trigger) {
        //   toast.success(`${hand} Trigger pressed`);
        // }
        // if (grip) {
        //   toast.success(`${hand} Grip pressed`);
        // }
        // if (buttonAorX) {
        //   toast.success(`${hand} A/X pressed`);
        // }
        // if (buttonBorY) {
        //   toast.success(`${hand} B/Y pressed`);
        // }

        return {
          joystickX: joystickX,
          joystickY: joystickY,
          trigger: trigger,
          grip: grip,
          button1: buttonAorX,
          button2: buttonBorY,
        };
      }
    };

    // Process inputs for both controllers
    const right = processControllerInput(rightController, "Right");
    const left = processControllerInput(leftController, "Left");

    setVRcontrols({ right: right, left: left });
  }

  const MovableBox = React.memo(function MovableBox({ x, y, z }) {
    return (
      <group position={[boxPos[0], boxPos[1], boxPos[2]]}>
        <group position={[-32, 27, -50]}>
          <mesh castShadow receiveShadow>
            <boxGeometry args={[30, 50, 10]} />
            <meshBasicMaterial color={boxReady ? "red" : "brown"} />
          </mesh>
        </group>
        {/* 
        <Texta
        position={[0,0,22]} // Position of the text
        rotation={[0,0,90]} // Rotation of the text
        fontSize={5} // Font size
        color="blue" // Font color
       
      >
        POS: {x}, {y}, {z}
      </Texta> */}
      </group>
    );
  });

  return (
    <Box
      border={4}
      sx={{
        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
        cursor: "pointer",
      }}
      height="100%"
      boxShadow={4}
    >
      {(vr && boxReady) && (
        <div className="absolute w-full bottom-0 z-10 text-center">
          In pos {pickupBox ? "Pickup" : "Dropoff"}
        </div>
      )}
      {vr && <VRButton />}
      {configured ? (
        <Canvas>
          {/* <VRSetup /> */}
          <Physics
          
          
          >
            <XR>

            
              <Controllers />
              <Hands />
              <MovementController />
              <MovementController
                hand="left"
                applyRotation={false}
                applyHorizontal={true}
              />
              <PerspectiveCamera makeDefault position={lookAt} />
              {vr && <Joystick />}
              <CameraController position={position} lookAt={lookAt} />
              <OrbitControls
                enableZoom={!lock}
                enablePan={!lock}
                enableRotate={!lock}
              />
              {/* <ambientLight/> */}
              {/* <axesHelper args={[1000]} /> */}

              <group rotation={[Math.PI / 2, Math.PI, 0]}>
                <pointLight position={[10, -40, 90]} intensity={1} castShadow />
                <Square
                  values={values}
                  opcData={opcData}
                  setCraneModelClicked={setCraneModelClicked}
                  debug={debug}
                  x={values?.Building_x}
                  y={values?.Building_y}
                  z={values?.Building_z}
                  obj={objFiles["Building"]}
                  objUrl={files["Building.obj"]}
                  mtlUrl={files["Building.mtl"]}
                  setTempMarker={setTempMarker}
                  tempMarker={tempMarker}
                  setNewMarkerPosition={setNewMarkerPosition}
                  setMarker={setMarker}
                />
                <CraneLift
                  setCraneModelClicked={setCraneModelClicked}
                  debug={debug}
                  setTrolleyCenter={setTrolleyCenter}
                  obj={objFiles["Trolley"]}
                  values={values}
                  objUrl={files["Trolley.obj"]}
                  mtlUrl={files["Trolley.mtl"]}
                  x={x}
                  y={y}
                  cargoWeight={cargoWeight}
                />

                <Wire
                  setCraneModelClicked={setCraneModelClicked}
                  debug={debug}
                  setWireCenter={setWireCenter}
                  setHookCenter={setHookCenter}
                  setTarget={setTarget}
                  files={files}
                  obj={objFiles["Hook"]}
                  values={values}
                  objUrl={files["Hook.obj"]}
                  mtlUrl={files["Hook.mtl"]}
                  x={x}
                  y={y - 4}
                  z={z}
                  cargoWeight={cargoWeight}
                  normal={normal}
                  constant={constant}
                  cargoProduct={cargoProduct}
                />
                <Crane
                  setCraneModelClicked={setCraneModelClicked}
                  debug={debug}
                  setBeemCenter={setBeemCenter}
                  obj={objFiles["Beem"]}
                  values={values}
                  objUrl={files["Beem.obj"]}
                  mtlUrl={files["Beem.mtl"]}
                  x={x}
                />
                {vr && <MovableBox x={boxPos[0]} y={boxPos[1]} z={boxPos[2]} />}
                {doesBeem1HaveTwoTrolleys && (
                  <group position={[values.Trolley_spacing, 0, 0]}>
                    <CraneLift
                      setCraneModelClicked={setCraneModelClicked}
                      obj={objFiles["Trolley"]?.clone()}
                      values={values}
                      objUrl={files["Trolley.obj"]}
                      mtlUrl={files["Trolley.mtl"]}
                      x={x}
                      y={y}
                      cargoWeight={cargoWeight}
                    />
                    <Wire
                      setCraneModelClicked={setCraneModelClicked}
                      setWireCenter={setWireCenter}
                      setTarget={setTarget}
                      files={files}
                      obj={objFiles["Hook"]?.clone()}
                      values={values}
                      objUrl={files["Hook.obj"]}
                      mtlUrl={files["Hook.mtl"]}
                      x={x}
                      y={y - 4}
                      z={z}
                      cargoWeight={cargoWeight}
                      normal={normal}
                      constant={constant}
                      cargoProduct={cargoProduct}
                    />
                  </group>
                )}
                {twobeems && (
                  <group position={[0, values.Beem_spacing, 0]}>
                    <Crane
                      setCraneModelClicked={setCraneModelClicked}
                      debug={debug}
                      obj={objFiles["Beem"]?.clone()}
                      values={values}
                      objUrl={files["Beem.obj"]}
                      mtlUrl={files["Beem.mtl"]}
                      x={x}
                    />
                    <CraneLift
                      setCraneModelClicked={setCraneModelClicked}
                      debug={debug}
                      obj={objFiles["Trolley"]?.clone()}
                      values={values}
                      objUrl={files["Trolley.obj"]}
                      mtlUrl={files["Trolley.mtl"]}
                      x={x}
                      y={y}
                      cargoWeight={cargoWeight}
                    />
                    <Wire
                      setCraneModelClicked={setCraneModelClicked}
                      debug={debug}
                      setWireCenter={setWireCenter}
                      setTarget={setTarget}
                      files={files}
                      obj={objFiles["Hook"]?.clone()}
                      values={values}
                      objUrl={files["Hook.obj"]}
                      mtlUrl={files["Hook.mtl"]}
                      x={x}
                      y={y - 4}
                      z={z}
                      cargoWeight={cargoWeight}
                      normal={normal}
                      constant={constant}
                      cargoProduct={cargoProduct}
                    />
                    {doesBeem2HaveTwoTrolleys && (
                      <group position={[values.Trolley_spacing, 0, 0]}>
                        <CraneLift
                          setCraneModelClicked={setCraneModelClicked}
                          debug={debug}
                          obj={objFiles["Trolley"]?.clone()}
                          values={values}
                          objUrl={files["Trolley.obj"]}
                          mtlUrl={files["Trolley.mtl"]}
                          x={x}
                          y={y}
                          cargoWeight={cargoWeight}
                        />
                        <Wire
                          setCraneModelClicked={setCraneModelClicked}
                          debug={debug}
                          setWireCenter={setWireCenter}
                          setTarget={setTarget}
                          files={files}
                          obj={objFiles["Hook"]?.clone()}
                          values={values}
                          objUrl={files["Hook.obj"]}
                          mtlUrl={files["Hook.mtl"]}
                          x={x}
                          y={y - 4}
                          z={z}
                          cargoWeight={cargoWeight}
                          normal={normal}
                          constant={constant}
                          cargoProduct={cargoProduct}
                        />
                      </group>
                    )}
                  </group>
                )}

                {/* <PulsatingTarget x={-24} y={11}/>
          {Object.keys(files).length > 0 && false && <ZoneBox Zone={Zone} createBlockedZone={createBlockedZone} tempZone={tempZone}/>}
          {setMarker && false && <>
          <CylinderBetweenPoints point1={[ translateWagonCordinates(9200) ,translateCordinates(15000), -70 ]} point2={[ translateWagonCordinates(1000), translateCordinates(15000), -70 ]}/>
          <CylinderBetweenPoints point1={[ translateWagonCordinates(9200) ,translateCordinates(5000), -70 ]} point2={[ translateWagonCordinates(1000), translateCordinates(5000), -70 ]} />
          
          <CylinderBetweenPoints point1={[ translateWagonCordinates(1000) ,translateCordinates(15000), -70 ]} point2={[ translateWagonCordinates(1000), translateCordinates(1000), -70 ]} />
          <CylinderBetweenPoints point1={[ translateWagonCordinates(9200) ,translateCordinates(15000), -70 ]} point2={[ translateWagonCordinates(9200), translateCordinates(1000), -70 ]} /></>} */}
                <CreateTempMarker
                  values={values}
                  opcData={opcData}
                  files={files}
                  tempMarker={tempMarker}
                  setSelectedMarker={setSelectedMarker}
                  selectedMarker={selectedMarker}
                />
                <CreateMarkers
                  values={values}
                  opcData={opcData}
                  files={files}
                  markerdata={markerdata}
                  setSelectedMarker={setSelectedMarker}
                  selectedMarker={selectedMarker}
                />
                {products?.length > 0 && (
                  <RenderProducts files={files} products={products} />
                )}
              </group>
            </XR>
          </Physics>
        </Canvas>
      ) : (
        <Box display="flex" justifyContent="center" mt={25} alignItems="center">
          <Stack display="flex" justifyContent="center" alignItems="center">
            <Typography fontSize="34px">
              Realtime 3D model not configured.
            </Typography>
            <Button
              variant="outlined"
              sx={{ maxWidth: "90px" }}
              onClick={() => navigate("configuration")}
            >
              Configure
            </Button>
          </Stack>
        </Box>
      )}
      {/* <PlaneAdjuster 
        normal={normal}
        constant={constant}
        onNormalChange={handleNormal Change}
        onConstantChange={handleConstantChange}
      /> */}
      {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {/* <Typography id="x-slider" gutterBottom>
          X: {position[0]}
        </Typography>
        <Slider
          aria-labelledby="x-slider"
          value={position[0]}
          min={-1000}
          max={1000}
          onChange={handleSliderChange(0)}
        />
        <Typography id="y-slider" gutterBottom>
          Y: {position[1]}
        </Typography>
        <Slider
          aria-labelledby="y-slider"
          value={position[1]}
          min={-1000}
          max={1000}
          onChange={handleSliderChange(1)}
        />
        <Typography id="z-slider" gutterBottom>
          Z: {position[2]}
        </Typography>
        <Slider
          aria-labelledby="z-slider"
          value={position[2]}
          min={-1000}
          max={1000}
          onChange={handleSliderChange(2)}
        /> 
        
      </Box> */}
    </Box>
  );
});

export default Scene2;

function reverseTranslateCordinates(y) {
  // const x1 = 15000;
  // const x2 = 1000;
  // const y1 = 66;
  // const y2 = -130;
  const x1 = -144;
  const x2 = 56;
  const y1 = 1000;
  const y2 = 15000;

  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * y + b;
}

function reverseTranslateWagonCordinates(y) {
  // const x1 = 9200;
  // const x2 = 1000;
  // const y1 = 50;
  // const y2 = -45;

  const x1 = -62;
  const x2 = 40;
  const y1 = 1000;
  const y2 = 9200;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * y + b;
}

function translateCordinates(value, realmin, realmax, modelmin, modelmax) {
  // Define the start and end of both ranges
  const x1 = realmax;
  const x2 = realmin;
  const y1 = modelmax;
  const y2 = modelmin;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  // console.log(m * value + b , realmax, realmin, modelmin,modelmax);
  return m * value + b;
}

function translateWagonCordinates(value) {
  // Define the start and end of both ranges
  const x1 = 9200;
  const x2 = 1000;
  const y1 = 40;
  const y2 = -62;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}
function translateWireCordinates(value, realmin, realmax, modelmin, modelmax) {
  // Define the start and end of both ranges
  const x1 = realmin;
  const x2 = realmax; //6780
  const y1 = modelmin;
  const y2 = modelmax;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}
function ReverseTranslateWireCordinates(
  value,
  realmin,
  realmax,
  modelmin,
  modelmax
) {
  // Define the start and end of both ranges
  // const x1 = realmin;
  // const x2 = realmax; //6780
  // const y1 = modelmin;
  // const y2 = modelmax;

  const y1 = realmin;
  const y2 = realmax; //6780
  const x1 = modelmin;
  const x2 = modelmax;

  // Calculate m and b
  const m = (y2 - y1) / (x2 - x1);
  const b = y1 - m * x1;

  // Return the translated value
  return m * value + b;
}

function Product(mtlUrl, objUrl, name) {
  // //console.log("Rendering 3D model for product: ", name);
  if (mtlUrl != undefined && mtlUrl != "") {
  } else {
    return <></>;
  }
  // //console.log("URL: ", mtlUrl);
  const mtlLoader = new MTLLoader();
  const mtl = mtlLoader.parse(mtlUrl);
  mtl.preload();

  // //console.log(data2);
  const objLoader = new OBJLoader();
  objLoader.setMaterials(mtl);
  const rawObjText = `${objUrl}`;
  const obj = objLoader.parse(rawObjText);
  // const obj = useLoader(OBJLoader, objUrl, loader => loader.setMaterials(mtl));

  return (
    <mesh position={[0, 0, 0]}>
      <primitive object={obj} />
    </mesh>
  );
}

const CreateMarkers = React.memo(function CreateMarkers({
  opcData,
  values,
  markerdata,
  setSelectedMarker,
  selectedMarker,
  files,
}) {
  // //console.log("Rendering markers");
  // const Short = Product(files["Short.mtl"], files["Short.obj"] , "Short");
  // const Long = Product(files["Long.mtl"], files["Long.obj"] , "Long");
  if (
    values?.Craneyposmin == undefined ||
    values?.Craneyposmax == undefined ||
    values?.Cranexposmin == undefined ||
    values?.Cranexposmax == undefined ||
    values?.Cranezposmin == undefined ||
    values?.Cranezposmax == undefined
  ) {
    return <></>;
  }

  return markerdata?.map((marker, index) => {
    return (
      <Marker
        files={files}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
        key={index}
        x={translateCordinates(
          marker.x,
          opcData?.xposlimitmin,
          opcData?.xposlimitmax,
          values.Cranexposmin,
          values.Cranexposmax
        )}
        y={translateCordinates(
          marker.y,
          opcData?.y1poslimitmin,
          opcData?.y1poslimitmax,
          values.Craneyposmin,
          values.Craneyposmax
        )}
        z={translateWireCordinates(
          marker.z,
          opcData?.z1poslimitmin,
          opcData?.z1poslimitmax,
          values.Cranezposmin,
          values.Cranezposmax
        )}
        text={marker.locationcode}
        id={marker.id}
        stack={marker.stack}
      />
    );
  });
});
const CreateTempMarker = React.memo(function CreateTempMarker({
  tempMarker,
  setSelectedMarker,
  selectedMarker,
  files,
}) {
  // //console.log("Rendering temp marker");
  return tempMarker?.map((marker, index) => {
    const [y, x, z] = marker;
    return (
      <Marker
        files={files}
        key={index}
        x={x}
        y={y}
        z={z}
        i={index}
        stack={[]}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
      />
    );
  });
});

const Marker = React.memo(function Marker({
  files,
  x,
  y,
  z,
  i,
  text,
  id,
  stack,
  setSelectedMarker,
  selectedMarker,
}) {
  const meshRef1 = useRef();
  // //console.log("Marker gets rendered");
  // //console.log("Marker: ", x,y,text);

  //console.log("Marker: ", x,y,z);

  const handlePointerDown = (event) => {
    // //console.log('Clicked on', event.point);
    setSelectedMarker([
      text,
      id,
      Math.round(reverseTranslateCordinates(x)),
      Math.round(reverseTranslateWagonCordinates(y)),
      stack,
    ]);
    // //console.log(stackAmount);
  };
  // const Long = () => Product(files["Long.mtl"], files["Long.obj"], "Long");
  // const Short = () => Product(files["Short.mtl"], files["Short.obj"], "Short");

  const stacks = [];

  let height = 0;

  // for (let i = 0; i < stack?.length; i++) {

  //   // //console.log(text, " ", height);
  //   stacks.push(
  //       <group key={i} position={[0,0, height]}>
  //          {stack[i] == 1 ? <group key={i} position={[0,0,0]}>
  //             <Long/>

  //           </group>

  //           :
  //           stack[i] == 2 ?
  //           <group key={i} position={[119,26,0]}>
  //            <Short/>

  //           </group>

  //           :
  //           <group key={i} position={[19,-300,-167]}>
  //             <mesh position={[0, 0, 0]}>
  //             <boxGeometry args={[30, 30, 5]} />
  //             <meshBasicMaterial color={'gray'} />
  //             </mesh>

  //           </group>

  //         }

  //       </group>

  //   );

  //   if(stack[i] == 1){
  //     height = height + 6;
  //   }
  //   else if(stack[i] == 2){
  //     height = height + 17;
  //   }
  //   else {
  //     height = height + 7;
  //   }

  // }

  // //console.log(text," ",stacks);
  return (
    <>
      <Text
        position={[y, x + 12, z + 1]} // Position of the text
        fontSize={5} // Font size
        color="black" // Font color
        onClick={handlePointerDown}
      >
        {text ? text : ""}
      </Text>
      <group
        position={[y, x, z + 1]}
        ref={meshRef1}
        onClick={handlePointerDown}
      >
        <mesh>
          <ringGeometry args={[0, 5, 62]} />
          <meshBasicMaterial
            color={
              selectedMarker && selectedMarker[1] === id ? "orange" : "red"
            }
          />
        </mesh>
        <group position={[-20, 300, 170]}>{stacks}</group>
      </group>
    </>
  );
});
