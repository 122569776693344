import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import {  useNavigate } from 'react-router-dom';

function compareVersions(version1, version2) {
    const parts1 = version1 !== null ? version1.split('.').map(Number) : "0.0.0";
    const parts2 = version2.split('.').map(Number);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        // Compare each part; if they are not equal, return the comparison result
        if ((parts1[i] || 0) > (parts2[i] || 0)) {
            return 1; // version1 is greater
        }
        if ((parts1[i] || 0) < (parts2[i] || 0)) {
            return -1; // version2 is greater
        }
        // If parts are equal, continue to the next part
    }

    return 0; // versions are equal
}

function NewVersionPopup({ open, setOpen, softwareVersion, setLastVersionSeen, lastVersionSeen }) {
  const navigate = useNavigate();


  
  const seenBefore = softwareVersion == lastVersionSeen;

  const handleClose = () => {
    setOpen(false);
    setLastVersionSeen(softwareVersion);
    localStorage.setItem('version', softwareVersion);
    
  };


  const parseTextWithLinks = (text) => {
    const linkRegex = /\{([^}]+)\}\(([^)]+)\)/g; // Matches {text}(path)
    const parts = text.split(linkRegex);
    return parts.map((part, index) => {
      if (index % 3 === 0) {
        return part;
      }
      if (index % 3 === 1) {
        // Text for link
        return (
          <span
            key={index}
            onClick={() => navigate(parts[index + 1])}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {part}
          </span>
        );
      }
      // Skip path parts
      return null;
    });
  };

const versionHistoryData = [
    {version: "0.8.0",
      changes: [
        `A first version of a simulator has been built in {NoSwing Academy}(/certificate). Control the crane from your keyboard or click on predefined positions on the Siemens HMI to move the crane. `,
        "3D models and other files now loads even faster.",
        "The 3D library models now comes with configuration files meaning minimal setup is required if selecting a prebuilt crane enviroment.",
      ],
    },
    {version: "0.7.0",
      changes: [
        `CC {user permissions}(/users) can now be restricted , for testing purposes a button to get all roles is available.`,
        "Non crane related documents can now be uploaded in the main {documents}(/documents) tab. All crane related documents can also be viewed and downloaded from here.",
      ],
    },
    {
      version: "0.6.0",
      changes: [
        `Made the {sidepanel}(/cranes/4), that displays daily data, scrollable to fit more data like important opc parameters and controls for the new Simulation Mode feature.`,
        "Simulation Mode, if the 3D environment is configured the cranes movement and order creation can be simulated. The simulation runs in the frontend which means it is always available and does not impact the database. Speed control is currently not accurate but will be improved in the future. WMS simulation in the frontend is coming later.",
        "Double-click the crane 3D environment to enter fullscreen mode, and double-click to exit.",
        "AntiSway Analyser V1, The AntiSway swing data can now be analysed and visualized by clicking on the AntiSway function. Data can be recorded to compare the difference the NS AntiSway Sensor does to the swinging. #NoSwing",
      ],
    },
    {
      version: "0.5.3",
      changes: [
        "Bug fixing.",
        "The configured 3D environment can now be saved and displayed.",
        "Optimized the 3D model system which means faster initial download and less performance demanding when the crane is moving in the environment.",
        "Fixed the language-switcher-glitch and added support for more languages.",
      ],
    },
    {
      version: "0.5.0",
      changes: [
        "Re-built the configurations page with better UI and more functionality.",
        "Massively simplified the process for setting up the crane-3D-environment.",
        "The crane structure (amount of beams or trolleys) can now be changed in the setup process.",
        "Added a library of 3D-models to choose from. (Currently only holds demo crane files but might be populated with common crane models in the future)",
      ],
    },
    {
      version: "0.4.0",
      changes: [
        "In the documents tab, you can now view, upload, and download files. OPC-data tag-names and types can also be extracted from XML files exported from TIA portal.",
        "Added a loading bar to the 3D crane monitor to visually display the downloading and rendering of the 3D files.",
        "Re-added buttons for creating pickup or leave orders with proper security-checks.",
        "A message and a button to configure the 3D crane monitor is now displayed on cranes that have not yet been configured.",
      ],
    },
    {
      version: "0.3.7",
      changes: [
        "Displaying a version log if new features or changes has been made since last time visited.",
        "The version log can also be opened from the navigation bar.",
      ],
    },
    {
      version: "0.3.6",
      changes: [
        "You can now choose how many items you want to move.",
        "Added a success message after placing an order successfully.",
        "Order buttons are now disabled after making an order until the orders are done to prevent spamming and overloading the system.",
        "Added error indicator on the Warehouse tab to indicate when something in the storage system is not as it should be.",
      ],
    },
  ];

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log("Close")}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-4xl sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Version History 
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 mb-5">
                          Here's what's new in NoSwing CC.
                        </p>
                        <div className='h-[600px] overflow-y-auto pr-10 '>
                        {versionHistoryData.map((version, index) => (
                        <div key={index} className="mt-4 pb-8 border-b">
                            <div className='flex flex-row gap-3 mb-4 '> 
                            <h4 className="font-bold text-gray-800items-center">Version {version.version}</h4>
                            {compareVersions(lastVersionSeen, version.version) < 0 && !seenBefore && <div className='bg-red-500 rounded-full px-2'  >
                                <p className='text-white'>NEW</p>
                            </div>}
                            </div>
                            <ul className="list-disc pl-5 text-black gap-5 flex flex-col">
                            {version.changes.map((change, changeIndex) => (
                                <li key={changeIndex}>
                                {parseTextWithLinks(change)}
                                </li>
                            ))}
                            </ul>
                        </div>
                        ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => handleClose()}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default NewVersionPopup;

