import React, { useState, useEffect } from 'react'
import { Box, Typography, CircularProgress, Badge } from '@mui/material';
import useFetch from '../../components/fetch';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import RealtimeTab from './TabPages/realtime';

import Components from './TabPages/componentList';
import Docs from '../documents';
import ServiceTask from './TabPages/servicetasks';
import PLCtags from './TabPages/plctags';
import Data from './TabPages/data';
import Orderslog from './TabPages/orderslog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getPartsWithCraneID, deleteCranePart } from '../../components/PartsApi';
import { deleteCrane } from '../../components/CraneApi';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import GetScreenSize from '../../components/screensizeTest';
import { useTranslation, Trans } from 'react-i18next';
import Documents from './TabPages/documents';
import useFetchV2 from '../../components/PartsApi';
import { get3DFile, getFileContent } from '../../components/FilesApi';
import { OBJLoader, MTLLoader } from 'three-stdlib';
import Warehouse from './TabPages/Warehouse';
import { styled } from '@mui/material/styles';

import { useLocation } from 'react-router-dom'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: -4,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{  }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function generateErrorMessages(data) {
  const errors = [];
  const locationCounts = {};
  const locationRowIndices = {};

  data.forEach(item => {
      const locationCode = item.cranelocation.locationcode;
      const rowIndex = item.rowindex;

      // Count items per location
      locationCounts[locationCode] = (locationCounts[locationCode] || 0) + 1;

      // Track row indices per location, except for "Scrap"
      if (locationCode !== "Scrap" && locationCode !== "Hook") {
          locationRowIndices[locationCode] = locationRowIndices[locationCode] || [];
          locationRowIndices[locationCode].push(rowIndex);
      }
  });

  // Check for "Multiple items in hook"
  if (locationCounts["Hook"] > 1) {
      errors.push("Multiple items in hook");
  }

  // Check for "Scrap pile is very big"
  if (locationCounts["Scrap"] > 100) {
      errors.push("Scrap pile is very big");
  }

  // Check for "Weird stack structure"
  for (const location in locationRowIndices) {
      const sortedRowIndices = locationRowIndices[location].sort((a, b) => a - b);
      for (let i = 0; i < sortedRowIndices.length - 1; i++) {
          if (sortedRowIndices[i] === sortedRowIndices[i + 1] || sortedRowIndices[i] + 1 < sortedRowIndices[i + 1]) {
              errors.push(`Weird stack structure at location ${location}`);
              break; // Stop checking further if one error is found for this location
          }
      }
  }

  return errors;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function populateStack(markerData, containerData) {
  // Make a deep copy of markerData to avoid mutating the original array
  const newMarkerData = JSON.parse(JSON.stringify(markerData));

  newMarkerData.forEach(marker => {
    // Initialize an empty stack array for each marker item
    marker.stack = [];

    // Filter containerData for matching cranelocation.id
    const matchingContainers = containerData.filter(container => container.cranelocation.id === marker.id);
    
    matchingContainers.forEach(container => {
      // Set the containercode at the position specified by rowindex (subtract 1 to convert to 0-based index)
      marker.stack[container.rowindex - 1] = container.product.productcode;
    });

    
  });

  return newMarkerData;
}
const CraneData = ({user , tier}) => {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [value, setValue] = React.useState(1);
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  let warehouseError = 0;
  const [obj, setObj] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const smallDevice = useMediaQuery('(min-width:1200px)')
  const { t, i18n } = useTranslation();
  const [modelsLoading, setModelsLoading] = useState(0);
  
  useEffect(() => {
    // Get the tab index from the URL (assuming it's something like "/yourpath?tab=1")
    const searchParams = new URLSearchParams(location.search);
    const tabIndex = parseInt(searchParams.get('tab'), 10);

    // Check if the tabIndex is a number and set the state
    if (!isNaN(tabIndex)) {
      setValue(tabIndex);
    }
  }, [location]);
  
  const { data: cranedata, loading: craneloading, error: craneerror } = useFetch(`https://noswingcc.net/noswingcc/api/cranes/${id}`);
  const { data, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranedocs?craneId.equals=${id}&folderName.equals=3D&page=0&size=20`);
  const { data: markerData, loading: markerDataLoading, error: markerDataError, refetch: refetchAllMarkers } = useFetchV2(`https://noswingcc.net/noswingcc/api/cranelocations?craneId.equals=${id}&inuse.equals=true&page=0&size=100`);
  const { data: containerData, loading: containerDataLoading, error: containerDataError, refetch: refetchAllContainers } = useFetchV2(`https://noswingcc.net/noswingcc/api/containers?id.greaterThan=9&page=0&size=1000`);
  const { data: zonedata, loading: zonedataIsLoading, error: zoneupdateError, refetch: refetchZoneData } = useFetchV2(`https://noswingcc.net/noswingcc/api/zones?page=0&size=20`);
  const { data: modelconf, loading: modelconfdataloading, error: modelconfdataerror } = useFetchV2(`https://noswingcc.net/noswingcc/api/modelconfs?craneId.equals=${id}&page=0&size=1`);
  const { data: useraccessdata, loading: useraccessloading, error: useraccesserror , refetch: refetchuseraccessdata} = useFetchV2(`https://noswingcc.net/noswingcc/api/craneusers?user_login.equals=${user}&craneId.equals=${id}&page=0&size=20`);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [Zone, setZone] = useState([0,0,0,0,0,0, false]);
  const [tempZone, setTempZone] = useState(Zone);
  const [objFiles, setObjFiles] = useState([]);
  const [containerInHook, setContainerInHook] = useState([]);
  const [values, setValues] = useState(1);

  React.useEffect(() => {
    
    
    if(!modelconfdataloading && modelconf.length > 0){

      const configObject = Object.keys(modelconf[0]).reduce((obj, key) => {
        // Exclude 'id' and 'crane' from the new object
        if (key !== "id" && key !== "crane") {
            // Capitalize the first letter of each key and replace it in the new object
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            obj[capitalizedKey] = modelconf[0][key];
        }
        return obj;
    }, {});
      //console.log(configObject);

      let updatedValues = { ...values };

      updatedValues = {
        ...updatedValues,
        ...configObject
        
      };

    setValues(updatedValues);
    }
    
  }, [modelconf, modelconfdataloading])
 
  useEffect(() => {
    if(!zonedataIsLoading){

      if(zonedata.length > 0){
        const data = zonedata[0];
        setZone([data.x,data.y,data.z,data.length,data.width,data.height, data.forbiddenzone]);
        setTempZone([data.x,data.y,data.z,data.length,data.width,data.height, data.forbiddenzone]);
      }
     
      
    }
  }, [zonedata])
  
  function preRender(mtlUrl, objUrl) {
    if (mtlUrl != undefined && mtlUrl != "") {
    } else {
      return null;
    }
    //console.log("URL: ", mtlUrl);
    const mtlLoader = new MTLLoader();
    const mtl = mtlLoader.parse(mtlUrl);
    mtl.preload();

    //console.log(data2);
    const objLoader = new OBJLoader();
    objLoader.setMaterials(mtl);
    const rawObjText = `${objUrl}`;
    const obj = objLoader.parse(rawObjText);

    return obj;
  }

  async function createObjFiles(type, example, files) {
    const objFileName = `${type}.obj`;
    const mtlFileName = `${type}.mtl`;

    //console.log("create obj ",files);
  
    const objFileData = files[type + ".obj"];
    const mtlFileData = files[type + ".mtl"];

    // console.log("OBJ FILE DATA: ", objFileData);
    // console.log("MTL FILE DATA: ", mtlFileData);
  
    if (!objFileData || !mtlFileData) {
      console.error(`Files for ${type} not found.`);
      return null;
    }
  
    const objFile = objFileData;
    const mtlFile = mtlFileData;
  
    if (objFile && mtlFile) {
      const obj = preRender(mtlFile, objFile);
      if (!obj) {
        toast.error("Could not load 3D model!");
        return null;
      }
      
      //console.log(obj);
      return { [type]: obj }; // Return an object with dynamic key
    }
    return null;
  }
  
  React.useEffect(() => {
    async function fetchData() {
      const files = await fetchModelFiles();
  
      setFiles(files);
      //console.log("FILES:" , files);
      const objFileTypes = ["Hook", "Trolley", "Beem", "Building", "Grabber"];
      //console.log(data);
      const objFilesPromises = objFileTypes.map(type => createObjFiles(type, "example", files));
      const objFilesArray = await Promise.all(objFilesPromises);
  
      const objFiles = objFilesArray.reduce((acc, current) => {
        return { ...acc, ...current }; // Merge objects
      }, {});
      //console.log("obj",objFiles);
      setObjFiles(objFiles);
    }
  
    
    fetchData();
  }, [data, dataIsLoading]);

  let fullMarkerData = null; 
  let container = [];
  
  if(!markerDataLoading && !containerDataLoading){
    if(markerData.length > 0){
      fullMarkerData = populateStack(markerData, containerData);
      const errors = generateErrorMessages(containerData);
      warehouseError = errors;
      // const test = containerData.filter(item => item.cranelocation.locationcode == selectedMarker[0] && item.rowindex == 1).map(item => item.product.productcode)[0];
      // console.log(test);
      const containers = containerData.filter(container => container.cranelocation.id === 42 && container.id != 9);
      if(containers.length > 0){
        container = containers[0];
      }
      if(containers.length > 1){
        container = containers[0];
    
      }
      if(containers.length == 0){
        container = null;
        
      }
      }
    
  }
  
  if(craneloading){
    return(
      <Box display="flex" justifyContent="space-around" alignItems="center" height="800px">
        <CircularProgress size={50}/>
      </Box>
    )
  }
  
  async function fetchModelFiles() {
    // toast.info("Loading 3D model files...");
    let modelFiles = {};
    console.log("data : ", data);
    if (!dataIsLoading && data?.length > 0) {
      const entries = await Promise.all(
        data.map(async (item) => {
          try {
            //toast.info(`Loading ${item.fileName}...`);
            const fileData = await getFileContent(item.document.id);
            const text = await fileData.text(); // Await the text promise directly
            //console.log("TEXT: ", text);
  
            // Increment the model loading counter
            setModelsLoading((prev) => prev + 0.5);
  
            // Return a tuple/array with the file name and its content
            return [item.fileName, text];
          } catch (error) {
            console.error(`Error loading file ${item.fileName}:`, error);
            return [item.fileName, ""]; // Return empty string in case of error
          }
        })
      );
  
      // Convert the array of entries into an object
      modelFiles = Object.fromEntries(entries.filter(entry => entry)); // Filter out undefined entries, if any
    }
  
    console.log("Model Files: ", modelFiles);
    return modelFiles;
  }

  //console.log(files);
  
 
  const handleClick = () => {
    if(useraccessdata?.length > 0 && useraccessdata[0].configure === false){
      toast.info("You do not have access to this feature!");
    }
    navigate("configuration");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log(localStorage.getItem('user'));
  if (localStorage.getItem('user') === null) {
    console.log("Not logged in!");
  }
  
    if (craneerror) {
      return <div>Error: {craneerror.message}</div>;  // or some error component
    }
    // if(!craneloading) console.log(cranedata);
 
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  console.log("crane permissions: ", useraccessdata);

  return (
    <div className='w-full flex justify-center mt-5'>
    <div className='px-5 ml-24 w-[86rem]' >
    
    
    <div className=' mt-5 flex flex-col '>

    {/* {smallDevice && <Link to="/cranes" style={{ textDecoration: 'none', color: 'inherit' }}>
      <Box mt={2} display="flex" alignContent="center" gap={1} position="absolute" left="30px" zIndex={1} >
        <ArrowBackOutlinedIcon/>
        Back
      </Box>
    </Link>} */}

      <div className='mb-2  '>
        <Typography sx={{ fontSize: 36 , fontWeight: 'bold'}} color="000">
          {!craneloading ? cranedata.name : "..."}
          <Tooltip title="Configure">
            <SettingsIcon onClick={handleClick} sx={{ ml: "10px", color: theme.palette.grey[600], cursor: 'pointer' }}></SettingsIcon>
          </Tooltip>

          
        </Typography>
        
      </div>      
      
      
      <Box sx={{  width: { xs: '90%', sm: '100%', md: '1000px', lg: '1000px' } }}   >
      {/* <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth" >
      <Tab label={t("pages.craneitem.live")} {...a11yProps(0)} />
      {tier > 1 && ( warehouseError.length > 0 ? <Tooltip title="WMS not configured" ><Tab hidden label={<StyledBadge badgeContent={warehouseError.length} disabled={!cranedata?.wms_configured} color="error">Warehouse</StyledBadge>} {...a11yProps(1)}></Tab></Tooltip>
      : <Tooltip title="WMS not configured" >
        <Tab label={"Warehouse"} hidden disabled={!cranedata?.wms_configured} {...a11yProps(1)} /> </Tooltip>
      )}
      <Tab label={t("pages.craneitem.data")} {...a11yProps(2)} />
      <Tab label={t("pages.craneitem.orderlog")} {...a11yProps(3)} />
      <Tab label="Service" disabled {...a11yProps(3)} />
      <Tab label={t("pages.craneitem.opcdata")} {...a11yProps(4)} />
      <Tab label={t("pages.craneitem.parts list")} iconPosition="end" {...a11yProps(5)} />
      <Tab label={t("pages.craneitem.documents")} {...a11yProps(6)} />
      
      </Tabs> */}
      <TabsV2 t={t} tier={tier} setValue={setValue} value={value}/>
      </Box>
      <TabPanel value={value} index={1}>
       <RealtimeTab tier={tier} objFiles={objFiles} values={values} modelconf={modelconf} modelconfdataloading={modelconfdataloading} modelsLoading={modelsLoading} totalAmountOfFiles={data?.length} containerInHook={container} tempZone={tempZone} refetchZones={refetchZoneData} Zone={Zone} obj={obj} containerData={containerData} fullMarkerData={fullMarkerData} refetchAllContainers={refetchAllContainers} opc={!craneloading && cranedata.opc} cranedata={!craneloading && cranedata} user={user} files={files} markerData={markerData} markerDataLoading={markerDataLoading} refetchAllMarkers={refetchAllMarkers}/>
      </TabPanel>
      {<TabPanel value={value} index={2} >
       <Warehouse id={id} fullMarkerData={fullMarkerData} warehouseError={warehouseError}/>
      </TabPanel>}
      <TabPanel value={value} index={3}>
       <Data id={id}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Orderslog id={id}/>
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
          <Box display="flex" justifyContent="space-between" mt="10px">
            <ServiceTask setSearchTerm={setSearchTerm} setTabOpen={setValue}/>
        </Box>
      </TabPanel> */}
      <TabPanel value={value} index={5}>
        <PLCtags opc={!craneloading && cranedata.opc}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Components inputSearchTerm={searchTerm} clearInputSearchTerm={setSearchTerm} id={id}/>
      </TabPanel>
      <TabPanel value={value} index={7} >
        <Documents id={id}/>
      </TabPanel>
      
    
    </div>
    </div>
    </div>
  );
};

export default CraneData;



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function TabsV2({t, tier, value , setValue}) {
  const tabs = [
    { name: t("pages.craneitem.live"), tab: 1, enabled: true },
    { name: "Warehouse", tab: 2, enabled: tier > 1 ? true : false},
    { name: t("pages.craneitem.data") , tab: 3, enabled: true },
    { name: t("pages.craneitem.orderlog"), tab: 4, enabled: tier > 1 ? true : false},
    { name: t("pages.craneitem.opcdata"), tab: 5, enabled: true },
    { name: t("pages.craneitem.parts list"), tab: 6, enabled: true },
    { name: t("pages.craneitem.documents"), tab: 7, enabled: true },
  ]
 
  return (
    <div className='w-full'>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          // defaultValue={tabs.find((tab) => tab.tab == value).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden w-full  sm:block">
        <nav className="flex space-x-10 w-full justify-start ml-5 " aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setValue(tab.tab)}
              className={classNames(
                value === tab.tab ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium', !tab.enabled ? 'hidden' : 'visible'
              )}
              
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  )
}