import { ReactSpreadsheetImport } from "react-spreadsheet-import";

import React ,{useState} from 'react'

const ExcelImportModal = ({isOpen, setIsOpen, handleSubmit}) => {
    

  function onClose () {
    console.log("Closing");
    setIsOpen(false);
  }

  
  return (
    <ReactSpreadsheetImport isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} fields={fields} />
  )
}

export default ExcelImportModal




const fields = [
    // {
    //   // Visible in table header and when matching columns.
    //   label: "Name",
    //   // This is the key used for this field when we call onSubmit.
    //   key: "name",
    //   // Allows for better automatic column matching. Optional.
    //   alternateMatches: ["first name", "first"],
    //   // Used when editing and validating information.
    //   fieldType: {
    //     // There are 3 types - "input" / "checkbox" / "select".
    //     type: "input",
    //   },
    //   // Used in the first step to provide an example of what data is expected in this field. Optional.
    //   example: "Stephanie",
    //   // Can have multiple validations that are visible in Validation Step table.
    //   validations: [
    //     {
    //       // Can be "required" / "unique" / "regex"
    //       rule: "required",
    //       errorMessage: "Name is required",
    //       // There can be "info" / "warning" / "error" levels. Optional. Default "error".
    //       level: "error",
    //     },
    //   ],
    // },
    
    {
     
      label: "Component Name",
      key: "componentname",
      alternateMatches: ["part", "component", "osa"],
      fieldType: {
        type: "input",
      },
      example: "PLC",
      validations: [
        {
          rule: "required",
          errorMessage: "Componentname is required",
          level: "error",
        },
      ],
    },
    {
     
      label: "Part Number",
      key: "partnumber",
      alternateMatches: ["nro", "part nbr"],
      fieldType: {
        type: "input",
      },
      example: "3300819",
  
    },
  
    {
     
      label: "Type",
      key: "type",
      alternateMatches: ["Tyyppi", "Typ"],
      fieldType: {
        type: "input",
      },
      example: "A W250-84",
    },
    {
     
      label: "Manufacturer",
      key: "manufacturer",
      alternateMatches: ["Valmistaja"],
      fieldType: {
        type: "input",
      },
      example: "Stahl Crane systems",
      validations: [
        {
          rule: "required",
          errorMessage: "Componentname is required",
          level: "error",
        },
      ],
    },
    {
     
      label: "Note",
      key: "note",
      fieldType: {
        type: "input",
      },
      example: "Note"
    },
    {
     
      label: "Lifetime in hours",
      key: "lifetime",
      fieldType: {
        type: "input",
      },
      example: "10000000"
    },
    
  ] 