import axios from 'axios';
import React, { useState, useEffect } from 'react';

function Get(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    //console.log("Token: " + localStorage.getItem('token'));
    
    useEffect(() => {
      setLoading(true);
      setData(null);
      setError(null);
      
      if(url === ''){
        return;
      }
      axios.get(url, {
        headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
        },
        })
        .then(response => setData(response.data))
        .catch(setError)
        .finally(() => setLoading(false));
    }, [url]);
  
    return { data, loading, error };
  }

  export default Get;
