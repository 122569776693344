import axios from 'axios';
import React, {useState, useEffect} from 'react';



export async function getAllCranes() {
    try {
      const response = await axios.get('https://noswingcc.net/noswingcc/api/parts?page=0&size=1000', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': '*/*'
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('An error occurred while trying to get all parts:', error);
      return null;
    }
}

export async function deleteCrane(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/cranes/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete crane:', error.response.data);
    return false;
  }
}
export async function deleteCraneLog(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/cranelogs/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete crane log:', error.response.data);
    return false;
  }
}
export async function postCrane(crane) {
  
  try {
    const response = await axios.post('https://noswingcc.net/noswingcc/api/cranes', crane, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response;
  } catch (error) {
    console.error('An error occurred while creating the crane:', error);
    return error.response.data;
  }                   
}

export async function postCranePart(part) {
  
  try {
    await axios.post('https://noswingcc.net/noswingcc/api/craneparts', part, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while creating the part:', error);
    return false;
  }
}

export async function getCranePos(id) {
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/opcs/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while trying to get opc data:', error);
    return null;
  }
}

export async function patchCrane(data) {
  
  try {
    await axios.patch('https://noswingcc.net/noswingcc/api/cranes/4', data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while patching crane:', error);
    return false;
  }
}
export async function updateCrane(id, data) {
  
  try {
    const response = await axios.put(`https://noswingcc.net/noswingcc/api/cranes/${id}`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while updating crane:', error);
    return false;
  }
}
export async function getCrane(id) {
  
  try {
    const response = await axios.get(`https://noswingcc.net/noswingcc/api/cranes/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return response.data;
  } catch (error) {
    console.error('An error occurred while patching crane:', error);
    return false;
  }
}

export async function updateZone(data) {
  
  try {
    await axios.put('https://noswingcc.net/noswingcc/api/zones/1', data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while updating zone:', error);
    return false;
  }
}


export async function getZone(data) {
  
  try {
    await axios.get('https://noswingcc.net/noswingcc/api/zones?id.equals=1&page=0&size=20', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while getting zone:', error);
    return false;
  }
}


export async function deleteZone(id) {
  try {
    const response = await axios.delete(`https://noswingcc.net/noswingcc/api/zones/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': '*/*'
      },
    });

    return true;
  } catch (error) {
    console.error('An error occurred while trying to delete zone:', error.response.data);
    return false;
  }
}