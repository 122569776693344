import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Divider, Stack, Tabs, Tab, Paper, List, ListItem, ListItemText, IconButton, Rating } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { useParams } from 'react-router-dom';
import useFetchV2 from '../../components/PartsApi';
import { getContainerCount } from '../../components/OrderApi';

const attributesOptions = ['length', 'thickness', 'width', 'height'];

const product = {
    name: 'Steel Plate A36',
    reference: 'OP12345',
    barcode: '9876543210',
    salesPrice: '$100.99',
    cost: '$67.99',
    category: 'Steel Materials',
};

function Productitem() {

    const { id } = useParams();
    const { data: product, loading: dataIsLoading, error: dateError, refetch: refetchAllData } = useFetchV2(`https://noswingcc.net/noswingcc/api/products/${id}`);
    const [amount, setAmount] = useState(0);

    
    React.useEffect(() => {
        const getAmount = async () => {
            return getContainerCount(id).then(response => {
                setAmount(response);
                return response;
              }).catch(error => {
                console.error(error);
                throw error; // Or return some error indication
              });
        }
        getAmount();

    }, [])
    
    

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    return (
        <Box display="flex" justifyContent="center">
            <Box p={4} boxShadow={1} bgcolor="white" borderRadius={1} width="1200px" mt={5}>
                <Box display="flex" justifyContent="space-between">
                    <Stack direction="column" spacing={3}>
                        <Stack>
                            <Typography fontSize="13px" fontWeight={600}>Product Name</Typography>
                            <Stack direction="row" >
                                <Typography variant="h4" mt={1}>{product?.name}</Typography>
                                
                                <Rating sx={{mt: 1.5 , ml: 1}} name="star" size="large" defaultValue={0} max={1} />
                            </Stack>
                        </Stack>

                        <Stack direction="row" spacing="15px">
                        <Stack direction="row" spacing="3px" sx={{cursor: "pointer"}}>
                            <Inventory2OutlinedIcon/>
                            <Typography>{amount} Units</Typography>
                        </Stack>
                        <Stack direction="row" spacing="3px" sx={{cursor: "pointer"}}>
                            <AutorenewOutlinedIcon/>
                            <Typography>0 Rules</Typography>
                        </Stack>

                    </Stack>
                    </Stack>
                    
                    <Box>
                        <Box position="absolute" mt={7.5} ml={0.5} bgcolor="white" borderRadius={5}>
                            <IconButton><EditOutlinedIcon/> </IconButton>
                        </Box>
                        <img src="https://sudbury.legendboats.com/resource/defaultProductImage" alt={product?.name} style={{ width: '100px', height: '100px', marginRight: '20px' }} />
                    </Box>
                </Box>

                

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={5}>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        textTransform="none"
                        sx={{textTransform: 'none'}}
                    >
                        <Tab label="General Information" />
                        <Tab label="Attributes and Variants" />
                        <Tab label="Inventory" />
                    </Tabs>
                </Box>
                    {/* <Divider sx={{ my: 2 }} /> */}
                {tabValue === 0 && (
                    <Box mt={2} display="flex" gap={4} height={340}>
                        <Box flex={1} mt={2}>
                            <Typography variant="h5">Product Type</Typography>
                            {/* You can add more fields in this column if needed */}
                        </Box>
                        <Box flex={1}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Sales Price" secondary={product?.salesPrice} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Cost" secondary={product?.cost} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Product Category" secondary={product?.category} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Internal Reference" secondary={product?.reference} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Barcode" secondary={product?.barcode} />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                )}
                {tabValue === 1 && (
                    
                    <Box mt={2} display="flex" gap={4} height={340}>
                    <Box flex={1} mt={2}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: "#ebeced" }}>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Attribute</Typography>
                                </th>
                                <th style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px', fontWeight: '600', textAlign: 'left' }}>
                                    <Typography ml={1}>Value</Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography>Size</Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography>{product?.length}x{product?.width}x{product?.height}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                                <td style={{ border: '0.5px solid #ebeced', padding: '0.5rem', height: '30px' }}>
                                    <Typography></Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>


                        
                    </Box>
                )}
                {tabValue === 2 && (
                    
                    <Box mt={2} display="flex" gap={4} height={340}>
                        <Box flex={1} mt={2}>
                            <Typography fontWeight="bold">Storage</Typography>
                            
                            <List>
                    
                                <ListItem>
                                    <ListItemText primary="Stored at" secondary="AC32" />
                                </ListItem>
                                
                                
                                
                            </List>
                        </Box>
                        <Box flex={1} mt={2}>
                            <Typography fontWeight="bold">Logistics</Typography>
                            
                            <List>
                    
                                <ListItem>
                                    <ListItemText primary="Weight" secondary={product?.weight} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Volume" secondary="-" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Customer Lead Time" secondary="-" />
                                </ListItem>
                                
                            </List>
                        </Box>
                    </Box>
                )}
                
            </Box>
        </Box>
    );
}

export default Productitem;

