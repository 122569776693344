import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getAllOrders } from '../../../components/OrderApi';
import { Box } from '@mui/material';

const Orderslog = ({id}) => {
  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const orders = await getAllOrders(id);
      setOrderData(orders);
    };
    console.log(orderData);
    fetchOrders();
  }, []);

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = dateObj.getFullYear();

    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const timeWithoutSeconds = `${hours}:${minutes}`;

    return ` ${day}.${month}.${year} [${timeWithoutSeconds}]`;
};

  const columns = [
    { field: 'id', headerName: 'Order Nbr', width: 100 },
    { 
      field: 'timestamp', 
      headerName: 'Timestamp', 
      width: 180,
      valueGetter: (params) => formatDate(params.row.timestamp)
    },
    { 
        field: 'ordertype', 
        headerName: 'Order Type', 
        width: 150, 
        valueGetter: (params) => getOrderType(params.row.ordertype)
      },
      { 
        field: 'orderstatus', 
        headerName: 'Order Status', 
        width: 150, 
        valueGetter: (params) => getOrderStatus(params.row.orderstatus)
      },
      
    // { field: 'orderpriority', headerName: 'Order Priority', width: 140 },
    // { 
    //   field: 'haswaypoint', 
    //   headerName: 'Has Waypoint', 
    //   width: 130,
    //   valueGetter: (params) => params.row.haswaypoint ? 'Yes' : 'No'
    // },
    // { 
    //   field: 'to_cranelocation_row', 
    //   headerName: 'To Crane Location Row', 
    //   width: 180 
    // },
    // { 
    //   field: 'from_cranelocation', 
    //   headerName: 'From Crane Location', 
    //   width: 180, 
    //   valueGetter: (params) => params.row.from_cranelocation?.locationcode
    // },
    { 
      field: 'to_cranelocation', 
      headerName: 'To Crane Location', 
      width: 180,
      valueGetter: (params) => params.row.to_cranelocation?.locationcode
    },
    { 
      field: 'user', 
      headerName: 'Created by', 
      width: 150,
      valueGetter: (params) => params.row.user.login
    },
    // { 
    //   field: 'productcode', 
    //   headerName: 'Product Code', 
    //   width: 130,
    //   valueGetter: (params) => params.row.product?.productcode
    // }
  ];

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        mt: 3,
        borderRadius: '10px',
        width: '75%',
        
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
        p: 2,
        mb: 2,
      }}
    >
        <Box  style={{ height: '600px', width: '100%' }}>
        <DataGrid 
            rows={orderData} 
            columns={columns} 
            pageSize={5}
            sortModel={[
                {
                  field: 'id',
                  sort: 'desc',
                },
            ]}
        />
        </Box>
    </Box>
  );
}

export default Orderslog;

function getOrderType(orderTypeNumber) {
    switch (orderTypeNumber) {
      case 1:
        return 'Pick Up';
      case 2:
        return 'Leave Cargo';
      case 3:
        return 'Move';
      case 4:
        return 'Waypoint';
      case 5:
        return 'Other';
      default:
        return 'Unknown Order Type';
    }
  }
  function getOrderStatus(statusNumber) {
    switch (statusNumber) {
      case 0:
        return 'Cancelled';
      case 1:
        return 'In Queue';
      case 2:
        return 'Received';
      case 3:
        return 'In Progress';
      case 4:
        return 'Arrived';
      case 5:
        return 'Complete';
      default:
        return 'Unknown Status';
    }
  }